import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import Config from '../../config';
export const equityInvestmentLetter  = (transaction: any, securities: any, transType: any) => {

    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q="qrcodeEquityInvestment";
    let id=`${Config.q_r_url}/admin/verify-transaction/`+transaction.txn_id; 
    let txnId = transaction.txn_id; 
    let branchName = '';
    let branchCity = '';
//     if(transaction.counter_branch != undefined){
//         branchName = transaction.counter_branch.split(',')[0];
//         branchCity = transaction.counter_branch.split(',')[1];
//         branchName = branchCity === undefined ? branchName+',' : branchName;
//         branchCity = branchCity === undefined ? '' : branchCity+',';
// }
if(transaction.counter_branch != undefined){
    if(transaction.counter_branch.includes('–')){
        branchName = transaction.counter_branch.split('–')[0];
        branchCity = transaction.counter_branch.split('–')[1];
        branchName = branchCity === undefined ? branchName.trim()+',' : branchName.trim()+',';
        branchCity = branchCity === undefined ? '' : branchCity.trim()+'.';
    }else{
        branchName = transaction.counter_branch+',';
    }
}
    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 50,
             height : 50
         });
           qrcode.makeCode(id);
     }
    
    } , 500)

    const numberWithCommas = (amount: any) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
 
    const getData = (items)=>(
        items.map((data,i)=>(`
               <tr key={i}>
                <td style="border: 1px solid #333">${data && data.type}</td>
                <td style="border: 1px solid #333">${data && data.market}</td>
                <td style="border: 1px solid #333">${data && data.symbol}</td>
                <td style="border: 1px solid #333">${data && data.quantity}</td> 
                <td style="border: 1px solid #333">${data && data.avg_rate}</td>
                <td style="border: 1px solid #333">${data && data.gross_amount}</td>
                <td style="border: 1px solid #333">${data && data.charges}</td>
                <td style="border: 1px solid #333">${data && data.net_amount}</td> 
                </tr>`
        ))
    )

    return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Chief Manager <br /> ${transaction.counter_bank} <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <div> Sub: <b style="border-bottom:1px solid black"> Equity Investment ${transaction.fund_account} ${transaction.bank}.</b></div>
            <br />
            <div>Kindly execute the transaction as per below instruction.</div>    
            
            <table style="width: 100%;">

            <tr>
                <th style="border: 1px solid #333; width: 10%"> Type  </th>
                <th style="border: 1px solid #333; width: 15%"> MKT   </th>
                <th style="border: 1px solid #333; width: 15%"> Symbol    </th>
                <th style="border: 1px solid #333; width: 15%"> QTY   </th>
                <th style="border: 1px solid #333; width: 10%"> AVG  </th>
                <th style="border: 1px solid #333; width: 15%"> Gross AMT   </th>
                <th style="border: 1px solid #333; width: 20%"> Charges   </th>
                <th style="border: 1px solid #333; width: 20%"> Net AMT   </th>
            </tr>

         

            ${getData(securities)}
            </table>
            
            <br />
            <table>
                <tr> 
                    <td>
                    You are requested to kindly transfer coupon maturity amount to our account number <b> ${transaction.account_number} </b>. </td>
                    </tr>
                    
                    <tr> <td> Title <b>${transaction.fund_account}</b> maintained with <b> ${transaction.counter_bank} ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch} </b>  </td> </tr>
            </table>
            <br />
            <div>
                This is to inform you that deduction of withholding tax is not applied on the profit of above transaction, as mutual funds are exempted from withholding tax as per Clause (47B)
                of Part IV of Second Schedule of Income Tax Ordinance 2001
            </div> 
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `
}
