import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { activityMoneyMarketTransactionReport } from '../../stores/services/report.service';
import { txnDropDown, getReuiredFieldForDropDown } from './fields';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isNumber, numberWithCommas, sortAMCS } from '../../utils/customFunction';
import { getDefaultLibFilePath } from 'typescript';

const ActivityMoneyMarketReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [moneyMarketData, setMoneyMarketData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [amc_code, setAmcCode] = useState('');
  const [fund, setFund] = useState('');
  const [fund_code, setFundCode] = useState('');
  const [type, setType] = useState('');
  const [security, setSecurity] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  let [allDropdownColumns, setAllDropdownColumns] = useState<any>([]);
  let [selectedFields, setSelectedFields] = useState<any>([]);
  let [headerColumns, setHeaderCoumns] = useState<any>([]);

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resSecurity, setResSecurity] = useState('');
  const [resType, setResType] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [errorShow, setErrorShow] = useState(false);





  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }

      let fieldsData = await getReuiredFieldForDropDown('moneymarketdetailreport');
      setHeaderCoumns(fieldsData);

    }
    getAllAMC();
  }, [])

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }

    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      if (amc_code == '' || fund_code == '' || type === '' || security === '') {
        setErrorShow(true);

      } else {
        setLoading(true);
        const response = await activityMoneyMarketTransactionReport(email, amc_code, fund_code, security, type, fromDate, toDate);
        let unique_heading: any;
        if (response.data.data.length === undefined) {
          toast.error('No record found.');
          setLoading(false);
        } else {
          let total_security_types = response.data.data.map((item: any) => {
            return item.name;
          })

          unique_heading = total_security_types.filter((value, index) => total_security_types.indexOf(value) === index);
          let securityWiseData = unique_heading.map((head) => {
            let colseBalance: any = 0;
            let count = 0;
            let openingBalance: any = 0;
            return {
              HeadingName: head,
              data: response.data.data.filter((item, index) => {
                if (item.name === head) {
                  count = count + 1;
                  if (count === 1) {
                    colseBalance += parseFloat(item.opening_balance || '0');
                    openingBalance += parseFloat(item.opening_balance || '0');
                  }
                  if (item.type === 'Buy') {
                    colseBalance += parseFloat(item.total_face_value || '0');
                  } else if (item.type === 'Sell') {
                    colseBalance -= parseFloat(item.total_face_value || '0');
                  } else if (item.type === 'Maturity') {
                    colseBalance -= parseFloat(item.total_face_value || '0');
                  }
                  //  else {
                  //   colseBalance += parseFloat(item.total_face_value || '0');
                  // }
                  return item;
                }
              }),
              opening_balance: openingBalance,
              closing_balance: colseBalance
            }
          })
          setMoneyMarketData(securityWiseData);
          setResAmc(Amc);
          setResFund(fund);
          setResSecurity(security);
          setResType(type);
          setResFromDate(fromDate);
          setResToDate(toDate);

          setLoading(false);

        }

      }
    } catch (error: any) {
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.response.data.message)
      }
      setLoading(false);
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Activity Money Market Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className={"form-control " + ErrorBorder(Amc)}
                            onChange={(e) => {
                              setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text);
                              setAmcCode(e.target.value);
                              getFunds(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className={"form-control " + ErrorBorder(fund_code)}
                            onChange={(e) => {
                              setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              setFundCode(e.target.value);
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Secuity</label>
                          <select
                            className={'form-control ' + ErrorBorder(security)}
                            onChange={(e) => {
                              setSecurity(e.target.value);
                            }}
                            value={security}>
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Security</option>
                            <optgroup>Money Market</optgroup>
                            <option value='PIB'>Pakistan Investment Bonds</option>
                            <option value='TBILL'>Treasury Bills</option>

                            <optgroup>Debt Market</optgroup>
                            <option value='SUKUK'>Sukuk Certificates</option>
                            <option value='IJARA'>GoP Ijara Sukuks</option>
                            <option value='TFC'>Term Finance Certificates</option>
                            <option value='CP'>Commercial Papers</option>

                            <optgroup>Term Deposits</optgroup>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Letter of placement</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Type</label>
                          <select className={"form-control w-100 " + ErrorBorder(type)}
                            onChange={(e) => {
                              setMoneyMarketData([]);
                              setType(e.target.value);
                            }}
                            value={type}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            <option value="All">All</option>
                            <option value="Buy">Buy</option>
                            <option value="Sell">Sell</option>
                            <option value="Maturity">Maturity</option>
                            <option value="Coupon">Coupon</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} landscape={true} fileName={"Money-Market-Transaction-Report-" + date + ".pdf"} ref={pdfExportComponent}>
                        {moneyMarketData.length > 0 ? (
                          <>
                            <ReportHeader title="Activity Money Market Report" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Money Market Transaction Report</h3>
                  </div> */}
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Security:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resSecurity}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Type:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resType}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  From Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  To Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap" >{resFund}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Security:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resSecurity}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Type:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resType}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                            </Row> */}

                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                    <th style={{ width: '15%' }}>Transaction Date</th>
                                    <th style={{ width: '20%' }}>Security Type</th>
                                    <th style={{ width: '15%' }}>Issue Date</th>
                                    <th style={{ width: '15%' }}>Maturity Date</th>
                                    <th style={{ width: '15%' }}>Type</th>
                                    <th className='text-right' style={{ width: '20%' }}>Balanace</th>
                                  </tr>
                                </thead>
                              </table>
                              {moneyMarketData.map((security: any, index: number) => {
                                return (
                                  <>
                                    <div className="float-left" style={{ fontSize: "10px", fontWeight: "bold" }}>{security.HeadingName}</div>
                                    <div className="float-right" style={{ fontSize: "11px" }}><b>Opening Balance</b> : {numberWithCommas(parseFloat(isNumber(security.opening_balance)).toFixed(2))}</div>
                                    <table className="report" style={{ color: 'black' }}>



                                      <tbody className="report-table-body">
                                        {security.data.map((transaction: any, index: number) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td style={{ width: '15%' }}>{transaction.created_date}</td>
                                                <td style={{ width: '20%' }}>{transaction.name}</td>
                                                <td style={{ width: '15%' }}>{transaction.issue_date}</td>
                                                <td style={{ width: '15%' }}>{transaction.maturity_date}</td>
                                                <td style={{ width: '15%' }}>{transaction.type}</td>
                                                <td className='text-right' style={{ width: '20%' }}>{numberWithCommas(parseFloat(transaction.total_face_value).toFixed(2))}</td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                      <tfoot>
                                        <tr key={index}>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className='text-right' style={{ fontSize: "11px" }}><b>Closing Balance</b>:{numberWithCommas(parseFloat(security.closing_balance).toFixed(2))}</td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                    {/* <div className="float-right" style={{fontSize:"11px"}}><b>Opening Balance</b> : {"0.00"}</div> */}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActivityMoneyMarketReport;
