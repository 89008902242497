import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllStaff, searchAPI, updateStaffStatus } from '../../stores/services/staff.service';

const StaffListing = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [allStaffData, setAllStaffData] = useState<any>([]);

  const [isSearchActive, setSearchActive] = useState(false);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');
  const [currentStatus, setCurrentStatus] = useState('');
  const [staffCode, setStaffCode] = useState('');
  const [statusSetPopup, setStatusSetPopup] = useState(false);

  const getAllStaffRecord = async () => {
    setLoading(true);
    try {
      const response = await getAllStaff(email, activePage, pageSize);
      if (response.data.status === 200) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.nextPage
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
        ) {
          pageArr.push(response.data.page_info.nextPage);
        }

        setPaginationArr(pageArr);
        setAllStaffData(response.data.data);
        setLoading(false);
      }else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllStaffRecord();
  }, [activePage]);


  const [searchValue, setSearchValue] = useState('');
  const [staffCodeError, setStaffCodeError] = useState('');
  const validate = () => {
    let staffCodeErr = '';
    searchValue.trim() === '' ? (staffCodeErr = 'Required') : (staffCodeErr = '');

    if (staffCodeErr) {
      setStaffCodeError(staffCodeErr);
      return false;
    } else {
      return true;
    }
  };

  const searchStaffData = async () => {
    let isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await searchAPI(email, searchValue);
        setAllStaffData(response.data.data || []);
        setSearchActive(true);
        setPageSize('10');
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
  };


  const renderData = () => {
    return allStaffData.map((item: any, index: any) => {
      return (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.father_name}</td>
          <td>{item.email}</td>
          <td>{item.mobile}</td>
          <td>{item.phone}</td>
          <td>{item.designation}</td>
          <td>{item.cnic}</td>
          <td>{item.status}</td>
          <td className="pointer">
            {item.status !== 'active' ?
              <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                onClick={() => {
                  setStatusSetPopup(true);
                  setCurrentStatus(item.status);
                  setStaffCode(item.staff_code);
                }}>
                <i className="tim-icons icon-simple-remove">
                </i></button>
              :
              <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                onClick={() => {
                  setStatusSetPopup(true);
                  setCurrentStatus(item.status);
                  setStaffCode(item.staff_code);
                }}>
                <i className="fas fa-check"></i>
              </button>}
            <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
              sessionStorage.setItem('staffOBJ', JSON.stringify(item));
              history.replace('/admin/view-staff-management')
            }}>
              <i className="fa fa-eye">  </i>
            </button>
            <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
              sessionStorage.setItem('staffOBJ', JSON.stringify(item));
              history.replace(`/admin/edit-staff-management`);
            }}>
              <i className="tim-icons icon-pencil"> </i>
            </button>
          </td>
        </tr>
      );
    })
  }

  const [statusLoading, setStatusLoading] = useState(false)
  const updateStatus = async () => {
    setStatusLoading(true);
    let status = '';
    if (currentStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateStaffStatus(email, staffCode, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getAllStaffRecord();
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setStatusLoading(false);
  };

  const renderComponents = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            enforceFocus={false}
            show={true}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => { setStatusSetPopup(false) }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentStatus === 'active' ? <img src="assets/ban.svg" alt="" width="70" /> : <img src="assets/check.svg" alt="" width="70" />}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to {currentStatus === 'active' ? 'Deactivate' : 'Activate'} this Staff Member?
                  </div>
                </Row>
                <Row className="mt-3">
                  <Col >
                    <div className="">
                      <button className="btn btn-primary" disabled={Boolean(statusLoading)} onClick={() => { updateStatus() }}>
                        {statusLoading ? <><span className="fa fa-spinner fa-spin p-3 " role="status" aria-hidden="true"></span>
                          {/* <span className="login-txt"> Loading...</span> */}
                        </> : 'Yes'}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal >
        )
      default:
        return '';
    }
  };



  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {!Loading ? (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Staff Management
                      <button className="btn btn-primary btn-sm float-right" onClick={() => {
                        history.replace('/admin/add-staff-management');
                      }}>
                        <i className="fa fa-plus mr-2"></i>  Add New
                      </button></h4>
                  </div>
                  {/* {!Loading ? ( */}
                  <div className="card-body">
                    <div className="input-holder">
                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                            <input type="search" id="myInput" placeholder="Enter Name" className={`form-control  w-100 ${staffCodeError ? 'required-border' : ''
                              }`} data-tip="Enter Staff Code" value={searchValue} onChange={(e: any) => {
                                setSearchValue(e.target.value);
                                setStaffCodeError('')
                                // myFunction(e);	
                              }} />
                          </div>
                        </Col>
                        <Col md="3">
                          <button className="btn btn-primary mt-a" onClick={searchStaffData}>Search </button>
                        </Col>
                        <Col md="3">
                          <button className="btn btn-secondary mt-a" onClick={() => {
                            setStaffCodeError('')
                            setSearchValue('');
                            setActivePage('');
                            getAllStaffRecord()
                          }}>Clear</button>
                        </Col>
                      </Row>
                    </div>
                    <div className="table-responsive">
                      <table className="table" id="myTable" >
                        <thead>
                          <th>Name</th>
                          <th>Father Name</th>
                          <th>Email</th>
                          <th>Mobile No</th>
                          <th>Mobile No</th>
                          <th>Designation</th>
                          <th>CNIC</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </thead>
                        <tbody id="myUL">{renderData()}</tbody>
                      </table>
                      {!isSearchActive &&
                        <div
                          className="row pr-3 pointer"
                          style={{
                            float: 'right',
                          }}
                        >
                          {/* PREVIOUS BUTTON */}
                          {activePage !== paginationArr[0] ? (
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex((x) => x === activePage);
                                if (index !== -1) {
                                  setActivePage(paginationArr[index - 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ''
                          )}
                          {/* NEXT BUTTON */}
                          {activePage !== paginationArr[paginationArr.length - 1] ? (
                            <i
                              className="fa ml-3 fa-arrow-right"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex((x) => x === activePage);
                                if (index !== -1) {
                                  setActivePage(paginationArr[index + 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ''
                          )}
                        </div>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Container>
          </Col>
        </Row>
        {renderComponents()}
      </div>
    </>
  )
};

export default StaffListing;