import React from "react";
import { useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Container, Row, Col } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadTransaction } from "./../../stores/services/beta-transaction.service";
import { JsonToTable } from "react-json-to-table";
import * as fs from "fs";
import { Modal } from "react-bootstrap";
import xls from "xlsx";
import { getAmc } from "../../stores/services/amc.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import {
  getAccountByFund,
  getActiveAccountByFund,
} from "../../stores/services/account.service";
import moment from "moment";
import { isDateValid } from "../../utils/customFunction";
// import SaleOfUnit from './../../Uploader-Sample/Sale-of-unit.xlsx';

let csvToJson = require("convert-csv-to-json");
const csv = require("csvtojson/v2");

let xlsxToJson = require("convert-excel-to-json");
//const xlsx = require('xlsx-to-json');

const BulkUplaod = () => {
  const history = useHistory();
  let txnTypeCatagory = JSON.parse(
    sessionStorage.getItem("txnTypeCatagory") || ""
  );
  const [loading, setLoading] = useState(false);
  const [fileData, setfileData] = useState<any>([]);
  const [fileDataCount, setFileDataCount] = useState(0);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState("");
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const email = sessionStorage.getItem("email") || "";

  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [invalidTransactions, setInvalidTransactions] = useState<any>([]);
  const [invalidTransactionsCount, setInvalidTransactionsCount] = useState(0);
  const [totalFileData, setTotalFileData] = useState(0);

  const [errorInvalidTxnsBoolean, setErrorInvalidTxnsBoolean] =
    useState<boolean>(false);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [Amc, setAmc] = useState("");
  const [fund, setFund] = useState("");
  const [fundAccount, setFundAccount] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountno, setAccountNo] = useState("");
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [txnrType, setTxnrType] = useState("");

  const [errorData, setErrorData] = useState<any>([]);
  const [popusHeading, setPopupHeading] = useState<any>([]);
  const [errorShow, setErrorShow] = useState(false);
  const [invalidFormatData, setInvalidFormatData] = useState<any>([]);
  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  const [bankBalance, setBankBalance] = useState("");

  React.useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, "", "", "");
        setAllAmc(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
    };
    getAllAMC();
  }, []);

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace("AMC_", "")}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  };

  const getFundAccounts = async (fund_code) => {
    try {
      const accResponse = await getActiveAccountByFund(email, fund_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (error) {}
  };

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        // item.account_code
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  };

  const getFundAccountData = (accountno) => {
    allFundAccounts.map((item, index) => {
      if (item.account_no === accountno) {
        setFundAccount(item.account_code.replaceAll("ACCOUNT_", ""));
        setAccountTitle(item.account_title);
        setAccountNo(item.account_no);
        setBank(item.bank_name);
        setBranch(
          item.bank_city
            ? `${item.branch_name}  – ${item.bank_city}`
            : item.city
            ? `${item.branch_name}  – ${item.city}`
            : item.branch_name
        );
      }
    });
  };
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setLoading(false);
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setLoading(false);
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields['detail']}
                      className={
                        'form-control w-100 '
                      }
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setLoading(false);
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(loading)}
                        onClick={() => {
                          setLoading(false);
                          let temp = fileData;
                          temp[0].low_balance_agree = "Yes";
                          setfileData([...temp]);
                          UploadTxn("no");
                        }}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  // here to upload the transation bulk upload
  const UploadTxn = async (folioApproval) => {
    if (Amc === "" || fund === "" || fundAccount === "") {
      setErrorShow(true);
    } else if (fileData.length === 0 || fileName === "") {
      toast.error("Relevant transactions are not found.");
      return;
    } else {
      for (let i = 0; i < fileData.length; i++) {
        fileData[i]["AmcCode"] = Amc;
        fileData[i]["FundCode"] = fund;
        fileData[i]["FundAccount"] = fundAccount;
        fileData[i]["AccountTitle"] = accountTitle;
        fileData[i]["AccountNumber"] = accountno;
        fileData[i]["Bank"] = bank;
        fileData[i]["Branch"] = branch;
      }
      if (file) {
        setLoading(true);
        try {
          const res = await uploadTransaction(email, fileData, folioApproval);
          if (res.data.status === 200) {
          }
          if (res.data.message === "Transaction upload started") {
            setErrorInvalidTxnsBoolean(false);
            setFile("");
            setFileName("");
            setfileData([]);
            setFileDataCount(0);
            toast.success("Transaction upload completed");
            errorData.length = 0;
            invalidFormatData.length = 0;
            setInvalidFormatData(invalidFormatData);
            setErrorData(errorData);
            allFunds.length = 0;
            setAllFunds(allFunds);
            allFundAccounts.length = 0;
            setAllFundsAccounts(allFundAccounts);
            setfileData([]);
            setFileName("");
            setFileError("");
            setApprove(false);
            setErrorBooleanAddfolio(false);
            setAmc("");
            setFund("");
            setFundAccount("");
            setAccountTitle("");
            setAccountNo("");
            setBank("");
            setBranch("");
            setUnderStand(false);
            setViewBankBalanceModal(false);

            // errorInvalidTxnsBoolean=false;
          } else if (
            res.data.message == "Insufficient balance for transaction."
          ) {
            setBankBalance(res.data.data);
            setViewBankBalanceModal(true);
            toast.warning(res.data.message);
          } else {
            toast.error("Error! Transaction is not uploaded");
            setApprove(false);
            setErrorBooleanAddfolio(false);
          }
        } catch (error: any) {
          setLoading(false);

          if (error?.response?.data.status == 500) {
            toast.error(error.response.data.message);
          } else if (
            error?.response?.data.message ==
            "Insufficient balance for transaction."
          ) {
            setBankBalance(error.response.data.data);
            setViewBankBalanceModal(true);
            toast.warning(error.response.data.message);
          } else if (error?.response?.data.status == 400) {
            setErrorBoolean(true);
            setErrorInvalidTxnsBoolean(false);
            setPopupHeading(error.response.data.message);
            setErrorData(error.response.data.data);
            setApprove(false);
            setErrorBooleanAddfolio(false);
          } else if (error.response.status == 417) {
            setErrorBooleanAddfolio(true);
            setPopupHeading(error.response.data.message);
            setErrorData(error.response.data.data);
          } else {
            setApprove(false);
            setErrorBooleanAddfolio(false);
            toast.error(error.response.data.message);
          }
        }
        setLoading(false);
        setErrorInvalidTxnsBoolean(false);
      } else {
        toast.error("Kindly Upload File");
      }
    }
  };

  const upload = async (e: any) => {
    if (Amc === "" || fund === "" || fundAccount === "") {
      setErrorShow(true);
    } else {
      setFileError("");
      setfileData([]);
      invalidFormatData.length = 0;
      setInvalidFormatData(invalidFormatData);
      let file = e?.target.files[0];
      let filesize = parseInt(file.size);
      if (filesize <= 2097152) {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (type === "xlsx" || type === "csv") {
          setFile(file);
          setFileName(file.name);
          const reader = new FileReader();
          const rABS = !!reader.readAsBinaryString;
          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target?.result;
            const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */

            const data: any = xls.utils.sheet_to_json(ws, {
              header: 2,
              raw: false,
              defval: "",
              dateNF: "YYYY-MM-DD",
            });
            /* Update state */
            if (data.length > 0) {
              let flag = false;
              if (
                data[0]["TransactionType"].trim() === "saleofunit" ||
                data[0]["TransactionType"].trim() === "conversionin" ||
                data[0]["TransactionType"].trim() === "redemptionofunits" ||
                data[0]["TransactionType"].trim() === "conversionout" ||
                data[0]["TransactionType"].trim() === "brokagefee"
              ) {
                // if (data[0]['TransactionType'].trim() === 'saleofunit' || data[0]['TransactionType'].trim() === 'conversionin' || data[0]['TransactionType'].trim() === 'redemptionofunits' || data[0]['TransactionType'].trim() === 'conversionout') {
                flag = true;
              }
              for (var i = 0; i < data.length; i++) {
                if (
                  data[0]["TransactionType"].trim() !==
                  data[i]["TransactionType"].trim()
                ) {
                  toast.error(
                    `You can't upload multiple type of transaction in one uploader`
                  );
                  data.length = 0;
                  setfileData(data);
                  setFileDataCount(0);
                } else if (data[i]["TransactionType"].trim().length > 0) {
                  setTxnrType(data[0]["TransactionType"]);
                  data[i]["AmcCode"] = Amc;
                  data[i]["FundCode"] = fund;
                  data[i]["FundAccount"] = fundAccount;
                  data[i]["AccountTitle"] = accountTitle;
                  data[i]["AccountNumber"] = accountno;
                  data[i]["Bank"] = bank;
                  data[i]["Branch"] = branch;
                  //data[i]['InstructionDate'] = moment(data[i].InstructionDate).format('YYYY-MM-DD');
                  if (data[i]["InstructionDate"]) {
                    data[i]["InstructionDate"] = isDateValid(
                      moment(data[i].InstructionDate).format("YYYY-MM-DD")
                    ); //moment(data[i].InstructionDate).format('YYYY-MM-DD') === 'Invalid date' ? '' : moment(data[i].InstructionDate).format('YYYY-MM-DD');
                  }
                  if (data[i]["SaleDate"]) {
                    data[i]["SaleDate"] = isDateValid(
                      moment(data[i].SaleDate).format("YYYY-MM-DD")
                    );
                  }
                  if (data[i]["UnitsSold"] !== undefined) {
                    data[i]["UnitsSold"] = data[i]["UnitsSold"].replaceAll(
                      ",",
                      ""
                    );
                  }
                  if (data[i]["NetAmount"] !== undefined) {
                    data[i]["NetAmount"] = data[i]["NetAmount"].replaceAll(
                      ",",
                      ""
                    );
                  }
                  if (data[i]["GrossAmount"] !== undefined) {
                    data[i]["GrossAmount"] = data[i]["GrossAmount"].replaceAll(
                      ",",
                      ""
                    );
                  }
                  if (data[i]["RedemptionDate"] !== undefined) {
                    data[i]["RedemptionDate"] = isDateValid(
                      moment(data[i].RedemptionDate).format("YYYY-MM-DD")
                    );
                  }

                  if (
                    data[i]["TransactionType"].trim() === "saleofunit" ||
                    data[i]["TransactionType"].trim() === "conversionin"
                  ) {
                    if (
                      data[i]["SaleLoad"] &&
                      data[i]["SaleLoad"].trim() != "" &&
                      data[i]["SaleLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["SaleLoad"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "SaleLoad" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["ApplicablePrice"] &&
                      data[i]["ApplicablePrice"].trim() != "" &&
                      data[i]["ApplicablePrice"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["ApplicablePrice"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "ApplicablePrice",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["ApplicableUnitLoad"] &&
                      data[i]["ApplicableUnitLoad"].trim() != "" &&
                      data[i]["ApplicableUnitLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["ApplicableUnitLoad"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "ApplicableUnitLoad",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["DiscountonLoad"] &&
                      data[i]["DiscountonLoad"].trim() != "" &&
                      data[i]["DiscountonLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["DiscountonLoad"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "DiscountonLoad",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["SalePrice"] &&
                      data[i]["SalePrice"].trim() != "" &&
                      data[i]["SalePrice"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["SalePrice"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "SalePrice" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["NAV"] &&
                      data[i]["NAV"].trim() != "" &&
                      data[i]["NAV"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["NAV"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "NAV" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["UnitsSold"] &&
                      data[i]["UnitsSold"].trim() != "" &&
                      data[i]["UnitsSold"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["UnitsSold"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "UnitsSold" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["NetAmount"] &&
                      data[i]["NetAmount"].trim() != "" &&
                      data[i]["NetAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["NetAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "NetAmount" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["GrossAmount"] &&
                      data[i]["GrossAmount"].trim() != "" &&
                      data[i]["GrossAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["GrossAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "GrossAmount",
                      });
                      setInvalidFormatData([...temp]);
                    }
                  }

                  if (
                    data[i]["TransactionType"].trim() === "redemptionofunits" ||
                    data[i]["TransactionType"].trim() === "conversionout"
                  ) {
                    if (
                      data[i]["Zakat"] &&
                      data[i]["Zakat"].trim() != "" &&
                      data[i]["Zakat"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["Zakat"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "Zakat" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["CGT"] &&
                      data[i]["CGT"].trim() != "" &&
                      data[i]["CGT"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["CGT"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "CGT" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["RedemptionLoad"] &&
                      data[i]["RedemptionLoad"].trim() != "" &&
                      data[i]["RedemptionLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["RedemptionLoad"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "RedemptionLoad",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["ApplicablePrice"] &&
                      data[i]["ApplicablePrice"].trim() != "" &&
                      data[i]["ApplicablePrice"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["ApplicablePrice"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "ApplicablePrice",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["ApplicableUnitLoad"] &&
                      data[i]["ApplicableUnitLoad"].trim() != "" &&
                      data[i]["ApplicableUnitLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["ApplicableUnitLoad"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "ApplicableUnitLoad",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["DiscountonLoad"] &&
                      data[i]["DiscountonLoad"].trim() != "" &&
                      data[i]["DiscountonLoad"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["DiscountonLoad"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "DiscountonLoad",
                      });
                      setInvalidFormatData([...temp]);
                    }

                    if (
                      data[i]["SalePrice"] &&
                      data[i]["SalePrice"].trim() != "" &&
                      data[i]["SalePrice"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["SalePrice"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "SalePrice" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["NAV"] &&
                      data[i]["NAV"].trim() != "" &&
                      data[i]["NAV"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["NAV"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "NAV" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["UnitsSold"] &&
                      data[i]["UnitsSold"].trim() != "" &&
                      data[i]["UnitsSold"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["UnitsSold"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "UnitsSold" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["NetAmount"] &&
                      data[i]["NetAmount"].trim() != "" &&
                      data[i]["NetAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["NetAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "NetAmount" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["GrossAmount"] &&
                      data[i]["GrossAmount"].trim() != "" &&
                      data[i]["GrossAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["GrossAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "GrossAmount",
                      });
                      setInvalidFormatData([...temp]);
                    }
                  }

                  if (data[i]["TransactionType"].trim() === "brokagefee") {
                    if (
                      data[i]["NetAmount"] &&
                      data[i]["NetAmount"].trim() != "" &&
                      data[i]["NetAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["NetAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "NetAmount" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["GrossAmount"] &&
                      data[i]["GrossAmount"].trim() != "" &&
                      data[i]["GrossAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["GrossAmount"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "GrossAmount",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["SST/PST_%"] &&
                      data[i]["SST/PST_%"].trim() != "" &&
                      data[i]["SST/PST_%"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["SST/PST_%"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "SST/PST_%" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["SST_PSTAmount"] &&
                      data[i]["SST_PSTAmount"].trim() != "" &&
                      data[i]["SST_PSTAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["SST_PSTAmount"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "SST_PSTAmount",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["TotalCharges"] &&
                      data[i]["TotalCharges"].trim() != "" &&
                      data[i]["TotalCharges"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["TotalCharges"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "TotalCharges",
                      });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["WH_IT_%"] &&
                      data[i]["WH_IT_%"].trim() != "" &&
                      data[i]["WH_IT_%"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["WH_IT_%"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "WH_IT_%" });
                      setInvalidFormatData([...temp]);
                    }

                    if (
                      data[i]["TaxWHT"] &&
                      data[i]["TaxWHT"].trim() != "" &&
                      data[i]["TaxWHT"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["TaxWHT"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "TaxWHT" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["WH_ST_%"] &&
                      data[i]["WH_ST_%"].trim() != "" &&
                      data[i]["WH_ST_%"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(data[i]["WH_ST_%"])
                    ) {
                      let temp = invalidFormatData;
                      temp.push({ row_number: i + 1, field_name: "WH_ST_%" });
                      setInvalidFormatData([...temp]);
                    }
                    if (
                      data[i]["WH_SaleTaxAmount"] &&
                      data[i]["WH_SaleTaxAmount"].trim() != "" &&
                      data[i]["WH_SaleTaxAmount"].trim() != "" &&
                      !/^[+-]?([0-9]*[.])?[0-9]+$/.test(
                        data[i]["WH_SaleTaxAmount"]
                      )
                    ) {
                      let temp = invalidFormatData;
                      temp.push({
                        row_number: i + 1,
                        field_name: "WH_SaleTaxAmount",
                      });
                      setInvalidFormatData([...temp]);
                    }

                    // if (!data[i]["PaymentType"] || data[i]["PaymentType"].trim().toLowerCase() != "payment" ) {
                    //   toast.error("Payment Type must be Payment")
                    //   return
                    // }
                    data[i]["PaymentType"] = "Payment";
                  }

                  // if (data[i]['TransactionType'].trim() === 'debtmarketinvestment') {
                  //   let m = true
                  //   let n = i;
                  //   let q = i
                  //   let temp1: any = [];
                  //   while (m) {
                  //     if (data[n] !== undefined && (data[n]['TransactionType'] === "" || data[n] === undefined || n === i)) {

                  //       let issueDte: any = isDateValid(moment(data[n]["IssueDate"]).format('YYYY-MM-DD'));
                  //       let maturityDte: any = isDateValid(moment(data[n]["MaturityDate"]).format('YYYY-MM-DD'));
                  //       let secType: any = securitySymbol(data[n]["SecuritiesPlacement"].trim());
                  //       let secSymbol: any = "";
                  //       if (data[q]["Type"] !== 'Buy') {
                  //         secSymbol = getSymbolFromFund(data[n]);
                  //       } else {
                  //         secSymbol = data[n]["Security"].trim() === 'Other' ? createSymboleInCaseOfOther(data[n]) : getSecuritySymbol(data[n]["Security"].trim())
                  //       }
                  //       temp1.push({
                  //         security_type: secType, symbol: secSymbol.trim(), type: data[n]["Type"].trim(), name: data[n]["Security"] === 'Other' ? createSymboleInCaseOfOther(data[n]).trim() : data[n]["Security"].trim(), investment_type: data[n]["InvestmentType"], issue_date: issueDte, maturity_date: maturityDte, money_market_face_value: data[n]["MoneyMarketFaceValue"], primary_dealer: data[n]["PrimaryDealer"], last_coupon_date: isDateValid(moment(data[n]["LastCouponDate"]).format('YYYY-MM-DD')), next_coupon_date: isDateValid(moment(data[n]["NextCouponDate"]).format('YYYY-MM-DD')), coupon_payment: data[n]["CouponPaymentYear"], coupon_rate: data[n]["CouponRate"].replaceAll(',', ''), accrued_days: data[n]["AccruedDays"], detail: data[n]["Detail"], price: data[n]["Price"].replaceAll(',', ''), units: data[n]["Units"].replaceAll(',', ''), yield: data[n]["Yield"].replaceAll(',', ''), total_face_value: data[n]["TotalFaceValue"].replaceAll(',', ''), accrued_amount: data[n]["AccruedAmount"].replaceAll(',', ''), brokerage: data[n]["Brokerage"], principal_amount: data[n]["PrincipalAmount"].replaceAll(',', ''), unredeem_value: data[n]['UnRedeemValue'].replaceAll(',', ''), settlement_amount: data[n]["SettlementAmount"].replaceAll(',', ''), fund_ips_account: data[n]["FundIPSAccount"], counter_party_ips_account: data[n]["CounterPartyIPSAccount"], broker_name: data[n]["Broker"], days_of_maturity: data[n]["DaysToMaturity"], counter_party_name: data[n]["CounterPartyName"], premium_discount: data[n]["PremiumDiscount"]
                  //       });
                  //       if (data[n]["SecuritiesPlacement"].trim() === '' || data[n]["Type"].trim() === '' || secSymbol.includes('unmatchDate') || secSymbol.trim() === '' || issueDte.trim() === '' || maturityDte.trim() === '' || data[n]["Units"].trim() === '' || data[n]["PrincipalAmount"].trim() === '') {
                  //         checkValidations(data[n], 'Missing_Fields', secSymbol, '', n + 2, data[n]["Type"].trim());

                  //       } else {
                  //         if (data[q]["Type"] !== 'Buy') {
                  //           checkValidations(secType.trim(), secSymbol.trim(), parseFloat(data[n]["Units"].replaceAll(',', '')), parseFloat(data[n]["PrincipalAmount"].replaceAll(',', '')), n + 2, data[n]["Type"].trim());
                  //         } else if (data[q]["Type"] === 'Buy' && data[q]["Security"].trim() !== 'Other') {
                  //           checkValidations(secType.trim(), secSymbol.trim(), parseFloat(data[n]["Units"].replaceAll(',', '')), parseFloat(data[n]["PrincipalAmount"].replaceAll(',', '')), n + 2, data[n]["Type"].trim());
                  //         }
                  //       }
                  //     } else {
                  //       data[q]['PaymentType'] = ((data[q]["Type"] === 'Sell' || data[q]["Type"] === 'Maturity' || data[q]["Type"] === 'Coupon') ? 'Receipt' : 'Payment')
                  //       data[q]["Securities"] = JSON.stringify(temp1);
                  //       moneyMarketData.push(data[q])
                  //       m = false
                  //     }
                  //     n++
                  //   }
                  // }
                  // if (data[i]['TransactionType'].trim() === 'equityinvestment') {
                  //   let m = true
                  //   let n = i;
                  //   let q = i
                  //   let temp1: any = [];
                  //   while (m) {
                  //     if (data[n] !== undefined && (data[n]['TransactionType'] === "" || data[n] === undefined || n === i)) {
                  //       data[n]["Symbol"] = data[n]['Type'] === 'Buy' ? renderPsxDataDropdown(data[n]["Symbol"]) : getFundStockMarketData(data[n]["Symbol"])
                  //       //  data[n]['InstructionDate'] = moment(data[n]['InstructionDate']).format('YYYY-MM-DD') === 'Invalid date'? '':moment(data[n]['InstructionDate']).format('YYYY-MM-DD');
                  //       temp1.push({ type: data[n]["Type"], market: data[n]["Market"], symbol: data[n]["Symbol"], avg_rate: data[n]["AvgRate"], investment_type: data[n]["InvestmentType"], quantity: data[n]["Quantity"], commission_charges: data[n]["CommissionCharges"], commission_rate: data[n]["CommissionRate"], net_rate: data[n]["NetRate"], gross_amount: data[n]["GrossAmount"], sst_on_commission: data[n]["SSTonCommission"], net_amount: data[n]["NetAmount"], });

                  //       if (data[n]["Type"].trim() === '' || data[n]["Market"].trim() === '' || data[n]["Symbol"].trim() === '' || data[n]["AvgRate"].trim() === '' || data[n]["InvestmentType"].trim() === '' || data[n]["Quantity"].trim() === '' || data[n]["CommissionCharges"].trim() === '' || data[n]["SSTonCommission"].trim() === '' || data[n]["NetRate"].trim() === '' || data[n]["CommissionRate"].trim() === '' || data[n]["CommissionCharges"].trim() === '' || data[n]["NetAmount"].trim() === '' || data[n]["GrossAmount"].trim() === '') {
                  //         equityValidations(data[n], 'missingFields', n);
                  //       } else {
                  //         equityValidations(data[n], '', n);
                  //       }
                  //     } else {
                  //       data[q]["Securities"] = JSON.stringify(temp1);
                  //       stockMarketData.push(data[q])
                  //       m = false
                  //     }
                  //     n++
                  //   }
                  // }
                }
              }

              // if (moneyMarketData.length > 0) {
              //   setfileData(moneyMarketData);
              //   setFileDataCount(moneyMarketData.length)
              // } else if (stockMarketData.length > 0) {
              //   setfileData(stockMarketData);
              //   setFileDataCount(stockMarketData.length)
              // } else {
              if (flag === false) {
                toast.error("Not supported this Transaction Type");
                data.length = 0;
                setfileData(data);
              } else if (invalidFormatData.length == 0) {
                setfileData(data);
                setFileDataCount(data.length);
              } else {
                setFileError("");
                setFile("");
                setFileName("");
                setfileData([]);
                setInvalidFormatPopup(true);
              }
            }
            // data[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
            // data[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
            // finalData[i].DividendDate = moment(data[i].DividendDate).format('YYYY-MM-DD');
            // finalData[i]['realized'] ='true';
            // finalData[i]['realized_date'] = moment(date).format('YYYY-MM-DD');
            //   data[i]['AmcCode'] = Amc;
            //   data[i]['FundCode'] = fund;
            //   data[i]['FundAccount'] = fundAccount;
            //   data[i]['AccountTitle'] = accountTitle;
            //   data[i]['AccountNumber'] = accountno;
            //   data[i]['Bank'] = bank;
            //   data[i]['Branch'] = branch;
            //   data[i]['InstructionDate'] = moment(data[i].InstructionDate).format('YYYY-MM-DD');
            //   if(data[i]['SaleDate']!==undefined){
            //     data[i]['SaleDate'] = moment(data[i].SaleDate).format('YYYY-MM-DD');
            //   }
            //   if(data[i]['RedemptionDate']!==undefined){
            //     data[i]['RedemptionDate'] = moment(data[i].RedemptionDate).format('YYYY-MM-DD');
            //   }

            // }
            // setfileData(data);
            // setFileDataCount(data.length)
          };
          if (rABS) reader.readAsBinaryString(file);
          else reader.readAsArrayBuffer(file);
        } else {
          toast.error("Please Upload Correct Format of File");
        }
      } else {
        toast.error("File size should be less than 2MB");
      }
    }
  };

  const [errorBooleanAddFolio, setErrorBooleanAddfolio] = useState(false);
  const [invalidFormatPopup, setInvalidFormatPopup] = useState(false);
  const [approve, setApprove] = useState(false);
  const renderErrorAddFolioPopup = () => {
    switch (errorBooleanAddFolio) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            size="lg"
            show={true}
            enforceFocus={false}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBooleanAddfolio(false);
                  setApprove(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              {/* <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6> */}
              <h6 className="title title-up">{popusHeading}</h6>
              {/* <h6 className="title title-up">Units Holder not exist</h6> */}
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col>
                    {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                    <div className={"d-flex border w-100"}>
                      <div className={"w-50"}>Row Number</div>
                      <div className={"w-50"}>Field Name</div>
                    </div>
                    {errorData.length > 0 &&
                      errorData.map((item, index) => {
                        return (
                          <div className={"d-flex w-100"}>
                            <div className={"w-50"}>{item.row_number}</div>
                            <div className={"w-50"}>{item.field_name}</div>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          // defaultValue={underStand}
                          // className={
                          //   'form-control w-100 '
                          // }
                          onChange={(e) => {
                            setApprove(!approve);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Are you sure you want to add new Folio Number
                        and create transactions.{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
                {approve && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={async () => {
                          UploadTxn("yes");
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Upload</span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
            enforceFocus={false}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{popusHeading}</h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={"d-flex border w-100"}>
                    <div className={"w-50"}>Row Number</div>
                    <div className={"w-50"}>Field Name</div>
                  </div>
                  {errorData.length > 0 &&
                    errorData.map((item, index) => {
                      return (
                        <div className={"d-flex w-100"}>
                          <div className={"w-50"}>{item.row_number}</div>
                          <div className={"w-50"}>{item.field_name}</div>
                        </div>
                      );
                    })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderInvalidTransactionsPopup = () => {
    switch (errorInvalidTxnsBoolean) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            size="lg"
            show={true}
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setErrorInvalidTxnsBoolean(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorInvalidTxnsBoolean(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Irrelevant Transactions</h6>
            </div>

            <div className="modal-body">
              <div className="">
                <span className="text-primary">
                  Note: {invalidTransactionsCount}
                </span>{" "}
                transactions are irrelevant out of{" "}
                <span className="text-primary">{totalFileData}.</span>
                <div className="table-responsive">
                  <JsonToTable json={invalidTransactions} />
                </div>
              </div>
              <button
                className="btn btn-default"
                onClick={() => {
                  setErrorInvalidTxnsBoolean(false);
                  setFile("");
                  setFileName("");
                  setfileData(null);
                  setFileDataCount(0);
                  errorData.length = 0;
                  setErrorData(errorData);
                  setfileData([]);
                  setInvalidTransactionsCount(0);
                  setTotalFileData(0);
                  setFileName("");
                  setFileError("");
                  setAmc("");
                  setFund("");
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  UploadTxn("no");
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Posting...</span>
                  </>
                ) : (
                  <span>Continue</span>
                )}
              </button>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderinvalidFormatPopup = () => {
    switch (invalidFormatPopup) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
            enforceFocus={false}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setInvalidFormatPopup(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                Kindly enter only numbers in following fields{" "}
              </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={"d-flex border w-100"}>
                    <div className={"w-50"}>Row Number</div>
                    <div className={"w-50"}>Field Name</div>
                  </div>
                  {invalidFormatData.map((item, index) => {
                    return (
                      <div className={"d-flex w-100"}>
                        <div className={"w-50"}>{item.row_number}</div>
                        <div className={"w-50"}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <Link to="/admin/transaction-types">
                      {" "}
                      <i className="fa fa-angle-left"></i>{" "}
                    </Link>
                    Transaction Bulk Upload
                    <Dropdown className="float-right">
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        Download Samples
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {txnTypeCatagory[0].unitManagementTxn.map(
                          (item: any, index: number) => {
                            return (
                              <>
                                {item.api_name === "saleofunit" && (
                                  <Dropdown.Item
                                    href="./../../Uploader-Sample/Sale-of-unit.xlsx"
                                    download
                                  >
                                    Sale of Units
                                  </Dropdown.Item>
                                )}
                                {item.api_name === "conversionin" && (
                                  <Dropdown.Item
                                    href="./../../Uploader-Sample/Conversion-in.xlsx"
                                    download
                                  >
                                    Conversion In
                                  </Dropdown.Item>
                                )}
                                {item.api_name === "conversionout" && (
                                  <Dropdown.Item
                                    href="./../../Uploader-Sample/Conversion-out.xlsx"
                                    download
                                  >
                                    Conversion Out
                                  </Dropdown.Item>
                                )}
                                {item.api_name === "redemptionofunits" && (
                                  <Dropdown.Item
                                    href="./../../Uploader-Sample/Redemption-of-unit.xlsx"
                                    download
                                  >
                                    Redemption of Units
                                  </Dropdown.Item>
                                )}
                              </>
                            );
                          }
                        )}
                        <Dropdown.Item
                          href="./../../Uploader-Sample/BrokerageCommissionTransactionUploader.xlsx"
                          download
                        >
                          Brokerage Commission
                        </Dropdown.Item>

                        {/* <Dropdown.Item href="./../../Uploader-Sample/Sale-of-unit.xlsx" download>Sale of Units</Dropdown.Item>
                        <Dropdown.Item href="./../../Uploader-Sample/Redemption-of-unit.xlsx" download>Redemption of Units</Dropdown.Item>
                        <Dropdown.Item href="./../../Uploader-Sample/Conversion-in.xlsx" download>Conversion In</Dropdown.Item>
                        <Dropdown.Item href="./../../Uploader-Sample/Conversion-out.xlsx" download>Conversion Out</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </h4>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md="2">
                      <div className="form-group  ">
                        <label>Select AMC</label>
                        <select
                          className={"form-control " + ErrorBorder(Amc)}
                          value={Amc}
                          onChange={(e) => {
                            setAmc(e.target.value);
                            allFunds.length = 0;
                            setAllFunds(allFunds);
                            getFunds(e.target.value);

                            // clear fields
                            setFund("");
                            setFundAccount("");
                            setAccountTitle("");
                            setAccountNo("");
                            setBank("");
                            setBranch("");
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select AMC
                          </option>
                          {renderAmcDropdown()}
                        </select>
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group">
                        <label>Select Fund</label>
                        <select
                          className={"form-control " + ErrorBorder(fund)}
                          onChange={(e) => {
                            // setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                            setFund(e.target.value);
                            getFundAccounts(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select Fund
                          </option>
                          {renderFundsDropDown()}
                        </select>
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group">
                        <label>Select Fund Account</label>
                        <select
                          className={"form-control " + ErrorBorder(fundAccount)}
                          onChange={(e) => {
                            setFundAccount(e.target.value);
                            getFundAccountData(e.target.value);
                            // var AccountTitle=e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text;
                            // setAccountTitle(AccountTitle.split('(')[1].replace(')',''));
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select Fund Account
                          </option>
                          {renderFundAccountsDropDown()}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <label>AMC : {Amc}</label>
                    </Col>
                    <Col md="2">
                      <label>Fund : {fund}</label>
                    </Col>
                    <Col md="2">
                      <label>Fund Acount : {fundAccount}</label>
                    </Col>
                    <Col md="2">
                      <label>Title : {accountTitle}</label>
                    </Col>
                    <Col md="2">
                      <label>Account No. : {accountno}</label>
                    </Col>
                    <Col md="2">
                      <label>Bank : {bank}</label>
                    </Col>
                    <Col md="2">
                      <label>Branch : {branch}</label>
                    </Col>
                  </Row>
                  <div className="">
                    <div className="row">
                      <div className="col-md-2">
                        <label>Choose File</label>
                        <div
                          className="multi-input pointer"
                          onClick={() => myRef1?.current?.click()}
                        >
                          <div className="form-group">
                            <div className="form-control">
                              {fileName === "" ? "Upload File" : fileName}
                            </div>
                            <input
                              type="file"
                              ref={myRef1}
                              style={{ display: "none" }}
                              multiple={false}
                              onChange={(e) => {
                                upload(e);
                                e.target.value = "";
                              }}
                            />
                            {fileError ? (
                              <p className="error-labels error-message2">
                                {fileError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {fileUploadingFlag && (
                          <img src="assets/loader.svg" width="50" />
                        )}
                      </div>
                    </div>
                  </div>
                  {fileDataCount <= 0 ? (
                    ""
                  ) : (
                    <div className="table-responsive mt-3">
                      <JsonToTable json={fileData} />
                    </div>
                  )}
                  Total Rows:{" "}
                  <span className="text-primary mt-3">{fileDataCount} </span>
                  <div className="mt-4">
                    <button
                      className="btn btn-primary "
                      onClick={() => {
                        UploadTxn("no");
                      }}
                      disabled={Boolean(loading)}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Upload</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {renderErrorPopup()}
              {renderErrorAddFolioPopup()}
              {renderInvalidTransactionsPopup()}
              {renderinvalidFormatPopup()}
              {renderModalForBankBalance()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BulkUplaod;

// import React, { useEffect } from 'react';
// import { useState, useRef } from 'react';
// import { useHistory, Link } from 'react-router-dom';
// import { Container, Row, Col } from 'reactstrap';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { uploadTransaction } from './../../stores/services/beta-transaction.service';
// import { JsonToTable } from 'react-json-to-table';
// import * as fs from 'fs';
// import { Modal } from 'react-bootstrap';
// import xls from "xlsx";
// import { getAmc } from '../../stores/services/amc.service';
// import { getFundByAmc } from '../../stores/services/funds.service';
// import { getActiveAccountByFund } from '../../stores/services/account.service';
// import moment from 'moment';
// import { getAllPsx, getAllSecurities } from '../../stores/services/security.service';
// import { isDateValid, numberWithCommas } from '../../utils/customFunction';
// // import SaleOfUnit from './../../Uploader-Sample/Sale-of-unit.xlsx';

// let csvToJson = require('convert-csv-to-json');
// const csv = require('csvtojson/v2');

// let xlsxToJson = require('convert-excel-to-json');
// //const xlsx = require('xlsx-to-json');

// const BulkUplaod = () => {
//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const [fileData, setfileData] = useState<any>([]);
//   const [fileDataCount, setFileDataCount] = useState(0);
//   const myRef1 = useRef<HTMLInputElement>(null);
//   const [fileName, setFileName] = useState('');
//   const [fileError, setFileError] = useState('');
//   const [file, setFile] = useState('');
//   const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
//   const email = sessionStorage.getItem('email') || '';

//   const [allAmc, setAllAmc] = useState<any>([]);
//   const [allFunds, setAllFunds] = useState<any>([]);

//   const [allSecurities, setAllSecurities] = useState<any>([]);

//   const [psxList, setPsxList] = useState<any>([]);

//   const [invalidTransactions, setInvalidTransactions] = useState<any>([]);
//   const [invalidTransactionsCount, setInvalidTransactionsCount] = useState(0);
//   const [totalFileData, setTotalFileData] = useState(0);

//   const [errorInvalidTxnsBoolean, setErrorInvalidTxnsBoolean] = useState<boolean>(false);
//   const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
//   const [Amc, setAmc] = useState('');
//   const [fund, setFund] = useState('');
//   const [fundAccount, setFundAccount] = useState<any>('');
//   const [accountTitle, setAccountTitle] = useState('');
//   const [accountno, setAccountNo] = useState('');
//   const [bank, setBank] = useState('');
//   const [branch, setBranch] = useState('');
//   const [txnrType, setTxnrType] = useState('');

//   const [errorData, setErrorData] = useState<any>([]);
//   const [errorShow, setErrorShow] = useState(false);
//   const [unitsErrorBoolean, setUnitsErrorBoolean] = useState(false);
//   const [errorBoolean, setErrorBoolean] = useState(false);

//   let date = new Date();

//   React.useEffect(() => {
//     const getAllAMC = async () => {
//       try {
//         const amcResponse = await getAmc(email, '', '', '');
//         setAllAmc(amcResponse.data.data);
//       } catch (err: any) {
//         if (err.response !== undefined) {
//           toast.error(err.response.data.message);
//         } else {
//           toast.error("Request Failed!")
//         }
//       }
//     }
//     const getSecurities = async () => {
//       try {
//         const secResponse = await getAllSecurities(email);
//         setAllSecurities(secResponse.data.data);
//       } catch (err: any) {
//         if (err.response !== undefined) {
//           toast.error(err.response.data.message);
//         } else {
//           toast.error("Request Failed!")
//         }
//       }
//     }
//     const equityPsXList = async () => {
//       try {
//         const response = await getAllPsx(email);
//         let sortedData = response.data.data.sort(function (a, b) {
//           let x = a.company_name.toLowerCase();
//           let y = b.company_name.toLowerCase();
//           if (x > y) { return 1; }
//           if (x < y) { return -1; }
//           return 0;
//         });
//         // setPsxList(response.data.data);
//         setPsxList(sortedData);
//       } catch (error) { }
//     }
//     getAllAMC();
//     getSecurities();
//     equityPsXList();
//   }, [])

//   const renderAmcDropdown = () => {
//     return allAmc.map((item: any, index: number) => {
//       return (
//         <option key={index} value={item.amc_code.replace('AMC_', '')}>
//           {item.name}
//         </option>
//       );
//     });
//   };

//   const getFunds = async (code: any) => {
//     try {
//       const response = await getFundByAmc(email, code);
//       setAllFunds(response.data.data);
//     } catch (error) {

//     }
//   }

//   const renderFundsDropDown = () => {
//     return allFunds.map((item: any, index: number) => {
//       return (
//         <option key={index} value={item.symbol_code}>
//           {item.fund_name} - {item.symbol_code}
//         </option>
//       );
//     });
//   }

//   const getFundAccounts = async (fund_code) => {
//     try {
//       const accResponse = await getActiveAccountByFund(email, fund_code);
//       setAllFundsAccounts(accResponse.data.data);
//     } catch (error) {

//     }
//   }

//   const renderFundAccountsDropDown = () => {
//     return allFundAccounts.map((item: any, index: number) => {
//       return (
//         // item.account_code
//         <option key={index} value={item.account_no}>
//           {item.bank_name} ({item.account_title})
//         </option>
//       );
//     });
//   }

//   const getFundAccountData = (accountno) => {
//     allFundAccounts.map((item, index) => {
//       if (item.account_no === accountno) {
//         setFundAccount(item.account_code.replaceAll('ACCOUNT_', ''));
//         setAccountTitle(item.account_title);
//         setAccountNo(item.account_no);
//         setBank(item.bank_name);
//         setBranch(item.branch_name);
//       }
//     });

//   };

//   //retrun error border class
//   const ErrorBorder = (value) => {
//     if (errorShow && value === '') {
//       return 'required-border';
//     } else {
//       return '';
//     }
//   };

//   // here to upload the transation bulk upload
//   const UploadTxn = async () => {
//     if (Amc === '' || fund === '' || fundAccount === '') {
//       setErrorShow(true);
//       // }else if(fileData.length===0 || fileData===[] || fileName===''){
//     } else if (fileData.length === 0 || fileName === '') {
//       toast.error('Relevant transactions are not found.')
//       return;
//     } else {
//       if (file) {
//         setLoading(true);
//         if (errorData.length === 0) {
//           try {
//             const res = await uploadTransaction(email, fileData);
//             if (res.data.message === "Transaction upload started") {
//               setErrorInvalidTxnsBoolean(false);
//               setFile('');
//               setFileName('');
//               setfileData([]);
//               setFileDataCount(0);
//               toast.success('Transaction upload completed');
//               errorData.length = 0;
//               setErrorData(errorData);
//               setfileData([]);
//               setFileName('');
//               setFileError('');
//               setAmc('');
//               setFund('');
//               setFundAccount('');
//               setAccountTitle('');
//               setAccountNo('');
//               setBank('');
//               setBranch('');
//               // errorInvalidTxnsBoolean=false;
//             } else {
//               toast.error('Error! Transaction is not uploaded');
//             }

//           } catch (error: any) {
//             setLoading(false);
//             if (error.response.data.status == 500) {
//               toast.error(error.response.data.message);
//             } else if (error.response.data.status == 404) {
//               setErrorBoolean(true);
//               setErrorInvalidTxnsBoolean(false);
//               setErrorData(error.response.data.data);
//             } else if (error.response.data.status == 400) {
//               setErrorBoolean(true);
//               setErrorInvalidTxnsBoolean(false);
//               setErrorData(error.response.data.data);
//             } else {
//               toast.error(error.response.data.message);
//             }

//           }
//         } else {
//           toast.error('Kindly Fill Correct Data.');
//         }
//         setLoading(false);
//         setErrorInvalidTxnsBoolean(false);
//       } else {
//         toast.error('Kindly Upload File');
//       }
//     }
//   };

//   // Money Market Functions Start.
//   const securitySymbol = (value) => {
//     if (value === 'Pakistan Investment Bonds') {
//       return 'PIB'
//     } else if (value === 'Treasuary bill') {
//       return 'TBILL'
//     } else if (value === 'Sukuk Certificates') {
//       return 'SUKUK'
//     } else if (value === 'Term Finance Certificates') {
//       return 'TFC'
//     } else if (value === 'Commercial Papers') {
//       return 'CP'
//     } else if (value === 'Term Deposit Receipts') {
//       return 'TDR'
//     } else if (value === 'Call Deposit Receipts') {
//       return 'CDR'
//     } else if (value === 'Letter of Placement') {
//       return 'LOP'
//     }
//   }

//   const getSecuritySymbol = (value) => {
//     let symbol = '';
//     allSecurities.filter((item: any, index: string) => {
//       if (item.name.trim() === value.trim()) {
//         symbol = item.code;
//       }
//     });
//     return symbol;
//   }
//   const getSymbolFromFund = (value) => {
//     let symbol = '';
//     let flag1: any = false;
//     const s = allFunds.filter((item: any, index: number) => {
//       return (fund === item.symbol_code)
//     });
//     let data = JSON.parse(s[0].money_market);
//     data.filter((item: any, index: number) => {
//       if (value['Security'].trim() === item.name.trim()) {
//         if (moment(value["MaturityDate"]).format('YYYY-MM-DD') === item.issue_date && moment(value["MaturityDate"]).format('YYYY-MM-DD') === item.maturity_date) {
//           symbol = item.code;
//         } else {
//           symbol = 'unmatchDate';
//         }
//       }
//     });
//     return symbol;

//   }
//   const checkValidations = (security_type, security_symbol, value, princpaleAmnt, line, type) => {
//     if (security_symbol !== 'Missing_Fields') {
//       if (security_type === 'PIB' || security_type === 'TBILL' || security_type === 'SUKUK' || security_type === 'TFC') {
//         if (value <= 0) {
//           setUnitsErrorBoolean(true);
//           let temp = errorData
//           temp.push({ row_number: line, field_name: `Units(Enter units must be greater than zero)` })
//           setErrorData([...temp])
//         } else {
//           if (type.trim() !== 'Buy') {
//             const s = allFunds.filter((item: any, index: number) => {
//               return (fund === item.symbol_code)
//             });
//             let data = JSON.parse(s[0].money_market);
//             let count: any = 0;
//             let totalLength: any = 0;
//             data.filter((item: any, index: number) => {
//               if (security_type.trim() === item.security_type.trim()) {
//                 if (security_symbol.trim() === item.code.trim()) {
//                   if (value > parseFloat(item.units)) {
//                     setUnitsErrorBoolean(true);
//                     let temp = errorData
//                     temp.push({ row_number: line, field_name: `Units(Enter units must be less than or equal to ${item.units})` })
//                     setErrorData([...temp]);
//                   }
//                 } else {
//                   count += 1;
//                 }
//                 totalLength += 1;
//               }
//             });
//             if (count === totalLength) {
//               setUnitsErrorBoolean(true);
//               let temp = errorData
//               temp.push({ row_number: line, field_name: `Security(You cannot Sell units before Buy)` })
//               setErrorData([...temp]);
//             }
//           } else {
//             const check = allSecurities.filter((item: any, index: string) => {
//               if (security_type.trim() === item.security_type.trim()) {
//                 if (security_symbol.trim() === item.code.trim()) {
//                   return item;
//                 }
//               }
//             });
//             if (check.length === 0) {
//               setUnitsErrorBoolean(true);
//               let temp = errorData
//               temp.push({ row_number: line, field_name: `Security(secuirty is not registered)` })
//               setErrorData([...temp]);
//             }

//           }
//         }
//       } else {
//         if (princpaleAmnt <= 0) {
//           setUnitsErrorBoolean(true);
//           let temp = errorData
//           temp.push({ row_number: line, field_name: `PrincipalAmount(Enter principal amount must be greater than zero)` })
//           setErrorData([...temp])
//         } else {
//           if (type.trim() !== 'Buy') {
//             const s = allFunds.filter((item: any, index: number) => {
//               return (fund === item.symbol_code)
//             });
//             let data = JSON.parse(s[0].money_market);
//             let count: any = 0;
//             let totalLength: any = 0;

//             data.filter((item: any, index: number) => {
//               if (security_type.trim() === item.security_type.trim()) {
//                 if (security_symbol.trim() === item.code.trim()) {
//                   if (princpaleAmnt > parseFloat(item.total_amount)) {
//                     setUnitsErrorBoolean(true);
//                     let temp = errorData
//                     temp.push({ row_number: line, field_name: `PrincipalAmount(Enter principal amount must be less than or equal to ${item.total_amount})` })
//                     setErrorData([...temp]);
//                   }
//                 } else {
//                   count += 1;
//                 }
//                 totalLength += 1;
//               }
//             });

//             if (count === totalLength) {
//               setUnitsErrorBoolean(true);
//               let temp = errorData
//               temp.push({ row_number: line, field_name: `Security(You cannot Sell principal amount before Buy)` })
//               setErrorData([...temp]);
//             }
//           } else {
//             const check = allSecurities.filter((item: any, index: string) => {
//               if (security_type.trim() === item.security_type.trim()) {
//                 if (security_symbol.trim() === item.code.trim()) {
//                   return item;
//                 }
//               }
//             });
//             if (check.length === 0) {
//               setUnitsErrorBoolean(true);
//               let temp = errorData
//               temp.push({ row_number: line, field_name: `Security(secuirty is not registered)` })
//               setErrorData([...temp]);
//             }

//           }
//         }
//       }
//     } else {
//       let sec_symbol = value;

//       let emptyData: any = [];
//       if (security_type['SecuritiesPlacement'] === '') {
//         emptyData.push('SecuritiesPlacement,');
//       }
//       if (security_type['Type'] === '') {
//         emptyData.push(' , Type');
//       }
//       if (sec_symbol === '') {
//         emptyData.push(' , Security');
//       }
//       if (isDateValid(moment(security_type["IssueDate"]).format('YYYY-MM-DD')) === '') {
//         emptyData.push(' , IssueDate');
//       }
//       if (isDateValid(moment(security_type["MaturityDate"]).format('YYYY-MM-DD')) === '') {
//         emptyData.push(' , MaturityDate');
//       }
//       if (security_type['Units'] === '' && (securitySymbol(security_type['SecuritiesPlacement']) === 'PIB' || securitySymbol(security_type['SecuritiesPlacement']) === 'TBILL' || securitySymbol(security_type['SecuritiesPlacement']) === 'SUKUK' || securitySymbol(security_type['SecuritiesPlacement']) === 'TFC')) {
//         emptyData.push(' , Units');
//       } else if (security_type['PrincipalAmount'] === '') {
//         emptyData.push(' , PrincipalAmount');
//       }
//       if (sec_symbol.includes('unmatchDate')) {
//         emptyData.push(' , Security(issue and maturity date not match agianst given security)');
//       }
//       let temp = errorData;
//       if (emptyData.length > 0) {
//         temp.push({
//           row_number: line, field_name: emptyData.map((item, index) => {
//             if (index === 0) {
//               return item.replace(',', '');
//             } else {
//               return item;
//             }
//           }
//           )
//         });
//       }
//       setErrorData([...temp]);
//       if (errorData.length > 0) {
//         setUnitsErrorBoolean(true);
//       }
//     }

//   }

//   const createSymboleInCaseOfOther = (value) => {
//     // isDateValid(moment(security_type["IssueDate"]).format('YYYY-MM-DD')
//     let codeInCaseOther = `${securitySymbol(value["SecuritiesPlacement"])}-${isDateValid(moment(value["IssueDate"])).format('YYYY-MM-DD').replaceAll('-', '')}-${isDateValid(moment(value["MaturityDate"])).format('YYYY-MM-DD').replaceAll('-', '')}`;
//     return codeInCaseOther;
//   }
//   // Money Market Functions End.

//   // Equity Functions Start.
//   // In case of Buy Security.
//   const renderPsxDataDropdown = (symbol) => {
//     let symbl: any = ''
//     psxList.filter((item: any) => {
//       if (item.comapany_name === symbol) {
//         symbl = symbol;
//       }
//     });
//     return symbl;
//   };

//   // In case of Sell Security.
//   const getFundStockMarketData = (symbol) => {
//     let symbl: any = ''
//     const s = allFunds.filter((item: any, index: number) => {
//       return (fund === item.symbol_code)

//     });

//     let stockFundData = JSON.parse(s[0].stock_market);
//     stockFundData.filter((item: any) => {
//       if (item.name === symbol) {
//         symbl = symbol;
//       }
//     });
//     return symbl;
//   };

//   //render dropdown for psx data of fund
//   const renderStockMarketData = () => {

//   };

//   const equityValidations = (txn, value, line) => {
//     let emptyData: any = [];
//     if (value !== 'missingFields') {
//     } else {
//       if (txn["Type"] === '') {
//         emptyData.push('Type,');
//       }
//       if (txn["Market"] === '') {
//         emptyData.push(' , Market');
//       }
//       if (txn["Symbol"] === '') {
//         emptyData.push(' , Symbol');
//       }
//       if (txn["AvgRate"] === '') {
//         emptyData.push(' , AvgRate');
//       }
//       if (txn["InvestmentType"] === '') {
//         emptyData.push(' , InvestmentType');
//       }
//       if (txn["Quantity"] === '') {
//         emptyData.push(' , Quantity');
//       }
//       if (txn["CommissionCharges"] === '') {
//         emptyData.push(' , CommissionCharges');
//       }
//       if (txn["SSTonCommission"] === '') {
//         emptyData.push(' , SSTonCommission');
//       }
//       if (txn["NetRate"] === '') {
//         emptyData.push(' , NetRate');
//       }
//       if (txn["CommissionRate"] === '') {
//         emptyData.push(' , CommissionRate');
//       }
//       if (txn["NetAmount"] === '') {
//         emptyData.push(' , NetAmount');
//       }
//       if (txn["GrossAmount"] === '') {
//         emptyData.push(' , GrossAmount');
//       }
//       let temp = errorData;
//       if (emptyData.length > 0) {
//         temp.push({
//           row_number: line, field_name: emptyData.map((item, index) => {
//             if (index === 0) {
//               return item.replace(',', '');
//             } else {
//               return item;
//             }
//           }
//           )
//         });
//       }
//       setErrorData([...temp]);
//       if (errorData.length > 0) {
//         setUnitsErrorBoolean(true);
//       }
//     }
//   }

//   // Equity Functions End.

//   const upload = async (e: any) => {
//     if (Amc === '' || fund === '' || fundAccount === '') {
//       setErrorShow(true);
//     } else {
//       setFileError('');
//       setfileData([]);
//       errorData.length = 0
//       setErrorData(errorData)

//       let file = e?.target.files[0];
//       let filesize = parseInt(file.size);
//       if (filesize <= 2097152) {
//         let type = file.name.substring(file.name.lastIndexOf('.') + 1);
//         if (type === 'xlsx' || type === 'csv') {
//           setFile(file);
//           setFileName(file.name);
//           const reader = new FileReader();
//           const rABS = !!reader.readAsBinaryString;
//           reader.onload = e => {
//             /* Parse data */
//             const bstr = e.target?.result;
//             const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
//             /* Get first worksheet */
//             const wsname = wb.SheetNames[0];
//             const ws = wb.Sheets[wsname];
//             /* Convert array of arrays */
//             const data: any = xls.utils.sheet_to_json(ws, {
//               header: 2,
//               raw: false,
//               defval: "",
//               dateNF: 'YYYY-MM-DD',
//             });
//             /* Update state */
//             if (data.length > 0) {
//               let moneyMarketData: any = [];
//               let stockMarketData: any = [];
//               let validUnitsPut: any = ''

//               for (var i = 0; i < data.length; i++) {

//                 if (data[0]["TransactionType"].trim() !== data[i]["TransactionType"].trim()) {
//                   toast.error(`You can't upload multiple type of transaction in one uploader`);
//                   data.length = 0
//                   setfileData(data);
//                   setFileDataCount(0);
//                 } else if (data[i]["TransactionType"].trim().length > 0) {

//                     setTxnrType(data[0]["TransactionType"]);
//                     data[i]['AmcCode'] = Amc;
//                     data[i]['FundCode'] = fund;
//                     data[i]['FundAccount'] = fundAccount;
//                     data[i]['AccountTitle'] = accountTitle;
//                     data[i]['AccountNumber'] = accountno;
//                     data[i]['Bank'] = bank;
//                     data[i]['Branch'] = branch;
//                     data[i]['InstructionDate'] = moment(data[i].InstructionDate).format('YYYY-MM-DD');

//                     if (data[i]['InstructionDate'] !== undefined) {
//                       data[i]['InstructionDate'] = moment(data[i].InstructionDate).format('YYYY-MM-DD') === 'Invalid date' ? '' : moment(data[i].InstructionDate).format('YYYY-MM-DD');
//                     }
//                     if (data[i]['SaleDate'] !== undefined) {
//                       data[i]['SaleDate'] = moment(data[i].SaleDate).format('YYYY-MM-DD');
//                     }
//                     if (data[i]['UnitsSold'] !== undefined) {
//                       data[i]['UnitsSold'] = data[i]['UnitsSold'].replaceAll(',', '');
//                     }
//                     if (data[i]['NetAmount'] !== undefined) {
//                       data[i]['NetAmount'] = data[i]['NetAmount'].replaceAll(',', '');
//                     }
//                     if (data[i]['GrossAmount'] !== undefined) {
//                       data[i]['GrossAmount'] = data[i]['GrossAmount'].replaceAll(',', '');
//                     }
//                     if (data[i]['RedemptionDate'] !== undefined) {
//                       data[i]['RedemptionDate'] = moment(data[i].RedemptionDate).format('YYYY-MM-DD');
//                     }
//                     if (data[i]['TransactionType'].trim() === 'debtmarketinvestment') {
//                       let m = true
//                       let n = i;
//                       let q = i
//                       let temp1: any = [];
//                       while (m) {
//                         if (data[n] !== undefined && (data[n]['TransactionType'] === "" || data[n] === undefined || n === i)) {

//                           let issueDte: any = isDateValid(moment(data[n]["IssueDate"]).format('YYYY-MM-DD'));
//                           let maturityDte: any = isDateValid(moment(data[n]["MaturityDate"]).format('YYYY-MM-DD'));
//                           let secType: any = securitySymbol(data[n]["SecuritiesPlacement"].trim());
//                           let secSymbol: any = "";
//                           if (data[q]["Type"] !== 'Buy') {
//                             secSymbol = getSymbolFromFund(data[n]);
//                           } else {
//                             secSymbol = data[n]["Security"].trim() === 'Other' ? createSymboleInCaseOfOther(data[n]) : getSecuritySymbol(data[n]["Security"].trim())
//                           }
//                           temp1.push({
//                             security_type: secType, symbol: secSymbol.trim(), type: data[n]["Type"].trim(), name: data[n]["Security"] === 'Other' ? createSymboleInCaseOfOther(data[n]).trim() : data[n]["Security"].trim(), investment_type: data[n]["InvestmentType"], issue_date: issueDte, maturity_date: maturityDte, money_market_face_value: data[n]["MoneyMarketFaceValue"], primary_dealer: data[n]["PrimaryDealer"], last_coupon_date: isDateValid(moment(data[n]["LastCouponDate"]).format('YYYY-MM-DD')), next_coupon_date: isDateValid(moment(data[n]["NextCouponDate"]).format('YYYY-MM-DD')), coupon_payment: data[n]["CouponPaymentYear"], coupon_rate: data[n]["CouponRate"].replaceAll(',', ''), accrued_days: data[n]["AccruedDays"], detail: data[n]["Detail"], price: data[n]["Price"].replaceAll(',', ''), units: data[n]["Units"].replaceAll(',', ''), yield: data[n]["Yield"].replaceAll(',', ''), total_face_value: data[n]["TotalFaceValue"].replaceAll(',', ''), accrued_amount: data[n]["AccruedAmount"].replaceAll(',', ''), brokerage: data[n]["Brokerage"], principal_amount: data[n]["PrincipalAmount"].replaceAll(',', ''), unredeem_value: data[n]['UnRedeemValue'].replaceAll(',', ''), settlement_amount: data[n]["SettlementAmount"].replaceAll(',', ''), fund_ips_account: data[n]["FundIPSAccount"], counter_party_ips_account: data[n]["CounterPartyIPSAccount"], broker_name: data[n]["Broker"], days_of_maturity: data[n]["DaysToMaturity"], counter_party_name: data[n]["CounterPartyName"], premium_discount: data[n]["PremiumDiscount"]
//                           });
//                           if (data[n]["SecuritiesPlacement"].trim() === '' || data[n]["Type"].trim() === '' || secSymbol.includes('unmatchDate') || secSymbol.trim() === '' || issueDte.trim() === '' || maturityDte.trim() === '' || data[n]["Units"].trim() === '' || data[n]["PrincipalAmount"].trim() === '') {
//                             checkValidations(data[n], 'Missing_Fields', secSymbol, '', n + 2, data[n]["Type"].trim());

//                           } else {
//                             if (data[q]["Type"] !== 'Buy') {
//                               checkValidations(secType.trim(), secSymbol.trim(), parseFloat(data[n]["Units"].replaceAll(',', '')), parseFloat(data[n]["PrincipalAmount"].replaceAll(',', '')), n + 2, data[n]["Type"].trim());
//                             } else if (data[q]["Type"] === 'Buy' && data[q]["Security"].trim() !== 'Other') {
//                               checkValidations(secType.trim(), secSymbol.trim(), parseFloat(data[n]["Units"].replaceAll(',', '')), parseFloat(data[n]["PrincipalAmount"].replaceAll(',', '')), n + 2, data[n]["Type"].trim());
//                             }
//                           }
//                         } else {
//                           data[q]['PaymentType'] = ((data[q]["Type"] === 'Sell' || data[q]["Type"] === 'Maturity' || data[q]["Type"] === 'Coupon') ? 'Receipt' : 'Payment')
//                           data[q]["Securities"] = JSON.stringify(temp1);
//                           moneyMarketData.push(data[q])
//                           m = false
//                         }
//                         n++
//                       }
//                     }
//                     if (data[i]['TransactionType'].trim() === 'equityinvestment') {
//                       let m = true
//                       let n = i;
//                       let q = i
//                       let temp1: any = [];
//                       while (m) {
//                         if (data[n] !== undefined && (data[n]['TransactionType'] === "" || data[n] === undefined || n === i)) {
//                           data[n]["Symbol"] = data[n]['Type'] === 'Buy' ? renderPsxDataDropdown(data[n]["Symbol"]) : getFundStockMarketData(data[n]["Symbol"])
//                           //  data[n]['InstructionDate'] = moment(data[n]['InstructionDate']).format('YYYY-MM-DD') === 'Invalid date'? '':moment(data[n]['InstructionDate']).format('YYYY-MM-DD');
//                           temp1.push({ type: data[n]["Type"], market: data[n]["Market"], symbol: data[n]["Symbol"], avg_rate: data[n]["AvgRate"], investment_type: data[n]["InvestmentType"], quantity: data[n]["Quantity"], commission_charges: data[n]["CommissionCharges"], commission_rate: data[n]["CommissionRate"], net_rate: data[n]["NetRate"], gross_amount: data[n]["GrossAmount"], sst_on_commission: data[n]["SSTonCommission"], net_amount: data[n]["NetAmount"], });

//                           if (data[n]["Type"].trim() === '' || data[n]["Market"].trim() === '' || data[n]["Symbol"].trim() === '' || data[n]["AvgRate"].trim() === '' || data[n]["InvestmentType"].trim() === '' || data[n]["Quantity"].trim() === '' || data[n]["CommissionCharges"].trim() === '' || data[n]["SSTonCommission"].trim() === '' || data[n]["NetRate"].trim() === '' || data[n]["CommissionRate"].trim() === '' || data[n]["CommissionCharges"].trim() === '' || data[n]["NetAmount"].trim() === '' || data[n]["GrossAmount"].trim() === '') {
//                             equityValidations(data[n], 'missingFields', n);
//                           } else {
//                             equityValidations(data[n], '', n);
//                           }
//                         } else {
//                           data[q]["Securities"] = JSON.stringify(temp1);
//                           stockMarketData.push(data[q])
//                           m = false
//                         }
//                         n++
//                       }
//                     }
//                   }
//               }

//               if (moneyMarketData.length > 0) {
//                 setfileData(moneyMarketData);
//                 setFileDataCount(moneyMarketData.length)
//               } else if (stockMarketData.length > 0) {
//                 setfileData(stockMarketData);
//                 setFileDataCount(stockMarketData.length)
//               } else {
//                 setfileData(data);
//                 setFileDataCount(data.length)
//               }

//             }

//           };
//           if (rABS) reader.readAsBinaryString(file);
//           else reader.readAsArrayBuffer(file);
//         } else {
//           toast.error('Please Upload Correct Format of File');
//         }
//       } else {
//         toast.error("File size should be less than 2MB")
//       }
//     }
//   };

//   const renderErrorPopup = () => {
//     switch (errorBoolean) {
//       case true:
//         return (
//           <Modal
//             className=" "
//             dialogClassName="modal60w"
//             show={true}
//           >
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setErrorBoolean(false);
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up">Kindly Enter Correct Folio Number in given fields.</h6>
//             </div>

//             <div className="modal-body">
//               <div className="">
//                 <Row>
//                   {/* <div className="mx-auto">
//                     <h1 className="pb-3">Kindly fill following fields data</h1>
//                     Missing Fields
//                   </div> */}
//                   <div className={'d-flex border w-100'}>
//                     <div className={'w-50'}>Row Number</div>
//                     <div className={'w-50'}>Field Name</div>
//                   </div>
//                   {errorData.map((item, index) => {
//                     return (
//                       <div className={'d-flex w-100'}>
//                         <div className={'w-50'}>{item.row_number}</div>
//                         <div className={'w-50'}>{item.field_name}</div>
//                       </div>
//                     );
//                   })}
//                 </Row>
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const renderUnitsErrorPopup = () => {
//     switch (unitsErrorBoolean) {
//       case true:
//         return (
//           <Modal
//             className=" "
//             dialogClassName="modal60w"
//             size="lg"
//             show={true}
//             onHide={() => {
//               setUnitsErrorBoolean(false);
//             }}
//           >
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setUnitsErrorBoolean(false);
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up">Kindly Enter Correct Data in given fields.</h6>
//             </div>

//             <div className="modal-body">
//               <div className="">
//                 <Row>
//                   {/* <div className="mx-auto">
//                     <h1 className="pb-3">Kindly fill following fields data</h1>
//                     Missing Fields
//                   </div> */}
//                   <div className={'d-flex border w-100'}>
//                     <div className={'w-50'} style={{ color: '#fff' }}>Row Number</div>
//                     <div className={'w-50'} style={{ color: '#fff' }}>Field Name</div>
//                   </div>
//                   {errorData && errorData.map((item, index) => {
//                     return (
//                       <div className={'d-flex w-100'}>
//                         <div className={'w-50'} style={{ color: '#fff' }}>{item.row_number}</div>
//                         <div className={'w-50'} style={{ color: '#fff' }}>{item.field_name}</div>
//                       </div>
//                     );
//                   })}
//                 </Row>
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const renderInvalidTransactionsPopup = () => {
//     switch (errorInvalidTxnsBoolean) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal60w"
//             size="lg"
//             show={true}
//             backdrop={true}
//             onHide={() => { setErrorInvalidTxnsBoolean(false) }}
//           >
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setErrorInvalidTxnsBoolean(false);
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up">Irrelevant Transactions</h6>
//             </div>

//             <div className="modal-body">
//               <div className="">
//                 <span className='text-primary'>Note: {invalidTransactionsCount}</span> transactions are irrelevant out of <span className='text-primary'>{totalFileData}.</span>
//                 <div className="table-responsive">
//                   <JsonToTable json={invalidTransactions} />
//                 </div>
//               </div>
//               <button className='btn btn-default'
//                 onClick={() => {
//                   setErrorInvalidTxnsBoolean(false);
//                   setFile('');
//                   setFileName('');
//                   setfileData([]);
//                   setFileDataCount(0);
//                   errorData.length = 0;
//                   setErrorData(errorData);
//                   setfileData([]);
//                   setInvalidTransactionsCount(0);
//                   setTotalFileData(0);
//                   setFileName('');
//                   setFileError('');
//                   setAmc('');
//                   setFund('');
//                 }}>Cancel</button>
//               <button className='btn btn-primary'
//                 onClick={() => {
//                   UploadTxn();
//                 }}
//                 disabled={Boolean(loading)}
//               >
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border login-txt spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     <span className="login-txt"> Posting...</span>
//                   </>
//                 ) : (
//                   <span>Continue</span>
//                 )}</button>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const [flagForMoneyMarketSecutities, setFlagForMoneyMarketSecutities] = useState(false);
//   const [secutities, setSecurities] = useState<any>([]);

//   const renderMoneyMarketSecuritiesModalPopup = () => {
//     switch (flagForMoneyMarketSecutities) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal90w"
//             show={true}
//             size="lg"
//             backdrop={true}
//             onHide={() => { setFlagForMoneyMarketSecutities(false) }}
//           >
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setFlagForMoneyMarketSecutities(false);
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up">View Money Market Security  </h6>
//             </div><div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setFlagForMoneyMarketSecutities(false);
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               {/* <h6 className="title title-up">View Security  </h6> */}
//             </div>
//             <div className="modal-body">
//               <div className="table-responsive">
//                 {secutities.length > 0 &&
//                   <div className="form-group">
//                     <table className="table transform">
//                       <thead>
//                         <tr>

//                           <th>Security Type</th>
//                           <th>Investment Type</th>
//                           <th>Type</th>
//                           <th>Symbol</th>
//                           <th>Name</th>
//                           <th>Issue Date</th>
//                           <th>Maturity Date</th>
//                           <th>Money Market Face Value</th>
//                           <th>Price</th>
//                           <th>Units</th>
//                           <th>Yield</th>
//                           <th>Coupon Rate</th>
//                           <th>Next Coupon Date</th>
//                           <th>Last Coupon date</th>
//                           <th>Primary Dealer</th>
//                           <th>Fund IPS Account</th>
//                           <th>Counter Party IPS Account</th>
//                           <th>Broker Name</th>
//                           <th>Settlement Amount</th>
//                           <th>Total Face Value</th>
//                           <th>Accrued amount</th>
//                           <th>Brokerage</th>
//                           <th>Premium Discount</th>
//                           <th>Accrued Days</th>
//                           <th>Counter Party Name</th>
//                           <th>Detail</th>
//                           <th>Principal Amount</th>
//                           <th>UnRedeem Value</th>
//                           <th>Copoun Payment/Year</th>
//                           <th>Days of Maturity</th>
//                         </tr>
//                       </thead>
//                       <tbody >
//                         {renderMoneyMarketSecurityData()}
//                       </tbody>
//                     </table>
//                   </div>}
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const renderMoneyMarketSecurityData = () => {
//     if (secutities === '[]') {
//       return;
//     }
//     if (secutities === '') {
//       return;
//     }
//     return secutities.map((items: any, index: number) => {
//       return (
//         <tr key={index}>

//           <td>{items.security_type}</td>
//           <td>{items.investment_type}</td>
//           <td style={{ width: '20%' }}>{items.type}</td>
//           <td>{items.symbol}</td>
//           <td>{items.name}</td>
//           <td>{moment(items.issue_date).format('DD-MM-YYYY')}</td>
//           <td>{moment(items.maturity_date).format('DD-MM-YYYY')}</td>
//           <td>{items.money_market_face_value === undefined ? '0' : numberWithCommas(items.money_market_face_value)}</td>
//           <td>{items.price === undefined ? '0' : numberWithCommas(items.price)}</td>
//           <td>{items.units === undefined ? '0' : numberWithCommas(items.units)}</td>
//           <td>{items.yield === undefined ? '0' : numberWithCommas(items.yield)}</td>
//           <td>{items.coupon_rate === undefined ? '0' : numberWithCommas(items.coupon_rate)}</td>
//           <td>{(items.next_coupon_date === '') ? '' : moment(items.next_coupon_date).format('DD-MM-YYYY')}</td>
//           <td>{(items.last_coupon_date === '') ? '' : moment(items.last_coupon_date).format('DD-MM-YYYY')}</td>
//           <td>{items.primary_dealer}</td>
//           <td>{items.fund_ips_account}</td>
//           <td>{items.counter_party_ips_account}</td>
//           <td>{items.broker_name}</td>
//           <td>{items.settlement_amount === undefined ? '0' : numberWithCommas(items.settlement_amount)}</td>
//           <td>{items.total_face_value}</td>
//           <td>{items.accrued_amount === undefined ? '0' : numberWithCommas(items.accrued_amount)}</td>
//           <td>{items.brokerage}</td>
//           <td>{items.premium_discount}</td>
//           <td>{items.accrued_days}</td>
//           <td>{items.counter_party_name}</td>
//           <td>{items.detail}</td>
//           <td>{items.principal_amount === undefined ? '0' : numberWithCommas(items.principal_amount)}</td>
//           <td>{items.unredeem_value}</td>
//           <td>{items.coupon_payment}</td>
//           <td>{items.days_of_maturity}</td>
//         </tr>
//       );
//     });
//   };

//   const [flagForSecutities, setFlagForSecutities] = useState(false);
//   const renderSecuritiesModalPopup = () => {
//     switch (flagForSecutities) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal90w"
//             show={true}
//             size="lg"
//             backdrop={true}
//             onHide={() => {
//               setFlagForSecutities(false);
//             }}
//           >

//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setFlagForSecutities(false);
//                   // clearTaxobject();
//                 }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up"> View Security  </h6>
//             </div>

//             <div className="modal-body">
//               <div className="table-responsive">
//                 {securites.length > 0 && (
//                   <div className="form-group">
//                     <table className="table ">
//                       <thead>
//                         <tr>
//                           <th>Type</th>
//                           <th>Inv Type</th>
//                           <th>Mkt</th>
//                           <th>Symbol</th>
//                           <th>Quantity</th>
//                           <th>Avg Rate</th>
//                           {/* <th>Broker</th> */}
//                           <th>Comm Rate</th>
//                           <th>SST On Comm</th>
//                           {/* <th>Capital Value Tax</th> */}
//                           <th>Net Rate</th>
//                           <th>Gross Amount</th>
//                           <th>Commission</th>
//                           <th>Net Amount</th>
//                         </tr>
//                       </thead>
//                       <tbody>{renderPsxData()}</tbody>
//                     </table>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const [securites, setSecurites] = useState<any>([]);
//   const renderPsxData = () => {
//     if (securites === '[]') {
//       return;
//     }
//     if (securites === '') {
//       return;
//     }
//     return securites.map((items: any, index: number) => {
//       return (
//         <tr key={index}>
//           <td>{items.type}</td>
//           <td>{items.investment_type}</td>
//           <td>{items.market}</td>
//           <td>{items.symbol}</td>
//           <td>{items.quantity}</td>
//           <td>{parseFloat(items.avg_rate).toFixed(2)}</td>
//           {/* <td>{items.broker}</td> */}
//           <td>{items.commission_rate}</td>
//           <td>{items.sst_on_commission}</td>
//           {/* <td>{items.capital_value_tax}</td> */}
//           <td>{items.net_rate}</td>
//           <td>{parseFloat(items.gross_amount).toFixed(2)}</td>
//           <td>{items.commission_charges}</td>
//           <td>{parseFloat(items.net_amount).toFixed(2)}</td>
//         </tr>
//       );
//     });
//   };

//   return (
//     <>
//       <div className="content">
//         <Row>
//           <Col md="12">
//             <Container fluid>
//               <ToastContainer limit={1} />
//               <div className="card">
//                 <div className="card-header">
//                   <h4 className="card-title">
//                     <Link to="/admin/transaction-types"> <i className="fa fa-angle-left"></i> </Link>
//                     Transaction Bulk Upload

//                     <Dropdown className="float-right">
//                       <Dropdown.Toggle variant="dark" id="dropdown-basic">
//                         Download Samples
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item href="./../../Uploader-Sample/Sale-of-unit.xlsx" download>Sale of Units</Dropdown.Item>
//                         <Dropdown.Item href="./../../Uploader-Sample/Redemption-of-unit.xlsx" download>Redemption of Units</Dropdown.Item>
//                         <Dropdown.Item href="./../../Uploader-Sample/Conversion-in.xlsx" download>Conversion In</Dropdown.Item>
//                         <Dropdown.Item href="./../../Uploader-Sample/Conversion-out.xlsx" download>Conversion Out</Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </h4>

//                 </div>
//                 <div className="card-body">
//                   <Row>
//                     <Col md="2">
//                       <div className="form-group  ">
//                         <label>Select AMC</label>
//                         <select
//                           className={"form-control " +
//                             ErrorBorder(Amc)}
//                           value={Amc}
//                           onChange={(e) => {
//                             setAmc(e.target.value);
//                             allFunds.length = 0;
//                             setAllFunds(allFunds);
//                             getFunds(e.target.value);

//                             // clear fields
//                             allFundAccounts.length = 0;
//                             setAllFundsAccounts(allFundAccounts);
//                             setFund('');
//                             setFundAccount('');
//                             setAccountTitle('');
//                             setAccountNo('');
//                             setBank('');
//                             setBranch('');

//                           }}
//                         >
//                           <option value="" defaultChecked hidden>
//                             Select AMC
//                           </option>
//                           {renderAmcDropdown()}
//                         </select>
//                       </div>
//                     </Col>

//                     <Col md="2">
//                       <div className="form-group">
//                         <label>Select Fund</label>
//                         <select
//                           className={"form-control " +
//                             ErrorBorder(fund)}
//                           onChange={(e) => {
//                             // setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
//                             setFund(e.target.value);
//                             getFundAccounts(e.target.value);

//                             // clear fields
//                             allFundAccounts.length = 0;
//                             setAllFundsAccounts(allFundAccounts);
//                             setAccountTitle('');
//                             setAccountNo('');
//                             setBank('');
//                             setBranch('');
//                           }}>
//                           <option value="" defaultChecked hidden>
//                             Select Fund
//                           </option>
//                           {renderFundsDropDown()}
//                         </select>
//                       </div>
//                     </Col>

//                     <Col md="2">
//                       <div className="form-group">
//                         <label>Select Fund Account</label>
//                         <select
//                           className={"form-control " +
//                             ErrorBorder(fundAccount)}
//                           onChange={(e) => {
//                             setFundAccount(e.target.value);
//                             getFundAccountData(e.target.value);
//                             // var AccountTitle=e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text;
//                             // setAccountTitle(AccountTitle.split('(')[1].replace(')',''));
//                           }}>
//                           <option value="" defaultChecked hidden>
//                             Select Fund Account
//                           </option>
//                           {renderFundAccountsDropDown()}
//                         </select>
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md="2">
//                       <label>AMC : {' '} {Amc}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Fund : {' '} {fund}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Fund Acount : {' '} {fundAccount}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Title : {' '} {accountTitle}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Account No. : {' '} {accountno}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Bank : {' '} {bank}</label>
//                     </Col>
//                     <Col md="2">
//                       <label>Branch : {' '} {branch}</label>
//                     </Col>
//                   </Row>
//                   <div className="">
//                     <div className="row">
//                       <div className="col-md-2">
//                         <label>Choose File</label>
//                         <div
//                           className="multi-input pointer"
//                           onClick={() => myRef1?.current?.click()}
//                         >
//                           <div className="form-group">
//                             <div className="form-control">
//                               {fileName === '' ? 'Upload File' : fileName}
//                             </div>
//                             <input
//                               type="file"
//                               ref={myRef1}
//                               style={{ display: 'none' }}
//                               multiple={false}
//                               onChange={async (e) => {
//                                 upload(e);
//                               }}
//                             />
//                             {fileError ? (
//                               <p className="error-labels error-message2">{fileError}</p>
//                             ) : (
//                               ''
//                             )}

//                           </div>
//                         </div>
//                         {fileUploadingFlag && <img src="assets/loader.svg" width="50" />}
//                       </div>
//                     </div>
//                   </div>
//                   {fileDataCount <= 0 ? (
//                     ''
//                   ) : (
//                     <div className="table-responsive mt-3">
//                       {
//                         (txnrType === 'debtmarketinvestment' || txnrType === 'equityinvestment') ? (
//                           txnrType === 'debtmarketinvestment' ? (
//                             <table className="table ">
//                               <thead>
//                                 <tr  >
//                                   <th>Transaction Type</th>
//                                   <th>Instruction Date</th>
//                                   <th>Settlement Date</th>
//                                   <th>Counter Account Title</th>
//                                   <th>Counter Account Number</th>
//                                   <th>Counter Bank</th>
//                                   <th>Counter Branch</th>
//                                   <th>Mode of Payment</th>
//                                   <th>Instrumnet Type</th>
//                                   <th>Instrument No</th>
//                                   <th>Instrument Date</th>
//                                   <th>Gross Amount</th>
//                                   <th>Net Amount</th>
//                                   <th>Payment Type</th>
//                                   <th>Securities</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {
//                                   fileData.map((item) => {
//                                     return (
//                                       <tr>
//                                         <td>{item.TransactionType}</td>
//                                         <td>{item.InstructionDate}</td>
//                                         <td>{isDateValid(moment(item.settlement_date).format('YYYY-MM-DD')) === '' ? moment(date).format('YYYY-MM-DD') : isDateValid(moment(item.settlement_date).format('YYYY-MM-DD'))}</td>
//                                         <td>{item.CounterAccountTitle}</td>
//                                         <td>{item.CounterAccountNumber}</td>
//                                         <td>{item.CounterBank}</td>
//                                         <td>{item.CounterBranch}</td>
//                                         <td>{item.ModeOfPayment}</td>
//                                         <td>{item.InvestmentType}</td>
//                                         <td>{item.instrument_no}</td>
//                                         <td>{item.InstructionDate}</td>
//                                         <td>{item.GrossAmount}</td>
//                                         <td>{item.NetAmount}</td>
//                                         <td>{item.PaymentType}</td>
//                                         <td>
//                                           <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
//                                             onClick={() => {
//                                               setFlagForMoneyMarketSecutities(true);
//                                               setSecurities(JSON.parse(item.Securities))
//                                             }}
//                                           >
//                                             <i className="fa fa-eye"> </i>
//                                           </button>
//                                         </td>
//                                       </tr>
//                                     )
//                                   })
//                                 }

//                               </tbody>
//                             </table>
//                           ) : (
//                             <table className="table ">
//                               <thead>
//                                 <tr  >
//                                   <th>Transaction Type</th>
//                                   <th>Instruction Date</th>
//                                   <th>Settlement Date</th>
//                                   <th>Counter Account Title</th>
//                                   <th>Counter Account Number</th>
//                                   <th>Counter Bank</th>
//                                   <th>Counter Branch</th>
//                                   <th>Mode of Payment</th>
//                                   <th>Instrumnet Type</th>
//                                   <th>Settlement By</th>
//                                   <th>Sales Tax Collector</th>
//                                   <th>Gross Amount</th>
//                                   <th>Net Amount</th>
//                                   <th>Brokerage Commission</th>
//                                   <th>SST on Commission</th>
//                                   <th>Payment Type</th>
//                                   <th>Securities</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {
//                                   fileData.map((item) => {
//                                     return (
//                                       <tr>
//                                         <td>{item.TransactionType}</td>
//                                         <td>{item.InstructionDate}</td>
//                                         <td>{item.settlement_date}</td>
//                                         <td>{item.CounterAccountTitle}</td>
//                                         <td>{item.CounterAccountNumber}</td>
//                                         <td>{item.CounterBank}</td>
//                                         <td>{item.CounterBranch}</td>
//                                         <td>{item.ModeOfPayment}</td>
//                                         <td>{item.InvestmentType}</td>
//                                         <td>{item.SettlementBy}</td>
//                                         <td>{item.SalesTaxCollector}</td>
//                                         <td>{item.GrossAmount}</td>
//                                         <td>{item.NetAmount}</td>
//                                         <td>{item.BrokerageCommission}</td>
//                                         <td>{item.SSTonCommission}</td>
//                                         <td>{item.PaymentType}</td>
//                                         <td>
//                                           <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
//                                             onClick={() => {
//                                               setFlagForSecutities(true);
//                                               setSecurites(JSON.parse(item.Securities))
//                                             }}
//                                           >
//                                             <i className="fa fa-eye"> </i>
//                                           </button>
//                                         </td>
//                                       </tr>
//                                     )
//                                   })
//                                 }

//                               </tbody>
//                             </table>
//                           )

//                         ) : (
//                           <JsonToTable json={fileData} />
//                         )
//                       }

//                     </div>
//                   )}

//                   Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>

//                   <div className="mt-4">
//                     <button
//                       className="btn btn-primary "
//                       onClick={() => {
//                         UploadTxn();
//                       }}
//                       disabled={Boolean(loading)}
//                     >
//                       {loading ? (
//                         <>
//                           <span
//                             className="spinner-border login-txt spinner-border-sm"
//                             role="status"
//                             aria-hidden="true"
//                           ></span>
//                           <span className="login-txt"> Loading...</span>
//                         </>
//                       ) : (
//                         <span>Upload</span>
//                       )}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               {renderErrorPopup()}
//               {renderInvalidTransactionsPopup()}
//               {renderMoneyMarketSecuritiesModalPopup()}
//               {renderSecuritiesModalPopup()}
//               {renderUnitsErrorPopup()}
//             </Container>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// };

// export default BulkUplaod;
