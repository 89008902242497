import React, { useRef } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { addStaff } from '../../stores/services/staff.service';
import PhoneInput from 'react-phone-input-2';
const AddStaff = () => {
    const history = useHistory();
    const baseEmail = sessionStorage.getItem("email") || "";
    //  Signature Code
    const myRef = useRef<HTMLInputElement>(null);
    const myRef1 = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState('');
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState('');

    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('')
    const [fatherName, setFatherName] = useState('')
    const [cnic, setCnic] = useState('')
    const [designation, setDesignation] = useState('')
    const [selectedGroup, setSelectedGroup] = useState('')
    const [signature, setSignature] = useState<any | null>('');

    const [picture, setPicture] = useState<any | null>('');
    const [pictureFileName, setPictureFileName] = useState('');
    const [pictureFile, setPictureFile] = useState('');
    // const [signature, setSignature] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('');
    const [phoneNo, setPhoneNo] = useState('');

    const [nameError, setNameError] = useState('')
    const [cnicError, setCnicError] = useState('')
    // const [signatureError, setSignatureError] = useState('')
    const [emailError, setEmailError] = useState('');

    const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

    const handleChange = (e) => {
        setSelectedGroup(e.target.value);
    };

    const validate = () => {
        let nameError = '';
        let cnicError = '';
        // let signatureError = '';
        let emailError = '';
        name.trim() === '' ? (nameError = 'Required') : (nameError = '');
        cnic.trim() === '' ? (cnicError = 'Required') : (cnicError = '');
        // JSON.stringify(signature).trim() === '' || null ? (signatureError = 'Required') : (signatureError = '');
        email.trim() === '' ? (emailError = 'Required') : (emailError = '');
        const cnicWithoutHyphen = cnic.replace('_', '').replace(/-/g, ''); // Remove all hyphens
        if (cnicWithoutHyphen.length !== 13) {
            cnicError = 'Enter complete CNIC';
        }

        if (nameError || cnicError || emailError) {
            setNameError(nameError);
            setCnicError(cnicError);
            // setSignatureError(signatureError);
            setEmailError(emailError);
            return false;
        } else {
            return true;
        }
    };

    const AddStaff = async () => {
        const isValid = validate()
        if (isValid) {
            setLoading(true);
            const timestamp = new Date().toISOString();
            try {
                const response: any = await addStaff(
                    baseEmail,
                    name,
                    fatherName,
                    email,
                    designation,
                    contact,
                    phoneNo.replace('+', ''),
                    selectedGroup,
                    cnic,
                    `${name.replaceAll(" ", "")}_${timestamp}`,
                    timestamp,
                    JSON.stringify(signature) || "",
                    'active',
                    JSON.stringify(picture) || "",
                );
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    setName('');
                    setFatherName('');
                    setCnic('');
                    setDesignation('');
                    setSignature('');
                    setFileName('');
                    setEmail('');
                    setContact('');
                    setPhoneNo('');
                    setSelectedGroup('');
                    setPicture('');
                    setPictureFileName('');
                    setTimeout(() => {
                        history.push('/admin/staff-management');
                        setLoading(false);
                    }, 500)
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.data.statusCode) {
                    toast.error(error.response.data.message[0]);
                } else {
                    toast.error(error.response.data.message);
                }
            }

        }
    }

    const upload = (e) => {
        let file = e?.target.files[0];
        let filesize = parseInt(file.size);
        if (filesize <= 50000) {
            let type = file.name.substring(file.name.lastIndexOf('.') + 1);

            if (type === 'PNG' || type === 'png' || type === 'jpg' || type === 'jpeg') {
                //code here
                setFile(file);
                setFileName(file.name)
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = function () {
                    // setSignature(reader.result);
                    setSignature({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        content: reader.result,
                    });
                };
                // setSignatureError('')
            } else {

                toast.error('Invalid Format')
            }
        } else {
            toast.error("File size should be less than 50kb");
        }

    };
    const uploadPicture = (e) => {
        let picture_file = e?.target.files[0];
        let filesize = parseInt(picture_file.size);
        if (filesize <= 100000) {
            let type = picture_file.name.substring(picture_file.name.lastIndexOf('.') + 1);

            if (type === 'PNG' || type === 'png' || type === 'jpg' || type === 'jpeg') {
                //code here
                setPictureFile(picture_file);
                setPictureFileName(picture_file.name)
                const reader = new FileReader();
                reader.readAsDataURL(picture_file);

                reader.onload = function () {
                    // setPicture(reader.result);
                    setPicture({
                        name: picture_file.name,
                        type: picture_file.type,
                        size: picture_file.size,
                        content: reader.result,
                    });
                };
            } else {

                toast.error('Invalid Format')
            }
        } else {
            toast.error("File size should be less than 100kb");
        }

    };


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card">
                                <div className="card-header title-row">
                                    <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                                        history.replace('/admin/staff-management')
                                    }}></i>Add - Staff</h4>

                                    <Link to="/admin/staff-management" className="btn btn-primary btn-sm ml-auto" replace>
                                        <i className="fa fa-eye mr-2"></i>View All
                                    </Link>
                                </div>

                                <div className="card-body">
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Name <span className="color-amber">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                                        }`}
                                                    value={name}
                                                    onChange={(e) => {
                                                        setName(e.target.value)
                                                        setNameError('');
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Father Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Father Name"
                                                    className={`form-control  w-100`}
                                                    value={fatherName}
                                                    onChange={(e) => {
                                                        setFatherName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label> CNIC <span className="color-amber">*</span></label>
                                                <div  >
                                                    <InputMask
                                                        className={`form-control  w-100 ${cnicError || cnicError.includes('Enter complete CNIC') ? 'required-border' : ''
                                                            }`}
                                                        mask="99999-9999999-9"
                                                        placeholder="99999-9999999-9"
                                                        value={cnic}
                                                        onChange={(e) => {
                                                            setCnic(e.target.value);
                                                            setCnicError('');
                                                        }}></InputMask>
                                                    <span style={{ color: 'red' }}>{cnicError}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Email  <span className="color-amber">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    className={`form-control  w-100 ${emailError ? 'required-border' : ''
                                                        }`}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setEmailError('')
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Mobile No.</label>
                                                <PhoneInput
                                                    specialLabel=''
                                                    inputClass={`form-control  w-100`}
                                                    country={'pk'}
                                                    placeholder="+92 123-1234567"
                                                    value={contact}
                                                    onChange={(e) => {
                                                        setContact(e)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Phone No.</label>
                                                <input
                                                    className={`form-control  w-100`}
                                                    value={phoneNo}
                                                    placeholder='+92 52-99999999'
                                                    onChange={(e) => {
                                                        const val = e.target.value;

                                                        // Check if the value is a positive whole number or empty (for deleting content)
                                                        if (/^(\+)?[0-9\s-]*$/.test(val) && val.length <= 15) {
                                                            setPhoneNo(val);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Designation </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Designation"
                                                    className={`form-control  w-100`}
                                                    value={designation}
                                                    onChange={(e) => {
                                                        setDesignation(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Select Blood Group</label>
                                                <select className="form-control"
                                                    value={selectedGroup} onChange={handleChange}>
                                                    <option value="" disabled>Select your blood group</option>
                                                    {bloodGroups.map(group => (
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Blood Group</label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Blood Group"
                                                    className={`form-control  w-100`}
                                                    value={selectedGroup}
                                                    onChange={(e) => {
                                                        setSelectedGroup(e.target.value)
                                                    }}
                                                />
                                            </div> */}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Signature<small className="color-amber">(PNG or JPG of upto 50KB)</small></label>
                                                <div
                                                    className="multi-input pointer"
                                                    onClick={() => myRef?.current?.click()}
                                                >
                                                    <div className="form-group">
                                                        <div className={`form-control  w-100 
                                                        `}>
                                                            {fileName === '' ? 'Upload File' : fileName}
                                                        </div>
                                                        <input
                                                            type="file"
                                                            ref={myRef}
                                                            style={{ display: 'none' }}
                                                            multiple={false}
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => {
                                                                setSignature('');
                                                                upload(e);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {signature ? (
                                                <img src={signature?.content} className="rounded" alt="spicemen" width="100px" height="100px" />
                                            ) : (
                                                ''
                                            )}
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <label>Picture<small className="color-amber">(PNG or JPG of upto 100KB)</small></label>
                                                <div
                                                    className="multi-input pointer"
                                                    onClick={() => myRef1?.current?.click()}
                                                >
                                                    <div className="form-group">
                                                        <div className={`form-control  w-100`}>
                                                            {pictureFileName === '' ? 'Upload File' : pictureFileName}
                                                        </div>
                                                        <input
                                                            type="file"
                                                            ref={myRef1}
                                                            style={{ display: 'none' }}
                                                            multiple={false}
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => {
                                                                setPicture('');
                                                                uploadPicture(e);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {picture ? (
                                                <img src={picture?.content} className="rounded" alt="spicemen" width="100px" height="100px" />
                                            ) : (
                                                ''
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary ml-3"
                                        onClick={AddStaff}
                                        disabled={Boolean(loading)}
                                    >
                                        {false ? (
                                            <>
                                                <span
                                                    className="spinner-border login-txt spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="login-txt"> Loading...</span>
                                            </>
                                        ) : (
                                            <span>Create</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Container >

                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AddStaff;