import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { equityInvestmentTransactionReport } from '../../stores/services/report.service';
import { txnDropDown , getReuiredFieldForDropDown} from './fields';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { numberWithCommas, sortAMCS } from '../../utils/customFunction';

const EquityInvestmentTransactionReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [equityInvestmentData, setEquityInvestmentData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [amc_code, setAmcCode] = useState('');
  const [fund, setFund] = useState('');
  const [fund_code, setFundCode] = useState('');
  const [type, setType]= useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [settlementFrom, setSettlementFrom] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [settlementTo, setSettlementTo] = useState(moment(date).format('YYYY-MM-DD'));

  const [pageSize, setPageSize]= useState('Letter');
  const [oriantation, setOriantation]= useState<any>(true);
  let [allDropdownColumns, setAllDropdownColumns] = useState<any>([]);
  let [selectedFields, setSelectedFields] = useState<any>([]);
  let [headerColumns, setHeaderCoumns] = useState<any>([]);

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resType, setResType]= useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [quantity, setQuantity] = useState('0');
  const [avg_rate, setAvgRate] = useState('0');
  const [commission_rate, setCommissionRate] = useState('0');
  const [sst_on_commission, setSSTonCommission] = useState('0');
  const [net_rate, setNetRate] = useState('0');
  const [gross_amount, setGrossAmount] = useState('0');
  const [commission_charges, setCommissionCharges] = useState('0');
  const [net_amount, setNetAmount] = useState('0');


        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           let sortAMCSByName  = await sortAMCS(amcResponse.data.data)
           setAllAmc(sortAMCSByName);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
            }

            let fieldsData =  await getReuiredFieldForDropDown('equityinvestmentdetailreport');
            setHeaderCoumns(fieldsData);

          }
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_','')}>
                {item.name}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }

  //code for search data for Reports

  const searchData = async () => {  
    try {
      setLoading(true);
      equityInvestmentData.length=0;
      setEquityInvestmentData(equityInvestmentData);
      const response = await equityInvestmentTransactionReport(email, amc_code, fund_code,type, fromDate, toDate, settlementFrom, settlementTo);
      setEquityInvestmentData(response.data.data);
      setResAmc(Amc);
      setResFund(fund);
      setResType(type);
      setResFromDate(fromDate);
      setResToDate(toDate);
      setSelectedFields(headerColumns);
      setAllDropdownColumns(headerColumns);
      
      let data:any=[];
      let quantity=0, avg_rate=0, commission_rate=0, sst_on_commission=0, net_rate=0,gross_amount=0,commission_charges=0, net_amount=0;
      data=response.data.data;
      data.map((item:any, index:number)=>{
        if(item.net_amount === 'NaN'){
          item.net_amount = '0';
        }
        quantity+=parseFloat(item.quantity || '0');
        avg_rate+=parseFloat(item.avg_rate || '0');
        commission_rate+=parseFloat(item.commission_rate || '0');
        sst_on_commission+=parseFloat(item.sst_on_commission || '0');
        net_rate+=parseFloat(item.net_rate || '0');
        gross_amount+=parseFloat(item.gross_amount || '0');
        commission_charges+=parseFloat(item.commission_charges || '0');
        net_amount+=parseFloat(item.net_amount || '0');
      });
      setQuantity(quantity.toFixed(2));
      setAvgRate(avg_rate.toFixed(2));
      setCommissionRate(commission_rate.toFixed(4));
      setSSTonCommission(sst_on_commission.toFixed(2));
      setNetRate(net_rate.toFixed(2));
      setGrossAmount(gross_amount.toFixed(2));
      setCommissionCharges(commission_charges.toFixed(2));
      setNetAmount(net_amount.toFixed(2));
      setLoading(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
        setLoading(false);
    }
  }


  const renderDropdownValues = (selectedCol, transaction) => {
    let res = transaction[selectedCol.value];
    
    if (selectedCol.label.includes('date') || selectedCol.label.includes('Date')) {
      res = moment(transaction[selectedCol.value]).format('DD-MMM-YYYY');
    }
    if(selectedCol.value === 'quantity' || selectedCol.value === 'avg_rate' || selectedCol.value === 'commission_rate' || selectedCol.value === 'sst_on_commission' 
    || selectedCol.value==='net_rate' || selectedCol.value==='gross_amount' || selectedCol.value==='commission_charges' || selectedCol.value === 'net_amount'){
      res = numberWithCommas(transaction[selectedCol.value] || '0')
    }
    // if (selectedCol.value === 'txnr_type') {
    //   res = CapTxnType(transaction[selectedCol.value]);
    // }
    return res;
  };

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <Row>
            <Col md='8'>
              <div className="card-header">
                <h4 className="card-title">Equity Investment Transaction Report</h4>
              </div>
            </Col>
            <Col md='2'>
            {/* <div className='mt-2'>
                      <label >Print Setting</label> <br />
                        <button className=" btn-round btn-icon btn btn-success" 
                         onClick={(e)=>{}}
                          > <i className="fa fa-plus"></i>  </button>  
                      </div> */}





            {/* const renderModalForSecuirtyPopup = () => {
    switch (viewAddModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  // setViewAddModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Security </h6>
            </div>


            <div className="modal-body">
              <div className="">
               
              
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      // securitesEntity['avg_rate'] = closeRate;
                      // setSecuritesEntity(securitesEntity);
                      // addSecurityInArray();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  }; */}
              <div className="form-group  mt-3 mr-3">
                    <select
                      className="form-control"
                      onChange={() => {
                        setOriantation(!oriantation);
                        equityInvestmentData.length=0;
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Orientation
                      </option>
                      <option value={oriantation}>Portrait</option>
                      <option value={oriantation}>Landscap</option>
                    </select>
                </div>
            </Col>
            <Col md="2">
              <div className="form-group  mt-3 mr-3">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSize(e.target.value);
                      equityInvestmentData.length=0;
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select Paper Size
                    </option>
                    <option value="A4">A4</option>
                    <option value="A3">A3</option>
                    <option value="Letter">Letter</option>
                    <option value="Legal">Legal</option>
                  </select>
                </div>
                    </Col>
            </Row>

            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                    setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                    setAmcCode(e.target.value);
                    getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                        setFundCode(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Type</label>
                  <select className="form-control w-100"
                    onChange={(e) => {
                        setType(e.target.value);
                     }}
                     >
                        <option value="" defaultChecked hidden>
                        {' '}
                        Select Type
                        </option>
                        <option value="Buy">Buy</option>
                        <option value="Sell">Sell</option>
                        {/* <option value="Maturity">Maturity</option>
                        <option value="Coupon">Coupon</option> */}
                     </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group  ">
                  <label>Settlement Date From</label>
                  <input
                    type="date"
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromDate(date);
                        setSettlementFrom(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Settlement Date To</label>
                  <input
                    type="date"
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToDate(date);
                        setSettlementTo(date)
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

          {equityInvestmentData.length > 0 && (
          <Row>
                    <Col md="8"></Col>
                    
                    <Col md="4">
                      <div className="mt-3 mr-3">
                        {equityInvestmentData.length > 0 ? (
                          <MultiSelect
                            options={allDropdownColumns}
                            value={selectedFields}
                            onChange={setSelectedFields}
                            labelledBy={'Select Columns'}
                            className="multi-select"
                            overrideStrings={{
                              selectAll: 'Select all Columns',
                              selectSomeItems: 'Select Columns',
                            }}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Col>
                  </Row>
          )}

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize={`${pageSize}`} margin="0.5cm" landscape={oriantation} scale={0.6} repeatHeaders={true} fileName={"Equity-Investment-Transaction-Report-"+date+".pdf"} ref={pdfExportComponent}>
              {equityInvestmentData.length> 0 ? (
                <>
                <ReportHeader title="Equity Investment Transaction Report"/>
                {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Equity Investment Transaction Report</h3>
                  </div> */}

                  <Row>
                    <Col md="3" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="3">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="9">
                          <span className="text-nowrap">{resAmc}</span>
                        </Col>
                      </Row>
                    </Col>
                  <Col md='4'>
                  </Col>
                    <Col md="3" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            Date From:
                            </span>
                          </Col>
                          <Col md="7">
                            <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                  </Col>
                      <Col md="3" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="3">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Fund:
                            </span>
                          </Col>
                          <Col md="9">
                            <span className="text-nowrap" >{resFund}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md='4'>
                  </Col>
                      <Col md="3" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Date To:</span>
                          </Col>
                          <Col md="7">
                            <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                  </Col>
                      <Col md="3" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="3">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Type:
                            </span>
                          </Col>
                          <Col md="9">
                            <span className="text-nowrap">{resType}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md='4'>
                  </Col>
                      <Col md="3" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                          </Col>
                          <Col md="7">
                            <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row>
                  
                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr style={{borderTop: "2px outset black", borderBottom: "2px outset black"}} >
                        {selectedFields.map((item, index) => {
                          return(
                            <th align='left' key={index} className='text-nowrap'>
                              <span 
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                {item.label.toString()}
                              </span>
                            </th>
                          )
                               
                          })}
                        {/* {selectedFields.map((item, index) => {
                            if(item.value==='txn_id' || item.value==='fund_code' || item.value==='created_at' || item.value==='settlement_date' || item.value==='investment_type' || item.value==='market' || item.value==='symbol') { return (
                                <th align='left' key={index} className='text-nowrap'>
                                  <span 
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {item.label.toString()}
                                  </span>
                              </th>
                               )}
                               else{ return (
                                <th align='right'  key={index} className='text-nowrap'  >
                                      <span 
                                        style={{
                                          textTransform: 'capitalize'
                                        }}
                                      >
                                        {item.label.toString()}
                                      </span>
                              </th>
                                ) }
                          })} */}
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                      {equityInvestmentData.map((transaction: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                {selectedFields.map((item, i) => {
                                  //  return{
                                      if(item.value==='txn_id' || item.value==='fund_code' || item.value==='created_at' || item.value==='settlement_date' || item.value==='investment_type' || item.value==='market' || item.value==='symbol') { return (
                                        <td className='text-nowrap' align='left' style={{padding:'2px 0 2px 0'}} key={i}>
                                          {renderDropdownValues(item, transaction)}
                                          </td>
                                       )}
                                       else{ return (
                                        <td className='text-nowrap' align='right' style={{padding:'2px 0 2px 0'}} key={i}>
                                          {renderDropdownValues(item, transaction)}
                                        </td>
                                        ) }
                                })}
                              </tr>
                            </>
                          );
                        })}
                        
                      </tbody>
                      <tr className='text-nowrap' style={{borderTop: "2px solid black " , borderBottom: "2px solid black " }} >
                          {selectedFields.map(item=>{
                              if(item.value=='quantity'){
                                return <td align='right' style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(quantity)}</td>
                              }else if(item.value=='avg_rate'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(avg_rate)}</td>
                              }else if(item.value=='commission_rate'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(commission_rate)}</td>
                              }else if(item.value=='sst_on_commission'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(sst_on_commission)}</td>
                              }else if(item.value=='net_rate'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(net_rate)}</td>
                              }else if(item.value=='gross_amount'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(gross_amount)}</td>
                              }else if(item.value=='commission_charges'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(commission_charges)}</td>
                              }else if(item.value=='net_amount'){
                                return <td align='right'  style={{fontSize : "9px" , fontWeight: "bold"}} >{numberWithCommas(net_amount)}</td>
                              }else{
                              return <td></td>
                              }
                          })}
                      </tr>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
             Print
            </button>

            {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EquityInvestmentTransactionReport;


// import React, { useEffect } from 'react';
// import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { Container, Row, Col } from 'reactstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { BsTable } from 'react-icons/bs';
// import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
// import MultiSelect from 'react-multi-select-component';
// import moment from 'moment';
// import { getAmc } from '../../stores/services/amc.service';
// import { getFundByAmc } from '../../stores/services/funds.service';
// import { equityInvestmentTransactionReport } from '../../stores/services/report.service';
// import { txnDropDown , getReuiredFieldForDropDown} from './fields';
// import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import ReportHeader from './report-header';
// import { numberWithCommas, sortAMCS } from '../../utils/customFunction';

// const EquityInvestmentTransactionReport = () => {
//   const pdfExportComponent = React.useRef<PDFExport>(null);
//   const [loading, setLoading] = useState(false);
//   const email = sessionStorage.getItem('email') || '';
//   let date = new Date();
//   const [equityInvestmentData, setEquityInvestmentData] = useState<any>([]);
//   const [allAmc, setAllAmc] = useState<any>([]);
//   const [allFunds, setAllFunds] = useState<any>([]);
//   const [Amc, setAmc] = useState('');
//   const [amc_code, setAmcCode] = useState('');
//   const [fund, setFund] = useState('');
//   const [fund_code, setFundCode] = useState('');
//   const [type, setType]= useState('');
//   const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
//   const [settlementFrom, setSettlementFrom] = useState(moment(date).format('YYYY-MM-DD'));
//   const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));
//   const [settlementTo, setSettlementTo] = useState(moment(date).format('YYYY-MM-DD'));

//   let [allDropdownColumns, setAllDropdownColumns] = useState<any>([]);
//   let [selectedFields, setSelectedFields] = useState<any>([]);
//   let [headerColumns, setHeaderCoumns] = useState<any>([]);

//   const [resAmc, setResAmc] = useState('');
//   const [resFund, setResFund] = useState('');
//   const [resType, setResType]= useState('');
//   const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
//   const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

//   const [quantity, setQuantity] = useState('0');
//   const [avg_rate, setAvgRate] = useState('0');
//   const [commission_rate, setCommissionRate] = useState('0');
//   const [sst_on_commission, setSSTonCommission] = useState('0');
//   const [net_rate, setNetRate] = useState('0');
//   const [gross_amount, setGrossAmount] = useState('0');
//   const [commission_charges, setCommissionCharges] = useState('0');
//   const [net_amount, setNetAmount] = useState('0');


//         useEffect(()=>{
//           const getAllAMC=async()=>{
//             try{
//            const amcResponse = await getAmc(email, '', '', '');
//            let sortAMCSByName  = await sortAMCS(amcResponse.data.data)
//            setAllAmc(sortAMCSByName);
//             }catch(err:any){
//               if(err.response !== undefined ) {
//                 toast.error(err.response.data.message);	
//               }else {
//                 toast.error("Request Failed!")
//               }
//             }

//             let fieldsData =  await getReuiredFieldForDropDown('equityinvestmentdetailreport');
//             setHeaderCoumns(fieldsData);

//           }
//             getAllAMC();
//         },[])

//         let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

//         const CapTxnType = (type: any) => {
//             let i, txType;
//             for (i = 0; i < txnTypes.length; i++) {
//             if (txnTypes[i].api_name === type) {
//                 txType = txnTypes[i].dis_name;
//                 break;
//             }
//             }
//             return txType;
//         };

//         const renderAmcDropdown = () => {
//             return allAmc.map((item: any, index: number) => {
//             return (
//                 <option key={index} value={item.amc_code.replace('AMC_','')}>
//                 {item.name}
//                 </option>
//             );
//             });
//         };

//         const getFunds=async(code:any)=>{
//             try{
//                 const response = await getFundByAmc(email, code);
//                 setAllFunds(response.data.data);
//             }catch(error){

//             }
//         }

//         const renderFundsDropDown=()=>{
//             return allFunds.map((item: any, index: number) => {
//                 return (
//                     <option key={index} value={item.symbol_code}>
//                     {item.fund_name} - {item.symbol_code}
//                     </option>
//                 );
//           });
//         }

//   //code for search data for Reports

//   const searchData = async () => {  
//     try {
//       setLoading(true);
//       equityInvestmentData.length=0;
//       setEquityInvestmentData(equityInvestmentData);
//       const response = await equityInvestmentTransactionReport(email, amc_code, fund_code,type, fromDate, toDate, settlementFrom, settlementTo);
//       setEquityInvestmentData(response.data.data);
//       setResAmc(Amc);
//       setResFund(fund);
//       setResType(type);
//       setResFromDate(fromDate);
//       setResToDate(toDate);
//       setSelectedFields(headerColumns);
//       setAllDropdownColumns(headerColumns);
      
//       let data:any=[];
//       let quantity=0, avg_rate=0, commission_rate=0, sst_on_commission=0, net_rate=0,gross_amount=0,commission_charges=0, net_amount=0;
//       data=response.data.data;
//       data.map((item:any, index:number)=>{
//         quantity+=parseFloat(item.quantity || '0');
//         avg_rate+=parseFloat(item.avg_rate || '0');
//         commission_rate+=parseFloat(item.commission_rate || '0');
//         sst_on_commission+=parseFloat(item.sst_on_commission || '0');
//         net_rate+=parseFloat(item.net_rate || '0');
//         gross_amount+=parseFloat(item.gross_amount || '0');
//         commission_charges+=parseFloat(item.commission_charges || '0');
//         net_amount+=parseFloat(item.net_amount || '0');
//       });
//       setQuantity(quantity.toFixed(2));
//       setAvgRate(avg_rate.toFixed(2));
//       setCommissionRate(commission_rate.toFixed(4));
//       setSSTonCommission(sst_on_commission.toFixed(2));
//       setNetRate(net_rate.toFixed(2));
//       setGrossAmount(gross_amount.toFixed(2));
//       setCommissionCharges(commission_charges.toFixed(2));
//       setNetAmount(net_amount.toFixed(2));
//       setLoading(false);
//     } catch (error) {
//         setLoading(false);
//     }
//   }


//   const renderDropdownValues = (selectedCol, transaction) => {
//     let res = transaction[selectedCol.value];
    
//     if (selectedCol.label.includes('date') || selectedCol.label.includes('Date')) {
//       res = moment(transaction[selectedCol.value]).format('DD-MMM-YYYY');
//     }
//     if(selectedCol.value === 'quantity' || selectedCol.value === 'avg_rate' || selectedCol.value === 'commission_rate' || selectedCol.value === 'sst_on_commission' 
//     || selectedCol.value==='net_rate' || selectedCol.value==='gross_amount' || selectedCol.value==='commission_charges' || selectedCol.value === 'net_amount'){
//       res = numberWithCommas(transaction[selectedCol.value] || '0')
//     }
//     // if (selectedCol.value === 'txnr_type') {
//     //   res = CapTxnType(transaction[selectedCol.value]);
//     // }
//     return res;
//   };

//   return (
//     <>
//     <div className="content">
//       <Row>
//         <Col md="12">
//       <Container fluid>
//         <ToastContainer limit={1} />
//         <div className="">
//           <div className="card">
//             <div className="card-header">
//               <h4 className="card-title">Equity Investment Transaction Report</h4>
//             </div>
//             <div className="card-body">
//             <Row>
//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Select AMC</label>
//                   <select
//                     className="form-control"
//                     onChange={(e) => {
//                     setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
//                     setAmcCode(e.target.value);
//                     getFunds(e.target.value);
//                     }}
//                   >
//                     <option value="" defaultChecked hidden>
//                       Select AMC
//                     </option>
//                     {renderAmcDropdown()}
//                   </select>
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group">
//                   <label>Select Fund</label>
//                   <select
//                     className="form-control"
//                     onChange={(e) => {
//                         setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
//                         setFundCode(e.target.value);
//                     }}>
//                       <option value="" defaultChecked hidden>
//                       Select Fund
//                     </option>
//                       {renderFundsDropDown()}
//                   </select>
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group">
//                   <label>Select Type</label>
//                   <select className="form-control w-100"
//                     onChange={(e) => {
//                         setType(e.target.value);
//                      }}
//                      >
//                         <option value="" defaultChecked hidden>
//                         {' '}
//                         Select Type
//                         </option>
//                         <option value="Buy">Buy</option>
//                         <option value="Sell">Sell</option>
//                         {/* <option value="Maturity">Maturity</option>
//                         <option value="Coupon">Coupon</option> */}
//                      </select>
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Settlement Date From</label>
//                   <input
//                     type="date"
//                     defaultValue={fromDate}
//                     className="form-control"
//                     onChange={(e) => {
//                         let date = moment(e.target.value).format('YYYY-MM-DD');
//                         setFromDate(date);
//                         setSettlementFrom(date);
//                     }}
//                   />
//                 </div>
//               </Col>
//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Settlement Date To</label>
//                   <input
//                     type="date"
//                     defaultValue={toDate}
//                     className="form-control"
//                     onChange={(e) => {
//                         let date = moment(e.target.value).format('YYYY-MM-DD');
//                         setToDate(date);
//                         setSettlementTo(date)
//                     }}
//                   />
//                 </div>
//               </Col>
            
//               <Col md="2">
//             <div className=" ">
//               {/* here Search Button */}
//               <button
//                 className="btn btn-primary mt-4"
//                 onClick={() => {
//                   searchData();
//                 }}
//                 disabled={Boolean(loading)}
//               >
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border login-txt spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     <span className="login-txt"> Loading...</span>
//                   </>
//                 ) : (
//                   <span>Search</span>
//                 )}
//               </button>
//             </div>
//             </Col>
//             </Row>
//             {/* End */}
//           </div>
//           </div>

//           {equityInvestmentData.length > 0 && (
//           <Row>
//                     <Col md="8"></Col>
//                     <Col md="4">
//                       <div className="mt-3 mr-3">
//                         {equityInvestmentData.length > 0 ? (
//                           <MultiSelect
//                             options={allDropdownColumns}
//                             value={selectedFields}
//                             onChange={setSelectedFields}
//                             labelledBy={'Select Columns'}
//                             className="multi-select"
//                             overrideStrings={{
//                               selectAll: 'Select all Columns',
//                               selectSomeItems: 'Select Columns',
//                             }}
//                           />
//                         ) : (
//                           <div></div>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//           )}

//           {/* here Search results for report Data */}
//           <div className="card mt-3"> 
//             <div className="card-body">
//             <div
//               id="printDiv"
//               className="p-4"
//               style={{ background: 'white', color: 'black' }}
//             >
//               <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Money-Market-Transaction-Report-"+date+".pdf"} ref={pdfExportComponent}>
//               {equityInvestmentData.length> 0 ? (
//                 <>
//                 <ReportHeader title="Equity Investment Transaction Report"/>
//                 {/* <div className="title-row d-flex justify-content-center">
//                  <img src={DCClogo} alt="MACS-DCC" height="150" />
//                  </div>
//                   <div className="title-row m-4 d-flex justify-content-center">
//                     <h3 className="text-black">Equity Investment Transaction Report</h3>
//                   </div> */}

//                   <Row>
//                     <Col md="4" style={{ marginLeft: '70px' }}>
//                       <Row>
//                         <Col md="5">
//                           <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                             AMC:
//                           </span>
//                         </Col>
//                         <Col md="6">
//                           <span className="text-nowrap">{resAmc}</span>
//                         </Col>
//                       </Row>
//                     </Col>

//                     <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                             Date From:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               Fund:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap" >{resFund}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Date To:</span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               Type:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{resType}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                   </Row>
                  
//                   <div
//                     style={{
//                       overflowX: 'auto',
//                       overflowY: 'hidden',
//                     }}
//                   >
//                     <table className="report" style={{ color: 'black' }}>
//                       <thead style={{ alignSelf: 'center' }}>
//                         <tr>
//                         {selectedFields.map((item, index) => {
//                             return (
//                               <th key={index}>
//                                 <span
//                                   style={{
//                                     textTransform: 'capitalize',
//                                   }}
//                                 >
//                                   {item.label.toString()}
//                                 </span>
//                               </th>
//                             );
//                           })}
//                         </tr>
//                       </thead>
//                       <tbody className="report-table-body">
//                       {equityInvestmentData.map((transaction: any, index: number) => {
//                           return (
//                             <>
//                               <tr key={index}>
//                                 {selectedFields.map((item, i) => {
//                                   return (
//                                     <td key={i}>
//                                       {renderDropdownValues(item, transaction)}
//                                     </td>
//                                   );
//                                 })}
//                               </tr>
//                             </>
//                           );
//                         })}
//                       </tbody>
//                       <tr>
//                           {selectedFields.map(item=>{
//                               if(item.value=='quantity'){
//                                 return <td>{numberWithCommas(quantity)}</td>
//                               }else if(item.value=='avg_rate'){
//                                 return <td>{numberWithCommas(avg_rate)}</td>
//                               }else if(item.value=='commission_rate'){
//                                 return <td>{numberWithCommas(commission_rate)}</td>
//                               }else if(item.value=='sst_on_commission'){
//                                 return <td>{numberWithCommas(sst_on_commission)}</td>
//                               }else if(item.value=='net_rate'){
//                                 return <td>{numberWithCommas(net_rate)}</td>
//                               }else if(item.value=='gross_amount'){
//                                 return <td>{numberWithCommas(gross_amount)}</td>
//                               }else if(item.value=='commission_charges'){
//                                 return <td>{numberWithCommas(commission_charges)}</td>
//                               }else if(item.value=='net_amount'){
//                                 return <td>{numberWithCommas(net_amount)}</td>
//                               }else{
//                               return <td></td>
//                               }
//                           })}
//                       </tr>
//                     </table>
//                   </div>
//                 </>
//               ) : (
//                 <div className="table-empty">
//                   <BsTable className="icon" />
//                   <p className="text-center empty-text">No record found</p>
//                 </div>
//               )}
//                </PDFExport>
//             </div>
//             <button
//               className="btn btn-primary mt-3"
//               onClick={() => {
//                 // printReport();
//                 if (pdfExportComponent.current) {
//                   pdfExportComponent.current.save();
//                 }
//               }}
//             >
//              Print
//             </button>

//             {/* <button
//               className="btn btn-info mt-3"
//               onClick={() => {
//                 var printContents = document.getElementById("printDiv")!.innerHTML;
//                 var originalContents = document.body.innerHTML;
           
//                 document.body.innerHTML = printContents;
           
//                 window.print();
           
//                 document.body.innerHTML = originalContents;

//               }}
//             >
//               Print
//             </button> */}
//           </div>
//         </div>
//         </div>
//       </Container>
//       </Col>
//         </Row>
//       </div>
//     </>
//   );
// };

// export default EquityInvestmentTransactionReport;
