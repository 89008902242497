import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { equityUnsettledTransactionReport } from '../../stores/services/report.service';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { equityInvestmentTransactionReport } from '../../stores/services/report.service';
import { txnDropDown, getReuiredFieldForDropDown } from './fields';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isNumber, numberWithCommas, sortAMCS } from '../../utils/customFunction';

const UnsettledEquityTransactionReport = () => {
    const pdfExportComponent = React.useRef<PDFExport>(null);
    const [loading, setLoading] = useState(false);
    const email = sessionStorage.getItem('email') || '';
    let date = new Date();
    const [txnType, setTxnType] = useState('');
    const [status, setStatus] = useState('');
    let [selectedFields, setSelectedFields] = useState<any>([]);
    let [headerColumns, setHeaderCoumns] = useState<any>([]);
    let [unsettledData, setUnsettledData] = useState<any>([]);
    const [unsettlementTxnDate, setUnsettlementTxnDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [quantity, setQuantity] = useState('0');
    const [avg_rate, setAvgRate] = useState('0');
    const [commission_rate, setCommissionRate] = useState('0');
    const [sst_on_commission, setSSTonCommission] = useState('0');
    const [net_rate, setNetRate] = useState('0');
    const [gross_amount, setGrossAmount] = useState('0');
    const [commission_charges, setCommissionCharges] = useState('0');
    const [net_amount, setNetAmount] = useState('0');

    // const renderSettlementDropdown = () =>{

    // }

    useEffect(() => {
        const fieldsData = async () => {
            let fieldsData = await getReuiredFieldForDropDown('equityinvestmentdetailreport');
            setHeaderCoumns(fieldsData);
        }
        fieldsData();
    }, [])

    const searchData = async () => {
        try {
            const response = await equityUnsettledTransactionReport(email, unsettlementTxnDate);
            if(response.data.status===200){
                setUnsettledData(response.data.data);
                let data: any = [];
                setSelectedFields(headerColumns);
                let quantity = 0, avg_rate = 0, gross_amount = 0, net_amount = 0;
                data = response.data.data;
                data.map((item: any, index: number) => {
                    if (item.net_amount === 'NaN') {
                        item.net_amount = '0';
                    }
                    quantity += parseFloat(isNumber(item.quantity) || '0');
                    gross_amount += parseFloat(isNumber(item.gross_amount) || '0');
                    net_amount += parseFloat(isNumber(item.net_amount) || '0');
                    avg_rate += parseFloat(isNumber(item.avg_rate) || '0');
                });
                setQuantity(quantity.toFixed(2));
                setAvgRate(avg_rate.toFixed(2));
                setGrossAmount(gross_amount.toFixed(2));
                setNetAmount(net_amount.toFixed(2));
                setLoading(false);
            }else{
                toast.error(response.data.message);
                setUnsettledData([]);
                setLoading(false);
            }
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
            setLoading(false);
        }
    }




    return (
        <>
            <div className='content'>
                <Row>
                    <Col md='12'>
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card">
                                <div className="card-header">
                                    <h4>Unsettled Equity Transation Report</h4>
                                </div>
                                <div className="card-body">
                                    <Row>
                                        {/* <Col md="2">
                                            <div className="form-group  ">
                                                <label>Select Status</label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                    }}
                                                >
                                                    <option value="" defaultChecked hidden>
                                                        {' '}
                                                        Select Status
                                                    </option>
                                                    <option value="Unsettlement">Unsettlement</option>
                                                </select>
                                            </div>
                                        </Col> */}
                                        <Col md="2">
                                            <div className="form-group  ">
                                                <label>Select Unsettlement Txn Date</label>
                                                <input
                                                    type="date"
                                                    defaultValue={'21-05-2022'}
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        let date = moment(e.target.value).format('YYYY-MM-DD');
                                                        setUnsettlementTxnDate(date);
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className=" ">
                                                {/* here Search Button */}
                                                <button
                                                    className="btn btn-primary mt-4"
                                                    onClick={() => {
                                                        searchData();
                                                    }}
                                                    disabled={Boolean(loading)}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <span
                                                                className="spinner-border login-txt spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            <span className="login-txt"> Loading...</span>
                                                        </>
                                                    ) : (
                                                        <span>Search</span>
                                                    )}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div
                                            id="printDiv"
                                            className="p-4"
                                            style={{ background: 'white', color: 'black' }}
                                        >
                                            <PDFExport paperSize={`Letter`} margin="0.5cm" landscape={true} scale={0.6} repeatHeaders={true} fileName={"Unsettled Equity Transation Report-" + date + ".pdf"} ref={pdfExportComponent}>
                                                {unsettledData.length > 0 ? (
                                                    <>
                                                        <ReportHeader title="Unsettled Equity Transation Report" />
                                                        {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Equity Investment Transaction Report</h3>
                  </div> */}

                                                        <Row>
                                                            <Col md="3" style={{ marginLeft: '70px' }}>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                                                    </Col>
                                                                    <Col md="7">
                                                                        <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </Row>

                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                overflowY: 'hidden',
                                                            }}
                                                        >
                                                            <table className="report" style={{ color: 'black' }}>
                                                                <thead style={{ alignSelf: 'center' }}>
                                                                    <tr>
                                                                        <th>Transaction ID</th>
                                                                        <th>Trade Date</th>
                                                                        <th>Sett. Date</th>
                                                                        <th>Invest. Type</th>
                                                                        <th>Market</th>
                                                                        <th>Type</th>
                                                                        <th>Symbol</th>
                                                                        <th className='text-right'>Quantity</th>
                                                                        <th className='text-right'>Avg Rate</th>
                                                                        <th className='text-right'>Gross Amount</th>
                                                                        <th className='text-right'>Net Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="report-table-body">
                                                                    {unsettledData.map((item: any, index: number) => {
                                                                        return (
                                                                            <>
                                                                                {/* <tr key={index}>
                                                                                    {selectedFields.map((item, i) => {
                                                                                        if (item.value === 'txn_id' || item.value === 'fund_code' || item.value === 'created_at' || item.value === 'settlement_date' || item.value === 'investment_type' || item.value === 'market' || item.value === 'symbol') {
                                                                                            return (
                                                                                                <td className='text-nowrap' align='left' style={{ padding: '2px 0 2px 0' }} key={i}>
                                                                                                </td>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <td className='text-nowrap' align='right' style={{ padding: '2px 0 2px 0' }} key={i}>
                                                                                                </td>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </tr> */}
                                                                                <tr key={index}>
                                                                                    <td>{item.txn_id}</td>
                                                                                    <td></td>
                                                                                    {/* <td>{item.trade_date}</td> */}
                                                                                    <td>{item.settlement_date}</td>
                                                                                    <td>{item.investment_type}</td>
                                                                                    <td>{item.market}</td>
                                                                                    <td>{item.type}</td>
                                                                                    <td>{item.symbol}</td>
                                                                                    <td className='text-right'>{parseFloat(isNumber(item.quantity)).toFixed(4)}</td>
                                                                                    <td className='text-right'>{parseFloat(isNumber(item.avg_rate)).toFixed(2)}</td>
                                                                                    <td className='text-right'>{parseFloat(isNumber(item.gross_amount)).toFixed(2)}</td>
                                                                                    <td className='text-right'>{parseFloat(isNumber(item.net_amount)).toFixed(2)}</td>
                                                                                </tr>
                                                                            </>
                                                                        );
                                                                    })}

                                                                </tbody>
                                                                {/* <tr className='text-nowrap' style={{ borderTop: "2px solid black ", borderBottom: "2px solid black " }} >
                                                                    {selectedFields.map(item => {
                                                                        if (item.value == 'quantity') {
                                                                            return <td align='right' style={{ fontSize: "9px", fontWeight: "bold" }} >{numberWithCommas(quantity)}</td>
                                                                        } else if (item.value == 'avg_rate') {
                                                                            return <td align='right' style={{ fontSize: "9px", fontWeight: "bold" }} >{numberWithCommas(avg_rate)}</td>
                                                                        } else if (item.value == 'gross_amount') {
                                                                            return <td align='right' style={{ fontSize: "9px", fontWeight: "bold" }} >{numberWithCommas(gross_amount)}</td>
                                                                        } else if (item.value == 'net_amount') {
                                                                            return <td align='right' style={{ fontSize: "9px", fontWeight: "bold" }} >{numberWithCommas(net_amount)}</td>
                                                                        } else {
                                                                            return <td></td>
                                                                        }
                                                                    })}
                                                                </tr> */}
                                                                <tr className='text-nowrap' style={{ borderTop: "2px solid black ", borderBottom: "2px solid black " }} >
                                                                    <td>Total</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='text-right'>{numberWithCommas(parseFloat(isNumber(quantity)).toFixed(4))}</td>
                                                                    <td className='text-right'>{numberWithCommas(parseFloat(isNumber(avg_rate)).toFixed(2))}</td>
                                                                    <td className='text-right'>{numberWithCommas(parseFloat(isNumber(gross_amount)).toFixed(2))}</td>
                                                                    <td className='text-right'>{numberWithCommas(parseFloat(isNumber(net_amount)).toFixed(2))}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="table-empty">
                                                        <BsTable className="icon" />
                                                        <p className="text-center empty-text">No record found</p>
                                                    </div>
                                                )}
                                            </PDFExport>
                                        </div>
                                        <button
                                            className="btn btn-primary mt-3"
                                            onClick={() => {
                                                // printReport();
                                                if (pdfExportComponent.current) {
                                                    pdfExportComponent.current.save();
                                                }
                                            }}
                                        >
                                            Print
                                        </button>

                                        {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                                    </div>
                                </div>
                            </div>

                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default UnsettledEquityTransactionReport;
