import { invalid } from 'moment';
import React from 'react';

var accountTitle_AccountNumber_Regex = /^[0-9a-zA-Z -]+$/;
function numberWithCommas(amount:any){
  
  if(amount==='NaN' || isNaN(amount) || amount==='' || amount===undefined){
     return ''
  }else{
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
}
function toProperCase(str) {
    return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
  }

 function sortAMCS(data){ 
  let sortedData = data.sort(function(a,b){
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if(x>y){return 1;}
    if(x<y){return -1;}
    return 0;
  });
  return sortedData;
 }

 function isNumber(value){
  if(isNaN(value) || value=='NaN' || value==undefined || value=='undefined' || value=='' || value==null || value=='null'){
     value='0';
  }
 return value;
 }

 function isDateValid(date){
  if(date == invalid || date=='Invalid date'){
    date='';
  }
 return date;
 }
 
 // sort funds by fund_name
//  function sortFunds(data){ 
//   let sortedData = data.sort(function(a,b){
//     let x = a.fund_name.toLowerCase();
//     let y = b.fund_name.toLowerCase();
//     if(x>y){return 1;}
//     if(x<y){return -1;}
//     return 0;
//   });
//   return sortedData;
//  }

export {numberWithCommas, toProperCase,isNumber, isDateValid, accountTitle_AccountNumber_Regex, sortAMCS};