import React, { useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { getAmc } from "../../../stores/services/amc.service";
import { getFundByAmc, getFunds } from "../../../stores/services/funds.service";
import { isNumber, numberWithCommas } from "../../../utils/customFunction";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { ImCross } from "react-icons/im";
import { RiEdit2Fill } from "react-icons/ri";
import { AiOutlineArrowUp } from "react-icons/ai";
import moment from "moment";
import {
  addInvoice,
  searchInvoice,
  updateInvoice,
} from "../../../stores/services/invoice.service";
import NumberFormat from "react-number-format";

const NewInvoice = () => {
  let date = new Date();
  const history = useHistory();
  const [period, setPeriod] = useState("");
  const [allPeriod, setAllPeriod] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allAMCFund, setAllAMCFund] = useState<any>([]);
  const [AmcFundName, setAMCFundName] = useState("");
  const [Amc, setAmc] = useState("");
  const [fund, setFund] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [head, setHead] = useState("");
  const [amount, setAmount] = useState("");
  const [taxRate, setTaxRate] = useState("13");
  const [taxAmount, setTaxAmount] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [addHeadFlag, setAddHeadFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [refreshFund, setrefreshFund] = useState(false);
  const [startDate, setStartDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [dueDate, setDueDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [periodNameModal, setPeriodNameModal] = useState("");
  //const invoiceEditData = sessionStorage.getItem("editInvoice") || "";
  const [matchPflag, setMatchPFlag] = useState(false);
  const [matchPeriodFlag, setMatchPeriodFlag] = useState(false);


  const [addHeadPeriod, setAddHeadPeriod] = useState("");
  const [headOptions, setHeadOptions] = useState([
    { value: "Trustee Fee", label: "Trustee Fee" },
    { value: "Custody Fee", label: "Custody Fee" },
    { value: "Other", label: "Other" },
  ]);
  const [ViewAddHead, setViewAddHead] = useState(false);
  const [taxTypeOptions, setTaxTypeOptions] = useState([
    { value: "SST", label: "SST" },
    { value: "PST", label: "PST" },
  ]);
  const [taxType, setTaxType] = useState("");
  const [allInvoice, setAllInvoice] = useState<any>([]);
  const [ViewAddPeriod, setViewAddPeriod] = useState(false);
  const [addViewInvoiceHead, setAddViewInvoiceHead] = useState(false);
  const [errorShowSearch, setErrorShowSearch] = useState(false);
  const [errorShowPeriodModal, setErrorShowPeriodModal] = useState(false);
  const [headLabelEdit, setHeadLabelEdit] = useState(false);
  const [previousBalance, setPreviousBalance] = useState("0");
  const [addPreviousBalance, setAddPreviousBalance] = useState(true);

  const [periodOptions, setPeriodOptions] = useState([
    { value: "Other", label: "Other" },
  ]);

  const email = sessionStorage.getItem("email") || "";
  let sumAmount = 0;
  let sumTaxAmount = 0;
  let sumNetAmount = 0;
  React.useEffect(() => {
    const getAllFunds = async () => {
      let invoiceEditData = sessionStorage.getItem("editInvoice") || "";

      allInvoice.length = 0;
      setAllInvoice(allInvoice);
      setPeriod("");
      setAMCFundName("");
      setHeadLabelEdit(false);
      if (invoiceEditData && invoiceEditData != "") {
        let temp;
        temp = JSON.parse(invoiceEditData);
        setAMCFundName(temp.amcFund);
        allInvoice.push(temp);
        setAllInvoice([...allInvoice]);
        setHeadLabelEdit(true);
        setPeriod(temp.period);
        getPeriod(temp.amcFund);
        periodOptions.pop();
        periodOptions.push({
          label: temp.period,
          value: temp.period,
        });
        setAddHeadPeriod(temp.period);
        setPreviousBalance(temp.balance)
      }

      const response = await getFunds(email);
      let tempAmc = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.amc_code} - ${val.symbol_code}`,
          value: `${val.amc_code} - ${val.symbol_code}`,
        };
      });
      setAllAMCFund(tempAmc);
    };

    getAllFunds();
  }, [refreshFund]);

  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };
  const ErrorBorderonSearch = (value) => {
    if (errorShowSearch && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };
  const ErrorBorderOnAddPeriodModal = (value) => {
    if (errorShowPeriodModal && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  // New Invoice Validations
  const [addHeadPeriodError, setAddHeadPeriodError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [headError, setHeadError] = useState('');

  const validate = () => {
    let addHeadPeriodErr = '';
    let amountErr = '';
    let headErr = '';
    addHeadPeriod.trim() === '' ? (addHeadPeriodErr = 'Required') : (addHeadPeriodErr = '');
    head.trim() === '' ? (headErr = 'Required') : (headErr = '');
    amount.trim() === '' ? (amountErr = 'Required') : (headErr = '');
    if (addHeadPeriodErr || headErr || amountErr) {
      setAddHeadPeriodError(addHeadPeriodErr);
      setAmountError(headErr);
      setHeadError(amountErr);
      return false;
    } else {
      return true;
    }
  };

  const getPeriod = async (value: any) => {
    try {
      const response = await searchInvoice(
        email,
        value.split("-")[0],
        value.split("-")[1],
        "active"
      );

      let tempAmc = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.period}`,
          value: `${val.period}`,
        };
      });

      setPreviousBalance(response.data.data[0]?.balance);
      tempAmc.push({ label: "Other", value: "Other" })
      setPeriodOptions(tempAmc)
      setAllPeriod([...tempAmc]);
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const renderModalAddHead = () => {
    switch (ViewAddHead) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddHead(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Head</h6>
              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <div className="form-group">
                  <div>Head </div>
                  <input
                    type="text"
                    value={head}
                    className={"form-control w-100 "}
                    onChange={(e) => {
                      setHead(e.target.value);
                    }}
                  />
                </div>

                <div className=" ">
                  <div className=" mt-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        if (head.trim() === "") {
                          toast.error("Kindly fill all fields.");
                        } else {
                          headOptions.unshift({ value: head, label: head });

                          setViewAddHead(false);
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderModalAddPeriod = () => {
    switch (ViewAddPeriod) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="lg"
            backdrop={true}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddPeriod(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Period</h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Start Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={"form-control  w-100"}
                        value={startDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setStartDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group  ">
                    <label>End Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={`form-control  w-100 `}
                        value={endDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setEndDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Name</label>
                    <div className="">
                      <input
                        type="text"
                        className={
                          "form-control  w-100 " +
                          ErrorBorderOnAddPeriodModal(periodNameModal)
                        }
                        value={periodNameModal}
                        onChange={(e) => {
                          setPeriodNameModal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group  ">
                    <label>Due Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={`form-control  w-100 `}
                        value={dueDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setDueDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="row">
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      if (!periodNameModal || periodNameModal == "") {
                        setErrorShowPeriodModal(true);
                      } else {
                        setAddHeadPeriod(periodNameModal);
                        periodOptions.unshift({
                          value: periodNameModal,
                          label: periodNameModal,
                        });
                        setPeriodNameModal("");
                        setViewAddPeriod(false);
                      }
                    }}
                  >
                    <span>Add Period</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const addOrEditHead = () => {
    if (editFlag && editIndex != -1) {
      let temp =
        allInvoice && allInvoice.length > 0
          ? JSON.parse(allInvoice[0].head_detail)
          : [];
      temp[editIndex]["head_name"] = head;
      temp[editIndex]["gross_amount"] = parseFloat(amount ? amount.replace(",", "") : "0.00").toFixed(2);
      temp[editIndex]["net_amount"] = parseFloat(netAmount ? netAmount.replace(",", "") : "0.00").toFixed(2);
      temp[editIndex]["tax_rate"] = taxRate;
      temp[editIndex]["tax_amount"] = parseFloat(taxAmount ? taxAmount.replace(",", "") : "0.00").toFixed(2);
      temp[editIndex]["tax_type"] = taxType;

      allInvoice[0]["head_detail"] =
        temp && JSON.stringify(temp);
      setAllInvoice(allInvoice);
      setAddViewInvoiceHead(false);
      setEditFlag(false);
      setEditIndex(-1);
      setHead("");
      setAmount("");
      setAddHeadPeriodError("");
      setHeadError("");
      setAmountError("");
      setTaxType("");
      setTaxRate("13");
      setTaxAmount("");
      setNetAmount("");
    } else {
      const isValid = validate();
      if (isValid) {
        const match_period = allPeriod.filter(item => (item.period === addHeadPeriod));

        if (match_period.length > 0 && !editFlag && !matchPflag) {
          setMatchPeriodFlag(true);
        } else {
          if (allInvoice && allInvoice.length > 0) {
            let temp =
              allInvoice[0].head_detail &&
                allInvoice[0].head_detail != ""
                ? JSON.parse(allInvoice[0].head_detail)
                : [];
            temp.push({
              head_name: head,
              gross_amount: parseFloat(amount ? amount.replace(",", "") : "0.00").toFixed(2),
              tax_rate: taxRate || '0',
              tax_amount: parseFloat(taxAmount ? taxAmount.replace(",", "") : "0.00").toFixed(2),
              net_amount: parseFloat(netAmount ? netAmount.replace(",", "") : "0.00").toFixed(2),
              tax_type: taxType,
            });
            allInvoice[0].head_detail = JSON.stringify(temp);
            setAllInvoice(allInvoice);
          } else {
            let gross_amount = 0;
            let net_amount = 0;
            let head_detail = [
              {
                head_name: head,
                gross_amount: parseFloat(amount ? amount.replace(",", "") : "0.00").toFixed(2),
                tax_rate: taxRate || '0',
                tax_amount: parseFloat(taxAmount ? taxAmount.replace(",", "") : "0.00").toFixed(2),
                net_amount: parseFloat(netAmount ? netAmount.replace(",", "") : "0.00").toFixed(2),
                tax_type: taxType,
                period: addHeadPeriod,
                due_date: dueDate,
              },
            ];

            if (
              addPreviousBalance &&
              parseFloat(previousBalance) != 0
            ) {
              head_detail.push({
                head_name: "Previous Balance",
                gross_amount: parseFloat(previousBalance ? previousBalance.replace(",", "") : "0.00").toFixed(2),
                net_amount: parseFloat(previousBalance ? previousBalance.replace(",", "") : "0.00").toFixed(2),
                period: addHeadPeriod,
                tax_type: taxType,
                tax_rate: "0",
                tax_amount: "0.00",
                due_date: dueDate,
              });
              gross_amount =
                parseFloat(amount ? amount.replace(",", "") : "0.00") + parseFloat(previousBalance ? previousBalance.replaceAll(",", "") : "0.00");
              net_amount =
                parseFloat(netAmount ? netAmount.replace(",", "") : "0.00") +
                parseFloat(previousBalance ? previousBalance.replaceAll(",", "") : "0.00");
              setAmount(
                (
                  parseFloat(amount ? amount.replace(",", "") : "0.00") + parseFloat(previousBalance ? previousBalance.replaceAll(",", "") : "0.00")
                ).toString()
              );
              setNetAmount(
                (
                  parseFloat(netAmount ? netAmount.replace(",", "") : "0.00") +
                  parseFloat(previousBalance ? previousBalance.replaceAll(",", "") : "0.00")
                ).toString()
              );
            }
            let temp = allInvoice;
            temp.push({
              amc_code: AmcFundName.split("-")[0],
              fund_code: AmcFundName.split("-")[0],
              head_detail: JSON.stringify(head_detail),
              due_date: dueDate,
              period: addHeadPeriod,
              tax: parseFloat((taxAmount ? taxAmount?.toString().replaceAll(",", "") : "0") || '').toFixed(2),
              gross_amount: parseFloat(gross_amount?.toString().replaceAll(",", "")).toFixed(2),
              net_amount: parseFloat(net_amount?.toString().replaceAll(",", "")).toFixed(2),
              period_start: startDate.toString(),
              period_end: endDate.toString(),
              balance_detail: "[]",
            });
            let index = allPeriod.findIndex(
              (x) => x.value == addHeadPeriod
            );
            if (index < 0) {
              allPeriod.push({
                value: addHeadPeriod,
                label: addHeadPeriod,
              });
            }

            setAllPeriod(allPeriod);
            periodOptions.length = 0;
            setPeriod(addHeadPeriod);
            periodOptions.push({
              label: addHeadPeriod,
              value: addHeadPeriod,
            });
            setAllInvoice([...temp]);
          }

          setAddViewInvoiceHead(false);
          setHead("");
          setAmount("");
          setAddHeadPeriodError("");
          setHeadError("");
          setAmountError("");
          setTaxType("");
          setTaxRate("13");
          setTaxAmount("");
          setNetAmount("");
        }
      }
    }
  }

  const renderModalPeriodWarning = () => {
    switch (matchPeriodFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setMatchPeriodFlag(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                Period Match
              </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setMatchPFlag(!matchPflag);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Are you sure you want to upload same period!{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
                {matchPflag && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setMatchPeriodFlag(false)
                          addOrEditHead()
                        }}
                        disabled={Boolean(loading)}
                      >
                        <span>{editFlag ? "Update Head" : "Add Head"}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };


  const renderModalAddViewInvoiceHead = () => {
    switch (addViewInvoiceHead) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            enforceFocus={false}
            backdrop={true}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setAddViewInvoiceHead(false);
                  setAddHeadPeriodError("");
                  setHeadError("");
                  setAmountError("");
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">
                  {editFlag ? "Update Invoice Head" : "Add Invoice Head"}
                </h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <div className="col-md-6">
                  <label>Period</label>
                  <div
                    className={`form-group  w-100 ${addHeadPeriodError ? 'required-border' : ''
                      }`}
                    // {
                    //   "form-group  w-100 " +
                    //   addHeadPeriodError
                    // }
                    style={{ border: "1px solid #4f81bd" }}
                  >
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      label={"Select Period"}

                      onChange={(e) => {
                        if (e) {


                          if (e.value == "Other") {
                            setViewAddPeriod(true);
                          } else {
                            setAddHeadPeriod(e.value);
                            setStartDate(e.period_start || startDate)
                            setEndDate(e.period_end || endDate)
                            setDueDate(e.due_date || dueDate)

                           
                          }
                          setAddHeadPeriodError('');
                        }
                      }}
                      value={periodOptions.filter(
                        (option) => option.value === addHeadPeriod
                      )}
                      isClearable={false}
                      isSearchable={true}

                      name="color"
                      options={periodOptions}
                      menuPosition="fixed"
                      isDisabled={editFlag}
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>

                <Col md="6">
                  <div className="form-group  ">
                    <label>Head</label>
                    <div
                      className={`form-group  w-100 ${headError ? 'required-border' : ''
                        }`}
                      // {
                      //   "form-group  w-100 " +
                      //   headError
                      // }
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={"Select Head"}
                        onChange={(e) => {
                          if (e.value === "Other") {
                            setViewAddHead(true);
                            setHead("");
                          } else {
                            setHead(e.value);
                          }
                          setHeadError('');
                        }}
                        value={headOptions.filter(
                          (option) => option.value === head
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={headOptions}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Amount</label>
                    <div
                      className={`form-group d-flex w-100 ${amountError ? 'required-border' : ''
                        }`}
                      // {
                      //   "form-group  d-flex " +
                      //   amountError
                      // }
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className={"form-control text-right"}
                        value={parseFloat(amount || '').toFixed(2)}
                        onValueChange={(e) => {
                          setAmount(e.value);
                          if (taxRate) {
                            let amounttax = (parseFloat(isNumber(e.value)) * parseFloat(isNumber(taxRate))) / 100


                            let tempNetAmount = amounttax + parseFloat(e.value)
                            setTaxAmount(parseFloat(amounttax.toString() || '').toFixed(2))
                            setNetAmount(parseFloat(tempNetAmount.toString() || '').toFixed(2))


                          }
                          setAmountError('')
                        }}
                      />
                      <span
                        style={{
                          border: "1px solid #4f81bd",
                          textAlign: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                        }}
                      >
                        Rs
                      </span>
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group ">
                    <label>Tax Type</label>
                    <div
                      className="form-group"
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={"Select Tax Type"}
                        onChange={(e) => {
                          setTaxType(e.value);
                        }}
                        value={taxTypeOptions.filter(
                          (option) => option.value === taxType
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={taxTypeOptions}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="col-md-3">
                  <label>Tax Rate</label>
                  <div
                    className="form-group d-flex"
                    style={{ border: "1px solid #4f81bd" }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className={"form-control text-right"}
                      value={parseFloat(taxRate || '').toFixed(2)}
                      onValueChange={(e) => {
                        let tax_rate = '';
                        if (e.value.includes(".")) {
                          let decimalVal = e.value.split(".")[1]
                          let val = e.value.split(".")[0]
                          tax_rate = val.substring(0, 3) + "." + decimalVal.substring(0, 2)
                          setTaxRate(val.substring(0, 3) + "." + decimalVal.substring(0, 2));

                        } else {
                          tax_rate = e.value.substring(0, 3)
                          setTaxRate(e.value.substring(0, 3));

                        }


                        if (amount) {
                          let amounttax = (parseFloat(isNumber(amount)) * parseFloat(isNumber(tax_rate))) / 100
                          let tempNetAmount = amounttax + parseFloat(amount)
                          setTaxAmount(parseFloat(amounttax.toString()).toFixed(2))
                          setNetAmount(parseFloat(tempNetAmount.toString()).toFixed(2))



                        }
                      }}
                    />
                    <span
                      style={{
                        border: "1px solid #4f81bd",
                        textAlign: "center",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "5px",
                      }}
                    >
                      %
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Tax Amount</label>
                  <div
                    className="form-group d-flex"
                    style={{ border: "1px solid #4f81bd" }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className={"form-control text-right"}
                      value={parseFloat(taxAmount || '').toFixed(2)}
                      onValueChange={(e) => {
                        if (amount) {
                          if (parseFloat(e.value) <= parseFloat(amount) || e.value == "") {
                            setTaxAmount(e.value);
                          }


                        } else {
                          setTaxAmount(e.value);
                        }

                        if (amount && parseFloat(e.value) <= parseFloat(amount)) {
                          let temptax = (parseFloat(isNumber(e.value)) / parseFloat(isNumber(amount))) * 100
                          if (!isFinite(temptax)) {
                            temptax = 0
                          }
                          let tempNetAmount = parseFloat(isNumber(e.value)) + parseFloat(amount)
                          setTaxRate(parseFloat(temptax.toString()).toFixed(2))
                          setNetAmount(parseFloat(tempNetAmount.toString()).toFixed(2))



                        }
                      }}
                    />
                    <span
                      style={{
                        border: "1px solid #4f81bd",
                        textAlign: "center",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "5px",
                      }}
                    >
                      Rs
                    </span>
                  </div>
                </div>

                <Col md="6">
                  <div className="form-group ">
                    <label>Net Amount</label>
                    <div
                      className="form-group d-flex"
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className={"form-control text-right"}
                        value={parseFloat(netAmount || '').toFixed(2)}
                        onValueChange={(e) => {
                          setNetAmount(e.value);
                        }}
                      />
                      <span
                        style={{
                          border: "1px solid #4f81bd",
                          textAlign: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                        }}
                      >
                        Rs
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="row">
                {!editFlag ? (
                  <div className="col-md-6">
                    <label>Previous Balance</label>
                    <div
                      className={"form-group d-flex "}
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className={"form-control text-right"}
                        value={parseFloat(previousBalance || '0').toFixed(2)}
                        readOnly={true}
                      />
                      <span
                        style={{
                          border: "1px solid #4f81bd",
                          textAlign: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={parseFloat(previousBalance ? previousBalance : "0") > 0 && !headLabelEdit}
                          onChange={(e) => {
                            setAddPreviousBalance(!addPreviousBalance);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <Col md="6"> </Col>
                )}

                <div className="col-md-6">
                  {editFlag ? <></> : <br />}
                  <button
                    className="btn btn-primary mt-2"
                    style={{ width: "100%" }}
                    onClick={() => {

                      addOrEditHead();
                    }
                    }
                  >
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>{editFlag ? "Update Head" : "Add Head"}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const searchData = async () => {
    try {
      if (!AmcFundName || AmcFundName == "" || !period || period == "") {
        setErrorShow(false);
        setErrorShowSearch(true);
      } else {
        let response;
        if (period) {
          response = await searchInvoice(
            email,
            AmcFundName.split("-")[0],
            AmcFundName.split("-")[1],
            "active",
            period
          );
        } else {
          response = await searchInvoice(
            email,
            AmcFundName.split("-")[0],
            AmcFundName.split("-")[1],
            "active"
          );
        }

        if (response.data.status == 200) {
          if (response.data.data && response.data.data.length > 0) {
            setPreviousBalance(response.data.data[0].balance);
          }

          setHeadLabelEdit(true);
          setAddPreviousBalance(false);
          setAllInvoice(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const UploadInvoice = async () => {
    try {
      if (!AmcFundName || AmcFundName == "" || !period || period == "") {
        setErrorShow(false);
        setErrorShowSearch(true);
      } else {
        setLoadingUpload(true);
        let res
        if (headLabelEdit) {
          res = await updateInvoice(
            email,
            AmcFundName.split("-")[0],
            AmcFundName.split("-")[1],
            dueDate,
            allInvoice[0].period,
            sumTaxAmount.toString(),
            sumNetAmount.toString(),
            sumAmount.toString(),
            allInvoice[0].head_detail,
            allInvoice[0].period_start.toString(),
            allInvoice[0].period_end.toString()
          );

        } else {
          res = await addInvoice(
            email,
            AmcFundName.split("-")[0],
            AmcFundName.split("-")[1],
            dueDate,
            allInvoice[0].period,
            sumTaxAmount.toString(),
            sumNetAmount.toString(),
            sumAmount.toString(),
            allInvoice[0].head_detail,
            allInvoice[0].period_start.toString(),
            allInvoice[0].period_end.toString()
          );
        }
        if (res.data.status == 200) {
          setLoadingUpload(false);
          setrefreshFund(!refreshFund);
          setErrorShow(false);
          setErrorShowSearch(false);
          toast.success(res.data.message);
          allPeriod.length = 0;
          setPeriod("");
          setAddHeadPeriod("");
          periodOptions.length = 0;
          setPeriodOptions([{ value: "Other", label: "Other" }]);
          setAllPeriod(allPeriod);
          setAllInvoice([])
        } else {
          toast.error(res.data.message);
          setLoadingUpload(false);
        }
      }
      setLoadingUpload(false);
    } catch (err: any) {
      setLoadingUpload(false);
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      history.replace("/admin/invoice");
                    }}
                  ></i>
                  {headLabelEdit ? "Edit Invoice" : "New Invoice"}
                </h4>
              </div>
              <div className="card-body">
                <Row>
                  <div className="col-md-2">
                    <label>Fund</label>
                    <div
                      className={
                        "form-group " +
                        ErrorBorder(AmcFundName) +
                        ErrorBorderonSearch(AmcFundName)
                      }
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={"Select AMC Funds"}
                        isLoading={allAMCFund.length > 0 ? false : true}
                        onChange={(e) => {
                          setAMCFundName(e.value);
                          allInvoice.length = 0;
                          setAllInvoice(allInvoice);
                          setPeriod("");
                          allPeriod.length = 0;
                          setAllPeriod(allPeriod);
                          getPeriod(e.value);
                        }}
                        value={allAMCFund.filter(
                          (option) => option.value === AmcFundName
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={allAMCFund}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </div>

                  <Col md="2">
                    <div className={"form-group  "}>
                      <label>Select Period</label>
                      <Select
                        className={
                          "react-select info " + ErrorBorderonSearch(period)
                        }
                        classNamePrefix="react-select"
                        label={"Select Period"}
                        onChange={(e) => {
                          setPeriod(e.value);

                          periodOptions.pop();
                          periodOptions.push({
                            label: e.value,
                            value: e.value,
                          });
                          setAddHeadPeriod(e.value);
                        }}
                        value={allPeriod.filter(
                          (option) => option.value === period
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={allPeriod}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="row d-flex"

                    >
                      <div className="mr-3">
                        {/* here Search Button */}
                        <button
                          className="btn btn-primary mt-4"
                          onClick={() => {
                            searchData();
                          }}
                          disabled={Boolean(loading)}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Search</span>
                          )}
                        </button>
                      </div>
                      <div className="mr-3">
                        <button
                          className="btn  mt-4"
                          style={{ maxWidth: "130px" }}
                          onClick={() => {
                            setAMCFundName("");
                            setPeriod("");
                            setErrorShow(false);
                            setErrorShowSearch(false);
                            setHeadLabelEdit(false);
                            allInvoice.length = 0;
                            setAllInvoice(allInvoice);
                            periodOptions.length = 0;
                            setPeriodOptions([
                              { value: "Other", label: "Other" },
                            ]);
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            Clear All
                          </span>
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="btn btn-primary mt-4"
                          style={{ maxWidth: "130px" }}
                          onClick={() => {
                            if (!AmcFundName || AmcFundName == "") {
                              setErrorShow(true);
                              setErrorShowSearch(false);
                            } else {
                              setEditFlag(false);
                              setHead("");
                              setAmount("");
                              setTaxType("");
                              setTaxRate("13");
                              setTaxAmount("");
                              setNetAmount("");
                              setAddHeadPeriod("");


                              setAddViewInvoiceHead(true);
                            }
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            + Head
                          </span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>

                {allInvoice && allInvoice.length > 0 && (
                  <div
                    className="table-responsive"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    <table className="table" id="myTable">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Head</th>
                          <th>Tax%</th>
                          <th className="text-right">Tax Amount</th>
                          <th className="text-right">Gross Amount</th>
                          <th className="text-right">Net Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody id="myUL">
                        {allInvoice &&
                          allInvoice.length > 0 &&
                          JSON.parse(allInvoice[0].head_detail).map(
                            (item, index) => {
                              sumAmount =
                                parseFloat(
                                  item.gross_amount
                                    ? item.gross_amount
                                      .toString()
                                      .replaceAll(",", "")
                                    : "0"
                                ) + sumAmount;
                              sumNetAmount =
                                parseFloat(
                                  item.net_amount
                                    ? item.net_amount
                                      .toString()
                                      .replaceAll(",", "")
                                    : "0"
                                ) + sumNetAmount;
                              sumTaxAmount =
                                parseFloat(
                                  item.tax_amount
                                    ? item.tax_amount
                                      .toString()
                                      .replaceAll(",", "")
                                    : "0"
                                ) + sumTaxAmount;
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.head_name}</td>
                                  <td>{parseFloat(item.tax_rate || '0').toFixed(2)}%</td>
                                  <td className="text-right">
                                    {numberWithCommas(parseFloat(item.tax_amount ? item.tax_amount.toString().replaceAll(",", "") : "0").toFixed(2))}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(parseFloat(item.gross_amount ? item.gross_amount.toString().replaceAll(",", "") : "0").toFixed(2))}
                                  </td>

                                  <td className="text-right">
                                    {numberWithCommas(parseFloat(item.net_amount ? item.net_amount.toString().replaceAll(",", "") : "0").toFixed(2))}
                                  </td>
                                  <td className="d-flex pointer">
                                    <button
                                      type="button"
                                      className="btn-icon btn-link like btn btn-primary btn-sm"
                                      onClick={() => {
                                        let indexHead = headOptions.findIndex(
                                          (x) => x.value == item.head_name
                                        );
                                        let indexPeriod =
                                          periodOptions.findIndex(
                                            (x) =>
                                              x.value == allInvoice[0].period
                                          );

                                        if (indexPeriod > -1) {
                                          setAddHeadPeriod(
                                            allInvoice[0].period
                                          );
                                        } else {
                                          periodOptions.unshift({
                                            label: allInvoice[0].period,
                                            value: allInvoice[0].period,
                                          });
                                          setAddHeadPeriod(
                                            allInvoice[0].period
                                          );
                                        }
                                        if (indexHead > -1) {
                                          setHead(item.head_name);
                                        } else {
                                          headOptions.unshift({
                                            label: item.head_name,
                                            value: item.head_name,
                                          });
                                          setHead(item.head_name);
                                        }

                                        setAmount(parseFloat(item.gross_amount).toFixed(2));
                                        setTaxRate(parseFloat(item.tax_rate).toFixed(2));
                                        setTaxAmount(parseFloat(item.tax_amount).toFixed(2));
                                        setNetAmount(parseFloat(item.net_amount).toFixed(2));
                                        setTaxType(item.tax_type);
                                        setEditIndex(index);
                                        setEditFlag(true);
                                        setAddViewInvoiceHead(true);
                                      }}
                                    >
                                      <RiEdit2Fill size={17} />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-icon btn-link like btn btn-primary btn-sm"
                                      onClick={() => {
                                        sumAmount =
                                          sumAmount -
                                          parseFloat(
                                            item.gross_amount
                                              ? item.gross_amount
                                                .toString()
                                                .replaceAll(",", "")
                                              : "0"
                                          );
                                        sumNetAmount =
                                          sumNetAmount -
                                          parseFloat(
                                            item.net_amount
                                              ? item.net_amount
                                                .toString()
                                                .replaceAll(",", "")
                                              : "0"
                                          );
                                        sumTaxAmount =
                                          sumTaxAmount -
                                          parseFloat(
                                            item.tax_amount
                                              ? item.tax_amount
                                                .toString()
                                                .replaceAll(",", "")
                                              : "0"
                                          );
                                        let array = JSON.parse(
                                          allInvoice[0].head_detail
                                        );

                                        array.splice(index, 1);

                                        allInvoice[0].head_detail =
                                          JSON.stringify(array);

                                        setAllInvoice([...allInvoice]);
                                      }}
                                    >
                                      <ImCross size={14} />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>

                      <tfoot>
                        <tr>
                          <th></th>
                          <th>Total</th>
                          <th></th>
                          <th className="text-right">
                            {numberWithCommas(parseFloat(sumTaxAmount.toString().replaceAll(",", "")).toFixed(2))}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(parseFloat(sumAmount.toString().replaceAll(",", "")).toFixed(2))}
                          </th>

                          <th className="text-right">
                            {numberWithCommas(parseFloat(sumNetAmount.toString().replaceAll(",", "")).toFixed(2))}
                          </th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>

                    <div
                      className="row d-flex mr-4"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          UploadInvoice();
                        }}
                        disabled={Boolean(loadingUpload)}
                      >
                        {loadingUpload ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span
                              className="login-txt"
                              style={{
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </span>
                          </>
                        ) : (
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            {headLabelEdit ? "Update Invoice" : "Save Invoice"}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {renderModalAddHead()}
            {renderModalAddPeriod()}
            {renderModalPeriodWarning()}
            {renderModalAddViewInvoiceHead()}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default NewInvoice;
