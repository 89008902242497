/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./containers/components/DashboardPage";

// const data = JSON.parse(sessionStorage.getItem("features")) || "";

let routes = [];
// data.map((item, i) => {
//   let obj = {
//     path: "/dashboard",
//     name: item.feature,
//     rtlName: "لوحة القيادة",
//     icon: "tim-icons icon-chart-pie-36",
//     component: Dashboard,
//     layout: "/admin",
//   };
//   routes.push(obj);
// });

export default routes;
