import React from 'react';
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, Link } from 'react-router-dom'
import { addChecklist } from '../../../stores/services/checklist.service';
import { getAllRoles } from '../../../stores/services/role.service';

const SetupCheckList = () => {
  const history = useHistory();
  //hooks for getting all inputs from user 
  const [serialNo, setSerialNo] = useState('');
  const [title, setTitle] = useState('');
  const [role, setRole] = useState('');
  const email = sessionStorage.getItem('email') || '';
  //error getting hooks 
  const [serialNoError, setSerialNoError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState<any>([]);

  React.useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await getAllRoles(email);
        setRoleData(response.data.data);
      } catch (error) { }
    };
    fetchRole()
  }, [])

  const renderRoleDropdown = () => {
    return (
      roleData &&
      roleData.map((item, index) => {
        return (
          <option key={index} value={item.role_name.replace('ROLE_','')}>
            {item.role_name.replace('ROLE_','')}
          </option>
        );
      })
    );
  };


  const validate = () => {
    let serialErr = '';
    let titleErr,
      roleErr = '';
    serialNo.trim() === '' ? (serialErr = 'Required') : (serialErr = '');
    title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    role.trim() === '' ? (roleErr = 'Required') : (roleErr = '');
    if (serialErr || titleErr || roleErr) {
      setSerialNoError(serialErr);
      setTitleError(titleErr);
      setRoleError(roleErr);
      return false;
    } else {
      return true;
    }
  };
  const AddChecklist = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addChecklist(email, serialNo, title, role);
        if(response.data.status===200){
        setSerialNo('');
        setTitle('');
        setRole('')
        toast.success(response.data.message);
        }else{
          toast.error(response.data.message);
        }
      } catch (error:any) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header title-row">
            <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/check-list')
                  }}></i>Setup - Check List</h4>
 
            <Link to="/admin/check-list" className="btn btn-primary btn-sm ml-auto" replace>
              <i className="fa fa-eye mr-2"></i>View All
            </Link>
 
          </div>
         
          <div className="card-body">
            <Row >
              <Col md="6">
                <div className="form-group">
                  <label>Title <span className="color-amber">*</span></label>
                  <input type="text" className={`form-control  w-100 ${titleError ? 'required-border' : ''}`} value={title} onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError('');
                  }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label>Role <span className="color-amber">*</span></label>
                  <select className={`form-control  w-100 ${roleError ? 'required-border' : ''}`}
                    value={role} onChange={(e) => {
                      setRole(e.target.value)
                    }}
                  >
                    <option value="" defaultChecked hidden> Select Role</option>
                    {renderRoleDropdown()}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label>Serial.# / Sequence # (Number) <span className="color-amber">*</span></label>
                  <input type="text" className={`form-control  w-100 ${serialNoError ? 'required-border' : ''}`} value={serialNo} onChange={(e) => {
                    setSerialNo(e.target.value);
                    setSerialNoError('');
                  }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div> 
            <button
                className="btn btn-primary"
                onClick={AddChecklist}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Create</span>
                )}
              </button>
          </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  )
};

export default SetupCheckList;