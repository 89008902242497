import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsTable } from "react-icons/bs";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { getAmc } from "../../stores/services/amc.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import { moneyMarketTransactionReport } from "../../stores/services/report.service";
import { txnDropDown, getReuiredFieldForDropDown } from "./fields";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { numberWithCommas, sortAMCS } from "../../utils/customFunction";

const MoneyMarketTransactionReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  let date = new Date();
  const [moneyMarketData, setMoneyMarketData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState("");
  const [amc_code, setAmcCode] = useState("");
  const [fund, setFund] = useState("");
  const [fund_code, setFundCode] = useState("");
  const [type, setType] = useState("");
  const [security, setSecurity] = useState("");
  const [fromDate, setFromDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(date).format("YYYY-MM-DD"));

  let [allDropdownColumns, setAllDropdownColumns] = useState<any>([]);
  let [selectedFields, setSelectedFields] = useState<any>([]);
  let [headerColumns, setHeaderCoumns] = useState<any>([]);

  const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  const [resSecurity, setResSecurity] = useState("");
  const [resType, setResType] = useState("");
  const [resFromDate, setResFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resToDate, setResToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [money_market_face_value, setMMFaceValue] = useState("0");
  const [price, setPrice] = useState("0");
  const [units, setUnits] = useState("0");
  const [yields, setYields] = useState("0");
  const [settlement_amount, setSettlementAmount] = useState("0");
  const [total_face_value, setTotalFaceValue] = useState("0");
  const [accrued_amount, setAccruedAmount] = useState("0");
  const [principal_amount, setPrincipalAmount] = useState("0");
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, "", "", "");
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      let fieldsData = await getReuiredFieldForDropDown(
        "moneymarketdetailreport"
      );
      setHeaderCoumns(fieldsData);
    };
    getAllAMC();
  }, []);

  let txnTypes = JSON.parse(sessionStorage.getItem("txnType") || "");

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace("AMC_", "")}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  };
  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  //code for search data for Reports

  const searchData = async () => {
    setMoneyMarketData([]);
    try {
      if (Amc === "" || fund_code === "" || security === "" || type === "") {
        setErrorShow(true);
      } else {
        setLoading(true);
        const response = await moneyMarketTransactionReport(
          email,
          amc_code,
          fund_code,
          security,
          type,
          fromDate,
          toDate
        );
        setMoneyMarketData(response.data.data);
        setResAmc(Amc);
        setResFund(fund);
        setResSecurity(security);
        setResType(type);
        setResFromDate(fromDate);
        setResToDate(toDate);
        setSelectedFields(headerColumns);
        setAllDropdownColumns(headerColumns);

        let data: any = [];
        let netamount = 0,
          grossamount = 0,
          price = 0,
          yields = 0,
          money_market_face_value = 0,
          units = 0,
          total_face_value = 0,
          settlement_amount = 0,
          accrued_amount = 0,
          principal_amount = 0;
        data = response.data.data;
        if (data.length === undefined) {
          toast.error("No record found.");
          setLoading(false);
        } else {
          data.map((item: any, index: number) => {
            price += parseFloat(item.price || "0");
            yields += parseFloat(item.yield || "0");
            units += parseFloat(item.units || "0");
            money_market_face_value += parseFloat(
              item.money_market_face_value || "0"
            );
            total_face_value += parseFloat(item.total_face_value || "0");
            settlement_amount += parseFloat(item.settlement_amount || "0");
            accrued_amount += parseFloat(item.accrued_amount || "0");
            principal_amount += parseFloat(item.principal_amount || "0");
          });
          setMMFaceValue(money_market_face_value.toFixed(2));
          setPrice(price.toFixed(2));
          setUnits(units.toFixed(4));
          setYields(yields.toFixed(2));
          setSettlementAmount(settlement_amount.toFixed(2));
          setTotalFaceValue(total_face_value.toFixed(2));
          setAccruedAmount(accrued_amount.toFixed(2));
          setPrincipalAmount(principal_amount.toFixed(2));
          setLoading(false);
        }
      }
    } catch (error: any) {
      if (error.response.data.status === 400) {
        toast.error(error.response.data.message);
      } else {
        if (error.response.data.message.includes("Unexpected token")) {
          toast.error("Record not found!");
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };

  const renderDropdownValues = (selectedCol, transaction) => {
    let res = transaction[selectedCol.value];
    if (
      selectedCol.label.includes("date") ||
      selectedCol.label.includes("Date")
    ) {
      res = moment(transaction[selectedCol.value]).format("DD-MMM-YYYY");
    }
    if (
      selectedCol.value === "gross_amount" ||
      selectedCol.value === "net_amount" ||
      selectedCol.value === "money_market_face_value" ||
      selectedCol.value === "units" ||
      selectedCol.value === "price" ||
      selectedCol.value === "yield" ||
      selectedCol.value === "settlement_amount" ||
      selectedCol.value === "total_face_value" ||
      selectedCol.value === "accrued_amount" ||
      selectedCol.value === "accrued_days" ||
      selectedCol.value === "principal_amount" ||
      selectedCol.value === "unredeem_value" ||
      selectedCol.value === "days_of_maturity"
    ) {
      res = numberWithCommas(transaction[selectedCol.value] || "0");
    }
    // if (selectedCol.value === 'txnr_type') {
    //   res = CapTxnType(transaction[selectedCol.value]);
    // }
    return res;
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Money Market Transaction Report
                    </h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className={"form-control " + ErrorBorder(Amc)}
                            onChange={(e) => {
                              setType("");
                              setSecurity("");
                              setAmc(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                      .text
                              );
                              setAmcCode(e.target.value);
                              getFunds(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className={"form-control " + ErrorBorder(fund_code)}
                            onChange={(e) => {
                              setFund(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                      .text
                              );
                              setFundCode(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Secuity</label>
                          <select
                            className={"form-control " + ErrorBorder(security)}
                            onChange={(e) => {
                              setSecurity(e.target.value);
                            }}
                            value={security}
                          >
                            <option value="" defaultChecked hidden>
                              {" "}
                              Select Security
                            </option>
                            <optgroup>Money Market</optgroup>
                            <option value="PIB">
                              Pakistan Investment Bonds
                            </option>
                            <option value="TBILL">Treasury Bills</option>

                            <optgroup>Debt Market</optgroup>
                            <option value="SUKUK">Sukuk Certificates</option>
                            <option value="IJARA">GoP Ijara Sukuks</option>
                            <option value="TFC">
                              Term Finance Certificates
                            </option>
                            <option value="CP">Commercial Papers</option>

                            <optgroup>Term Deposits</optgroup>
                            <option value="TDR">Term Deposit Receipts</option>
                            <option value="CDR">Call Deposit Receipts</option>
                            <option value="LOP">Letter of placement</option>
                            <option value="ALL">All</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Type</label>
                          <select
                            className={
                              "form-control w-100 " + ErrorBorder(type)
                            }
                            onChange={(e) => {
                              setMoneyMarketData([]);
                              setType(e.target.value);
                            }}
                            value={type}
                          >
                            <option value="" defaultChecked hidden>
                              {" "}
                              Select Type
                            </option>
                            <option value="Buy">Buy</option>
                            <option value="Sell">Sell</option>
                            <option value="Maturity">Maturity</option>
                            <option value="Coupon">Coupon</option>
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {moneyMarketData.length > 0 && (
                  <Row>
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="mt-3 mr-3">
                        {moneyMarketData.length > 0 ? (
                          <MultiSelect
                            options={allDropdownColumns}
                            value={selectedFields}
                            onChange={setSelectedFields}
                            labelledBy={"Select Columns"}
                            className="multi-select"
                            overrideStrings={{
                              selectAll: "Select all Columns",
                              selectSomeItems: "Select Columns",
                            }}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        landscape={true}
                        fileName={
                          "Money-Market-Transaction-Report-" + date + ".pdf"
                        }
                        ref={pdfExportComponent}
                      >
                        {moneyMarketData.length > 0 ? (
                          <>
                            <ReportHeader title="Money Market Transaction Report" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Money Market Transaction Report</h3>
                  </div> */}
                            <Row noGutters style={{ marginLeft: "80px" }}>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  AMC:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Fund:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: "80px" }}>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Security:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">{resSecurity}</span>
                              </Col>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Type:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">{resType}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: "80px" }}>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  From Date:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">
                                  {moment(resFromDate).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  To Date:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">
                                  {moment(resToDate).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: "80px" }}>
                              <Col md="2">
                                <span
                                  className="text-nowrap"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Report Date:
                                </span>
                              </Col>
                              <Col md="4">
                                <span className="text-wrap">
                                  {moment(date).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap" >{resFund}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Security:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resSecurity}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Type:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resType}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                            </Row> */}

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    {selectedFields.map((item, index) => {
                                      return (
                                        <th key={index}>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {item.label.toString()}
                                          </span>
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {moneyMarketData.map(
                                    (transaction: any, index: number) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            {selectedFields.map((item, i) => {
                                              return (
                                                <td key={i}>
                                                  {renderDropdownValues(
                                                    item,
                                                    transaction
                                                  )}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}

                                  <tr>
                                    <td colSpan={selectedFields.length}></td>
                                  </tr>

                                  <tr>
                                    {selectedFields.map((item) => {
                                      if (
                                        item.value == "money_market_face_value"
                                      ) {
                                        return (
                                          <td>
                                            {numberWithCommas(
                                              money_market_face_value
                                            )}
                                          </td>
                                        );
                                      } else if (item.value == "price") {
                                        return (
                                          <td>{numberWithCommas(price)}</td>
                                        );
                                      } else if (item.value == "units") {
                                        return (
                                          <td>{numberWithCommas(units)}</td>
                                        );
                                      } else if (item.value == "yield") {
                                        return (
                                          <td>{numberWithCommas(yields)}</td>
                                        );
                                      } else if (
                                        item.value == "settlement_amount"
                                      ) {
                                        return (
                                          <td>
                                            {numberWithCommas(
                                              settlement_amount
                                            )}
                                          </td>
                                        );
                                      } else if (
                                        item.value == "total_face_value"
                                      ) {
                                        return (
                                          <td>
                                            {numberWithCommas(total_face_value)}
                                          </td>
                                        );
                                      } else if (
                                        item.value == "accrued_amount"
                                      ) {
                                        return (
                                          <td>
                                            {numberWithCommas(accrued_amount)}
                                          </td>
                                        );
                                      } else if (
                                        item.value == "principal_amount"
                                      ) {
                                        return (
                                          <td>
                                            {numberWithCommas(principal_amount)}
                                          </td>
                                        );
                                      } else {
                                        return <td></td>;
                                      }
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MoneyMarketTransactionReport;
