import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getFunds } from '../../../stores/services/funds.service';
import { addFundSecurity } from '../../../stores/services/security.service';
import NumberFormat from 'react-number-format';
import { MdDevicesOther } from 'react-icons/md';
import { setEmitFlags } from 'typescript';

const codeRegex = new RegExp('^[A-Z]{1,5}$');
const SetupFundSecurity = () => {
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const [fund, setFund] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');

    const [fundError, setFundError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [nameError, setNameError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [allFunds, setAllFunds] = useState<any>([]);
    const [flag, setFlag] = useState<any>(false);

    const [Loading, setLoading] = useState(false);


    useEffect(() => {
        const getAllfunds = async () => {
            try {
                setAllFunds([]);
                const response = await getFunds(email);
                let temp: any = '';
                temp = { fund_name: 'Other' };
                response.data.data.unshift(temp);
                setAllFunds(response.data.data);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message)
                }
            }
        }
        getAllfunds();
    }, [])


    const validate = () => {
        let fundErr,
            codeErr,
            priceErr,
            nameErr = '';
        fund.trim() === '' ? (fundErr = 'Required') : (fundErr = '');
        name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');
        code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');
        price.trim() === ('') ? (priceErr = 'Required') : (priceErr = '');

        if (fundErr ||
            nameErr ||
            codeErr ||
            priceErr
        ) {
            setFundError(fundErr);
            setNameError(nameErr);
            setCodeError(codeErr);
            setPriceError(priceErr);
            return false;
        } else {
            return true;
        }
    };

    const renderFundsDropdown = () => {
        return allFunds.map((item: any, index: number) => {
            return (
                <>
                    {
                        index === 0 ? (
                            <option key={index} value={item.fund_name}>
                                {item.fund_name}
                            </option>
                        ) : (
                            <option key={index} value={item.symbol_code.replace('FUND_', '')}>
                                {item.symbol_code.replace('FUND_', '')}-{item.fund_name}
                            </option>
                        )
                    }

                </>
            );
        });
    };

    const createFundSecurity = async () => {
        const isValid = validate();
        if (isValid) {
            if (parseFloat(price) <= 0) {
                toast.error('Price must be greater than zero');
            } else {

                setLoading(true)
                await addFundSecurity(email, name, code, price)
                    .then((response) => {
                        if (response.data.status == 200) {
                            toast.success(response.data.message);
                            setTimeout(function () {
                                history.push('/admin/securities', { params: '3' });
                            }, 3000);
                        } else if (response.data.status == 500) {
                            toast.error(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }

                    })
                    .catch((err: any) => {
                        if (err.response !== undefined) {
                            toast.error(err.response.data.message);
                        } else {
                            toast.error(err.message)
                        }
                    })
                setLoading(false);
            }
        }
    }

    const decimalValue = (value, len) => {
        let startingValue = value.split('.')[0];
        let decimalVal = value.split('.')[1];
        let temp = '';
        if (decimalVal !== undefined) {
            let decimalString = decimalVal.toString();
            if (decimalString.length <= len) {
                temp = startingValue + '.' + decimalString;
            } else {
                temp = startingValue + '.' + decimalString.substr(0, len);
            }
        } else {
            temp = startingValue;
        }
        return temp;
    };
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card ">
                                <div className="">
                                    <div className="card-header title-row">
                                        <h4 className="card-title">
                                            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                                                history.push('/admin/securities', { params: '3' })
                                            }}></i>Setup - Investment In Funds</h4>
                                        <button className="t-3 ml-auto btn btn-primary btn-sm" onClick={() => {
                                            history.push('/admin/securities', { params: '3' })
                                        }}><i className="fa fa-eye mr-2"></i>View All</button>
                                        {/* <Link to="/admin/securities" className="t-3 ml-auto btn btn-primary btn-sm" replace>  View All</Link> */}
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Select Fund</label>
                                                    <div>
                                                        <div className="">
                                                            <select
                                                                className={`form-control  w-100 ${fundError ? ' required-border' : ''
                                                                    }`}
                                                                onChange={(e) => {
                                                                    allFunds.filter((elem) => {
                                                                        if (elem.fund_name === 'Other') {
                                                                            setCode('');
                                                                            setName('');
                                                                            setPrice('');
                                                                            setFlag(false);
                                                                        } else {
                                                                            if (elem.symbol_code.replace('FUND_', '') === e.target.value) {
                                                                                setCode(elem.symbol_code.replace('FUND_', ''));
                                                                                setName(elem.fund_name);
                                                                                setFlag(true);
                                                                            }
                                                                        }
                                                                    })
                                                                    setFund(e.target.value);
                                                                    setPrice('')
                                                                    setFundError('');

                                                                }}>
                                                                <option value="" defaultChecked hidden>
                                                                    Select Fund
                                                                </option>
                                                                {renderFundsDropdown()}
                                                            </select>


                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Code: <span className="color-amber">*</span></label>
                                                    <div>
                                                        <div className="">
                                                            <input
                                                                type="text"
                                                                disabled={flag}
                                                                className={`form-control  w-100 ${codeError ? ' required-border' : ''
                                                                    }`}
                                                                value={code}
                                                                onChange={(e) => {
                                                                    setCode((e.target.value).toUpperCase());
                                                                    setCodeError('');
                                                                }}
                                                                readOnly={flag}
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Name: <span className="color-amber">*</span></label>
                                                    <div className="">
                                                        <input
                                                            className={`form-control  w-100 ${nameError ? ' required-border' : ''}`}
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                                setNameError('');
                                                            }} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <div>
                                                        <div className="">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                inputMode="numeric"
                                                                className={`form-control text-right  w-100 ${priceError ? ' required-border' : ''}`}
                                                                value={decimalValue(price, 4)}
                                                                onValueChange={(e) => {
                                                                    setPrice(e.value);
                                                                    setPriceError('');
                                                                }} />


                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                            {/* <Col md="6">
                                                <div className="form-group">
                                                    <label>Issue Date</label>
                                                    <div>
                                                        <div className="">
                                                            <input
                                                                type="date"
                                                                // className={`form-control  w-100 ${issueDateError ? ' required-border' : ''}`}
                                                                className="form-control w-100"
                                                                value={issueDate}
                                                                onChange={(e) => {
                                                                    setIssueDate(e.target.value);
                                                                    setIssueDateError('')
                                                                }} />


                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Maturity Date</label>
                                                    <div className="">
                                                        <input type='date' value={maturityDate}
                                                            // className={`form-control  w-100 ${maturityDateError ? ' required-border' : ''}`} 
                                                            className="form-control w-100"
                                                            onChange={(e) => {
                                                                setMaturityDate(e.target.value);
                                                                setMaturityDateError('');
                                                            }} />
                                                    </div>
                                                </div>
                                            </Col> */}
                                            {/* <Col md="6">
                                                <div className="form-group">
                                                    <label>Face Value</label>
                                                    <div>
                                                        <div className="">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                inputMode="numeric"
                                                                // className={`form-control text-right  w-100 ${faceValError ? ' required-border' : ''}`}
                                                                className="form-control w-100 text-right"
                                                                value={faceValue}
                                                                onValueChange={(e) => {
                                                                    let value = decimalValue(e.value, 2);
                                                                    setFaceValue(value);
                                                                    setFaceValError('');
                                                                }} />


                                                        </div>

                                                    </div>
                                                </div>
                                            </Col> */}
                                        </Row>
                                        <Row>


                                        </Row>
                                        <div className="">
                                            <button className="btn btn-primary" onClick={createFundSecurity} disabled={Boolean(Loading)}>
                                                {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="login-txt"> Loading...</span></> : <span>Create</span>}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    )
};
export default SetupFundSecurity;