import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';

const ViewBranch = () => {
  const history = useHistory();	
  const obj = JSON.parse(sessionStorage.getItem('branchObj') || '');

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <div className="card">
          <div className="">
            <div className="card-header input-holder ">
              <h4 className="card-title">
              <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/bank-branches')
                  }}></i>View Branch</h4>
              <Link
                to="/admin/bank-branches"
                className="btn btn-primary btn-sm t-3 ml-auto "
                replace
              >
                 <i className="fa fa-eye mr-2"></i> View All
              </Link>
            </div>
            <div className="card-body">
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>
                      Bank Code 
                    </label>
                    <div className="form-control">
                      <span>{obj.bank_name}</span>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group  ">
                    <label>
                      Branch Name 
                    </label>
                    <div className="form-control">
                      <span>{obj.branch_name}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>
                      Branch Code 
                    </label>
                    <div>
                      <div className="form-control">
                        <span>{obj.branch_code.replace("BRANCH_",'')}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Branch Address</label>
                    <div className="form-control">
                      <span>{obj.branch_address}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>City</label>
                    <div className="form-control">
                      <span>{obj.city}</span>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Area</label>
                    <div className="form-control">
                      <span>{obj.area}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewBranch;
