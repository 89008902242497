import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFunds } from "../../stores/services/funds.service";
import { getAmc } from "../../stores/services/amc.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { isDateValid, isNumber, sortAMCS } from "../../utils/customFunction";
import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "react-google-charts";

import { Options } from "tsparticles/Options/Classes/Options";
import { ConvertToExponential } from "../../stores/services/dashboard.service";
import { fundInvestmentSmry } from "../../stores/services/summary.service";
import { getFunctionalHighlightReport } from "../../stores/services/report.service";

const FunctionalHighlights = () => {
    const pdfExportComponent = React.useRef<PDFExport>(null);
    const [loading, setLoading] = useState(false);
    const email = sessionStorage.getItem("email") || "";
    let date = new Date();
    const [Amc, setAmc] = useState("");
    const [fund, setFund] = useState("");
    const [allAMC, setAllAMC] = useState([]);

    const [completeReportData, setCompleteReportData] = useState<any>({});
   
    const [reportData, setReportData] = useState<any>({
        mutual_funds: { company: 0, security: 0, investor: 0, amount : 0 }, special_accounts: { company: 0, security: 0, investor: 0, amount : 0 }, private_equities: { company: 0, security: 0, investor: 0, amount : 0 }, reit_schemes: { company: 0, security: 0, investor: 0, amount : 0 }, other: { company: 0, security: 0, investor: 0, amount : 0 }, registrar_ta: { company:  0, security:  0, investor:  0, amount : 0 }

    });
    // const data = [
    //     ["Task", "Rupees In Billion", { role: "style" }],
    //     ["Trustee Funds 130b", 130, "#4e67c8"],
    //     ["Custoday 60b", 60, "#A7EA52"],
    //     ["Reit Funds 30b", 30, "#5ECCF3"],
    // ];
    const [data,setData] = useState<any>([])


    useEffect(() => {
        async function getAllFundInvetment() {
            setLoading(true);
            try {
                const response = await getFunctionalHighlightReport(email);
                setCompleteReportData(response.data);
                let temp = { mutual_funds: { company: 0, security: 0, investor: 0, amount:0 }, special_accounts: { company: 0, security: 0, investor: 0,amount:0 }, private_equities: { company: 0, security: 0, investor: 0 ,amount:0}, reit_schemes: { company: 0, security: 0, investor: 0,amount:0 }, other: { company: 0, security: 0, investor: 0 ,amount:0}, registrar_ta: { company: response?.data?.registrar?.companies || 0, security: response?.data?.registrar?.companies || 0, investor: response?.data?.registrar?.shareholders || 0 ,amount:0} }
                response.data.data.map(item => {
                    if (item.mutualFunds?.mutualCount > 0 || item.mutualFunds.mutualUnitHolders > 0) {
                        temp.mutual_funds = { company: temp.mutual_funds.company + 1, security: temp.mutual_funds.security + item.mutualFunds?.mutualCount, investor: temp.mutual_funds.investor + (item.mutualFunds.mutualUnitHolders || 0), amount:temp.mutual_funds.amount +  (item.mutualFunds.mutualAmount || 0) }

                    }
                    if (item.others?.othersCount > 0 || item.others?.othersUnitholders) {
                        temp.other = { company: temp.other.company + 1, security: temp.other.security + item.others?.othersCount, investor: temp.other.investor + (item.others?.othersUnitholders || 0) , amount:temp.other.amount +  (item.others.othersAmount || 0)}

                    }
                    if (item.privateEquity?.privateCount > 0 ||  item.privateEquity?.privateUnitholders> 0) {
                        temp.private_equities = { company: temp.private_equities.company + 1, security: temp.private_equities.security + item.privateEquity?.privateCount, investor: temp.private_equities.investor + (item.privateEquity?.privateUnitholders || 0), amount:temp.private_equities.amount +  (item.privateEquity.privateAmount || 0) }
                    }

                    if (item.reit?.reitCount > 0 || item.reit?.reitUnitHolders >0) {
                        temp.reit_schemes = { company: temp.reit_schemes.company + 1, security: temp.reit_schemes.security + item.reit?.reitCount, investor: temp.reit_schemes.investor + (item.reit?.reitUnitHolders || 0) , amount:temp.reit_schemes.amount +  (item.reit.reitAmount || 0)}

                    }
                    if (item.sma?.smaCount > 0 || item.sma?.smaUnitHolders > 0) {
                        temp.special_accounts = { company: temp.special_accounts.company + 1, security: temp.special_accounts.security + item.sma?.smaCount, investor: temp.special_accounts.investor + (item.sma?.smaUnitHolders || 0), amount:temp.special_accounts.amount +  (item.sma.smaAmount || 0) }

                    }


                })

                 setData([
                    ["Task", "Rupees In Billion", { role: "style" }],
                    [`Mutual Funds ${(temp.mutual_funds.amount/1000000000)?.toString()?.split(".")[0]}b`, Number((temp.mutual_funds.amount/1000000000)?.toString()?.split(".")[0]),"#4e67c8"],
                    [`Special Accounts ${(temp.special_accounts.amount/1000000000)?.toString()?.split(".")[0]}b`,Number((temp.special_accounts.amount/1000000000)?.toString()?.split(".")[0]),"#A7EA52"],
                    [`Private Equities ${(temp.private_equities.amount/1000000000)?.toString()?.split(".")[0]}b`, Number((temp.private_equities.amount/1000000000)?.toString()?.split(".")[0]),"#5ECCF3"],
                    [`REIT Schemes ${(temp.reit_schemes.amount/1000000000)?.toString()?.split(".")[0]}b`, Number((temp.reit_schemes.amount/1000000000)?.toString()?.split(".")[0]),"#5ECC23"],
                    [`Other ${(temp.other.amount/1000000000)?.toString()?.split(".")[0]}b`, Number((temp.other.amount/1000000000)?.toString()?.split(".")[0]),"#5EC888"],
                    [`Registrat/TA ${(temp.registrar_ta.amount/1000000000)?.toString()?.split(".")[0]}b`, Number((temp.registrar_ta.amount/1000000000)?.toString()?.split(".")[0]),"#615C43"]

                ])


              

                setReportData(temp)
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
            setLoading(false);
        }

        getAllFundInvetment();
    }, []);

   



    const options = {
        pieHole: 0.4,
        is3D: false,
        width: 285,
        height: 230,
        
        chartArea: {
            left: 8,
            top: 0,
            right: 8,
            width: "100%",
            height: "100%",
        },
        pieSliceText: "value", // Display actual data values
        pieSliceTextStyle: {
            color: "#000000", // Color of the text
            fontSize: 9, // Size of the text
            bold: true, // Bold style
        },
        legend: {
            position: "right",
            alignment: "center",
            maxLines: 1,
            textStyle: {
                color: "#a5a5a5",
                fontSize: 10,
                bold: true,
            },
        },
        annotations: {
            textStyle: {
                color: "#ffffff",
                fontSize: 14,
                bold: true,
            },
            stem: {
                color: "transparent",
                length: "0px",
            },
            highContrast: false,
            total: {
                label: "Total Value",
                color: "red",
                fontSize: 16,
                showValue: true,
                position: "center",
            },
        },
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Functional Highlights Report</h4>
                                    </div>
                                    {/* <div className="card-body">
                                        <Row>
                                            <Col md="2">
                                                <div className="form-group  ">
                                                    <label>Select AMC</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setAmc(
                                                                e.nativeEvent.target == null
                                                                    ? ""
                                                                    : e.nativeEvent.target[e.target.selectedIndex]
                                                                        .text
                                                            );
                                                            getFunds(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" defaultChecked hidden>
                                                            Select AMC
                                                        </option>
                                                        {renderAmcDropdown()}
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="form-group">
                                                    <label>Select Fund</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            //   setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                                                            //   getFundAccounts(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" defaultChecked hidden>
                                                            Select Fund
                                                        </option>
                                                         {renderFundsDropDown()} 
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className=" ">
                                                   
                                                    <button
                                                        className="btn btn-primary mt-4"
                                                        onClick={() => {
                                                            searchData();
                                                        }}
                                                        disabled={Boolean(loading)}
                                                    >
                                                        {loading ? (
                                                            <>
                                                                <span
                                                                    className="spinner-border login-txt spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="login-txt"> Loading...</span>
                                                            </>
                                                        ) : (
                                                            <span>Search</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                     
                                    </div> */}
                                </div>

                                {/* here Search results for report Data */}
                                {loading ? 
                                <div className="d-flex justify-content-center my-5">	
                                <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                              </div> :
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div
                                            id="printDiv"
                                            className="p-4"
                                            style={{ background: "white", color: "black" }}
                                        >
                                            <PDFExport
                                                paperSize="A4"
                                                margin="1.5cm"
                                                scale={0.6}
                                                repeatHeaders={true}
                                                fileName={
                                                    "Functional-Highlights-Report-" + date + ".pdf"
                                                }
                                                ref={pdfExportComponent}
                                            >
                                                {/* {bankStatement.length > 0 ? ( */}
                                                <>
                                                    <Row>
                                                        <Col md="6">
                                                            <span
                                                                className="text-nowrap functionalHighlightsFont"
                                                                style={{
                                                                    fontSize: "20px",
                                                                    color: "#0e79ca",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Functional Highlights
                                                            </span>
                                                        </Col>
                                                        <Col md="2"></Col>
                                                        <Col md="4">
                                                            <div
                                                                className="text-center functionalHighlightsFont"
                                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                                            >
                                                                Assets Under Administration <br />
                                                                <span
                                                                    className="text-center functionalHighlightsFont"
                                                                    style={{
                                                                        fontSize: "10px",
                                                                        color: "#0e79ca",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                   {ConvertToExponential(reportData.mutual_funds.amount || 0 + reportData.special_accounts.amount || 0 + reportData.private_equities.investor + reportData.reit_schemes.amount || 0 + reportData.other.amount || 0 + reportData.registrar_ta.amount || 0 ,2)} Rupees 
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="8">
                                                            <div
                                                                style={{
                                                                    overflowX: "auto",
                                                                    overflowY: "hidden",
                                                                }}
                                                            >
                                                                <table
                                                                    className="functionalHighlights functionalHighlightsFont"
                                                                    style={{ color: "black" }}
                                                                >
                                                                    <thead style={{ alignSelf: "center" }}>
                                                                        <tr>
                                                                            <th style={{ width: "35%" }}>
                                                                                <div
                                                                                    className="text-nowrap"
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    SEGMENTS
                                                                                </div>
                                                                            </th>
                                                                            <th className="text-center">
                                                                                <div style={{ fontSize: "12px" }}>
                                                                                    COMPANIES
                                                                                </div>
                                                                            </th>
                                                                            <th className="text-center">
                                                                                <div style={{ fontSize: "12px" }}>
                                                                                    SECURITIES
                                                                                </div>
                                                                            </th>
                                                                            <th className="text-center">
                                                                                <div style={{ fontSize: "12px" }}>
                                                                                    INVESTORS
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="invoice-table-body">
                                                                        <tr style={{ backgroundColor: "#EDFBDC" }}>
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>Mutual Funds</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Trustee
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>
                                                                                    {ConvertToExponential(
                                                                                        reportData.mutual_funds.company || 0,
                                                                                        0
                                                                                    )}
                                                                                </b>{" "}
                                                                                <br />
                                                                                <span>AMCs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.mutual_funds.security || 0,
                                                                                    0
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Funds</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.mutual_funds.investor || 0,
                                                                                    2
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Unitholders</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>Special Accounts</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Custody
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>
                                                                                  
                                                                                    {ConvertToExponential(
                                                                                        reportData?.special_accounts
                                                                                            ?.company || 0,
                                                                                        0
                                                                                    )}
                                                                                </b>
                                                                                <br />
                                                                                <span>AMCs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>
                                                                                    {ConvertToExponential(
                                                                                        reportData?.special_accounts
                                                                                            ?.security || 0,
                                                                                        0
                                                                                    )}
                                                                                </b>
                                                                                <br />
                                                                                <span>SMAs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>
                                                                                    {ConvertToExponential(
                                                                                        reportData?.special_accounts
                                                                                            ?.investor || 0,
                                                                                        2
                                                                                    )}
                                                                                </b>
                                                                                <br />
                                                                                <span>Investors</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ backgroundColor: "#EDFBDC" }}>
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>Private Equities</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Trustee
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.private_equities.company || 0,
                                                                                    0
                                                                                )}</b>
                                                                                <br />
                                                                                <span>AMCs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.private_equities.security || 0,
                                                                                    0
                                                                                )}</b>
                                                                                <br />
                                                                                <span>Funds</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.private_equities.investor || 0,
                                                                                    2
                                                                                )}</b>
                                                                                <br />
                                                                                <span>Unitholders</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>REIT Schemes</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Trustee
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.reit_schemes.company || 0,
                                                                                    0
                                                                                )}</b>
                                                                                <br />
                                                                                <span>RMCs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.reit_schemes.security || 0,
                                                                                    0
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Schemes</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.reit_schemes.investor || 0,
                                                                                    2
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Investors</span>
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr style={{ backgroundColor: "#EDFBDC" }}>
                                      <td className="text-nowrap">
                                        <div
                                          style={{
                                            fontSize: "20px",
                                            color: "#073c65",
                                          }}
                                        >
                                          <b>Escrow</b> <br />
                                          <span style={{ fontSize: "16px" }}>
                                            Custody
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <b style={{ fontSize: "20px" }}>3</b>{" "}
                                        <br />
                                        <span>Entities</span>
                                      </td>
                                      <td className="text-center">
                                        <b style={{ fontSize: "20px" }}>5</b>{" "}
                                        <br />
                                        <span>Agreements</span>
                                      </td>
                                      <td className="text-center">
                                        <b style={{ fontSize: "20px" }}>10</b>{" "}
                                        <br />
                                        <span>Parties</span>
                                      </td>
                                    </tr> */}
                                                                        <tr style={{ backgroundColor: "#EDFBDC" }}>
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>Other</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Trustee
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.other.company || 0,
                                                                                    0
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>AMCs</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.other.security || 0,
                                                                                    0
                                                                                )}</b>
                                                                                <br />
                                                                                <span>Funds</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.other.investor || 0,
                                                                                    2
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Unitholders</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr
                                                                            style={{
                                                                                borderBottom: "3px solid #B5ED6F",

                                                                            }}
                                                                        >
                                                                            <td className="text-nowrap">
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "20px",
                                                                                        color: "#073c65",
                                                                                    }}
                                                                                >
                                                                                    <b>Registrar / TA</b> <br />
                                                                                    <span style={{ fontSize: "16px" }}>
                                                                                        Corporate Share Registrar
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.registrar_ta.company || 0,
                                                                                    0
                                                                                )}</b>
                                                                                <br />
                                                                                <span>Companies</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.registrar_ta.security || 0,
                                                                                    0
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Securities</span>
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    borderBottom: "3px solid #B5ED6F",
                                                                                }}
                                                                                className="text-center"
                                                                            >
                                                                                <b style={{ fontSize: "20px" }}>{ConvertToExponential(
                                                                                    reportData.registrar_ta.investor || 0,
                                                                                    2
                                                                                )}</b>{" "}
                                                                                <br />
                                                                                <span>Shareholders</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    color: "#073c65",
                                                                                }}
                                                                            >
                                                                                <b>Total</b>
                                                                            </td>
                                                                            <td
                                                                                style={{ fontSize: "20px" }}
                                                                                className="text-center"
                                                                            >
                                                                                <b>{ConvertToExponential(reportData.mutual_funds.company + reportData.special_accounts.company + reportData.private_equities.company + reportData.reit_schemes.company + reportData.other.company + reportData.registrar_ta.company,0)}</b>
                                                                            </td>
                                                                            <td
                                                                                style={{ fontSize: "20px" }}
                                                                                className="text-center"
                                                                            >
                                                                                <b>{ConvertToExponential(reportData.mutual_funds.security + reportData.special_accounts.security + reportData.private_equities.security + reportData.reit_schemes.company + reportData.other.security + reportData.registrar_ta.security,0)}</b>
                                                                            </td>
                                                                            <td
                                                                                style={{ fontSize: "20px" }}
                                                                                className="text-center"
                                                                            >
                                                                                <b>{ConvertToExponential(reportData.mutual_funds.investor + reportData.special_accounts.investor + reportData.private_equities.investor + reportData.reit_schemes.investor + reportData.other.investor + reportData.registrar_ta.investor,2)}</b>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <Row>
                                                                <Col className="mt-2 d-flex justify-content-center">
                                                                   
                                                                    {data?.length > 0 && 
                                                                    <Chart
                                                                        chartType="PieChart"
                                                                        data={data}
                                                                        options={options}
                                                                        style={{
                                                                            borderRadius: "1rem",
                                                                            paddingRight: "0px",
                                                                        }}
                                                                    />}
                                                                </Col>
                                                            </Row>
                                                            {/* <Col
                                                                md="4"
                                                                className="text-center text-nowrap mt-3"
                                                            ></Col> */}
                                                            <Row style={{ position:'relative'}} className="justify-content-center" >
                                                                <Col
                                                                    md="6"
                                                                    className="text-center functionalHighlightsFont text-nowrap mt-3"
                                                                    style={{borderBottom:'1px solid #000', paddingBottom:'10px'}}
                                                                >
                                                                    <div 
                                                                     style={{ marginTop: "-45px" }}
                                                                    >
                                                                        Monthly <br />
                                                                        <span
                                                                            style={{
                                                                                fontSize: "32px",
                                                                                color: "#0273C7",
                                                                            }}
                                                                        >
                                                                            {ConvertToExponential(completeReportData?.monthlyTransactions || 0,2)}
                                                                        </span>
                                                                        <br />
                                                                        <span className="p-2">Transactions</span>
                                                                    </div>
                                                                </Col>
                                                                <Col
                                                                    md="6"
                                                                    className="text-center functionalHighlightsFont mt-3"
                                                                    style={{borderBottom:'1px solid #000', paddingBottom:'10px'}}
                                                                >
                                                                    <div
                                                                        className="text-nowrap"
                                                                         style={{ marginTop: "-45px" }}
                                                                    >
                                                                        Monthly <br />
                                                                        <span
                                                                            style={{
                                                                                fontSize: "32px",
                                                                                color: "#0273C7",
                                                                            }}
                                                                        >
                                                                            {ConvertToExponential(completeReportData?.monthlyTransactionAmount || 0,2)}
                                                                        </span>
                                                                        <br />
                                                                        <span className="p-3">Rupees</span>
                                                                    </div>
                                                                </Col>
                                                                <div style={{position:'absolute', borderRight:'1px solid #000', height:'156%', marginTop:'-30px',right:"50%"}}>
                                                                    &nbsp;
                                                                </div>
                                                            </Row>
                                                            <Row className="justify-content-center" style={{position:'relative'}} >
                                                                <Col
                                                                    md="6"
                                                                    className="text-center functionalHighlightsFont text-nowrap mt-2"
                                                                >
                                                                    Yearly <br />
                                                                    <span
                                                                        style={{
                                                                            fontSize: "32px",
                                                                            color: "#0273C7",
                                                                        }}
                                                                    >
                                                                       {ConvertToExponential(completeReportData?.yearlyTransactions || 0,2)}
                                                                    </span>
                                                                    <br />
                                                                    <span className="p-2">Transactions</span>
                                                                </Col>
                                                                <Col
                                                                    md="6"
                                                                    className="text-center functionalHighlightsFont text-nowrap mt-2"
                                                                >
                                                                    Yearly <br />
                                                                    <span
                                                                        style={{
                                                                            fontSize: "32px",
                                                                            color: "#0273C7",
                                                                        }}
                                                                    >
                                                                       {ConvertToExponential(completeReportData?.yearlyTransactionAmount || 0,2)}
                                                                    </span>
                                                                    <br />
                                                                    <span className="p-3">Rupees</span>
                                                                </Col>
                                                                <div style={{position:'absolute', borderRight:'1px solid #000', height:'100%',right:"50%"}}>
                                                                    &nbsp;
                                                                </div>
                                                                
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </>
                                            </PDFExport>
                                        </div>
                                        <button
                                            className="btn btn-primary mt-3"
                                            onClick={() => {
                                                // printReport();
                                                if (pdfExportComponent.current) {
                                                    pdfExportComponent.current.save();
                                                }
                                            }}
                                        >
                                            Print
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FunctionalHighlights;
