import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';



const paymentToBank = async (
    Company: string,
    TransactionType: string,
    CustomerReferenceNumber: string,
    SenderAccountID: string,
    SenderBankName: string,
    SenderBankAccountNo: string,
    ReceiversName: string,
    ReceiversAddress: string,
    ReceiversCNICorNTN: string,
    ReceiversContactDetails: string,
    ReceiverID: string,
    ReceiverBankName: string,
    ReceiverBankCode: string,
    ReceiverBankAccountNo: string,
    ReceiverBranchAddress: string,
    ReceiverBranchCode: string,
    Amount: number,
    PurposeofPayment: string,
    EmailAddress: string,
    PrintLocation: string,
    Deliverto: string,
    ChequeNumber: string,
    ChequeDate: string,
    userid: string,
    UniqueRequestID: string,
    CompanyName: string,
    PaymentDate: string,
    TransactionRefNumber: string,
    TransactionMonth: string,
    UD1: string,
    UD2: string,
    UD3: string,
    TransactionStatus: string,
) => {
    // const url = `https://apis1.hbl.com:8343/sandbox/CorpPay/Transaction/Process`;
    const url = `${Config.hblUrl}`;
    try {
        const result: any = await axios.post(
            url,
            {
                Company,
                TransactionType,
                CustomerReferenceNumber,
                SenderAccountID,
                SenderBankName,
                SenderBankAccountNo,
                ReceiversName,
                ReceiversAddress,
                ReceiversCNICorNTN,
                ReceiversContactDetails,
                ReceiverID,
                ReceiverBankName,
                ReceiverBankCode,
                ReceiverBankAccountNo,
                ReceiverBranchAddress,
                ReceiverBranchCode,
                Amount,
                PurposeofPayment,
                EmailAddress,
                PrintLocation,
                Deliverto,
                ChequeNumber,
                ChequeDate,
                userid,
                UniqueRequestID,
                CompanyName,
                PaymentDate,
                TransactionRefNumber,
                TransactionMonth,
                UD1,
                UD2,
                UD3,
                TransactionStatus,
            },
            {
                headers: {
                    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJPOW52ZVlha0FvOVNGR1J5dllCNnVDTU1abnRFQkpoMSJ9.UmnMf_lgTzEOrqHeVbSrxMmbDhbkjQxVi-Mi1MOZZPQ`,
                    // Token: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJPOW52ZVlha0FvOVNGR1J5dllCNnVDTU1abnRFQkpoMSJ9.UmnMf_lgTzEOrqHeVbSrxMmbDhbkjQxVi-Mi1MOZZPQ`,
                    Authorization: `${Config.hblAuthorization}`,
                    Token: `${Config.hblToken}`,
                    'x-channel-id': 'CRPL',
                    'x-req-id': CustomerReferenceNumber,
                },
            }
        );
        return result;
    } catch (err: any) {
        throw err
    }
};


const postHBLID = async (
    email: string,
    x_req_id: string,
    customer_reference_number: string,
    sender_account_id: string,
) => {
    const url = `${Config.baseUrl}/hblapi`;
    try {
        const result: any = await axios.post(
            url,
            {
                email,
                x_req_id,
                customer_reference_number,
                sender_account_id,
            },
            {
                headers: {
                    Authorization: sessionStorage.getItem("token") || "",

                },
            }
        );
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await postHBLID(
                    email,
                    x_req_id,
                    customer_reference_number,
                    sender_account_id,
                )
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

const getHBLID = async (email: string) => {
    const url = `${Config.baseUrl}/hblapi?email=${email}`;
    try {
        const result: any = await axios.get(url, {
            headers: {
                Authorization: sessionStorage.getItem('token') || '',
            },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await getHBLID(email)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

const searchBankPaymnetTransactions = async (
    email: string,
    amc_code: string,
    fund_code: string,
    search_value: string,
    page_number: string
) => {
    const url = `${Config.baseUrl}/transaction/bank-payment?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&txn_id=${search_value}&page_number=${page_number}&page_size=10`;
    try {
        const result: any = await axios.get(url, {
            headers: {
                Authorization: sessionStorage.getItem('token') || '',
            },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await searchBankPaymnetTransactions(email, amc_code, fund_code, search_value, page_number)
            } else {
                throw err;
                // return result
            }

        } else {
            throw err
        }

    }
};

const updateBankPaymentTransactionStatus = async (
    email: string,
    ledger_id: string,
    realized_in_bank: Boolean,
    hbl_payment_reference_number: string,
    realized_in_bank_date: string,
) => {
    const url = `${Config.baseUrl}/transaction/make-account-ledger-payment`;
    try {
        const result: any = await axios.post(
            url,
            {
                email,
                ledger_id,
                realized_in_bank,
                hbl_payment_reference_number,
                realized_in_bank_date,
            },
            {
                headers: {
                    Authorization: sessionStorage.getItem('token') || '',
                },
            }
        );
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await updateBankPaymentTransactionStatus(email, ledger_id, realized_in_bank, hbl_payment_reference_number, realized_in_bank_date)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};


export { paymentToBank, postHBLID, getHBLID, searchBankPaymnetTransactions, updateBankPaymentTransactionStatus }