import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SetupFeeCollection = () => {

    return(
        <div className="content">
            <Row>
            <Col md="12">
            <Container fluid>
                <ToastContainer limit={1} />
                  <div className=" input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    
                  }}></i>Setup - Fee Collection</h4>
                
              </div>


              
                    </Container>
                    </Col>
            </Row>
        </div>
    )
}


export default SetupFeeCollection;
