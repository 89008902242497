import { env } from './../env';

const Config = {

    // IP
    baseUrl: `${env.REACT_APP_BASE_URL}`, //deployed
    socketBaseUrl: `${env.REACT_APP_SOCKET_URL}`, //deployed
    imageBaseUrl: `${env.REACT_APP_IMAGE_URL}`, //forimage
    loginRegisterUrl: `${env.REACT_LOGIN_REGISTER}`, //forimage
    q_r_url: `${env.REACT_APP_QR_URL}`, 
    hblUrl: `${env.REACT_APP_HBL_URL}`,
    hblToken: `${env.REACT_APP_HBL_API_TOKEN}`,
    hblAuthorization: `${env.REACT_APP_HBL_API_AUTHORIZATION}`,

    // Naya Tell
    // baseUrl: "http://124.109.39.158:3000/api", //deployed
    // socketBaseUrl: "http://124.109.39.158:3000/", //deployed
    // imageBaseUrl: "http://124.109.39.158:3000/", //forimage 

    // Naya Tell prox max
    // baseUrl: "http://192.168.18.152:3000/api", //deployed
    // socketBaseUrl: "http://192.168.18.152:3000/", //deployed
    // imageBaseUrl: "http://192.168.18.152:3000/", //forimage 

    // PTCL IP
    // baseUrl: "http://182.191.93.242:3000/api", //deployed
    // socketBaseUrl: "http://182.191.93.242:3000/", //deployed
    // imageBaseUrl: "http://182.191.93.242:3000/", //forimage

    // for prod testing
    // baseUrl: "http://192.168.18.188:3000/api", //deployed
    // socketBaseUrl: "http://192.168.18.188:3000/", //deployed
    // imageBaseUrl: "http://192.168.18.188:3000/", //forimage 

    // Naya Tell Dev 3
    // baseUrl: "http://124.109.39.158:13000/api", //deployed
    // socketBaseUrl: "http://124.109.39.158:13000/", //deployed
    // imageBaseUrl: "http://124.109.39.158:13000/", //forimage 

    // NayaTel
    // baseUrl: "http://192.168.18.156:3000/api", //deployed
    // socketBaseUrl: "http://192.168.18.156:3000/", //deployed
    // imageBaseUrl: "http://192.168.18.156:3000/", //forimage

    // Local NayaTel
    // baseUrl: "http://192.168.18.183:3000/api", //deployed
    // socketBaseUrl: "http://192.168.18.183:3000/", //deployed
    // imageBaseUrl: "http://192.168.18.183:3000/", //forimage

    // baseUrl: "..//182.191.93.242:3000/api", //deployed
    // socketBaseUrl: "..//182.191.93.242:3000/", //deployed
    // imageBaseUrl: "..//182.191.93.242:3000/", //forimage

    // VPS-1
    // baseUrl: "http://167.86.69.108:3000/api", //deployed
    // socketBaseUrl: "http://167.86.69.108:3000/", //deployed
    // imageBaseUrl: "http://167.86.69.108:3000/", //forimage


    // VPS-1 - domain name
    // baseUrl: "http://digitalcustodian.co:3000/api", //deployed
    // socketBaseUrl: "http://digitalcustodian.co:3000/", //deployed
    // imageBaseUrl: "http://digitalcustodian.co:3000/", //forimage

    // VPS-3
    // baseUrl: "http://75.119.132.100:3000/api", //deployed
    // socketBaseUrl: "http://75.119.132.100:3000/", //deployed
    // imageBaseUrl: "http://75.119.132.100:3000/", //forimage

    // VPS-2
    // baseUrl: "https://macs-core.com:3000/api", //deployed
    // socketBaseUrl: "https://macs-core.com:3000/", //deployed
    // imageBaseUrl: "https://macs-core.com:3000/", //forimage

    // VPS-2 - unsecure
    // baseUrl: "https://194.233.69.251:3000/api", //deployed
    // socketBaseUrl: "https://194.233.69.251:3000/", //deployed
    // imageBaseUrl: "https://194.233.69.251:3000/", //forimage

    // vps production
    // baseUrl: "https://prod.macs-core.com:3000/api", //deployed
    // socketBaseUrl: "https://prod.macs-core.com:3000/", //deployed
    // imageBaseUrl: "https://prod.macs-core.com:3000/", //forimage
    
}
export default Config