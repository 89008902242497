import moment from "moment";
import DCClogo from "../../assets/img/DCC-Logo.png";

export const authorizedStaffBankStatementLetter = (
  bank_name: any,
  bank_branch: any,
  bank_city: any,
  accounts: any,
  staffSelect: any,
  fromDate,
  toDate
) => {
  let date = new Date();

  const handleImageError = (e) => {
    e.target.style.display = 'none';
  };

  let dateFormat = moment(date).format("LL");
  const getData = () => {
    let rows = "";
    for (let i = 0; i < staffSelect.length; i++) {
      let parseDataSignature;
      try {
        parseDataSignature = JSON.parse(staffSelect[i]?.signature_specimen || '');
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON data:", error);
      }
      if (i == staffSelect.length - 1) {
        rows += ` 
                <tr style="border-top: 1px solid #333; border-bottom: 1px solid #333;" >
                    <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">${i + 1
          }</td>
                    <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;; width:31%">${staffSelect[i].name}</td>
                    <td style="  padding-left:5px; padding-right:5px;  border-right: 1px solid #333; width:31%">${staffSelect[i].cnic}</td>
                    <td style="  border-right: 1px solid #333; width:31%">${parseDataSignature?.content === '' || parseDataSignature?.content === undefined ? '' : `<img src=${parseDataSignature?.content} onError=${handleImageError} alt="" width="200px" height="25vh" />`}</td>
                      
                </tr>
            `;

      } else {
        rows += ` 
                <tr style="border-top: 1px solid #333;" >
                    <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">${i + 1
          }</td>
                    <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;; width:31%">${staffSelect[i].name}</td>
                    <td style="  padding-left:5px; padding-right:5px;  border-right: 1px solid #333; width:31%">${staffSelect[i].cnic}</td>
                    <td style="  border-right: 1px solid #333; width:31%">${parseDataSignature?.content === '' || parseDataSignature?.content === undefined ? '' : `<img src=${parseDataSignature?.content} onError=${handleImageError} alt="" width="200px" height="25vh" />`}</td>
                      
                </tr>
            `;

      }

    }
    return rows;
  };

  const getAllAccountsData = () => {
    let rows = "";
    for (let i = 0; i < accounts.length; i++) {
      if (i == accounts.length - 1) {
        rows += ` 
            <tr style="border-top: 1px solid #333; border-bottom: 1px solid #333;" >
                <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">
                  ${i + 1}
                </td>
                <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;">
                  ${accounts[i].label.split('–')[0]}
                </td>
                <td style="  padding-left:5px; padding-right:5px;  border-right: 1px solid #333; width:30%">
                  ${accounts[i].label.split('–')[1]}
                </td>
            </tr>
            `;
      } else {
        rows += ` 
                <tr style="border-top: 1px solid #333;" >
                  <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">
                  ${i + 1}
                    </td>
                    <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;">
                      ${accounts[i].label.split('–')[0]}
                    </td>
                    <td style="  padding-left:5px; padding-right:5px;  border-right: 1px solid #333; width:30%">
                    ${accounts[i].label.split('–')[1]}
                    </td>
                      
                </tr>
            `;

      }
    }
    return rows;
  }

  return `<html> 
  <head>
      <title>MACS Trustee</title>
  </head>
  <body style="font-family:Arial">
      <header>
         <div style="float: none; margin: 0 auto; width: 100%; text-align:center; ">
         <img src="${DCClogo}" alt="Trustee" height="90" />
         </div
      </header>
      <hr />
      <section>
          <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
          <br />
          <table style="width: 100%;"><tr><td>Ref: ${date.getTime()}</td> </tr></table>
          <br />
          <div>The Manager, <br /> ${bank_name}, <br />  ${bank_branch}, ${bank_city}.</div>
          <br />
          <div><b style="border-bottom:1px solid black">Authority Letter</b></div>
          <br />
          <div>Dear Sir/Madam,</div>
          <br />
          <div>
          You are requested to deliver Bank Statement From ${moment(fromDate).format("DD/MM/YYYY")} To ${moment(toDate).format("DD/MM/YYYY")} of following bank account(s) to anyone
          of the below mentioned authorized persons:
          </div>
          <br />
          <table style="width: 100%; border-collapse:collapse; ">
          
          <thead>
          <tr style="border-right: 1px solid #333; border-left: 1px solid #333; border-top: 1px solid #333;">
              <th style="border-right: 1px solid #333; text-align:center;  width:6%"> S.No. </th>
              <th style="border-right: 1px solid #333; padding-left:5px"> Account Title </th>
              <th style="padding-left:5px; width:30%"> Account No  </th>
          </tr>
          </thead>
            <tbody>
            ${getAllAccountsData()}
            </tbody>
          </table>
          <br />
          <table style="width: 100%; border-collapse:collapse; ">
            <thead>
              <tr style="border-right: 1px solid #333; border-left: 1px solid #333; border-top: 1px solid #333;">
                <th style="border-right: 1px solid #333; text-align:center;  width:100%"> Authorized Persons With CNIC and Signatures </th>
              </tr>
            </thead>
          </table>
          <table style="width: 100%; border-collapse:collapse; ">
            <thead>
              <tr style="border-right: 1px solid #333; border-left: 1px solid #333; border-top: 1px solid #333;">
                  <th style="border-right: 1px solid #333; text-align:center;  width:6%"> S.No. </th>
                  <th style="border-right: 1px solid #333; padding-left:5px"> Name </th>
                  <th style="border-right: 1px solid #333; padding-left:5px"> CNIC  </th>
                  <th style="padding-left:5px; width:30%"> Signature  </th>
              </tr>
            </thead>
          <tbody>
          ${getData()}
          </tbody>
          </table>

          <div>Regards,</div>
         
          <br />
          <br />
          
          <table  style="width: 100%; ">
          <tr>
              
              <td >
                  <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
              </td>
              <td style="width: 40%;"> </td>
              <td>
                  <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
              </td>
             
          </tr>
      </table>
  </body>
  </html>
  `;
};
