import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import ToggleButton from 'react-toggle-button';
import { useHistory } from 'react-router-dom';
import { getAllUsers, updateUserStatus, enableDisableTwoFaAdmin, RefreshTransactionLimit, searchTransactionLimitData } from '../../stores/services/user.service';
import { getAmc } from '../../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { getAssignTransaction } from '../../stores/services/role.service';

const UsersList = () => {
  const history = useHistory();
  let [users, setUsers] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allTxnType, setAllTxnType] = useState([]);

  React.useEffect(() => {
    const getUserList = async () => {
      setLoading(true);
      try {
        const response = await getAllUsers(email);
        setUsers(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
    getUserList();

    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        setAllAmc(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }
    }
    getAllAMC();

    const assignTxnToRole = async () => {
      try {
        const response = await getAssignTransaction(email);
        setAllTxnType(response.data.txn_assign_category);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    assignTxnToRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const getUserListUpdated = async () => {
    setSearchValue('')
    setLoading(true);
    try {
      const response = await getAllUsers(email);
      setUsers(response.data.data);
    } catch (error: any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (users) {
      let i;
      res.length = 0;
      setRes(res)
      for (i = 0; i < users.length; i++) {
        res.push(users[i]);
        setRes(res)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const searchUsersByAmc = (amc_code: any) => {
    if (users) {
      let i;
      res.length = 0;
      setRes(res)
      for (i = 0; i < users.length; i++) {
        res.push(users[i]);
        setRes(res)
      }
    }
    let user = res.filter((item: any, index: any) => {
      return item.amc_code === amc_code;
    });
    setRes(user);
  }

  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < users.length; i++) {
      td = users[i].name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(users[i]);
          setRes(res);
        } else {

        }
      }
    }
  }
  const email = sessionStorage.getItem('email') || '';
  const updateStatus = async () => {
    setbankLoading(true);
    let status = '';
    if (currentBankStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateUserStatus(email, userEmail, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      setTimeout(() => {
        getUserListUpdated();
      }, 3000)

    } catch (error) { }
    setbankLoading(false);
  }
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentBankStatus, setCurrentBankStatus] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const onClickToggle = (email: string, isTwoFaEnabled: string) => {
    const index = res.findIndex((i: any) => i.email === email);
    const list = [...res];
    if (index >= 0) {
      list[index].two_fa_enabled = isTwoFaEnabled === 'true' ? 'false' : 'true';
      setRes(list);
    }
    enableDisableTwoFAFn(email, isTwoFaEnabled === 'true');
  };

  const [bankLoading, setbankLoading] = useState(false);
  const borderRadiusStyle = { borderRadius: 2 };

  const enableDisableTwoFAFn = (emailAddress: string, isEnabled: boolean) => {
    enableDisableTwoFaAdmin(email, emailAddress, !isEnabled)
      .then((response: any) => {
        toast.success(response.data.message);
      })
      .catch((err: any) => {
        toast.error(err.message);
      })
  }

  const [refreshTransactionLimit, setRefreshTransactionLimit] = useState(false);
  const [txnEmail, setTxnEmail] = useState('');
  const [txnEmailError, setTxnEmailError] = useState('');
  const [refreshLoading, setRefreshLoading] = useState(false);
  let [balance, setBalance] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);

  //   const handleSearchValue = (e: any) => {
  //     setSearchEmail(e.target.value);
  // }
  const addTxnLimit = async (userEmail) => {
    setDataLoading(true);
    setRefreshTransactionLimit(true);
    try {
      const response = await searchTransactionLimitData(email, userEmail);
      if (response.data.status === 200) {
        if (response.data.balance > 18000000) {
          toast.success('Sufficient balance to perform transaction')
        } else {
          toast.error('Insufficient balance to perform transaction')
        }
      }
      setBalance(response.data.balance);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setDataLoading(false);
  }


  const refreshValidate = () => {
    let txnEmalErr = ''
    txnEmail.trim() === '' ? (txnEmalErr = 'Required') : (txnEmalErr = '');
    if (txnEmalErr) {
      setTxnEmailError(txnEmalErr);
      return false;
    } else {
      return true;
    }
  }

  const refreshTxnLimitData = async () => {
    const isValid = refreshValidate();
    if (isValid) {
      setRefreshLoading(true)
      try {
        const response = await RefreshTransactionLimit(email, txnEmail);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setRefreshTransactionLimit(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        toast.error(error.response.data.message)
      }
      setRefreshLoading(false);
    }
  }

  const renderModalRefreshTransactionLimit = () => {
    switch (refreshTransactionLimit) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="lg"
            backdrop={true}
            onHide={() => {

            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setRefreshTransactionLimit(false);
                  setTxnEmailError('');
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Refresh Limit</h6>

              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">

                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />

                <div className="form-group">
                  <div>Remaining Limit</div>
                  {
                    dataLoading === false ? (
                      <input
                        type="text"
                        value={balance}
                        className="form-control"
                        readOnly
                      />
                    ) : (
                      <div className="form-control">
                      <i className="fa fa-spinner fa-spin fa-1x" ></i>
                      </div>
                    )
                  }
                </div>


                <div className="form-group">
                  <div>Enter Email</div>
                  <input
                    type="text"
                    // value={transfereesFolioNo}
                    data-tip={'Enter email.'}
                    placeholder='Enter email.'
                    value={txnEmail}
                    className={`form-control  w-100 ${txnEmailError ? 'required-border' : ''
                      }`}
                    onChange={(e) => {
                      setTxnEmail(e.target.value);
                      setTxnEmailError('');
                    }}
                  />
                </div>
                <div className=" ">

                  <div className=" mt-3">
                    <button type="button" disabled={Boolean(refreshLoading)} className="btn btn-primary btn-block"
                      onClick={() => {
                        refreshTxnLimitData();

                      }}>
                      {refreshLoading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Searching...</span>
                        </>
                      ) : (
                        'Refresh Limit'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const renderUpdateStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
            enforceFocus={false}
          >

            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => { setStatusSetPopup(false) }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Action</h6>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentBankStatus === 'active' ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{' '}
                    {currentBankStatus === 'active'
                      ? 'Deactivate'
                      : 'Activate'}{' '}
                    this User?
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col >
                    <div className="">
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(bankLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {bankLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          'Yes'
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button className="btn btn-default" onClick={() => { setStatusSetPopup(false); }}>
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal >
        )
      default:
        return '';
    }
  };
  const renderData = () => {
    return res.map((user: any, index: any) => {
      return (
        <tr key={index}>
          <td>{user.name}</td>
          <td>{user.role.replace('ROLE_', '')}</td>
          <td>{user.email}</td>
          <td>{moment(user.created_at).format('DD-MM-YYYY')}</td>
          <td className="captilize">{user.status}</td>
          <td className=" ">
            <div className="multi-icons pointer">
              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={() => {
                  sessionStorage.setItem('userObj', JSON.stringify(user));
                  sessionStorage.setItem('allTxnTypes', JSON.stringify(allTxnType));
                  sessionStorage.setItem('userPageType', 'view');
                  history.replace('/admin/edit-user')
                }}
              >
                <i className="fas fa-eye"></i>
              </button>

              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem('userObj', JSON.stringify(user));
                  sessionStorage.setItem('allTxnTypes', JSON.stringify(allTxnType));
                  sessionStorage.setItem('userPageType', 'edit');
                  history.replace('/admin/edit-user')
                }
                }>
                <i className="tim-icons icon-pencil"></i></button>

              {user.status !== 'active' ?
                <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setCurrentBankStatus(user.status);
                    setUserEmail(user.email)
                  }}>
                  <i className="tim-icons icon-simple-remove"></i>
                </button>
                :

                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setCurrentBankStatus(user.status);
                    setUserEmail(user.email)
                  }}>
                  <i className="fas fa-check"></i>
                </button>

              }
              {/* <button data-tip={'Refresh Limit.'} className="btn-icon btn-link like btn btn-primary btn-sm" onClick={async () => {
                await setTxnEmail(user.email);
                addTxnLimit(user.email);
              }}
              ><i className="fa fa-plus"></i></button> */}
            </div>
          </td>
          <td>
            <ToggleButton
              value={user.two_fa_enabled === 'true' ? true : false}
              thumbStyle={borderRadiusStyle}
              trackStyle={borderRadiusStyle}
              onToggle={() => {
                onClickToggle(user.email, user.two_fa_enabled);
              }}
            />
          </td>
        </tr>
      );
    })
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">User Management
                    <button className="btn btn-primary btn-sm float-right" onClick={() => {
                      history.replace('/admin/add-user');
                    }}>
                      <i className="fa fa-plus mr-2"></i>  Add New
                    </button></h4>
                </div>
                {!Loading ? (
                  <div className="card-body">
                    <div className="input-holder">
                      <Row>
                        <Col md="4">
                          <div className="form-group">
                            <label>Search</label>
                            <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                            <input type="search" id="myInput" className="form-control" data-tip="Search User" placeholder="Search Name" value={searchValue} onChange={(e) => {
                              setSearchValue(e.target.value);
                              myFunction(e);
                            }} />
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Select AMC</label>
                            <select
                              className="form-control"
                              onChange={(e) => {

                                searchUsersByAmc(e.target.value);
                              }}
                            // value={searchUsersByAmc}
                            >
                              <option value="" defaultChecked hidden>
                                Select AMC
                              </option>
                              {renderAmcDropdown()}
                            </select>
                          </div>
                        </Col>

                        <Col md="4">
                          <button className="btn btn-default mt-4"
                            onClick={() => {
                              getUserListUpdated();
                            }}
                          >
                            Clear
                          </button>
                        </Col>
                      </Row>


                    </div>
                    <div className="table-responsive">
                      <table className="table mt-3 ">
                        <thead>
                          <th>Name</th>
                          <th>Roles</th>
                          <th>Email</th>
                          <th>Created At</th>
                          <th>Status</th>
                          <th>Actions</th>
                          <th>2FA Authentication</th>
                        </thead>
                        <tbody>{renderData()}</tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {renderUpdateStatusPopup()}
                {renderModalRefreshTransactionLimit()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default UsersList;


// import React, { useState } from 'react';
// import { Modal } from 'react-bootstrap';
// import ReactTooltip from 'react-tooltip';
// import {
//   Container,
//   Row,
//   Col,
// } from 'reactstrap';
// import ToggleButton from 'react-toggle-button';
// import { useHistory } from 'react-router-dom';
// import { getAllUsers, updateUserStatus, enableDisableTwoFaAdmin, RefreshTransactionLimit, searchTransactionLimitData } from '../../stores/services/user.service';
// import { getAmc } from '../../stores/services/amc.service';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment';
// import { getAssignTransaction } from '../../stores/services/role.service';

// const UsersList = () => {
//   const history = useHistory();
//   let [users, setUsers] = useState<any>([]);
//   const [Loading, setLoading] = useState(false);
//   const [allAmc, setAllAmc] = useState<any>([]);
//   const [allTxnType, setAllTxnType] = useState([]);

//   React.useEffect(() => {
//     const getUserList = async () => {
//       setLoading(true);
//       try {
//         const response = await getAllUsers(email);
//         setUsers(response.data.data);
//       } catch (err: any) {
//         if (err.response !== undefined) {
//           toast.error(err.response.data.message);
//         } else {
//           toast.error(err.message)
//         }
//       }
//       setLoading(false);
//     }
//     getUserList();

//     const getAllAMC = async () => {
//       try {
//         const amcResponse = await getAmc(email, '', '', '');
//         setAllAmc(amcResponse.data.data);
//       } catch (err: any) {
//         if (err.response !== undefined) {
//           toast.error(err.response.data.message);
//         }
//       }
//     }
//     getAllAMC();

//     const assignTxnToRole = async () => {
//       try {
//         const response = await getAssignTransaction(email);
//         setAllTxnType(response.data.txn_assign_category);
//       } catch (err: any) {
//         if (err.response !== undefined) {
//           toast.error(err.response.data.message);
//         } else {
//           toast.error(err.message)
//         }
//       }
//     }
//     assignTxnToRole();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);



//   const renderAmcDropdown = () => {
//     return allAmc.map((item: any, index: number) => {
//       return (
//         <option key={index} value={item.amc_code.replace('AMC_', '')}>
//           {item.name}
//         </option>
//       );
//     });
//   };

//   const getUserListUpdated = async () => {
//     setSearchValue('')
//     setLoading(true);
//     try {
//       const response = await getAllUsers(email);
//       setUsers(response.data.data);
//     } catch (error: any) {
//       toast.error(error.response.data.message[0]);
//     }
//     setLoading(false);
//   };
//   React.useEffect(() => {
//     if (users) {
//       let i;
//       res.length = 0;
//       setRes(res)
//       for (i = 0; i < users.length; i++) {
//         res.push(users[i]);
//         setRes(res)
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [users]);

//   const searchUsersByAmc = (amc_code: any) => {
//     if (users) {
//       let i;
//       res.length = 0;
//       setRes(res)
//       for (i = 0; i < users.length; i++) {
//         res.push(users[i]);
//         setRes(res)
//       }
//     }
//     let user = res.filter((item: any, index: any) => {
//       return item.amc_code === amc_code;
//     });
//     setRes(user);
//   }

//   const [res, setRes] = useState<any>([]);
//   const [searchValue, setSearchValue] = useState('');
//   function myFunction(e: any) {
//     res.length = 0;
//     setRes(res);
//     var filter, td, i;
//     filter = e.target.value;
//     for (i = 0; i < users.length; i++) {
//       td = users[i].name;
//       if (td) {
//         if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
//           res.push(users[i]);
//           setRes(res);
//         } else {

//         }
//       }
//     }
//   }
//   const email = sessionStorage.getItem('email') || '';
//   const updateStatus = async () => {
//     setbankLoading(true);
//     let status = '';
//     if (currentBankStatus === 'active') {
//       status = 'deactive';
//     } else {
//       status = 'active';
//     }
//     try {
//       const response = await updateUserStatus(email, userEmail, status);
//       toast.success(response.data.message);
//       setStatusSetPopup(false);
//       setTimeout(() => {
//         getUserListUpdated();
//       }, 3000)

//     } catch (error) { }
//     setbankLoading(false);
//   }
//   const [statusSetPopup, setStatusSetPopup] = useState(false);
//   const [currentBankStatus, setCurrentBankStatus] = useState('');
//   const [userEmail, setUserEmail] = useState('');

//   const onClickToggle = (email: string, isTwoFaEnabled: string) => {
//     const index = res.findIndex((i: any) => i.email === email);
//     const list = [...res];
//     if (index >= 0) {
//       list[index].two_fa_enabled = isTwoFaEnabled === 'true' ? 'false' : 'true';
//       setRes(list);
//     }
//     enableDisableTwoFAFn(email, isTwoFaEnabled === 'true');
//   };

//   const [bankLoading, setbankLoading] = useState(false);
//   const borderRadiusStyle = { borderRadius: 2 };

//   const enableDisableTwoFAFn = (emailAddress: string, isEnabled: boolean) => {
//     enableDisableTwoFaAdmin(email, emailAddress, !isEnabled)
//       .then((response: any) => {
//         toast.success(response.data.message);
//       })
//       .catch((err: any) => {
//         toast.error(err.message);
//       })
//   }

//   // const [refreshTransactionLimit, setRefreshTransactionLimit] = useState(false);
//   // const [txnEmail, setTxnEmail] = useState('');
//   // const [txnEmailError, setTxnEmailError] = useState('');
//   // const [refreshLoading, setRefreshLoading] = useState(false);
//   // let [balance, setBalance] = useState<any>([]);
//   // const [dataLoading, setDataLoading] = useState(false);

//   // //   const handleSearchValue = (e: any) => {
//   // //     setSearchEmail(e.target.value);
//   // // }
//   // const addTxnLimit = async (userEmail) => {
//   //   setDataLoading(true);
//   //   setRefreshTransactionLimit(true);
//   //   try {
//   //     const response = await searchTransactionLimitData(email, userEmail);
//   //     if (response.data.status === 200) {
//   //       if (response.data.balance > 18000000) {
//   //         toast.success('Sufficient balance to perform transaction')
//   //       } else {
//   //         toast.error('Insufficient balance to perform transaction')
//   //       }
//   //     }
//   //     setBalance(response.data.balance);
//   //   } catch (err: any) {
//   //     if (err.response !== undefined) {
//   //       toast.error(err.response.data.message);
//   //     } else {
//   //       toast.error(err.message)
//   //     }
//   //   }
//   //   setDataLoading(false);
//   // }


//   // const refreshValidate = () => {
//   //   let txnEmalErr = ''
//   //   txnEmail.trim() === '' ? (txnEmalErr = 'Required') : (txnEmalErr = '');
//   //   if (txnEmalErr) {
//   //     setTxnEmailError(txnEmalErr);
//   //     return false;
//   //   } else {
//   //     return true;
//   //   }
//   // }

//   // const refreshTxnLimitData = async () => {
//   //   const isValid = refreshValidate();
//   //   if (isValid) {
//   //     setRefreshLoading(true)
//   //     try {
//   //       const response = await RefreshTransactionLimit(email, txnEmail);
//   //       if (response.data.status === 200) {
//   //         toast.success(response.data.message);
//   //         setRefreshTransactionLimit(false);
//   //       } else {
//   //         toast.error(response.data.message);
//   //       }
//   //     } catch (error: any) {
//   //       toast.error(error.response.data.message)
//   //     }
//   //     setRefreshLoading(false);
//   //   }
//   // }

//   // const renderModalRefreshTransactionLimit = () => {
//   //   switch (refreshTransactionLimit) {
//   //     case true:
//   //       return (
//   //         <Modal
//   //           className=" "
//   //           dialogClassName="modal90w"
//   //           show={true}
//   //           enforceFocus={false}
//   //           size="lg"
//   //           backdrop={true}
//   //           onHide={() => {

//   //           }}
//   //         >
//   //           <div className="modal-header">
//   //             <button
//   //               aria-hidden={true}
//   //               className="close"
//   //               data-dismiss="modal"
//   //               type="button"
//   //               onClick={() => {
//   //                 setRefreshTransactionLimit(false);
//   //                 setTxnEmailError('');
//   //               }}
//   //             >
//   //               <i className="tim-icons icon-simple-remove" />
//   //             </button>
//   //             <div>
//   //               <h6 className="title title-up">Refresh Limit</h6>

//   //             </div>
//   //           </div>

//   //           <div className="modal-body">
//   //             <div className="  ml-3 mt-3 mb-3 mr-4">

//   //               <ReactTooltip
//   //                 textColor="white"
//   //                 backgroundColor="black"
//   //                 effect="float"
//   //               />

//   //               <div className="form-group">
//   //                 <div>Remaining Limit</div>
//   //                 {
//   //                   dataLoading === false ? (
//   //                     <input
//   //                       type="text"
//   //                       value={balance}
//   //                       className="form-control"
//   //                       readOnly
//   //                     />
//   //                   ) : (
//   //                     <div className="form-control">
//   //                     <i className="fa fa-spinner fa-spin fa-1x" ></i>
//   //                     </div>
//   //                   )
//   //                 }
//   //               </div>


//   //               <div className="form-group">
//   //                 <div>Enter Email</div>
//   //                 <input
//   //                   type="text"
//   //                   // value={transfereesFolioNo}
//   //                   data-tip={'Enter email.'}
//   //                   placeholder='Enter email.'
//   //                   value={txnEmail}
//   //                   className={`form-control  w-100 ${txnEmailError ? 'required-border' : ''
//   //                     }`}
//   //                   onChange={(e) => {
//   //                     setTxnEmail(e.target.value);
//   //                     setTxnEmailError('');
//   //                   }}
//   //                 />
//   //               </div>
//   //               <div className=" ">

//   //                 <div className=" mt-3">
//   //                   <button type="button" disabled={Boolean(refreshLoading)} className="btn btn-primary btn-block"
//   //                     onClick={() => {
//   //                       refreshTxnLimitData();

//   //                     }}>
//   //                     {refreshLoading ? (
//   //                       <>
//   //                         <span
//   //                           className="spinner-border login-txt spinner-border-sm"
//   //                           role="status"
//   //                           aria-hidden="true"
//   //                         ></span>
//   //                         <span className="login-txt"> Searching...</span>
//   //                       </>
//   //                     ) : (
//   //                       'Refresh Limit'
//   //                     )}
//   //                   </button>
//   //                 </div>
//   //               </div>
//   //             </div>
//   //           </div>
//   //         </Modal>
//   //       );
//   //     default:
//   //       return '';
//   //   }
//   // };


//   const renderUpdateStatusPopup = () => {
//     switch (statusSetPopup) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal60w"
//             show={true}
//             enforceFocus={false}
//           >

//             <div className="modal-header">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => { setStatusSetPopup(false) }}
//               >
//                 <i className="tim-icons icon-simple-remove" />
//               </button>
//               <h6 className="title title-up">Action</h6>
//             </div>

//             <div className="modal-body">
//               <div className="text-center">
//                 <Row>
//                   <div className="mx-auto">
//                     {currentBankStatus === 'active' ? (
//                       <img src="assets/ban.svg" alt="" width="70" />
//                     ) : (
//                       <img src="assets/check.svg" alt="" width="70" />
//                     )}
//                     <h1 className="pb-3">Are You Sure?</h1>
//                     Are you sure you want to{' '}
//                     {currentBankStatus === 'active'
//                       ? 'Deactivate'
//                       : 'Activate'}{' '}
//                     this User?
//                   </div>
//                 </Row>
//                 <Row className="mt-4">
//                   <Col >
//                     <div className="">
//                       <button
//                         className="btn btn-primary"
//                         disabled={Boolean(bankLoading)}
//                         onClick={() => {
//                           updateStatus();
//                         }}
//                       >
//                         {bankLoading ? (
//                           <>
//                             <span
//                               className="spinner-border login-txt spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>
//                             <span className="login-txt"> Loading...</span>
//                           </>
//                         ) : (
//                           'Yes'
//                         )}
//                       </button>
//                     </div>
//                   </Col>
//                   <Col>
//                     <div className="">
//                       <button className="btn btn-default" onClick={() => { setStatusSetPopup(false); }}>
//                         Cancel
//                       </button>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//           </Modal >
//         )
//       default:
//         return '';
//     }
//   };
//   const renderData = () => {
//     return res.map((user: any, index: any) => {
//       return (
//         <tr key={index}>
//           <td>{user.name}</td>
//           <td>{user.role.replace('ROLE_', '')}</td>
//           <td>{user.email}</td>
//           <td>{moment(user.created_at).format('DD-MM-YYYY')}</td>
//           <td className="captilize">{user.status}</td>
//           <td className=" ">
//             <div className="multi-icons pointer">
//               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
//                 onClick={() => {
//                   sessionStorage.setItem('userObj', JSON.stringify(user));
//                   sessionStorage.setItem('userPageType', 'view');
//                   history.replace('/admin/edit-user')
//                 }}
//               >
//                 <i className="fas fa-eye"></i>
//               </button>

//               <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
//                 onClick={() => {
//                   sessionStorage.setItem('userObj', JSON.stringify(user));
//                   sessionStorage.setItem('allTxnTypes', JSON.stringify(allTxnType));
//                   sessionStorage.setItem('userPageType', 'edit');
//                   history.replace('/admin/edit-user')
//                 }
//                 }>
//                 <i className="tim-icons icon-pencil"></i></button>

//               {user.status !== 'active' ?
//                 <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
//                   onClick={() => {
//                     setStatusSetPopup(true);
//                     setCurrentBankStatus(user.status);
//                     setUserEmail(user.email)
//                   }}>
//                   <i className="tim-icons icon-simple-remove"></i>
//                 </button>
//                 :

//                 <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
//                   onClick={() => {
//                     setStatusSetPopup(true);
//                     setCurrentBankStatus(user.status);
//                     setUserEmail(user.email)
//                   }}>
//                   <i className="fas fa-check"></i>
//                 </button>

//               }
//               {/* <button data-tip={'Refresh Limit.'} className="btn-icon btn-link like btn btn-primary btn-sm" onClick={async () => {
//                 await setTxnEmail(user.email);
//                 addTxnLimit(user.email);
//               }}
//               ><i className="fa fa-plus"></i></button> */}
//             </div>
//           </td>
//           <td>
//             <ToggleButton
//               value={user.two_fa_enabled === 'true' ? true : false}
//               thumbStyle={borderRadiusStyle}
//               trackStyle={borderRadiusStyle}
//               onToggle={() => {
//                 onClickToggle(user.email, user.two_fa_enabled);
//               }}
//             />
//           </td>
//         </tr>
//       );
//     })
//   }
//   return (
//     <>
//       <div className="content">
//         <Row>
//           <Col md="12">
//             <Container fluid>
//               <ToastContainer limit={1} />
//               <div className="card">
//                 <div className="card-header">
//                   <h4 className="card-title">User Management
//                     <button className="btn btn-primary btn-sm float-right" onClick={() => {
//                       history.replace('/admin/add-user');
//                     }}>
//                       <i className="fa fa-plus mr-2"></i>  Add New
//                     </button></h4>
//                 </div>
//                 {!Loading ? (
//                   <div className="card-body">
//                     <div className="input-holder">
//                       <Row>
//                         <Col md="4">
//                           <div className="form-group">
//                             <label>Search</label>
//                             <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
//                             <input type="search" id="myInput" className="form-control" data-tip="Search User" placeholder="Search Name" value={searchValue} onChange={(e) => {
//                               setSearchValue(e.target.value);
//                               myFunction(e);
//                             }} />
//                           </div>
//                         </Col>

//                         <Col md="4">
//                           <div className="form-group  ">
//                             <label>Select AMC</label>
//                             <select
//                               className="form-control"
//                               onChange={(e) => {

//                                 searchUsersByAmc(e.target.value);
//                               }}
//                             // value={searchUsersByAmc}
//                             >
//                               <option value="" defaultChecked hidden>
//                                 Select AMC
//                               </option>
//                               {renderAmcDropdown()}
//                             </select>
//                           </div>
//                         </Col>

//                         <Col md="4">
//                           <button className="btn btn-default mt-4"
//                             onClick={() => {
//                               getUserListUpdated();
//                             }}
//                           >
//                             Clear
//                           </button>
//                         </Col>
//                       </Row>


//                     </div>
//                     <div className="table-responsive">
//                       <table className="table mt-3 ">
//                         <thead>
//                           <th>Name</th>
//                           <th>Roles</th>
//                           <th>Email</th>
//                           <th>Created At</th>
//                           <th>Status</th>
//                           <th>Actions</th>
//                           <th>2FA Authentication</th>
//                         </thead>
//                         <tbody>{renderData()}</tbody>
//                       </table>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="d-flex justify-content-center">
//                     <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
//                       <span className="sr-only">Loading...</span>
//                     </div>
//                   </div>
//                 )}
//                 {renderUpdateStatusPopup()}
//                 {/* {renderModalRefreshTransactionLimit()} */}
//               </div>
//             </Container>
//           </Col>
//         </Row>
//       </div>
//     </>
//   )
// };

// export default UsersList;