import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
/**
 *
 * @param email
 */
const getNotifications = async (email: string) => {
  const url = `${Config.baseUrl}/notification?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getNotifications(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param data
 */
const AllReadCall = async (email: string, data: any) => {
  const url = `${Config.baseUrl}/notification/bulk-update`;
  try{
  const result: any = await axios.post(
    url,
    {
      email,
      data,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await AllReadCall(email,data)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

/**
 *
 * @param email
 * @param notification_id
 */
const updateNotification = async (email: string, notification_id: string) => {
  const url = `${Config.baseUrl}/notification/update?email=${email}&notification_id=${notification_id}`;
  try{
  const result: any = await axios.post(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await updateNotification(email,notification_id)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};
export { getNotifications,AllReadCall, updateNotification };
