import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { getAllBrokers, searchBrokerAPI } from '../../../stores/services/broker.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';

const BrokerList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isSearchActive, setSearchActive] = useState(false);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  React.useEffect(() => {
    getBrokerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const getBrokerList = async () => {
    setLoading(true);
    try {
      const response = await getAllBrokers(email, activePage, pageSize);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    } catch (err:any) {
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      const newText = data[i].account_no;
      td = newText.split(/\s/).join('');
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const [brokerError, setBrokerError] = useState('');
  const validate = () => {
    let brokerError = '';
    searchValue.trim() === '' ? (brokerError = 'Required') : (brokerError = '');

    if (brokerError) {
      setBrokerError(brokerError);
      return false;
    } else {
      return true;
    }
  };

  const searchBrokerData = async () => {
    const isValid = validate();
    if (isValid) {
    setLoading(true);
    try {
      const response = await searchBrokerAPI(email, searchValue);
      setData(response.data.data);
      setSearchActive(true);
      setPageSize('10');
    } catch (err:any) {
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  }
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value.toUpperCase());
    if (e.target.value.length === 0) {
      getBrokerList();
      setSearchActive(false);
      setPageSize('10');
    } else {
      setSearchValue(e.target.value.toUpperCase());
    }
  };
  
  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.company_name}</td>
          <td>{items.company_type}</td>
          <td>{items.company_code}</td>
          <td>{items.account_title}</td>
          <td>{items.iban}</td>
          <td>{items.bank_name}</td>
          <td>{items.branch}</td>
          <td className="pointer">  
             <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                sessionStorage.setItem('brokerObj', JSON.stringify(items));
                history.replace(`/admin/edit-broker`);
              }}>
                    <i className="tim-icons icon-pencil"></i>
                </button>
                
          </td>
        </tr>
      );
    });
  };
  return (
    <> 
<div className="content">
        <Row>
          <Col md="12">

      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card mt-4">
          <div className="d-flex align-items-center"></div>
          {!Loading ? (
            <div className="">
              <div className="card-header">
                <h4 className="card-title">Associated Beneficiary Listing
                <div
                    className="btn btn-primary btn-sm float-right"
                    onClick={() => {
                      history.replace('/admin/setup-brokers');
                    }}
                  >
                    <span><i className="fa fa-plus mr-2"></i>Add New</span>
                </div></h4>
              </div>

              <div className="card-body">
                <Row>
                  <Col md="4">
                  <div className="form-group">
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    <input
                      type="search"
                      id="myInput"
                      className={`form-control  w-100 ${brokerError ? 'required-border' : ''
                    }`}
                      data-tip="Enter Company Name / Code"
                      placeholder="Enter Company Name / Code"
                      value={searchValue}
                      onChange={(e) => {
                        // setSearchValue(e.target.value);	
                        // myFunction(e);	
                        handleSearchValue(e);
                        setBrokerError('')
                      }}
                      onKeyPress={(e) => {
                        let key_board_keycode = e.which || e.keyCode;
                        if (key_board_keycode == 13) {
                          e.preventDefault();
                          searchBrokerData()
                        }
                      }}
                    />
                    </div>
                    </Col>

                    <Col md="4">
                    <button className="btn btn-primary mt-a" onClick={searchBrokerData}>
                      <i className="fa fa-search"></i>	Search
                  </button>
                  </Col>
                </Row>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Company Type</th>
                        <th>Code</th>
                        <th>Account Title</th>
                        <th>Account No</th>
                        <th>Bank Name</th>
                        <th>Branch Name</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderData()}</tbody>
                  </table>

                  {!isSearchActive && (
                    <div
                      className="row p-3 pointer"
                      style={{
                        float: 'right',
                      }}
                    >
                      {/* PREVIOUS BUTTON */}
                      {activePage !== paginationArr[0] ? (
                        <i
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex((x) => x === activePage);
                            if (index !== -1) {
                              setActivePage(paginationArr[index - 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                      {/* NEXT BUTTON */}
                      {activePage !== paginationArr[paginationArr.length - 1] ? (
                        <i
                          className="fa ml-3 fa-arrow-right"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex((x) => x === activePage);
                            if (index !== -1) {
                              setActivePage(paginationArr[index + 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                    </div>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center my-5" >
            <i className="fa fa-spinner fa-spin fa-3x" ></i> 
            </div>
          )}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};
export default BrokerList;
