
import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import { numberWithCommas } from '../../utils/customFunction';
import Config from '../../config';

export const transferInFundRTGSEmailTemplate = (transaction: any, transType:any, amtWords: any, transNetAmount: any, transGrossAmount: any, grossAmtWords:any) => {

    
    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q="qrcodeinflow";
    let id=`${Config.q_r_url }/admin/verify-transaction/`+transaction.txn_id; 
    let txnId = transaction.txn_id;
    let branchName = '';
    let branchCity = '';

    if(transaction.branch != undefined){
        if(transaction.branch.includes('–')){
            branchName = transaction.branch.split('–')[0];
            branchCity = transaction.branch.split('–')[1];
            branchName = branchCity === undefined ? branchName.trim()+',' : branchName.trim()+',';
            branchCity = branchCity === undefined ? '' : branchCity.trim()+'.';
        }else{
            branchName = transaction.branch+',';
        }
    }

    

    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 50,
             height : 50
         });
           qrcode.makeCode(id);
     }
    
    } , 500);

    let amount = transType === 'saleofunit' ? transGrossAmount : transNetAmount; 
    let amountInWords = transType === 'saleofunit' ? grossAmtWords : amtWords;
 
    return `<html>
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <div> Sub: <b style="border-bottom:1px solid black"> Receive of funds through RTGS from "${transaction.counter_bank}". </b></div>
            <br />

            <div> Dear Sir/Madam, </div>
            <br />
            
            <div> You are requested to kindly credit the Account as per below details.</div>       
            
 
            <table style="width: 100%;" > 
                <tr>
                    <td valign="top" style="width:110px"> Account Title</td> 
                    <td><b>: ${transaction.account_title} </b></td> 
                </tr>
                <tr>
                    <td valign="top"> Credit A/C </td>
                    <td><b>: ${transaction.account_number} (${transaction.bank}, ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch})   </b></td>
                   
                </tr>

                <tr>
                    <td valign="top">RTGS Number</td> 
                    <td><b>:</b></td> 
                </tr>
                <tr>
                    <td valign="top"> Value Date</td> 
                    <td><b>: ${moment(transaction.execution_date).format('LL')} </b></td> 

                </tr>
                
                <tr>
                    <td valign="top"> Amount</td>
                    <td valign="top">
                        <b>: PKR: ${numberWithCommas(amount)} (Rupees ${amountInWords})</b>
                    </td> 
                </tr>
    
            </table>
            <br />
            <div>Please acknowledge receipt of the said letter.</div>
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
                <tr>
                    <td></td>
                    <td >
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td style="width: 40%;"> </td>
                    <td>
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td></td>
                </tr>
            </table>
    </body>
    </html>
    `
}
