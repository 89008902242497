import { Coordinates } from './leterFormter';

export const postionCoordinates: Coordinates[] = [
  { id: '1', x: 700, y: 50, classes: '', label: 'Date', value: '' },
  { id: '2', x: 700, y: 70, classes: '', label: 'Ref: ', value: 'Value Here' },
  {
    id: '3',
    x: 700,
    y: 90,
    classes: '',
    label: 'The Chief Manager,',
    value: '',
  },
  { id: '4', x: 700, y: 120, classes: '', label: 'Bank Name', value: '' },
  { id: '5', x: 700, y: 150, classes: '', label: 'Address', value: '' },
  { id: '6', x: 700, y: 170, classes: '', label: 'City', value: '' },
  { id: '7', x: 700, y: 185, classes: '', label: 'Dear Sir,', value: '' },
  {
    id: '8',
    x: 700,
    y: 210,
    classes: 'font-weight-bold text-decoration-underline',
    label: 'Heading Label One',
    value: '',
  },
  {
    id: '9',
    x: 700,
    y: 230,
    classes: '',
    label: 'Fund Bank Account Title',
    value: 'Value Here',
  },
  {
    id: '10',
    x: 700,
    y: 255,
    classes: '',
    label: 'Fund Bank Account No',
    value: 'Value Here',
  },
  {
    id: '12',
    x: 700,
    y: 265,
    classes: '',
    label: 'Broker',
    value: 'Value Here',
  },
  {
    id: '13',
    x: 700,
    y: 280,
    classes: '',
    label: 'Mode of Payment',
    value: 'Value Here',
  },
  {
    id: '14',
    x: 700,
    y: 295,
    classes: '',
    label: 'Settlement Amount',
    value: 'Value Here',
  },
  {
    id: '15',
    x: 700,
    y: 310,
    classes: 'font-weight-bold text-decoration-underline',
    label: 'Instrument Detail',
    value: 'Value Here',
  },
  {
    id: '16',
    x: 700,
    y: 330,
    classes: '',
    label: 'Instrument Type',
    value: 'Value Here',
  },
  {
    id: '17',
    x: 700,
    y: 345,
    classes: '',
    label: 'Issued Date',
    value: 'Value Here',
  },
  {
    id: '18',
    x: 700,
    y: 360,
    classes: '',
    label: 'Maturity Date',
    value: 'Value Here',
  },
  {
    id: '19',
    x: 700,
    y: 375,
    classes: '',
    label: 'Face Value',
    value: 'Value Here',
  },
  {
    id: '20',
    x: 700,
    y: 390,
    classes: '',
    label: 'Coupon / Yeild',
    value: 'Value Here',
  },
  {
    id: '21',
    x: 700,
    y: 410,
    classes: 'font-weight-bold text-decoration-underline',
    label: 'Counter Party Detial',
    value: 'Value Here',
  },
  {
    id: '22',
    x: 700,
    y: 430,
    classes: '',
    label: 'Counter Party Name',
    value: 'Value Here',
  },
  {
    id: '23',
    x: 700,
    y: 450,
    classes: '',
    label: 'Bank Account Detail',
    value: 'Value Here',
  },
  {
    id: '24',
    x: 700,
    y: 470,
    classes: '',
    label: 'SGL / IPS Account Detail',
    value: 'Value Here',
  },
  {
    id: '25',
    x: 700,
    y: 500,
    classes: 'border p-2',
    label: 'Text Box',
    value: '',
  },
  {
    id: '26',
    x: 700,
    y: 530,
    classes: '',
    label: 'Amount',
    value: 'Value Here',
  },
  { id: '27', x: 700, y: 550, classes: '', label: 'Your Truly', value: '' },
  {
    id: '28',
    x: 700,
    y: 590,
    classes: 'border-top',
    label: 'Authorized Signatory',
    value: '',
  },
  {
    id: '29',
    x: 700,
    y: 630,
    classes: '',
    label: 'AMC Name',
    value: 'Value Here',
  },
  {
    id: '30',
    x: 700,
    y: 650,
    classes: 'border-top',
    label: 'Authorized Signatory',
    value: '',
  },
  { id: '31', x: 700, y: 670, classes: '', label: 'NAV', value: 'Value Here' },
  {
    id: '32',
    x: 700,
    y: 680,
    classes: '',
    label: 'Gross Amount',
    value: 'Value Here',
  },
  {
    id: '33',
    x: 700,
    y: 690,
    classes: '',
    label: 'Net Amount',
    value: 'Value Here',
  },
  {
    id: '34',
    x: 700,
    y: 710,
    classes: '',
    label: 'Folio No',
    value: 'Value Here',
  },
  {
    id: '35',
    x: 700,
    y: 720,
    classes: '',
    label: 'Units',
    value: 'Value Here',
  },
  {
    id: '36',
    x: 700,
    y: 730,
    classes: '',
    label: 'Sale Date',
    value: 'Value Here',
  },
];
