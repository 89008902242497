declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  //DEV https
  REACT_APP_TITLE: "MACS Core Alpha DEV";
  REACT_APP_VERSION: "v3.0.";
  REACT_APP_BASE_URL: "https://dccl.pk:3000/api";
  REACT_APP_SOCKET_URL: "https://dccl.pk:3000/";
  REACT_APP_IMAGE_URL: "https://dccl.pk:3000/";
  CAPTCHA_ENABLED: "false"; // true or false
  REACT_LOGIN_REGISTER: "https://dccl.pk:3011";
  REACT_APP_QR_URL: "https://dccl.pk:3000/";
  REACT_APP_HBL_URL: `https://apis1.hbl.com:8343/sandbox/CorpPay/Transaction/Process`;
  REACT_APP_HBL_API_AUTHORIZATION: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJPOW52ZVlha0FvOVNGR1J5dllCNnVDTU1abnRFQkpoMSJ9.UmnMf_lgTzEOrqHeVbSrxMmbDhbkjQxVi-Mi1MOZZPQ`;
  REACT_APP_HBL_API_TOKEN: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJPOW52ZVlha0FvOVNGR1J5dllCNnVDTU1abnRFQkpoMSJ9.UmnMf_lgTzEOrqHeVbSrxMmbDhbkjQxVi-Mi1MOZZPQ`;

  //DEV https
  // REACT_APP_TITLE: "MACS Core Alpha DEV",
  // REACT_APP_VERSION: "v3.0.28",
  // REACT_APP_BASE_URL: "https://trustee-bak.dccl.com.pk:3000/api",
  // REACT_APP_SOCKET_URL: "https://trustee-bak.dccl.com.pk:3000/",
  // REACT_APP_IMAGE_URL: "https://trustee-bak.dccl.com.pk:3000/",
  // CAPTCHA_ENABLED: "false", // true or false
  // REACT_LOGIN_REGISTER: "https://trustee-bak.dccl.com.pk:3011",
  // REACT_APP_QR_URL: "https://trustee-bak.dccl.com.pk:3000/",

  // For NayaTel
  // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
  // REACT_APP_VERSION: "v2.2.7",
  // REACT_APP_BASE_URL: "http://124.109.39.158:3000/api",
  // REACT_APP_SOCKET_URL: "http://124.109.39.158:3000/",
  // REACT_APP_IMAGE_URL: "http://124.109.39.158:3000/",
  // REACT_APP_QR_URL: "http://124.109.39.158:3000/",
  // CAPTCHA_ENABLED: "true", // true or false

  // VPS-1 - domain name
  // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
  // REACT_APP_VERSION: "v2.2.3",
  // REACT_APP_BASE_URL: "http://digitalcustodian.co:3000/api",
  // REACT_APP_SOCKET_URL: "http://digitalcustodian.co:3000/",
  // REACT_APP_IMAGE_URL: "http://digitalcustodian.co:3000/",
  // REACT_APP_QR_URL: "http://digitalcustodian.co:3000/",
  // CAPTCHA_ENABLED: "true", // true or false

  // for Production
  // REACT_APP_TITLE: "MACS Core Beta",
  // REACT_APP_VERSION: "v2.2.3",
  // REACT_APP_BASE_URL: "https://prod.macs-core.com:3000/api",
  // REACT_APP_SOCKET_URL: "https://prod.macs-core.com:3000/",
  // REACT_APP_IMAGE_URL: "https://prod.macs-core.com:3000/",
  // REACT_APP_QR_URL: "https://prod.macs-core.com:3000/",
  // CAPTCHA_ENABLED: "true", // true or false
};
export const env: EnvType = { ...process.env, ...window.env };
