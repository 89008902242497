import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getUnRealizedLedgers,saveCollectivePayment } from '../../stores/services/collective-payment.service';
import { getInstrumentType } from '../../stores/services/funds.service';
import {numberWithCommas} from './../../utils/customFunction';

const CollectiveTaxPayment = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [ledgersData, setLedgersData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]); // Instrument Type
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [type, setType]= useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));
  let disableDates=moment(date).format('YYYY-MM-DD');

  // fields states
  const [realize, setRealize] = useState(false);
  const [instrumentType, setInstrumentType] = useState('');
  const [instrumentNo, setInstrumentNo] = useState('');
  const [instrumentDate, setInstrumentDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [detail, setDetail] = useState('');
  const [realizedDate, setRealizedDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [accounTitle, setAccountTitle] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');

  const [totalAmount, setTotalAmount] = useState('0');

  // error fields states
  const [instrumentTypeErr, setInstrumentTypeErr] = useState('');
  const [instrumentNoErr, setInstrumentNoErr] = useState('');
  const [instrumentDateErr, setInstrumentDateErr] = useState('');
  const [realizedDateErr, setRealizedDateErr] = useState('');

  const [accounTitleErr, setAccountTitleErr] = useState('');
  const [accountNoErr, setAccountNoErr] = useState('');
  const [bankErr, setBankErr] = useState('');
  const [branchErr, setBranchErr] = useState('');

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           setAllAmc(amcResponse.data.data);
            }catch(err:any){
              if(err.response !== undefined ) {
                toast.error(err.response.data.message);	
              }else {
                toast.error("Request Failed!")
              }
            }

            // instrument detail 
          try {
            const inTypeResponse = await getInstrumentType(email);
            setITypeData(inTypeResponse.data.instrument_types);
          } catch (error) {}
          }
          
          
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_','')}>
                {item.name}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(error){

            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }

         //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  // for validation
  const validate = () => {	
    let insTypeError = '';	
    let insNoError = '';	
    let insdateError = '';	
    let insdetailError = '';	
    let realizeddateError = '';	
    let accTitleError = '';	
    let accNoError = '';	
    let accbankError = '';	
    let accbranchError = '';	
    instrumentType.trim() === '' ? (insTypeError = 'Required') : (insTypeError = '');	
    instrumentNo.trim() === ''? (insNoError = 'Required') : (insNoError = '');	
    instrumentDate.trim() === ''? (insdateError = 'Required') : (insdateError = '');		
    realizedDate.trim() === ''? (realizeddateError = 'Required') : (realizeddateError = '');	
    accounTitle.trim() === ''? (accTitleError = 'Required') : (accTitleError = '');	
    accountNo.trim() === ''? (accNoError = 'Required') : (accNoError = '');	
    bank.trim() === ''? (accbankError = 'Required') : (accbankError = '');	
    branch.trim() === ''? (accbranchError = 'Required') : (accbranchError = '');	
    if (insTypeError || insNoError || insdateError || insdetailError || realizeddateError || accTitleError || accNoError || accbankError || accbranchError) {	
    setInstrumentTypeErr(insTypeError);
    setInstrumentNoErr(insNoError);
    setInstrumentDateErr(insdateError);
    setRealizedDateErr(realizeddateError);
    setAccountTitleErr(accTitleError);
    setAccountNoErr(accNoError);
    setBankErr(accbankError);
    setBranchErr(accbranchError);
      return false;	
    } else {	
      return true;	
    }	
  };

  //code for search data for Reports

  const searchData = async () => {  
    try {
      setLoading(true);
      ledgersData.length=0;
      setLedgersData(ledgersData);
      setAccountTitle('');
      setAccountNo('');
      setBank('');
      setBranch('');
      setTotalAmount('');
      const response = await getUnRealizedLedgers(email, Amc, fund, type, fromDate,toDate);
      let array= Object.entries(response.data.data);
      let data:any=[];
      array.forEach(([key, value]) => { 
        data=value;
        let record=[];
        record = data.Record; 
        record['ledger_id']= data.Key; 
        record['flag']= false; 
        ledgersData.push(record);
      });
      setAccountTitle(ledgersData[0].counter_account_title);
      setAccountNo(ledgersData[0].counter_account_number);
      setBank(ledgersData[0].counter_bank);
      setBranch(ledgersData[0].counter_branch);
      setLoading(false);
    } catch (error) {
        setLoading(false);
    }
  }

  const toggleTotalAmount =()=>{
    var amount = 0;
      ledgersData.map((item, index) => {
       if(item.flag===true){
        amount += parseFloat(item.credit_amount)
        }
      })
      setTotalAmount(amount.toFixed(2).toString())
     }

  const save = async ()=>{
    try{
      var isvalid = validate();
      if(isvalid){
        setSaveLoading(true);
        let filterRealizedData =  ledgersData.filter((item:any, index:number)=>{
          return item.realized==="true";
        })  
        filterRealizedData.map((item:any, index:number)=>{
              item.instrument_no=instrumentNo;
              item.instrument_by=email;
              item.instrument_type=instrumentType;
              item.instrument_date=instrumentDate;
              item.realized_date=realizedDate;
              item.realized_by=email;
              item.counter_account_number=accountNo;
              item.counter_account_title=accounTitle;
              item.counter_bank=bank;
              item.counter_branch=branch;
        });
        let data : any = {};
        data['total_amount']=totalAmount;
        data['amc_code']=Amc;
        data['fund_code']=fund;
        data['type']=type;
        data['date_from']=fromDate;
        data['date_to']=toDate;
        data['detail']=detail;
        data['ledgers'] = filterRealizedData
       
        if(data.ledgers.length>0){
         const response = await saveCollectivePayment(email, data);
         if(response.data.status==200)
         {
          toast.success(response.data.message)
          ledgersData.length=0;
          setLedgersData(ledgersData);
          setAccountTitle('');
          setAccountNo('');
          setBank('');
          setBranch('');
          setInstrumentType('');
          setInstrumentNo('');
          setDetail('');
          setTotalAmount('');
         }else if(response.data.status==500){
          toast.error(response.data.message)
         }else{
          toast.error(response.data.message)
         }
        }else{
          toast.error('Ledgers are not selected.')
        }
        setSaveLoading(false);
      }else {
        setSaveLoading(false);
      }
    }catch(error:any){
      setSaveLoading(false);
      if(error.response!==undefined){
        toast.error(error.response.data.message);
      }else{
        toast.error('Request Failed!');
      }
    }
    setSaveLoading(false);
  }

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Collective Payment
              
              <button
                className="btn btn-info btn-sm float-right"
                onClick={() => {
                  history.replace('/admin/collective-payment')
                }}
              >
                <i className="fa fa-eye"></i> View All
              </button>
              </h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAmc(e.target.value);
                        getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.target.value)
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Type</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setType(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Type
                    </option>
                    <option value="sale_load">Sale Load</option>
                    <option value="redemption_load">Redemption Load</option>
                    <option value="tax_cgt">CGT</option>
                    <option value="tax_sst">SST</option>
                    <option value="tax_wht_it">WH IT</option>
                    <option value="tax_wht_sst">WH ST</option>
                    <option value="zakat_amount">Zakat</option>
                    {/* <option value="broker_commission">Broker Commission</option> */}
                    {/* <option value="tax_sst">SST Commission</option> */}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group  ">
                  <label>Date From</label>
                  <input
                    type="date"
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromDate(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Date To</label>
                  <input
                    type="date"
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToDate(date);
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

        <Row>
            <Col>
          <div className="card mt-3"> 
          <div className="card-header">
              <div className="card-title">Ledgers</div>
          </div>
            <div className="card-body">
             {ledgersData.length>0 ? (
               <>
                <div className='table-responsive'>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Date</th>
                            <th>Ledger ID</th>
                            <th>Description</th>
                            <th className="text-right">Debit</th>
                            <th className="text-right">Credit</th>
                            <th>Txn ID</th>
                        </tr>
                    </thead>
                    <tbody>
                      {ledgersData.map((item:any, index:number)=>{
                        return (
                          <>
                          <tr key={index}>
                            <td>
                                <input type="checkbox" 
                                checked={item.flag}
                                onChange={(e)=>{	                                  
                                  if(item.flag===true){
                                    item.flag=false;
                                    item.realized='false';
                                    setRealize(!realize);
                                    toggleTotalAmount();
                                  }
                                  else{
                                   item.flag=true;
                                   item.realized='true';
                                    setRealize(!realize);
                                    toggleTotalAmount();
                                  };
                                }} 
                                />
                            </td>  
                            <td>{moment(item.transaction_date).format('DD-MM-YYYY')}</td>
                            <td>{item.ledger_id}</td>
                            <td>{item.description}</td>
                            <td className="text-right">{numberWithCommas(item.debit_amount)}</td>
                            <td className="text-right">{numberWithCommas(item.credit_amount)}</td>
                            <td>{item.txn_id}</td>
                        </tr>
                        </>
                        );
                      })}
                        
                    </tbody>
                    
                    <tfoot>
                          <tr>
                              <td colSpan={5}>Total Amount:</td>
                              <td className="text-primary float-right">{totalAmount==='0' ? ' 0' :  numberWithCommas(totalAmount)}</td>
                              <td></td>
                          </tr>
                      </tfoot>

                </table>
                
              
            </div>
            </>
            ):(
              <></>
            )}
          </div>
         </div>
         </Col>

         <Col>
         <div className="card mt-3"> 
         <div className="card-header">
              <div className="card-title">Instrument Detail</div>
          </div>
            <div className="card-body">
            <div className="form-group">
             <div>Instrument Type</div>
             
             <select className={`form-control  w-100 ${instrumentTypeErr ? 'required-border' : ''}`}
              value={instrumentType}
              onChange={(e) => {
              setInstrumentType(e.target.value);
              setInstrumentTypeErr(''); 
              }}
              >
              <option value="" defaultChecked hidden>
               {' '}
               Select Type
               </option>
               {renderiTypeDataDropdown()}
                </select>

            </div>

            <div className="form-group">
             <div>Instrument No.</div>
            <input type="text" value={instrumentNo} className={`form-control  w-100 ${instrumentNoErr ? 'required-border' : ''}`}
            onChange={(e) => {
                setInstrumentNo(e.target.value);
                setInstrumentNoErr('');
            }}
            />
            </div>

            <div className="form-group">
             <div>Instrument Date</div>
            <input type="date" value={instrumentDate} className={`form-control  w-100 ${instrumentDateErr ? 'required-border' : ''}`}
            onChange={(e) => {
               let date = moment(e.target.value).format('YYYY-MM-DD');
               setInstrumentDate(date);
               setInstrumentDateErr('');
            }}
            />
            </div>

            <div className="form-group">
             <div>Realized Date</div>
            <input type="date" disabled value={realizedDate} className={`form-control`}
            />
            </div>

            <div className="form-group">
             <div>Detail</div>
           <textarea className="form-control w-100" value={detail}
           onChange={(e)=>{
            setDetail(e.target.value);
           }}></textarea>
        </div>

          </div>
         </div>

         <div className="card mt-3"> 
         <div className="card-header">
              <div className="card-title">Beneficiary Detail</div>
          </div>
            <div className="card-body">

            <div className="form-group">
             <div>Account Title</div>
            <input type="text" value={accounTitle} className={`form-control  w-100 ${accounTitleErr ? 'required-border' : ''}`}
            onChange={(e) => {
              setAccountTitle(e.target.value);
              setAccountTitleErr('');
            }}
            />
            </div>

            <div className="form-group">
             <div>Account No.</div>
            <input type="text" value={accountNo} className={`form-control  w-100 ${accountNoErr ? 'required-border' : ''}`}
            onChange={(e) => {
                setAccountNo(e.target.value);
                setAccountNoErr('');
            }}
            />
            </div>

            <div className="form-group">
             <div>Bank</div>
            <input type="text" value={bank} className={`form-control  w-100 ${bankErr ? 'required-border' : ''}`}
            onChange={(e) => {
             setBank(e.target.value);
             setBankErr('');
            }}
            />
            </div>

            <div className="form-group">
             <div>Branch</div>
            <input type="text" value={branch} className={`form-control  w-100 ${branchErr ? 'required-border' : ''}`}
            onChange={(e) => {
               setBranch(e.target.value);
               setBranchErr('');
            }}
            />
            </div>

          </div>
         </div>
         </Col>
        </Row>

            <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  save();
                }}
                disabled={Boolean(saveLoading)}
              >
                {saveLoading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Save</span>
                )}
              </button>

        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default CollectiveTaxPayment;
