import axios from 'axios';
import https from 'https';
import { any } from 'prop-types';
import Config from '../../config/index';
import RefreshTokenHandler from './refresh-token';
//import preval from 'babel-plugin-preval/macro'
var { HttpsProxyAgent } = require('https-proxy-agent');
const jks = require('jks-js');



// let keystore = preval`
//   const fs = require('fs')
//   module.exports = fs.readFileSync(require.resolve('./cddcuat.cer'))
// `



// const allCDCData = async () => {
//   const agent = new https.Agent({
//     rejectUnauthorized: false
//   })

//   try {
//     let result = await axios({
//      method:'POST' ,
//       url: `https://cdcwebservice.cdcpak.com:5013/DCCL/Services/getCDSAccountBalance`,
//       // data: { uid, password, reserved1, reserved2, reserved3 },
//       data: {
//         "userId": "DCCL03",
//         "password": "A@I82EBm$2p&",
//         "reserved1": "",
//         "reserved2": "",
//         "reserved3": ""
//       },
//       headers: { 'Content-Type': 'application/json' },
//       httpsAgent: agent,
//     });
//     return result;
//   } catch (err: any) {
//     throw err
//   }
// }
const allCDCData = async () => {
  const agent = new https.Agent({
    rejectUnauthorized: false
  });

  try {
    const result = await axios.post(
      'https://cdcwebservice.cdcpak.com:5013/DCCL/Services/getCDSAccountBalance',
      {
        userId: 'DCCL03',
        password: 'A@I82EBm$2p&',
        reserved1: '',
        reserved2: '',
        reserved3: ''
      },
      {
        headers: { 'Content-Type': 'application/json' },
        httpsAgent: agent
      }
    );
    return result.data;
  } catch (err) {
    throw err;
  }
};

export {
  allCDCData,
};