import moment from "moment";
import DCClogo from "../../assets/img/DCC-Logo.png";

export const poCDRLetter = (
  bank_name: any,
  bank_branch: any,
  bank_city: any,
  accounts: any
) => {
  let date = new Date();

  let dateFormat = moment(date).format("LL");
  const getData = () => {
    let rows = "";
    for (let i = 0; i < accounts.length; i++) {
      if(i == accounts.length-1)
      {
        rows += ` 
                <tr style="border-top: 1px solid #333; border-bottom: 1px solid #333;" >
                    <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">${
                      i + 1
                    }</td>
                    <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;">${accounts[i].value
                      .split("–")[0]
                      .trim()}</td>
                    <td style="  padding-left:5px; padding-right:5px;  border-right: 1px solid #333; width:30%">${accounts[i].value
                      .split("–")[1]
                      .trim()}</td>
                      
                </tr>
            `;

      }else{
        rows += ` 
                <tr style="border-top: 1px solid #333;" >
                    <td style="border-right: 1px solid #333; border-left: 1px solid #333;  text-align:center; width:5%">${
                      i + 1
                    }</td>
                    <td style="border-right: 1px solid #333;  padding-left:5px; padding-right:5px;">${accounts[i].value
                      .split("–")[0]
                      .trim()}</td>
                    <td style="  padding-left:5px; padding-right:5px; border-right: 1px solid #333; width:30%">${accounts[i].value
                      .split("–")[1]
                      .trim()}</td>
                      
                </tr>
            `;

      }
          
    }
    return rows;
  };

  return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
           <div style="float: none; margin: 0 auto; width: 100%; text-align:center; ">
           <img src="${DCClogo}" alt="Trustee" height="90" />
           </div
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <table style="width: 100%;"><tr><td>Ref: ${date.getTime()}</td> </tr></table>
            <br />
            <div>The Manager, <br /> ${bank_name}, <br />  ${bank_branch}, ${bank_city}.</div>
            <br />
            <div><b style="border-bottom:1px solid black">Authority Letter</b></div>
            <br />
            <div>Dear Sir/Madam,</div>
            <br />
            <div>
                Please be informed that under-mentioned account(s) maintained with you, we request you to kindly issue the Pay
                Order(s)/CDR of the end of day closing balance after retaining Rs.10,000/=(Rupees Ten Thousand only).
            </div>
            <br />
            <div>
                Kindly make sure that the Pay Order(s)/CDR should be prepared one day before 1st Ramzan ${
                  date.getFullYear() - 579
                } (Subject to sight
                of moon). Please also deposit the same Pay Order(s)/CDR On Next Working day in same accounts from where they
                issued.
            </div>
            <br />
            <div>
                Your coopertion in this regard would be highly appreciated.
            </div>
            <br />
            <table style="width: 100%; border-collapse:collapse; ">
            
            <thead>

            <tr style="border-right: 1px solid #333; border-left: 1px solid #333; border-top: 1px solid #333;">
                <th style="border-right: 1px solid #333; text-align:center;  width:6%"> S.No. </th>
                <th style="border-right: 1px solid #333; padding-left:5px"> Account Title </th>
                <th style="padding-left:5px; width:30%"> Account No  </th>
            
            </tr>
            </thead>
            <tbody>
            ${getData()}
            </tbody>
            </table>
            <br />

            <div>Regards,</div>
           
            <br />
            <br />
            
            <table  style="width: 100%; ">
            <tr>
                
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
               
            </tr>
        </table>
    </body>
    </html>
    `;
};
