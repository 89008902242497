import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";
import converter from "number-to-words";

const addBankTemplate = async (
  email: string,
  name: string,
  detail: string,
  bank_name: string
) => {
  const url = `${Config.baseUrl}/template`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, detail, bank_name },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addBankTemplate(email, name, detail, bank_name);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getAllBankTemplates = async (email, bank_name = "") => {
  const url = `${Config.baseUrl}/template?email=${email}&bank_name=${bank_name}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllBankTemplates(email, bank_name);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getMailTemplates = async () => {
  const email = sessionStorage.getItem("email") || "";
  const url = `${Config.baseUrl}/template/email-template?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getMailTemplates();
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const UpdateBankTemplates = async (
  email: string,
  template_name: string,
  template_detail: string,
  bank_name: string
) => {
  const url = `${Config.baseUrl}/template/update`;
  try {
    const result: any = await axios.post(
      url,
      { email, template_name, template_detail, bank_name },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await UpdateBankTemplates(
          email,
          template_name,
          template_detail,
          bank_name
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const SendMailTo = async (
  email: string,
  value: string,
  fund_account: string,
  data: string
) => {
  const url = `${Config.baseUrl}/template/send-email`;
  try {
    const result: any = await axios.post(
      url,
      { email, value, fund_account, data },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await SendMailTo(email, value, fund_account, data);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

var a = [
  "",
  "One ",
  "Two ",
  "Three ",
  "Four ",
  "Five ",
  "Six ",
  "Seven ",
  "Eight ",
  "Nine ",
  "Ten ",
  "Eleven ",
  "Twelve ",
  "Thirteen ",
  "Fourteen ",
  "Fifteen ",
  "Sixteen ",
  "Seventeen ",
  "Eighteen ",
  "Nineteen ",
];
var b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

// const inWords = (num) => {
//   if ((num = num.toString()).length > 12) return 'overflow';
//   let n = ('000000000' + num)
//     .substr(-12)
//     .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2}).(\d{2})$/);
//   if (!n) return;
//   var str = '';
//   str += +n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore '
//       : '';
//   str +=
//     +n[2] != 0
//       ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh '
//       : '';
//   str +=
//     +n[3] != 0
//       ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand '
//       : '';
//   str +=
//     +n[4] != 0
//       ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred '
//       : '';
//   str +=
//     +n[5] != 0
//       ? (str != '' ? 'and ' : '') +
//         (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
//         'Rupees and '+ (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]]) +' Paisa only'
//       : '';

//   return str;
// };

const capitalizeFirstLetter = (word) => {
  let ret: any;
  if (word.includes("-")) {
    let temp = word.split("-");
    ret =
      temp[0].charAt(0).toUpperCase() +
      temp[0].slice(1).toLowerCase() +
      "-" +
      temp[1].charAt(0).toUpperCase() +
      temp[1].slice(1).toLowerCase();
  } else {
    ret = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  return ret;
};

const inWords = (num: any) => {
  // eslint-disable-next-line
  if (num === "Infinity") {
    return num;
  }
  if (
    num.toString() === "" ||
    num.toString() === "NaN" ||
    isNaN(num.toString())
  ) {
    return "";
  }

  // num = num==='NaN' ? num='0' : num;
  // if(num!==''){
  // let value= inWord(num.toString().split('.')[0]);
  // if((num.toString().split('.').length>1) && ((num.toString().split('.')[1])>0)){
  // value+= " and "  + " Paisa" + " " + inWord(num.toString().split('.')[1]);
  // value+= " Only"
  // }else{
  //   value+= " Only"
  // }
  if (num !== "") {
    // let value =
    //   num.toString().split(".")[0] &&
    //   converter
    //     .toWords(num.toString().split(".")[0])
    //     .replaceAll(",", "")
    //     .toString()
    //     .replace(/\w\S*/g, function (txt) {
    //       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    //     });
    // if (num.toString().split(".").length > 1 && num.toString().split(".")[1]) {
    //   let decimalDigit = num.toString().split(".")[1].substring(0, 2);

    //   //value+= " and "  + " Paisa" + " " + converter.toWords(num.toString().split('.')[1]);
    //   value +=
    //     " and " +
    //     "Paisa" +
    //     " " +
    //     converter
    //       .toWords(decimalDigit)
    //       .replaceAll(",", "")
    //       .toString()
    //       .replace(/\w\S*/g, function (txt) {
    //         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    //       });
    //   value += " Only";
    // } else {
    //   value += " Only";
    // }

    // return value;
    let value =
      num.toString().split(".")[0] &&
      converter
        .toWords(num.toString().split(".")[0])
        .split(" ")
        .map(capitalizeFirstLetter)
        .join(" ");

    if (num.toString().split(".").length > 1 && num.toString().split(".")[1]) {
      let decimalDigit = num.toString().split(".")[1].substring(0, 2);

      value +=
        " and " +
        "Paisa" +
        " " +
        converter
          .toWords(decimalDigit)
          .split(" ")
          .map(capitalizeFirstLetter)
          .join(" ");
      value += " Only";
    } else {
      value += " Only";
    }

    return value;

    //return inWord(num)
  }
};

//var converter = require('number-to-words');

const inWord = (num) => {
  if ((num = num.toString()).length > 12) return "overflow";
  let n = ("000000000" + num)
    .substr(-12)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2}).(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    +n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Billion "
      : "";
  str +=
    +n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Million "
      : "";
  str +=
    +n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    +n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  if (n[6] === "00") {
    str +=
      +n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "Rupees and Paisa Zero Only"
        : "";
  } else {
    str +=
      +n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "Rupees and " +
          (a[Number(n[6])] || b[n[6][0]] + " " + a[n[6][1]]) +
          " Paisa Only"
        : "";
  }
  return str;
};

const addLetterTemplate = async (
  email: string,
  name: string,
  detail: string,
  bank_name: string
) => {
  const url = `${Config.baseUrl}/template/letter`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, detail, bank_name },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addLetterTemplate(email, name, detail, bank_name);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getAllLetterTemplates = async (email, bank_name = "") => {
  const url = `${Config.baseUrl}/template/letter?email=${email}&bank_name=${bank_name}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllLetterTemplates(email, bank_name);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const UpdateLatterTemplates = async (
  email: string,
  name: string,
  detail: string,
  bank_name: string
) => {
  const url = `${Config.baseUrl}/template/update-letter`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, detail, bank_name },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (
      err.response.data.status == 401 ||
      err.response.status == 403 ||
      err.response.statusCode == 403
    ) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await UpdateLatterTemplates(email, name, detail, bank_name);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {
  addBankTemplate,
  getAllBankTemplates,
  UpdateBankTemplates,
  inWords,
  addLetterTemplate,
  getAllLetterTemplates,
  UpdateLatterTemplates,
  getMailTemplates,
  SendMailTo,
};
