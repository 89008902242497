import React, { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { Container, Row, Col } from 'reactstrap';
import { getFeatures, addRole } from '../../stores/services/role.service';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const AddRole = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  let [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [checked, setChecked] = useState<any>([]);
  let [expanded, setExpanded] = useState<any>([-1]);
  let [nodes, setNodes] = useState<any>([]);
  let [nameError, setNameError] = useState(false);
  let [descriptionError, setDescriptionError] = useState(false);
  let [featruesError, setFeaturesError] = useState(false);
  const [features, setFeatures] = useState([]);
  React.useEffect(() => {
    getFeatures(sessionStorage.getItem('email') || '')
      .then((response) => {
        setFeatures(response.data.features);
        response.data.features.unshift({
          id: -1,
          feature: 'Trustee',
          parent_id: null,
        });
        for (let index = 0; index < response.data.features.length; index++) {
          if (response.data.features[index].parent_id === 0) {
            response.data.features[index].parent_id = -1;
          }
          response.data.features[index].value = response.data.features[index].id;
          response.data.features[index].label = response.data.features[index].feature;
        }
        var data = response.data.features;
        var root: any;
        const idMapping = data.reduce((acc: any, el: any, i: any) => {
          acc[el.id] = i;
          return acc;
        }, {});
        data.forEach((el: any) => {
          // Handle the root element
          if (el.parent_id === null) {
            root = el;
            return;
          }
          // Use our mapping to locate the parent element in our data array
          const parentEl = data[idMapping[el.parent_id]];
          // Add our current el to its parent's `children` array
          parentEl.children = [...(parentEl.children || []), el];
        });
        setNodes([root]);
      })
      .catch((err) => {
      });
  }, []);
  const addRoleBtn = () => {
    setNameError(false);
    setDescriptionError(false);
    setFeaturesError(false);
    setLoading(true);
    if (!name || name.trim() === '') {
      toast.error('Name is Required');
      setLoading(false);
      return;
    }
    if (!description || description.trim() === '') {
      toast.error('Description is Required');
      setLoading(false);
      return;
    }
    if (checked.length === 0) {
      toast.error('Please Select Features to proceed');
      setLoading(false);
      return;
    }
    const selected_features: any = [];
    for (let index = 0; index < checked.length; index++) {
      const feature = features.find((x:any) => x.id === parseInt(checked[index]));
      if (feature) {
        selected_features.push(feature);
      }
    }
    addRole(sessionStorage.getItem('email') || '', name, description, JSON.stringify(selected_features))
      .then((response) => {
        if(response.data.status===200){
        setLoading(false);
        toast.success(response.data.message);
        setTimeout(function () {
          history.replace('/admin/role-management');
        }, 3000);
      }else{
        setLoading(false);
        toast.error(response.data.message);
      }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer />
        <div className="card mt-4">
          <div className="card-header">
          <h4 className="card-title"> Add Role </h4>{' '}
          </div>
          <div className="card-body">
            <div className="title-row">
              <h3 className="mb-1"> Role Info </h3>
              <Link to="/admin/role-management" className="t-3 btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>
            </div>{" "}
            <p className="t-3 mb-2">
              {" "}
              Please enter the following information to add a new Role{" "}
            </p>{" "}
            <div className="">
            <Row>
              <Col md="6">
                <div className="form-group  ">
                  <label> Name </label>{" "}
                  <input className="form-control" onChange={(e) => {
                    setName(e.target.value);
                  }} />

                </div>{" "}
                {
                  nameError === true ?
                    <p className="error-labels">Name is Required.</p>
                    : ''
                }
              </Col>{" "}
            </Row>{" "}
            <Row>
              <Col md="6">
                <div className="form-group   align-items-start">
                  <label> Description </label>{" "}
                  <textarea className="form-control description-box" onChange={(e) => {
                    setDescription(e.target.value)
                  }}> </textarea>

                </div>{" "}
                {
                  descriptionError === true ?
                    <p className="error-labels">Description is Required.</p>
                    : ''
                }
              </Col>
            </Row>{" "}
            <div className="line"> </div>{" "}
            <h3 className="mb-1"> Assign Features </h3>{" "}
            <p className="t-3 mb-2"> Features </p>
            <Row className="mt-3 ml-2">
              <CheckboxTree
                nodes={nodes}
                checked={checked}
                expanded={expanded}
                onCheck={(checked) => {
                  setChecked(checked)
                }}
                onExpand={(expanded) => setExpanded(expanded)}
                iconsClass="fa5"
              />
              {
                featruesError === true ?
                  <p className="error-labels">Select Features.</p>
                  : ''
              }
            </Row>{" "}
            </div>
            <div className="d-flex mt-3">
              <div className="">
                <Link className="btn btn-default bg-negative" to="/admin/role-management" replace>Cancel</Link>
              </div>
              <div className="hov">
                <button className="btn btn-primary ml-4" onClick={addRoleBtn} disabled={Boolean(Loading)}>
                  {Loading ? (
                   <>
                   <span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="login-txt"> Loading...</span>
                    </> 
                    ) : (
                     <span>Add Role</span>
                     )}
                </button>
              </div>
            </div>{" "}
          </div>
        </div>{" "}
      </Container>{" "}
      </Col>
        </Row>
      </div>
   </>
  );
};

export default AddRole;