import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
import https from 'https';

var {HttpsProxyAgent } = require('https-proxy-agent');

// const autoSettlementNCCPL = async (settlementDate:string) =>{
//     // const url = `https://cors-anywhere.herokuapp.com/https://ncss.nccpl.pk/NCSSServices/PSSR/${settlementDate}`;
//     const url  = `https://ncss.nccpl.pk/NCSSServices/PSSR/${settlementDate}`;
//     // const url = `/NCSSServices/PSSR/${settlementDate}`;

//     // 20-MAY-2022
//     // const headers:any = {
//     //   'Access-Control-Allow-Origin':'*',
//     //   "Access-Control-Allow-Headers" : ['Content-Type', 'Authorization', 'Accept'],
//     //   "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
//     //   crossDomain: true,
//     //   'Content-Type': 'application/json',
//     //   'Authorization': 'DIGICUST:Ac45#$)jn',
//     // };

//     const headers:any = {
//       'Content-Type': 'application/json',
//       'Authorization': 'DIGICUST:Ac45#$)jn',
//     };
 
//     try{
//       // const result = await axios.get(url,  { headers });
//       const httpsAgent = new HttpsProxyAgent({
//         rejectUnauthorized: false,
//       });
//       const result = await axios({
//         method: 'get',
//         url: url,
//         headers: headers,
//         httpsAgent: httpsAgent

//       });
//       return result;
//       }catch(err:any) {
//         throw err
//     }
// }

const autoSettlementNCCPL = async (settlementDate:string)=>{
  const agent = new https.Agent({
    rejectUnauthorized: false
  })

  try {
    const headers:any = {
            'Content-Type': 'application/json',
            'Authorization': 'DIGICUST:Ac45#$)jn',
          };
    let result = await axios({
      method: 'GET',
      url: `https://ncss.nccpl.pk/NCSSServices/PSSR/${settlementDate}`,
      // data: { uid, password, reserved1, reserved2, reserved3 },
      headers: headers,
      httpsAgent: agent,
    });
    return result;
  } catch (err: any) {
    throw err
  }
}


export {
    autoSettlementNCCPL
};