import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import { isNumber, numberWithCommas } from '../../utils/customFunction';
import Config from '../../config';
import { getLedger } from '../../stores/services/transactions.service';
import { toast } from 'react-toastify';

export const demandDraftLetterTemplate = (transaction: any, amtWords: any, transType: any, transNetAmount: any, transGrossAmount: any, grossAmtWords: any) => {

    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q = "qrcodeinflow";
    let id = `${Config.q_r_url}/admin/verify-transaction/` + transaction.txn_id;
    let txnId = transaction.txn_id;
    let branchName = '';
    let branchCity = '';
    if (transaction.branch != undefined) {
        if (transaction.branch.includes('–')) {
            branchName = transaction.branch.split('–')[0];
            branchCity = transaction.branch.split('–')[1];
            branchName = branchCity === undefined ? branchName.trim() + ',' : branchName.trim() + ',';
            branchCity = branchCity === undefined ? '' : branchCity.trim() + '.';
        } else {
            branchName = transaction.branch + ',';
        }
    }
    let CBranch:any = '';
    if (transaction.counter_branch != undefined) {
    CBranch = transaction.counter_branch.split(' – ')[1]  !==undefined? (transaction.counter_branch).split(' – ')[1] : '';
    }

    let netAmount = numberWithCommas(parseFloat(isNumber(transaction.net_amount)).toFixed(2)) === '0' ? numberWithCommas(parseFloat(isNumber(transaction.gross_amount)).toFixed(2)) : numberWithCommas(parseFloat(isNumber(transaction.net_amount)).toFixed(2));

    setTimeout(() => {
        if (id != undefined) {
            var element = document.getElementById(q)!;
            if (element != null) {
                element.innerHTML = "";
            }
            var qrcode = new QRCode(document.getElementById(q), {
                width: 50,
                height: 50
            });
            qrcode.makeCode(id);
        }

    }, 500);

    let amount = transType === 'saleofunit' ? transGrossAmount : transNetAmount;
    let amountInWords = transType === 'saleofunit' ? grossAmtWords : amtWords;

    const getData = () => {
        return` 
            <tr key={i}>
                <td style="border: 1px solid #333; text-align:center;">1</td>
                <td style="border: 1px solid #333">${transaction.counter_account_title}</td>
                <td style="border: 1px solid #333">${CBranch}</td>
                <td style="border: 1px solid #333">${transaction.detail}</td>
                <td style="border: 1px solid #333;text-align:right;">${netAmount}</td>   
            </tr>`
    }

    return `<html>
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <div> Re: <b style="border-bottom:1px solid black"> Issuance of Pay Order/Demand Draft.</b></div>
            <br />

            <div> Dear Sir/Madam, </div>
            <br />
            
            <div> You are requested to issue Pay Order/Demand Draft as per below details.</div>      
                <table style="width: 100%;" > 
                    <tr><td style="font-weight: bold;" colspan="3">Debit Account Details: </td> </tr>
                    <tr>
                        <td valign="top" style="width:110px"> Account Title</td> 
                        <td><b>: ${transaction.account_title} </b></td> 
                    </tr>
                    <tr>
                        <td valign="top"> Account No. </td>
                        <td><b>: ${transaction.account_number} (${transaction.bank}, ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch})   </b></td>
                    
                    </tr>

                    <tr>
                        <td valign="top"> Instrument No.</td> 
                        <td><b>: ${transaction.instrument_no}</b></td>
                        </tr>
                    <br />
                    <table style="width: 100%;">
                        <tr><td style="font-weight: bold;" colspan="3"> In favor of: </td> </tr>
                        <tr>
                            <th style="border: 1px solid #333; text-align:center; width: 6%"> S.No.  </th>
                            <th style="border: 1px solid #333; text-align:center; width: 32%"> Title    </th>
                            <th style="border: 1px solid #333; text-align:center; width: 20%"> Drawn On    </th>
                            <th style="border: 1px solid #333; text-align:center; width: 20%"> Purpose    </th>
                            <th style="border: 1px solid #333; text-align:center; width: 20%"> Amount  </th>
                        </tr>
                        ${getData()}    
                     </table>
                    
    
                </table>
                <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
                <tr>
                    <td></td>
                    <td >
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td style="width: 40%;"> </td>
                    <td>
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td></td>
                </tr>
            </table>
    </body>
    </html>
    `
}