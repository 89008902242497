import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import {
  uploadUnitHolder
} from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { JsonToTable } from 'react-json-to-table';
import xls from "xlsx";
import { Modal } from 'react-bootstrap';
import { getAmc } from '../../../stores/services/amc.service';
import { sortAMCS } from '../../../utils/customFunction';
import { element } from 'prop-types';
const csv = require('csvtojson/v2');
//const xlsx = require('xlsx-to-json');

const ViewFile = () => {
  const email = sessionStorage.getItem('email') || '';
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [fileData, setfileData] = useState<any>([]);
  const [fileSomeData, setfileSomeData] = useState<any>([]);
  const [remainingCount, setRemainingCount] = useState('');
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorData, setErrorData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [errorShow, setErrorShow] = useState(false);

  React.useEffect(() => {
    const getAllAMC = async () => {
      setLoading(true)
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false)
    }
    getAllAMC();
  }, [])

  const UploadTxn = async () => {
    if (file) {
      setLoading(true);
      try {
        setLoading(true);
        const res = await uploadUnitHolder(email, fileData);
        if (res.data.status == 200) {
          toast.success(res.data.message);
          setFile('');
          setFileName('');
          fileData.length = 0;
          setfileData(fileData);

          errorData.length = 0;
          setErrorData(errorData);
          setFileDataCount(0);

          setRemainingCount('');
          setfileSomeData(fileData);
        }
        else {
          toast.error(res.data.message);
        }


      } catch (error: any) {
        setLoading(false);
        if (error.response.data.status == 500) {
          toast.error(error.response.data.message);
        }
        else if (error.response.data.status == 400) {
          setErrorBoolean(true);
          setErrorData(error.response.data.data);
        }
        else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    } else {
      toast.error('Kindly Upload File');
    }
  };

  const upload = async (e: any) => {
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === "xlsx" || type === 'csv') {
      fileData.length = 0;
      setfileSomeData(fileData);
      setFile(file);
      setFileName(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          defval: "",
          dateNF: 'YYYY-MM-DD',
        });
        /* Update state */

        if (data) {
          let flag = false;

          let check = false;
          allAmc.map((elem) => {
            if (elem.amc_code === data[0].AmcCode) {
              check = true;
            }
          })
          if (check === false) {
            toast.error("Please enter valid amc code");
          } else {
            for (let i = 1; i < data.length; i++) {

              if (data[0].AmcCode !== data[i].AmcCode) {
                flag = true;
                break;
              }
            }

            if (flag === true) {
              toast.error('Amc code should be same.');
              fileData.length = 0;
              setfileSomeData(fileData);
            } else {
              setfileData(data);
              setFileDataCount(data.length)
              if (data.length > 100) {
                const someData: any = [...data];
                someData.length = 100;
                let remainingRecords = parseFloat(data.length) - 100;
                setRemainingCount(remainingRecords.toString());
                setfileSomeData(someData);
              } else {
                // setRemainingCount(data.length.toString());
                setfileSomeData(data);
              }
            }
          }
        }

      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            enforceFocus={false}
            show={true}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="d-flex align-items-center"></div>

                <div className="">
                  <div className="card-header">
                    <h4 className="card-title">Unit Holders
                      <a className='float-right' href="./../../Uploader-Sample/Upload-Unit-Holder.xlsx" download>Download Sample</a>
                    </h4>
                  </div>
                  <div className="card-body">

                    {/* <div className="ml-3 mr-3"> */}
                    <div className="row">
                      <div className="col-md-4">


                        <div onClick={() => myRef1?.current?.click()}>
                          <div className="form-group">
                            <div className="form-control" data-tip="Upload File">

                              {fileName === '' ? 'Upload File' : fileName}
                            </div>
                            <input
                              type="file"
                              ref={myRef1}
                              style={{ display: 'none' }}
                              multiple={false}
                              data-tip="Upload File"
                              onChange={(e) => {
                                upload(e);
                              }}
                              onClick={(e) => {
                                e.currentTarget.value = '';
                              }}
                            />
                            {fileError ? (
                              <p className="error-labels error-message2">{fileError}</p>
                            ) : (
                              ''
                            )}

                          </div>
                        </div>
                        {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i>}

                      </div>
                      <div className="col-md">
                        <Link
                          to="/admin/unit-holders"
                          className="t-3 ml-auto btn btn-primary btn-sm float-right"
                          replace
                        >
                          <i className="fa fa-eye mr-2"></i> View All
                        </Link>
                      </div>
                    </div>
                    {/* </div> */}

                    <div className="table-responsive">
                      <JsonToTable json={fileSomeData} />
                    </div>
                    {remainingCount !== '' && (
                      <>
                        <span className='text-primary'>{'... ' + remainingCount + ' More Rows'}</span><br />
                      </>
                    )}

                    Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>

                    <div className="">
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          UploadTxn();
                        }}
                        disabled={Boolean(loading)}
                      >

                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Save</span>
                        )}
                      </button>
                    </div>

                  </div>
                </div>

              </div>
              {renderErrorPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewFile;