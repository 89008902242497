import React from 'react';
import Particles from 'react-particles-js';

const ParticlesComp = () => {
  

    return (
        <>

           {/* particles */}
    <Particles
     params= {{
      particles:{
        color: {
          value: "#1F8EF1"
          // value: "#ba54f5"
        },
        lineLinked: {
          color: {
            value: "#1F8EF1"
            // value: "#1F8EF1"
            // value: "#fff"
          },
          "enable": true,
          "distance": 150,
          "opacity": 0.4,
          "width": 1
            },
            number: {
              value: 150
            },
            size: {
              value: 5
            },

            "move": {
              "enable": true,
              "speed": 2,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
        
        },

        "interactivity": {
          "detect_on": "canvas",
          "events": {
          "onhover": {
          "enable": true,
          "mode": "grab"
          // "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
          },

          "modes": {
            "grab": {
              "distance": 150,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8, 
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
          

          }
         
        
      }
    } />
        </>
    )
    
}



export default ParticlesComp;