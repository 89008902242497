
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { equitySettlementReport } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { numberWithCommas, sortAMCS } from '../../utils/customFunction';

const EquitySettlementReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  let [equitySettlementData, setEquitySettlementData] = useState<any>([]);
  let [secutitiesEquitySettlementData, setBuyEquitySettlementData] = useState<any>([]);

  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [amc_code, setAmccode] = useState('');
  const [fund, setFund] = useState('');
  const [fund_code, setFundCode] = useState('');
  const [fromSettlementDate, setFromSettlementDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toSettlementDate, setToSettlementDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resfromSettlementDate, setResFromSettlementDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [restoSettlementDate, setResToSettlementDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [totalGrossAmount, setTotalGrossAmount] = useState('');
  const [totalNetAmount, setTotalNetAmount] = useState('');
  const [totalBrokerageCommission, setTotalBrokerageCommission] = useState('');
  const [totalSSTonCommission, setTotalSSTonCommission] = useState('');

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           let sortAMCSByName = await sortAMCS(amcResponse.data.data);
           setAllAmc(sortAMCSByName);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
            }
          }
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_','')}>
                {item.name}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{   
              allFunds.length=0;
              setAllFunds(allFunds);           
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }

  const searchData = async () => {  
    try {
        setLoading(true);
        equitySettlementData.length=0;
        setEquitySettlementData(equitySettlementData)
        const response = await equitySettlementReport(email,amc_code,fund_code,fromSettlementDate, toSettlementDate, 'execution');
        setEquitySettlementData(response.data.data)

        let totalGrossAmount=0, totalNetAmount=0, totalBrokerageCommission=0, totalSSTonCommission=0;
        response.data.data.map(item=>{  
          totalGrossAmount+=parseFloat(item.gross_amount || '0');
          totalNetAmount+=parseFloat(item.net_amount  || '0');
          totalBrokerageCommission+=parseFloat(item.broker_commission || '0');
          totalSSTonCommission+=parseFloat(item.tax_sst || '0');
        })
        setTotalGrossAmount(totalGrossAmount.toFixed(2))
        setTotalNetAmount(totalNetAmount.toFixed(2))
        setTotalBrokerageCommission(totalBrokerageCommission.toFixed(2))
        setTotalSSTonCommission(totalSSTonCommission.toFixed(2))
        setResAmc(Amc);
        setResFund(fund);
        setResFromSettlementDate(fromSettlementDate);
        setResToSettlementDate(toSettlementDate);
        setLoading(false);

    } catch (error:any) {
      if(error.response.data.status===500){
          toast.error(error.response.data.message)
      }else{
        toast.error(error.response.data.message)
      }
        setLoading(false);
    }
  }

  // const numberWithCommas = (amount:any) => {
  //   return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  // }

  const securitiesScripWise=(securities:any)=>{
    let uniqueSymbols = Array.from(new Set(securities.map(x=>x.symbol)));
           let allArraysFiltered = uniqueSymbols.map((uniqueKey :any) =>{
            let filterData = securities.filter(item=>{
            return uniqueKey==item.symbol;
            });
            return filterData;
           }); // end map

          let filteredArraysWithTotalRowAdded: any = []; 
            allArraysFiltered.forEach(filterArray => {
            if(filterArray.length > 1) {
           let rowAdded : any =  filterArray.reduce((row1 , row2) => {
             return {
               type:'~~~'+row1.symbol+' Total:',
              //  type:'Scrip wise Total:',
               net_amount : (parseFloat(row1.net_amount || '0') + parseFloat(row2.net_amount || '0')).toFixed(2) ,
               gross_amount : (parseFloat(row1.gross_amount || '0') + parseFloat(row2.gross_amount || '0')).toFixed(2),
               commission_charges : (parseFloat(row1.commission_charges || '0') + parseFloat(row2.commission_charges || '0')).toFixed(2),
              //  net_rate : (parseFloat(row1.net_rate || '0') + parseFloat(row2.net_rate || '0')).toFixed(2),
               sst_on_commission : (parseFloat(row1.sst_on_commission || '0') + parseFloat(row2.sst_on_commission || '0')).toFixed(2),
              //  commission_rate : (parseFloat(row1.commission_rate || '0') + parseFloat(row2.commission_rate || '0')).toFixed(2),
              //  avg_rate : (parseFloat(row1.avg_rate || '0') + parseFloat(row2.avg_rate || '0')).toFixed(2),
               quantity : (parseFloat(row1.quantity || '0') + parseFloat(row2.quantity || '0')),     
             }
           })
           filteredArraysWithTotalRowAdded.push([...filterArray , rowAdded , {}])
         }else {
           let objToShowInSingle = {
            type:'~~~'+filterArray[0].symbol+' Total:',
            // type:'Scrip wise Total:',
            net_amount : (parseFloat(filterArray[0].net_amount || '0')).toFixed(2) ,
            gross_amount : (parseFloat(filterArray[0].gross_amount || '0')).toFixed(2),
            commission_charges : (parseFloat(filterArray[0].commission_charges || '0')).toFixed(2),
           //  net_rate : (parseFloat(row1.net_rate || '0') + parseFloat(row2.net_rate || '0')).toFixed(2),
            sst_on_commission : (parseFloat(filterArray[0].sst_on_commission || '0')).toFixed(2),
           //  commission_rate : (parseFloat(row1.commission_rate || '0') + parseFloat(row2.commission_rate || '0')).toFixed(2),
           //  avg_rate : (parseFloat(row1.avg_rate || '0') + parseFloat(row2.avg_rate || '0')).toFixed(2),
            quantity : (parseFloat(filterArray[0].quantity || '0')),         
           }
           filteredArraysWithTotalRowAdded.push([...filterArray ,objToShowInSingle, {}])
         }
       });

       let seperatedArray :any = [];
       filteredArraysWithTotalRowAdded.forEach(item => {
          item.forEach(objItem => {
          seperatedArray.push(objItem)
          })
          
        })
        return seperatedArray;

  }

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Equity Settlement Report</h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                      setAmccode(e.target.value);
                        getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                        setFundCode(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>
              
              <Col md="2">
                <div className="form-group  ">
                  <label>Settelment Date From</label>
                  <input
                    type="date"
                    defaultValue={fromSettlementDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromSettlementDate(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Settlement Date To</label>
                  <input
                    type="date"
                    defaultValue={toSettlementDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToSettlementDate(date);
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Equity-Settlement-Report-"+date+".pdf"} ref={pdfExportComponent}>
              {equitySettlementData.length > 0 ? (
                <>
                <ReportHeader title="Equity Settlement Report"/>
                {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Equity Settlement Report</h3>
                  </div> */}
                  <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  From Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resfromSettlementDate).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  To Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(restoSettlementDate).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>

                  {/* <Row>
                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{resAmc}</span>
                        </Col>
                      </Row>
                    </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Fund:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap" >{resFund}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>From Date:</span>
                          </Col>
                          <Col md="">
                            <span className="">{moment(resfromSettlementDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(restoSettlementDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row> */}
                  
                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                            <th>Settlement Date </th>
                            <th>MKT</th>
                            <th>Symbol</th>
                            <th>B/S</th>
                            <th>Quantity</th>
                            <th>Avg Rate</th>
                            <th>Commission</th>
                            <th>Commission Rate</th>
                            <th>Net Rate</th>
                            <th>Gross Amount</th>
                            <th>SST on Commission</th>
                            <th>Net Amount</th>                              
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                      {equitySettlementData.map((item: any, index: number) => {
                        let data : any=[];
                        data=JSON.parse(item.securities);
                        let dataSecutities =securitiesScripWise(data);
                        return (
                          <>
                          <tr className="header-tr">
                            <td colSpan={2}>{'ID: '}<span style={{fontWeight:'bold'}}> {item.txn_id} </span></td>
                            <td colSpan={6}>{'Payee: '}<span style={{fontWeight:'bold'}}>{item.counter_account_title} {' '}</span>({item.counter_account_number}) {item.counter_bank +'-'+ item.counter_branch}</td>
                                                       
                            <td colSpan={2}>{'Settlement By: '}<span style={{fontWeight:'bold'}}>{item.settlement_by}</span></td>
                            <td colSpan={2}>{'Instruction Date: '}<span style={{fontWeight:'bold'}}>{moment(item.instruction_date).format('DD-MM-YYYY')}</span></td>
                            </tr>
                        {dataSecutities.length>0 && dataSecutities.map((i:any, ind:any)=>{
                              // var types=i.type.toString();
                              if(Object.keys(i).length == 0){
                                return (
                                  <>
                                   <tr>
                                     <td colSpan={12}></td>
                                     </tr>
                                  </>
                                )
                              }else if(i.type.startsWith('~~~')){
                                return  Object.keys(i).length>0 && (
                                <tr key={ind}>
                                  <td style={{fontWeight:'bold'}}>{i.type.replace('~~~','')}</td>
                                  
                                  <td style={{fontWeight:'bold'}}>{i.market}</td>
                                  <td style={{fontWeight:'bold'}}>{i.symbol}</td>
                                  <td style={{fontWeight:'bold'}}></td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.quantity==undefined ? '': numberWithCommas(i.quantity)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.avg_rate==undefined ? '': numberWithCommas(i.avg_rate)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.commission_charges==undefined ? '': numberWithCommas(i.commission_charges)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.net_rate==undefined ? '': numberWithCommas(i.net_rate)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.commission_rate==undefined ? '': i.commission_rate}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.gross_amount==undefined ? '': numberWithCommas(i.gross_amount)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.sst_on_commission==undefined ? '': numberWithCommas(i.sst_on_commission)}</td>
                                   <td className="text-right" style={{fontWeight:'bold'}}>{i.net_amount==undefined ? '': numberWithCommas(i.net_amount)}</td>
                                   {/* <td className="text-right" style={{fontWeight:'bold'}}>{i.broker}</td>     */}
                                  </tr>
                                  )
                              }else{
                              return  Object.keys(i).length>0 && (<tr key={ind}>
                              <td>{item.settlement_date}</td>
                              <td>{i.market}</td>
                              <td>{i.symbol}</td>
                               <td>{i.type}</td>
                               <td className="text-right">{i.quantity==undefined ? '': numberWithCommas(i.quantity)}</td>
                               <td className="text-right">{i.avg_rate==undefined ? '': numberWithCommas(i.avg_rate)}</td>
                               <td className="text-right">{i.commission_charges==undefined ? '': numberWithCommas(i.commission_charges)}</td>
                               <td className="text-right">{i.net_rate==undefined ? '': numberWithCommas(i.net_rate)}</td>
                               <td className="text-right">{i.commission_rate==undefined ? '': i.commission_rate}</td>
                               <td className="text-right">{i.gross_amount==undefined ? '': numberWithCommas(i.gross_amount)}</td>
                               <td className="text-right">{i.sst_on_commission==undefined ? '': numberWithCommas(i.sst_on_commission)}</td>
                               <td className="text-right">{i.net_amount==undefined ? '': numberWithCommas(i.net_amount)}</td>
                               {/* <td className="text-right">{i.broker}</td>     */}
                              </tr>)
                              }
                        })} 
                         <tr className="footer-tr">
                         <td style={{fontWeight:'bold'}}>Payment Total</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(item.broker_commission)}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(item.gross_amount)}</td>
                            <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(item.tax_sst)}</td>
                            <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(item.net_amount)}</td>
                            </tr>
                            <tr>
                              <td colSpan={12}></td>
                              </tr>
                        </>
                        )
                        })}
                        {equitySettlementData.length>0 &&(
                                  <tr style={{backgroundColor:"#7eb4d3"}}>
                                  <td style={{fontWeight:'bold'}} colSpan={5}>Grand Total</td>
                                  <td className="text-right" colSpan={2}style={{fontWeight:'bold'}}>{numberWithCommas(totalBrokerageCommission)}</td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(totalGrossAmount)}</td>
                                  <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(totalSSTonCommission)}</td>
                                  <td className="text-right" style={{fontWeight:'bold'}}>{numberWithCommas(totalNetAmount)}</td>
                                  </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
             Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EquitySettlementReport;

