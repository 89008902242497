import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { editChecklistData } from '../../../stores/services/checklist.service';
import { getAllRoles } from '../../../stores/services/role.service';

const EditCheckList = () => {
  const history = useHistory();
  const [serialNo, setSerialNo] = useState('');
  const [title, setTitle] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [serialNoError, setSerialNoError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [roleError, setRoleError] = useState('');
  const [role, setRole] = useState('');
  const [roleData, setRoleData] = useState<any>([]);

  React.useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await getAllRoles(email);
        setRoleData(response.data.data);
      } catch (error) {}
    };
    fetchRole();
  }, []);

  const renderRoleDropdown = () => {
    return (
      roleData &&
      roleData.map((item, index) => {
        return (
          <option key={index} value={item.role_name.replace('ROLE_','')}>
            {item.role_name.replace('ROLE_','')}
          </option>
        );
      })
    );
  };
  const [code, setCode] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('checklistObj') || '');
    setSerialNo(obj.serial_no);
    setTitle(obj.message);
    setRole(obj.role);
    setCode(obj.code);
  }, []);

  const validate = () => {
    let serialErr = '';
    let titleErr,
      roleErr = '';
    serialNo.trim() === '' ? (serialErr = 'Required') : (serialErr = '');
    title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    role.trim() === '' ? (roleErr = 'Required') : (roleErr = '');
    if (serialErr || titleErr || roleErr) {
      setSerialNoError(serialErr);
      setTitleError(titleErr);
      setRoleError(roleErr);
      return false;
    } else {
      return true;
    }
  };
  const AddChecklist = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await editChecklistData(email, serialNo, title, role , code);
        if(response.data.status===200){
        setTitle('');
        setRole('');
        setSerialNo('');
        toast.success(response.data.message);
        sessionStorage.removeItem('checklistObj');
        setTimeout(() => {
          history.replace(`/admin/check-list`);
        }, 2000);
      }else{
        toast.error(response.data.message);
      }
      } catch (error:any) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
            <div className="card-header">
              <div className="title-row">
                <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/check-list')
                  }}></i>Edit - Check List</h4> 
                 <Link
                    to="/admin/check-list"
                    className="btn btn-primary btn-sm ml-auto"
                    replace
                  >
                    <i className="fa fa-eye"></i>&nbsp;View All
                  </Link>
                </div>
            </div>
            <div className="card-body">
              
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleError('');
                        }}
                      />
                      {titleError ? (
                        <p className="error-labels error-message2">
                          {titleError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Role</label>
                      <select
                        className="form-control w-100"
                        value={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Role
                        </option>
                        {renderRoleDropdown()}
                      </select>
                      {roleError ? (
                        <p className="error-labels error-message">
                          {roleError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Serial.# / Sequence # (Number)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Serial.# / Sequence # (Number)"
                        value={serialNo}
                        onChange={(e) => {
                          setSerialNo(e.target.value);
                          setSerialNoError('');
                        }}
                      />
                      {serialNoError ? (
                        <p className="error-labels error-message2">
                          {serialNoError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <div className="form-group">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      history.replace('/admin/check-list');
                    }}
                  >
                    <span>Cancel</span>
                  </button>
            
                  <button
                    className="btn btn-primary ml-3"
                    onClick={AddChecklist}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EditCheckList;
