import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { getFunds } from './../../stores/services/funds.service';
import { getAmc } from '../../stores/services/amc.service';
import { getDailyNavReport } from '../../stores/services/report.service';
import { BsTable } from 'react-icons/bs';	
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { sortAMCS } from '../../utils/customFunction';

const DailyNavReport = (props: any) => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let fulldate = new Date();
  let date= moment(fulldate).format('YYYY-MM-DD')
  const [amcData, setAmcData]= useState<any>([]);
  const [allFunds, setAllFunds]= useState<any>([]);
  const [fundsByAmcData, setFundsByAmcData]= useState<any>([]);
  const [fromDate, setFromDate]= useState(date);
  const [toDate, setToDate]= useState(date);
  const [amcCode, setAmcCode]= useState('');
  const [fund, setFund]= useState('');

  const [resFromDate, setResFromDate]= useState(date);
  const [resToDate, setResToDate]= useState(date);
  const [resAmc, setResAmc]= useState('');
  const [resFund, setResFund]= useState('');

  const [totalRedemptionPrice, setTotalRedemptionPrice] = useState('');
  const [totalOfferPrice, setTotalOfferPrice] = useState('');
  const [totalFundUnits, setTotalFundUnits] = useState('');
  const [totalNetAssets, setTotalNetAssets] = useState('');

  const [dailyNavReport, setDailyNavReport] = useState<any>([]);

React.useEffect(() => {
    const getAMC = async () => {
      try {
        const response = await getAmc(email);
        if (response.data.data.length > 0) {
          let sortAMCByName = await sortAMCS(response.data.data);
            setAmcData(sortAMCByName);
        //   setFundLoading(false);
        } else {
        //   setFundLoading(true);
        }
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }

      try {
        const response = await getFunds(email);
        if (response.data.data.length > 0) {
            setAllFunds(response.data.data)
        //   setFundLoading(false);
        } else {
        //   setFundLoading(true);
        }
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    
    getAMC();
  }, []);

  //code for search data for Reports
  const searchData = async () => {
      setLoading(true);
      dailyNavReport.length=0;
      setDailyNavReport(dailyNavReport);
      setTotalRedemptionPrice('');
      setTotalOfferPrice('');
      setTotalFundUnits('');
      setTotalNetAssets('');
    try {
      const searchResponse = await getDailyNavReport(email, amcCode, fund, fromDate, toDate);
      let data= Object.entries(searchResponse.data.data)
      let array=[] as any;
      data.forEach(([key, value]) => {
        array=value as any;
        dailyNavReport.push(array.Record)
      })
    //   setDailyNavReport(data);
      setResAmc(amcCode);
      setResFund(fund);
      setResToDate(toDate);
      setResFromDate(fromDate);

      let redemPrice=0, offerPrice=0, fundUnits=0, netAssets=0;
      dailyNavReport.map((item:any, index:number)=>{
          redemPrice+=parseFloat(item.redemption_price || '0');
          offerPrice+=parseFloat(item.offer_price || '0');
          fundUnits+=parseFloat(item.total_fund_units || '0');
          netAssets+=parseFloat(item.total_net_asset || '0');
      });

      setTotalRedemptionPrice(redemPrice.toFixed(4));
      setTotalOfferPrice(offerPrice.toFixed(4));
      setTotalFundUnits(fundUnits.toFixed(4));
      setTotalNetAssets(netAssets.toFixed(4));
        
      setLoading(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
        setLoading(false);
    }
  };

const renderAmcDropdown = () => {
    return amcData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_','')}>
          {item.name}-{item.amc_code.replace('AMC_','')}
        </option>
      );
    });
  };

  const renderFundsByAmc= (amccode)=>{
      let AmcFunds= allFunds.filter((item: any, index: string)=>{
       return amccode===item.amc_code;
      });
      if(AmcFunds.length>0){
      setFundsByAmcData(AmcFunds);
    }else{
        setFundsByAmcData(AmcFunds);
    }
  }

  const renderFundsDropdown = () => {
    return fundsByAmcData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code.replace('FUND_','')}>
          {item.fund_name}-{item.symbol_code.replace('FUND_','')}
        </option>
      );
    });
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Daily NAV Report</h4>
            </div>

            <div className="card-body">

            <Row>
              <Col md="3">
                <div className="form-group">
                  <label> Select AMC</label>
                  <select
                    className="form-control"
                    
                    onChange={(e) => {
                        renderFundsByAmc(e.target.value);
                        setAmcCode(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>
              <Col md="3">
                <div className="form-group  ">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    
                    onChange={(e) => {
                      setFund(e.target.value)
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                    {renderFundsDropdown()}
                    </select>
                </div>
              </Col>
              <Col md="3">
                <div className="form-group  ">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="form-group  ">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => {
                        setToDate(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            
            <div className="mt-4">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            {/* End */}
          </div>
          </div>
         
          <div className="card"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Daily-Nav-Report-"+date+".pdf"} ref={pdfExportComponent}>
                {dailyNavReport.length>0 ? (
                  <>
                   <ReportHeader title="Daily NAV Report"/>

                  {/* <div className="title-row d-flex justify-content-center">
                  <img src={DCClogo} alt="DCC-Logo" height="150" />
                  </div> */}
                   {/* <div className="title-row mt-4 d-flex justify-content-center">
                     <h3 className="text-black">Daily Nav Report</h3>
                   </div> */}
 
                   <Row>
                     <Col md="4" style={{ marginLeft: '70px' }}>
                       <Row>
                         <Col md="5">
                           <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                             AMC:
                           </span>
                         </Col>
                         <Col md="6">
                           <span className="text-nowrap">{resAmc}</span>
                         </Col>
                       </Row>
                     </Col>
 
                       <Col md="4" style={{ marginLeft: '70px' }}>
                         <Row>
                           <Col md="5">
                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                               Fund:
                             </span>
                           </Col>
                           <Col md="6">
                             <span className="text-nowrap" >{resFund}</span>
                           </Col>
                         </Row>
                       </Col>
 
                       <Col md="4" style={{ marginLeft: '70px' }}>
                         <Row>
                           <Col md="5">
                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                               From Date:
                             </span>
                           </Col>
                           <Col md="6">
 
                             <span className="text-nowrap">{resFromDate}</span>
                           </Col>
                         </Row>
                       </Col>
 
                       <Col md="4" style={{ marginLeft: '70px' }}>
                         <Row>
                           <Col md="5">
                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                               To Date:
                             </span>
                           </Col>
                           <Col md="6">
                             <span className="text-nowrap">{resToDate}</span>
                           </Col>
                         </Row>
                       </Col>
 
                       <Col md="4" style={{ marginLeft: '70px' }}>
                         <Row>
                           <Col md="5">
                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                           </Col>
                           <Col md="6">
                             <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                           </Col>
                         </Row>
                       </Col>
 
                   </Row>
                  <div
                   style={{
                     overflowX: 'auto',
                     overflowY: 'hidden',
                   }}
                 >
          <table className="report" style={{ color: 'black' }}>
              <thead style={{ alignSelf: 'center' }}>
              <tr>
                  {/* <th>AMC</th>
                  <th>Fund</th> */}
                  <th>Nav Date</th>
                  <th className="text-right">Nav</th>
                  <th className="text-right">Offer price</th>
                  <th className="text-right">Redemption Price</th>
                  <th className="text-right">Total Fund Units</th>
                  <th className="text-right">Total Net Assets</th>
              </tr>
              </thead>
              <tbody className="report-table-body">
              {dailyNavReport.map((dailynav: any, index: number) => {	
                return (	
                  <tr key={index}>
                    {/* <td>{dailynav.amc_code}</td>
                    <td>{dailynav.fund}</td> */}
                    <td>{moment(dailynav.nav_date).format('DD-MM-YYYY')}</td>
                    <td className="text-right">{dailynav.nav}</td>
                    <td className="text-right">{dailynav.offer_price}</td>
                    <td className="text-right">{dailynav.redemption_price}</td>
                    <td className="text-right">{dailynav.total_fund_units}</td>
                    <td className="text-right">{dailynav.total_net_asset}</td>
                  </tr>
                 );	
                })}
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    <td></td>
                    <td className="text-right" style={{ fontWeight: 'bold' }}>{totalOfferPrice}</td>
                    <td className="text-right" style={{ fontWeight: 'bold' }}>{totalRedemptionPrice}</td>
                    <td className="text-right" style={{ fontWeight: 'bold' }}>{totalFundUnits}</td>
                    <td className="text-right" style={{ fontWeight: 'bold' }}>{totalNetAssets}</td>
                  </tr>	
              </tbody>

          </table>
          </div>
          </>
          ):(
            <div className="table-empty">	
            <BsTable className="icon" />	
            <p className="text-center empty-text">No record found</p>	
          </div>
          )}
          </PDFExport>
          </div>
          <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
             Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyNavReport;
