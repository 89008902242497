import React, { useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFundByAmc,
  getFunds,
  getInstrumentType,
  getFundByAMCandFundCode,
} from "../../../stores/services/funds.service";
import { getAmc, getAmcByCode } from "../../../stores/services/amc.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { isNumber, numberWithCommas } from "../../../utils/customFunction";
import { BsTable } from "react-icons/bs";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import ReportHeader from "../../report/report-header";
import DCClogo from "../../../assets/img/DCC-Logo.svg";
import { Modal } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';


import MACSLogo from "../../../assets/img/MACS-Logo.svg";
import {
  FaTwitterSquare,
  FaEnvelope,
  FaArrowLeft,
  FaEye,
} from "react-icons/fa";
import { GiSevenPointedStar } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { RiEdit2Fill } from "react-icons/ri";
import Select from "react-select";

import { DiWebplatform } from "react-icons/di";
import {
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import {
  searchInvoice,
  sendInvoice,
  updateBalance,
  updateStatusInvoice,
} from "../../../stores/services/invoice.service";
import { getModeOfPayments } from "../../../stores/services/transactions.service";
import NumberFormat from "react-number-format";

const SetupInvoice = () => {
  let date = new Date();
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState("");
  const [fund, setFund] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("13");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [viewInVoice, setViewInVoice] = useState(false);
  const [period, setPeriod] = useState("");
  const [allPeriod, setAllPeriod] = useState([]);
  const [refreshFund, setrefreshFund] = useState(false);
  const [AmcFundName, setAMCFundName] = useState("");
  const [allAMCFund, setAllAMCFund] = useState<any>([]);
  const [allInvoice, setAllInvoice] = useState<any>([]);
  const [invoiceStatus, setInvoiceStatus] = useState("active");
  const [MOPData, setMOPData] = useState<any>([]);
  const [MOP, setMOP] = useState("");
  const [iTypeData, setITypeData] = useState<any>([]);
  const [instrument, setInstrument] = useState("");
  const [instrumentNo, setInstrumentNo] = useState("");
  const [amountRecieved, setAmountRecieved] = useState(0);
  const [instrumentDate, setInstrumentDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState<any>([
    {
      label: "Active",
      value: "active",
    },
    {
      label: "ALL",
      value: "all",
    },
  ]);

  const [errorShow, setErrorShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [invoiceViewModal, setInvoiceViewModal] = useState(false);
  const [invoiceViewData, setInvoiceViewData] = useState<any>({});
  const [previousBalance, setPreviousBalance] = useState("0");
  const [amountPay, setAmountPay] = useState("");
  const [invoiceIDPay, setInvoiceIDPay] = useState("");
  const email = sessionStorage.getItem("email") || "";
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [currentInvoiceStatus, setCurrentInvoiceStatus] = useState("");
  const [currentInvoiceID, setCurrentInvoiceID] = useState("");
  const [ccEmail, setCCEmail] = useState<any>([]);
  const [toEmail, setToEmail] = useState<any>([]);
  const [sendEmailModalView, setSendEmailModalView] = useState(false);
  const [flagPDF, setFlagPDF] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  var regExp = /[a-zA-Z]/g;
  let sum_amount = 0;
  let sum_tax = 0;
  let sum_net_amount = 0;
  let sum_balance = 0;
  let sumInvoiceViewNetAmount = 0;
  let sumInvoiceViewTaxAmount = 0;
  let sumInvoiceViewGrossAmount = 0;
  const history = useHistory();

  React.useEffect(() => {
    const getAllFunds = async () => {
      setAMCFundName("");
      setPeriod("");
      setInvoiceStatus("active");
      setPaymentModal(false);
      setAmountPay("");
      allInvoice.length = 0;
      setAllInvoice(allInvoice);
      setUpdateLoading(false);
      setInvoiceViewModal(false);
      setFlagPDF(false);
      setSendEmailModalView(false);
      const respo = await getModeOfPayments(email);
      setMOPData(respo.data.mode_of_payment);
      const inTypeResponse = await getInstrumentType(email);
      setITypeData(inTypeResponse.data.instrument_types);

      const response = await getFunds(email);
      let tempAmc = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.amc_code} - ${val.symbol_code}`,
          value: `${val.amc_code} - ${val.symbol_code}`,
        };
      });
      setAllAMCFund(tempAmc);
    };

    getAllFunds();
  }, [refreshFund]);

  const renderPeriodDropDown = () => {
    return allPeriod.map((item: any, index: number) => {
      return (
        <option key={index} value={item.period}>
          {item.period}
        </option>
      );
    });
  };
  const getPeriod = async (value: any) => {
    try {
      const response = await searchInvoice(
        email,
        value.split("-")[0],
        value.split("-")[1],
        "active",
      );
      setAllPeriod(response.data.data);

    } catch (error) { }
  };
  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  const searchData = async () => {
    let response;
    if (period) {
      response = await searchInvoice(
        email,
        AmcFundName.split("-")[0],
        AmcFundName.split("-")[1],
        invoiceStatus,
        period
      );
    } else {
      response = await searchInvoice(
        email,
        AmcFundName.split("-")[0],
        AmcFundName.split("-")[1],
        invoiceStatus
      );
    }

    if (response.data.status == 200) {
      setAllInvoice(response.data.data);
    } else {
      allInvoice.length = 0;
      setAllInvoice([]);
      toast.error(response.data.message);
    }
  };

  const UpdateStatus = async () => {
    setUpdateStatusLoading(true);
    let status = "";
    if (currentInvoiceStatus === "active") {
      status = "inactive";
    } else {
      status = "active";
    }
    const response = await updateStatusInvoice(email, currentInvoiceID, status);

    if (response.data.status == 200) {
      setUpdateStatusLoading(false);
      setUpdateStatusModal(false);
      toast.success(response.data.message);

      searchData();
    } else {
      setUpdateStatusLoading(false);
      toast.error(response.data.message);
    }
  };
  const renderUpdateModal = () => {
    switch (updateStatusModal) {
      case true:
        return (
          <Modal className="" enforceFocus={false} dialogClassName="modal60w" show={true}>
            <div className="modal-header justify-content-center">
              <button
                aria-hidden="true"
                className="close"
                onClick={() => {
                  setUpdateStatusModal(false);
                }}
                data-dismiss="modal"
                type="button"
              >
                <i className="tim-icons icon-simple-remove"></i>
              </button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentInvoiceStatus === "active" ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to
                    {currentInvoiceStatus === "active"
                      ? " Deactivate "
                      : " Activate "}
                    this Invoice?
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(updateStatusLoading)}
                        onClick={() => {
                          UpdateStatus();
                        }}
                      >
                        {updateStatusLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setUpdateStatusModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };
  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderModalPayment = () => {
    switch (paymentModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setPaymentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Payment Detail</h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <div className="col-md-6">
                  <label>Previous Balance</label>

                  <NumberFormat
                    thousandSeparator={true}
                    className={"form-control text-right"}
                    value={parseFloat(previousBalance.replaceAll(',', '') || '').toFixed(2)}
                    readOnly={true}
                  />
                </div>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Amount</label>
                    <NumberFormat
                      className={"form-control text-right"}
                      thousandSeparator={true}
                      value={parseFloat(amountPay || '').toFixed(2)}
                      onValueChange={(e) => {
                        setAmountPay(e.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="col-md-6">
                  <label>Remaining Balance</label>
                  <input
                    type="text"
                    className={"form-control text-right"}
                    value={
                      numberWithCommas(
                        (parseFloat(
                          previousBalance
                            ? previousBalance.toString().replace(/,/g, "")
                            : "0"
                        ) -
                          parseFloat(
                            amountPay
                              ? amountPay.toString().replace(/,/g, "")
                              : "0"
                          )).toFixed(2) === '-0.00' ? '0.00' : (parseFloat(
                            previousBalance
                              ? previousBalance.toString().replace(/,/g, "")
                              : "0"
                          ) -
                            parseFloat(
                              amountPay
                                ? amountPay.toString().replace(/,/g, "")
                                : "0"
                            )).toFixed(2)
                      )}
                    readOnly={true}
                  />
                </div>

                <div className="col-md-6">
                  <label>Mode of Payment</label>

                  <select
                    value={MOP}
                    className={"form-control"}
                    onChange={(e) => {
                      setMOP(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select Payment
                    </option>
                    {renderModeOfPayments()}
                  </select>
                </div>
              </Row>
              <Row>
                <div className="col-md-6">
                  <label>Instrument Type</label>
                  <select
                    className={"form-control w-100 "}
                    value={instrument}
                    onChange={(e) => {
                      setInstrument(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select Instrument Type
                    </option>
                    {renderiTypeDataDropdown()}
                  </select>
                </div>

                <Col>
                  <label>Instrument No</label>
                  <input
                    value={instrumentNo}
                    type="text"
                    className={"form-control w-100 "}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.length < 21) {
                        setInstrumentNo(value);
                      }
                    }}
                  />
                </Col>

                <Col>
                  <label>Instrument Date</label>
                  <input
                    type="date"
                    defaultValue={instrumentDate}
                    className={"form-control w-100 "}
                    onChange={(e) => {
                      setInstrumentDate(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <div className="col-md-6">
                  <br />

                  <button
                    className="btn btn-primary mt-2"
                    style={{ width: "100%" }}
                    disabled={updateLoading}
                    onClick={() => {
                      updateInvoiceBalance();
                    }}
                  >
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>
                      {updateLoading ? "Updating..." : "Update Balance"}
                    </span>
                  </button>
                </div>
              </Row>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderModalSendEmail = () => {
    switch (sendEmailModalView) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="sm"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setCCEmail([])
                  setSendEmailModalView(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Email Detail</h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <Col>
                  <div className="form-group  ">
                    <label>To</label>
                    <input
                      type="text"
                      placeholder="Recipients"
                      className={"form-control"}
                      value={toEmail.toString()}
                      onChange={(e) => {
                        let temp = e.target.value.split(",");
                        setToEmail([...temp]);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group  ">
                    <label>CC</label>
                    <input
                      type="text"
                      placeholder="CC"
                      className={"form-control"}
                      value={ccEmail.toString()}
                      onChange={(e) => {
                        let temp = e.target.value.split(",");
                        setCCEmail([...temp]);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setFlagPDF(!flagPDF);
                        }}
                      />
                    </div>
                    <span style={{ fontWeight: "bold" }}>Attach as PDF</span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button
                    className="btn btn-primary mt-2"
                    style={{ width: "100%" }}
                    disabled={emailLoading}
                    onClick={() => {
                      EmailInvoice()
                    }}
                  >
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>{emailLoading ? "Sending..." : "Send Email"}</span>
                  </button>
                </Col>
              </Row>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderModalViewInvoice = () => {
    switch (invoiceViewModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setInvoiceViewModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Invoice</h6>
              </div>
            </div>

            <div className="modal-body">
              <div
                id="printDiv"
                className="p-4"
                style={{ background: "white", color: "black" }}
              >
                <PDFExport
                  paperSize="A4"
                  scale={0.6}
                  margin={0}
                  repeatHeaders={false}
                  fileName={"Generate Invoice.pdf"}
                  ref={pdfExportComponent}
                  keepTogether="div"
                 
                  
                  

                >
                  <>
                    <Row>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div>
                          <img
                            src={DCClogo}
                            alt="DCC-Logo"
                            className=""
                            height="150px"
                          />
                        </div>

                        {/* <div
                          style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight: "bolder",
                            marginTop: "20px",
                            marginBottom: "5px",
                          }}
                        >
                          INVOICE TO
                        </div> */}
                         <div className="mb-3" style={{ color: "black", fontSize: "14px" }}>
                          {invoiceViewData?.billing_name}
                        </div>
                        <div style={{ color: "black", fontSize: "14px" }}>
                          {invoiceViewData?.fund_code}
                        </div>
                       
                        <div style={{ color: "black", fontSize: "14px" }}>
                          {invoiceViewData?.amc_code}
                        </div>
                        {/* <div style={{ color: "black", fontSize: "14px" }}>
                          [Street Adress], [City]
                        </div> */}
                        <div style={{ color: "black", fontSize: "14px" }}>
                          {invoiceViewData?.contact_number}
                        </div>
                        <br />
                        <div style={{ color: "black", fontSize: "14px", border:'1px solid rgba(0,0,0,0.2)', width:"fit-content", padding:'5px 10px 5px 5px' }}>
                          Customer NTN: {invoiceViewData?.billing_ntn}
                        </div>
                      </Col>

                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "right",
                        }}
                        xs="5"
                        sm="6"
                        md="5"
                        lg="6"
                        xl="4"
                      >
                        <div
                          style={{
                            border: "2px solid #4f81bd",
                            marginTop: "80px",
      
                            paddingBottom: "30px",
                            paddingRight: "20px",
                            paddingLeft: "5px",
                            width:'fit-content'
                            
                            
                          }}
                        >
                          <div
                            style={{
                              fontSize: "24px",
                              color: "white",
                              fontWeight: "bolder",

                              paddingBottom: "8px",
                              paddingTop: "8px",
                              textAlign: "center",
                              background: "#4f81bd",
                              marginRight: "-20px",
                              marginLeft: "-5px",
                            }}
                          >
                            I N V O I C E
                          </div>
                          <div
                         className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            <div className="col-md-6">
                            Invoice #
                            </div>
                            <div className="col-md-6">
                            <b>{invoiceViewData?.id}</b>
                            </div>
                             
                          </div>
                          <div
                          className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                             <div className="col-md-6">Invoice Date:</div>
                             <div className="col-md-6"><b>
                              {moment(invoiceViewData.created_at).format(
                                "DD-MMM-YYYY"
                              )}
                            </b></div>
                            
                            
                          </div>
                          <div
                          className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <div className="col-md-6">Invoice Month:</div>

                            <div className="col-md-6"><b>{invoiceViewData?.period}</b></div>
                             
                          </div>
                          <div
                          className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <div className="col-md-6">Due Date:</div>

                            <div className="col-md-6"><b>{invoiceViewData?.due_date}</b></div>
                             
                          </div>
                          <br />
                          <div
                          className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <div className="col-md-6">NTN/SNTN No:</div>

                            <div className="col-md-6"><b>0698133-0</b></div>
                             
                          </div>
                          {/* <div
                          className="row text-nowrap"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <div className="col-md-6">Tariff Headin:</div>

                            <div className="col-md-6"><b>9805.900</b></div>
                             
                          </div> */}
                        </div>
                      </Col>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                    </Row>
                    <div
                      style={{
                        overflowX: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      <table className="invoice">
                        <thead
                          style={{
                            alignSelf: "center",
                            textAlign: "center",

                          }}
                        >
                          <tr>
                            <th style={{ width: '40px', fontSize: '14px' }}>S.No.</th>
                            <th style={{ width: "40%", fontSize: '14px' }}>DESCRIPTION</th>
                            <th style={{ fontSize: '14px' }}>GROSS AMOUNT</th>
                            <th style={{ fontSize: '14px', width: "10%" }}>TAX RATE</th>
                            <th style={{ whiteSpace: "nowrap", fontSize: '14px' }}>TAX AMOUNT</th>
                            <th style={{ fontSize: '14px' }}>NET AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody className="invoice-table-body">
                          {invoiceViewData &&
                            invoiceViewData?.head_detail &&
                            invoiceViewData?.head_detail != "" &&
                            JSON.parse(invoiceViewData?.head_detail).map(
                              (item, index) => {
                                sumInvoiceViewTaxAmount =
                                  parseFloat(
                                    item.tax_amount
                                      ? item.tax_amount
                                        .toString()
                                        .replaceAll(",", "")
                                      : "0"
                                  ) + sumInvoiceViewTaxAmount;
                                sumInvoiceViewGrossAmount =
                                  parseFloat(
                                    item.gross_amount
                                      ? item.gross_amount
                                        .toString()
                                        .replaceAll(",", "")
                                      : "0"
                                  ) + sumInvoiceViewGrossAmount;
                                sumInvoiceViewNetAmount =
                                  parseFloat(
                                    item.net_amount
                                      ? item.net_amount
                                        .toString()
                                        .replaceAll(",", "")
                                      : "0"
                                  ) + sumInvoiceViewNetAmount;
                                return (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        borderLeft: "1px solid #4f81bd",

                                      }}
                                    >
                                      {index + 1}
                                    </td>
                                    <td>{item.head_name}</td>
                                    <td className="text-right" style={{ overflowWrap: "break-word", wordBreak: "break-all", hyphens: 'auto', paddingRight: "10px" }}>
                                      {numberWithCommas(
                                        parseFloat(
                                          item.gross_amount
                                            ? item.gross_amount
                                              .toString()
                                              .replaceAll(",", "")
                                            : "0"
                                        ).toFixed(2)
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.tax_type} {parseFloat(item.tax_rate || "0").toFixed(2)}%
                                    </td>
                                    <td className="text-right" style={{ overflowWrap: "break-word", wordBreak: "break-all", hyphens: 'auto', paddingRight: "10px" }}>
                                      {numberWithCommas(
                                        parseFloat(
                                          item.tax_amount
                                            ? item.tax_amount
                                              .toString()
                                              .replaceAll(",", "")
                                            : "0"
                                        ).toFixed(2)
                                      )}
                                    </td>
                                    <td className="text-right" style={{ overflowWrap: "break-word", wordBreak: "break-all", hyphens: 'auto', paddingRight: "10px" }}>
                                      {numberWithCommas(
                                        parseFloat(
                                          item.net_amount
                                            ? item.net_amount
                                              .toString()
                                              .replaceAll(",", "")
                                            : "0"
                                        ).toFixed(2)
                                      )}
                                    </td>

                                  </tr>
                                );
                              }
                            )}
                          {
                            amountRecieved !== 0 && (
                              <tr>
                                <td style={{
                                  textAlign: "center",
                                  borderLeft: "1px solid #4f81bd",
                                }}>{JSON.parse(invoiceViewData?.head_detail).length + 1}</td>
                                <td>Payment Recieved</td>
                                <td className="text-right" style={{ overflowWrap: "break-word", hyphens: 'auto', paddingRight: "10px" }}>{numberWithCommas(
                                  parseFloat(
                                    amountRecieved
                                      ? amountRecieved
                                        .toString()
                                        .replaceAll(",", "")
                                      : "0"
                                  ).toFixed(2)
                                )}</td>
                                <td></td>
                                <td></td>
                                <td className="text-right" style={{ overflowWrap: "break-word", hyphens: 'auto', paddingRight: "10px" }}>{numberWithCommas(
                                  parseFloat(
                                    amountRecieved
                                      ? amountRecieved
                                        .toString()
                                        .replaceAll(",", "")
                                      : "0"
                                  ).toFixed(2)
                                )}</td>
                              </tr>
                            )
                          }

                        </tbody>

                        <thead style={{ alignSelf: "center", }}>
                          <tr>
                            <th></th>
                            <th style={{ fontSize: '12px' }}>Total</th>
                            <th className="text-right" style={{ fontSize: '12px', overflowWrap: "break-word", hyphens: 'auto', paddingRight: "10px" }}>

                              {sumInvoiceViewGrossAmount - amountRecieved >= 0 ? numberWithCommas(
                                parseFloat(
                                  (sumInvoiceViewGrossAmount - amountRecieved)
                                    .toString()
                                    .replaceAll(",", "")
                                ).toFixed(2)
                              ) : "0.00"}
                            </th>
                            <th className="text-right"></th>
                            <th className="text-right" style={{ fontSize: '12px', overflowWrap: "break-word", hyphens: 'auto', paddingRight: "10px" }}>
                              {numberWithCommas(
                                parseFloat(
                                  sumInvoiceViewTaxAmount
                                    .toString()
                                    .replaceAll(",", "")
                                ).toFixed(2)
                              )}
                            </th>
                            <th className="text-right" style={{ fontSize: '12px', overflowWrap: "break-word", hyphens: 'auto', paddingRight: "10px" }}>
                              {numberWithCommas(
                                parseFloat(
                                  (parseFloat(sumInvoiceViewNetAmount.toFixed(2)) - parseFloat(amountRecieved.toFixed(2)))
                                    .toString()
                                    .replaceAll(",", "")
                                ).toFixed(2)
                              )}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>

                    {/* Payment Instruction */}
                    {/* <Row style={{ marginTop: "30px", fontSize: "18px" }}>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div style={{ fontWeight: "bolder" }}>
                          PAYMENT INSTRUCTIONS
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div style={{ fontSize: "16px" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;1. Please send payment within
                          the due date
                        </div>
                      </Col>
                    </Row> */}

                    {/* Payment Details */}
                    <Row style={{ marginTop: "17rem",}}>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div style={{ fontWeight: "bolder" }}>
                          Please find below the account details for payment: 
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col md="3">
                        <div >
                          Benef Name
                          {/* <span style={{ fontWeight: "bolder" }}>
                            &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;&ensp;&ensp;&ensp;Digital
                            Custodian Company Limited
                          </span> */}
                        </div>
                      </Col>
                      <Col >
                      <div style={{fontWeight:"bold"}}>Digital Custodian Company Limited</div>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col md="3">
                        <div>
                          Bank Name and Branch Address
                          {/* <span style={{ fontWeight: "bolder" }}>
                            &emsp;&emsp;&emsp;MCB Bank - Main Branch Karachi
                          </span> */}
                        </div>
                      </Col>
                      <Col >
                      <div style={{fontWeight:"bold"}}>
                      MCB Bank - Main Branch Karachi
                      </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div style={{ fontSize: "16px" }}>
                          Beneficiary Account Number
                          <span style={{ fontWeight: "bolder" }}>
                            &emsp;4009372741009362
                          </span>
                        </div>
                      </Col>
                    </Row> */}
                    <Row className="mb-2">
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col md="3">
                        <div>
                          IBAN Number
                          {/* <span style={{ fontWeight: "bolder" }}>
                            &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;&ensp;&emsp;&emsp;&nbsp;PK45MUCB4009372741009362
                          </span> */}
                        </div>
                      </Col>
                      <Col>
                      <div style={{fontWeight:"bold"}} >
                      PK45MUCB4009372741009362
                      </div>
                      </Col>
                    </Row>
                   <Row>
                   <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                    <Col>
                      <div style={{fontWeight:"bold", lineHeight:1}}>
                        In case of direct deposit,please send payment detail on below email ids.
                      </div>
                      </Col>
                      </Row>
                      <Row>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                      <div style={{lineHeight:1}}>
                        adeel.rehman@digitalcustodian.co
                      </div>
                      </Col>
                      </Row>
                      <Row>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                      <div style={{lineHeight:1}}>
                        adeel.aslam@digitalcustodian.co
                      </div>
                      </Col>
                      </Row>
                

                    {/* Thanks Message */}
                    {/* <Row style={{ marginTop: "30px" }}>
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "30px",
                            color: "#4f81bd",
                            marginTop: "50px"
                          }}
                        >
                          Thank you for your business!
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <br />
                     
                    </Row>
                    <Row >
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col>
                        <div
                          style={{
                            borderTop: "2px solid #4f81bd",
                            // textAlign: "center",
                            fontSize: "9px",
                          }}
                          className="pt-1"
                        >
                          {" "}
                          <span >
                            Note: This is a computer-generated invoice and doest
                            not require signature
                          </span>{" "}
                        </div>
                      </Col>

                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                    </Row>
                    <Row>
                      <br />
                     
                    </Row>
                    <div className="row ml-2 mt-2">
                 
                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                      <Col
                        style={{
                          borderLeft: "1px solid #4f81bd",
                          color: "#4f81bd",
                          // marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          ONLINE
                        </div>
                        <div style={{ fontSize: "16px" }}>
                          <span>
                            {" "}
                            <AiOutlineWhatsApp color="#4f81bd" size={"20px"} />
                          </span>{" "}
                          +923-111-322-228
                        </div>
                        <div style={{ fontSize: "16px" }}>
                          <span>
                            {" "}
                            <DiWebplatform color="#4f81bd" size={"20px"} />
                          </span>{" "}
                          digitalcustodian.co
                        </div>
                        <div style={{ fontSize: "16px" }}>
                          {" "}
                          <span>
                            {" "}
                            <AiOutlineFacebook
                              color="#4f81bd"
                              size="20px"
                            />{" "}
                            <FaTwitterSquare color="#4f81bd" size="20px" />{" "}
                            <AiOutlineInstagram color="#4f81bd" size="20px" />{" "}
                            <AiOutlineLinkedin color="#4f81bd" size="20px" />{" "}
                            <AiOutlineYoutube color="#4f81bd" size="20px" />
                          </span>{" "}
                          /digitalcustodian
                        </div>
                      </Col>
                      <Col
                        style={{
                          borderLeft: "1px solid #4f81bd",
                          color: "#4f81bd",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          LAHORE
                        </div>
                        <div style={{ fontSize: "16px" }}>LSE Plaza, 508</div>
                        <div style={{ fontSize: "16px" }}>
                          Kashmir Egerton Road
                        </div>
                        <div style={{ fontSize: "16px" }}>+92 42 3630 4406</div>
                      </Col>
                      <Col
                        style={{
                          borderLeft: "1px solid #4f81bd",
                          color: "#4f81bd",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          KARACHI
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "initial",
                          }}
                        >
                          Pardesi House
                        </div>
                        <div style={{ fontSize: "16px" }}>Old Queens Road</div>
                        <div style={{ fontSize: "16px" }}>+92 21 3241 9770</div>
                      </Col>

                      <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                  
                    </div>
                  </>
                </PDFExport>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-primary mt-3 mr-3"
                  onClick={() => {
                    if (pdfExportComponent.current) {
                      pdfExportComponent.current.save();
                    }
                  }}
                >
                  Print
                </button>
                <button
                  className="btn btn-primary mt-3 ml-3"
                  onClick={() => {


                    setSendEmailModalView(true);
                  }}
                >
                  Email
                </button>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const updateInvoiceBalance = async () => {
    try {
      setUpdateLoading(true);
      let balance_detail = {
        email: email,
        previoud_balance: parseFloat(previousBalance.replaceAll(',', '') || "0").toFixed(2).toString(),
        amount_pay: amountPay,
        mode_of_payment: MOP,
        instrument_type: instrument,
        instrumentNo: instrumentNo,
        instrumentDate: instrumentDate,
        payment_date: moment(date).format("YYYY-MM-DD"),
      };
      let response = await updateBalance(
        email,
        invoiceIDPay,
        parseFloat(
          amountPay ? amountPay.toString().replaceAll(",", "") : "0"
        ).toFixed(2),
        JSON.stringify(balance_detail)
      );
      if (response.data.status == 200) {
        setAmountPay("");
        setInvoiceIDPay("");
        toast.success(response.data.message);
        setPaymentModal(false);
        setrefreshFund(!refreshFund);
        setUpdateLoading(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err: any) {
      setUpdateLoading(false);
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const getContactDetails = async (fund_code, amc_code, data) => {
    try {
      let temp = { ...data };
      let responseFund = await getFundByAMCandFundCode(
        email,
        amc_code,
        fund_code
      );
      if (
        responseFund.data.status == 200 &&
        responseFund.data.data.length > 0
      ) {
        temp["fund_code"] = responseFund.data.data[0].fund_name;
      }
      let responseAmc = await getAmcByCode(email, amc_code);
      if (responseAmc.data.status == 200 && responseAmc.data.data.length > 0) {
        temp["amc_code"] = responseAmc.data.data[0].name;
        let contact =
          responseAmc.data.data[0].billing_contact &&
            responseAmc.data.data[0].billing_contact != ""
            ? JSON.parse(responseAmc.data.data[0].billing_contact)
            : [];
        if (contact.length > 0) {

          let sum: any = data?.payment_detail && data?.payment_detail != ""
            ? JSON.parse(data?.payment_detail).reduce(function (accumulator, curValue) {

              return accumulator + (parseFloat(curValue.amount_pay.replaceAll(',', '') || '0') || 0)

            }, 0) : 0;
          setAmountRecieved(sum);
          temp["billing_ntn"] = responseAmc.data.data[0].ntn;
          temp["billing_name"] = contact[0].name;
          temp["billing_contact"] = contact[0].contact_number;
          let allEmail = contact.map((x) => x.email);
          setInvoiceViewData(temp);
          setInvoiceViewModal(true);
          setToEmail([...allEmail]);
        } else {
          toast.error("Please Add Billing Contact Details in AMC");
        }
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const EmailInvoice = async () => {
    setEmailLoading(true);
    if (flagPDF) {
      const element = document.getElementById("printDiv") as any;

      drawDOM(element, { paperSize: "A4", scale: 0.5 })
        .then((group) => {
          return exportPDF(group);
        })
        .then((dataUri) => {

          SendEmail(dataUri.split(";base64,")[1], JSON.stringify(invoiceViewData));
        });
    } else {
      let temp = { ...invoiceViewData }
      if (amountRecieved !== 0) {

        let heads = JSON.parse(temp.head_detail)
        heads.push({ net_amount: amountRecieved.toString(), gross_amount: amountRecieved.toString(), head_name: "Payment Recieved" })
        temp.head_detail = JSON.stringify(heads)
        //setInvoiceViewData(temp)



      }
      SendEmail(JSON.stringify(temp), "")

    }
  };
  const SendEmail = async (data: string, pdfdetail: string = "") => {
    try {

      let response = await sendInvoice(
        email,
        JSON.stringify(ccEmail),
        JSON.stringify(toEmail),
        flagPDF.toString(),
        data,
        pdfdetail
      );
      if (response.data.status == 200) {
        toast.success(response.data.message);
        setEmailLoading(false);
        setSendEmailModalView(false);
        setCCEmail([])
        setFlagPDF(false);
        //setInvoiceViewModal(false)

      } else {
        toast.error(response.data.message);
        setEmailLoading(false);
      }
    } catch (err: any) {
      setEmailLoading(false);
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Invoices</h4>
              </div>

              <div className="card-body">
                <Row>
                  <div className="col-md-2">
                    <label>Fund</label>
                    <div
                      className={"form-group " + ErrorBorder(AmcFundName)}
                      style={{ border: "1px solid #4f81bd" }}
                    >
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={"Select AMC Funds"}
                        isLoading={allAMCFund.length > 0 ? false : true}
                        onChange={(e) => {
                          setAMCFundName(e.value);
                          allInvoice.length = 0;
                          setAllInvoice(allInvoice);
                          setPeriod("");
                          getPeriod(e.value);
                        }}
                        value={allAMCFund.filter(
                          (option) => option.value === AmcFundName
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={allAMCFund}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </div>

                  <Col md="2">
                    <div className="form-group  ">
                      <label>Select Period</label>
                      <select
                        className={"form-control " + ErrorBorder(period)}
                        onChange={(e) => {
                          setPeriod(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {AmcFundName ? "Select Period" : "First Select Fund"}
                        </option>
                        {renderPeriodDropDown()}
                      </select>
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="form-group  ">
                      <label>Select Status</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={"Select AMC Funds"}
                        onChange={(e) => {
                          setInvoiceStatus(e.value);
                        }}
                        value={invoiceStatusOptions.filter(
                          (option) => option.value === invoiceStatus
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={invoiceStatusOptions}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className=" ">
                      {/* here Search Button */}
                      <button
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          searchData();
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Search</span>
                        )}
                      </button>
                    </div>
                  </Col>

                  <Col
                    className="row d-flex mr-4"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className=" ">
                      {/* here Search Button */}
                      <button
                        className="btn btn-primary mt-4"
                        style={{ maxWidth: "130px" }}
                        onClick={() => {
                          sessionStorage.removeItem("editInvoice");
                          history.replace("/admin/new-invoice");
                        }}
                      >
                        <span
                          className="login-txt"
                          style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        >
                          + Invoice
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>
                {allInvoice && allInvoice.length > 0 && (
                  <div className="table-responsive">

                    <table className="table" id="myTable">
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Due Date</th>
                          <th>AMC</th>
                          <th>Fund</th>
                          <th>Inv ID</th>
                          <th className="text-right">Tax</th>
                          <th className="text-right">Amount</th>
                          <th className="text-right">Net Amount</th>
                          <th className="text-right">Balance</th>

                          <th style={{ textAlign: "center" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody id="myUL">
                        {allInvoice &&
                          allInvoice.map((item, index) => {
                            sum_amount =
                              parseFloat(
                                item.gross_amount
                                  ? item.gross_amount.replaceAll(",", "")
                                  : "0"
                              ) + sum_amount;
                            sum_tax =
                              parseFloat(
                                item.tax ? item.tax.replaceAll(",", "") : "0"
                              ) + sum_tax;
                            sum_net_amount =
                              parseFloat(
                                item.net_amount
                                  ? item.net_amount.replaceAll(",", "")
                                  : "0"
                              ) + sum_net_amount;
                            sum_balance =
                              parseFloat(
                                item.balance
                                  ? item.balance.replaceAll(",", "")
                                  : "0"
                              ) + sum_balance;
                            return (
                              <tr>
                                <td>{item.period}</td>
                                <td>{item.due_date}</td>
                                <td>{item.amc_code}</td>
                                <td>{item.fund_code}</td>
                                <td>{item.id}</td>
                                <td className="text-right">
                                  {item.tax
                                    ? numberWithCommas(
                                      parseFloat(
                                        item.tax
                                          .toString()
                                          .replaceAll(",", "")
                                      ).toFixed(2)
                                    )
                                    : "0.00"}
                                </td>
                                <td className="text-right">
                                  {item.gross_amount
                                    ? numberWithCommas(
                                      parseFloat(
                                        item.gross_amount
                                          .toString()
                                          .replaceAll(",", "")
                                      ).toFixed(2)
                                    )
                                    : "0.00"}
                                </td>

                                <td className="text-right">
                                  {item.net_amount
                                    ? numberWithCommas(
                                      parseFloat(
                                        item.net_amount
                                          .toString()
                                          .replaceAll(",", "")
                                      ).toFixed(2)
                                    )
                                    : "0.00"}
                                </td>
                                <td className="text-right">
                                  {item.balance
                                    ? numberWithCommas(
                                      parseFloat(
                                        item.balance
                                          .toString()
                                          .replaceAll(",", "")
                                      ).toFixed(2)
                                    )
                                    : "0.00"}
                                </td>

                                <td
                                  className="d-flex pointer"
                                  style={{ justifyContent: "center" }}
                                >
                                  <ReactTooltip
                                    textColor="white"
                                    backgroundColor="black"
                                    effect="float"
                                  />
                                  <button
                                    data-tip={'View Invoice'}
                                    type="button"
                                    className="btn-icon btn-link like btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      e.currentTarget.blur();
                                      getContactDetails(
                                        item.fund_code,
                                        item.amc_code,
                                        item
                                      );
                                      // setInvoiceViewData(item);
                                      // setInvoiceViewModal(true);
                                    }}
                                  >
                                    <FaEye size={17} />
                                  </button>

                                  <button
                                    data-tip={'Receive Amount'}
                                    type="button"
                                    className="btn-icon btn-link like btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      e.currentTarget.blur();
                                      setPreviousBalance(
                                        item.balance
                                          ? numberWithCommas(item.balance)
                                          : "0"
                                      );
                                      setInvoiceIDPay(item.id);
                                      setPaymentModal(true);
                                      setAmountPay("");
                                    }}
                                  >
                                    <GiSevenPointedStar size={17} />
                                  </button>
                                  <button
                                    data-tip={'Edit Invoice'}
                                    type="button"
                                    className="btn-icon btn-link like btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      e.currentTarget.blur();
                                      let editInvoiceData = {
                                        amcFund: `${item.amc_code} - ${item.fund_code}`,
                                        head_detail: item.head_detail,
                                        balance: item.balance,
                                        period: item.period,
                                        period_start: item.period_start,
                                        period_end: item.period_end,
                                        id: item.id,
                                      };
                                      sessionStorage.setItem(
                                        "editInvoice",
                                        JSON.stringify(editInvoiceData)
                                      );
                                      history.replace("/admin/new-invoice");
                                    }}
                                  >
                                    <RiEdit2Fill size={15} />
                                  </button>
                                  {item.period_status &&
                                    item.period_status == "active" ? (
                                    <button
                                      data-tip={'Deactivate Invoice'}
                                      type="button"
                                      className="btn-icon btn-link like btn btn-primary btn-sm"
                                      onClick={(e) => {
                                        e.currentTarget.blur();
                                        setCurrentInvoiceStatus("active");
                                        setCurrentInvoiceID(item.id);
                                        setUpdateStatusModal(true);
                                      }}
                                    >
                                      <ImCross size={15} />
                                    </button>
                                  ) : (
                                    <button
                                      data-tip={'Activate Invoice'}
                                      type="button"
                                      className="btn-icon btn-link like btn btn-primary btn-sm"
                                      onClick={(e) => {
                                        e.currentTarget.blur();
                                        setCurrentInvoiceStatus("inactive");
                                        setUpdateStatusModal(true);
                                        setCurrentInvoiceID(item.id);
                                      }}
                                    >
                                      <TiTick size={22} />
                                    </button>
                                  )}
                                  {/* <button
                                    data-tip={'Compare Invoice'}
                                    type="button"
                                    className="btn-icon btn-link like btn btn-primary btn-sm"
                                  >
                                    <FaArrowLeft size={17} />
                                  </button> */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>

                      <tfoot>
                        <tr>
                          <th>Total</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_tax.toString().replaceAll(",", "")
                              ).toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_amount.toString().replaceAll(",", "")
                              ).toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_net_amount.toString().replaceAll(",", "")
                              ).toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_balance.toString().replaceAll(",", "")
                              ).toFixed(2)
                            )}
                          </th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
            </div>
            {renderModalPayment()}
            {renderModalViewInvoice()}
            {renderUpdateModal()}
            {renderModalSendEmail()}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SetupInvoice;
