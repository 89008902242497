import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { red } from '@material-ui/core/colors';
import { isNumber, sortAMCS } from '../../utils/customFunction';
import { equityHoldersRecociliation } from '../../stores/services/equity-holder.service';
import { generateOtp } from '../../stores/services/auth.service';
import { verifyOTP } from '../../stores/services/unit-holder.service';

const EquityHoldingReconciliationReport = () => {
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [allAmc, setAllAmc] = useState<any>([]);
  const [cdcData, setCDCData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  let [reconcData, setReconcData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund_code, setFundCode] = useState('');
  const [fundStockMarketData, setFundStockMarketData] = useState<any>([]);
  let [participantID, setParticipantID] = useState('');
  let [actionLoading, setActionLoading] = useState(false);
  let [count, setCount] = useState(0);


  const [confirmLoading, setConfirmLoading] = useState(false);
  const [optModalToggle, setOTPModalToggle] = useState(false);
  const [otp, setOTP] = useState('');


  const myRef1 = useRef<HTMLInputElement>(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState('');

  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!")
        }
      }
    }
    getAllAMC();
  }, [])


  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}-{item.amc_code.replace('AMC_', '')}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (error) {

    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  async function processLineByLine(fileObj) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content: any = [];
    reader.onload = function (fileObj) {
      content = reader.result;
      content = content.split('\n');
      var row1 = content[0].split(',');
      var code = row1[1].replace(/"/g, '');
      if (code === participantID) {
        var data = [] as any;
        var i = 1;
        let TotalLine = content.length - 1;
        for (i = 1; i < TotalLine; i++) {
          content[i] = content[i].replaceAll('"', '').trim();
          let str = content[i].split(',');
          if (i >= 1) {
            let obj = {
              ['symbol']: str[0],
              ['name']: str[1],
              ['quantity']: str[4],
            };
            data.push(obj);
          }
        }
        setCDCData(data);
      } else {
        setFileName('');
        setFile('');
        toast.error("Invalid file for the selected fund.")
      }
      setLoading(false);
    }
  }

  const uploadCDCFile = (e: any) => {
    cdcData.length = 0;
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'txt') {
      setLoading(true);
      setFileName(file.name);
      setFile(file);
      processLineByLine(file);
    } else {
      setLoading(false);
      toast.error('Please Upload Correct Format of File');
    }
  };

  const loadData = () => {
    setLoading(true);
    // if (fundStockMarketData.length == 0) {
    //   toast.error('Select fund.')
    // } else 
    if (cdcData.length == 0) {
      toast.error('Upload file.')
    } else {
      let cdcArray = cdcData.map((cdcitem, index) => {
        let matchedObject = fundStockMarketData.find(fundItem => {
          return fundItem.code === cdcitem.symbol
        })

        if (matchedObject) {
          return {
            symbol: cdcitem.symbol,
            name: cdcitem.name,
            market:(matchedObject.market ===undefined || matchedObject.market ==='') ? 'Reg' : matchedObject.market,
            close_rate: isNumber(matchedObject.close_rate),
            macs_holding: matchedObject.unit,
            cdc_holding: cdcitem.quantity,
            difference: (+cdcitem.quantity - +matchedObject.unit).toFixed(0),
          }
        } else {
          return {
            symbol: cdcitem.symbol,
            name: cdcitem.name,
            market:'Reg',
            macs_holding: '0',
            close_rate: '0',
            cdc_holding: cdcitem.quantity,
            difference: cdcitem.quantity,
          }
        }
      })

      let cdcArrayCodes = cdcArray.map(item => item.symbol);
      let fundUnmatched = fundStockMarketData.filter(item => !cdcArrayCodes.includes(item.code))
      
      fundUnmatched = fundUnmatched.map((item, index) => {
        return {
          symbol: item.code,
          name: '',
          market:(item.market ===undefined || item.market ==='') ? 'Reg' : item.market,
          close_rate: isNumber(item.close_rate),
          macs_holding: item.unit,
          cdc_holding: '0',
          difference: '-' + item.unit,
        }
      })
      let finalArray = [...cdcArray, ...fundUnmatched];
      
      setData(finalArray)
    }
    setLoading(false);
  }

  const [requiredOTP, setRequiredOTP] = useState('');
  const renderOTPModal = () => {
    switch (optModalToggle) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false}
            enforceFocus={false}
            onHide={() => {
              setOTP('');
              setOTPModalToggle(false);

            }}
            fade={false}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                disabled={Boolean(confirmLoading)}
                onClick={() => {
                  setOTP('');
                  setOTPModalToggle(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Verification</h6>
            </div>

            <div className="modal-body">

              <div className="card" style={{
                backgroundColor: "#b8c8d0",
                color: "black"
              }}>

                <div className="form-group m-3">
                  <label style={{
                    color: "black"
                  }}>Enter OTP</label>
                  <input type='text' placeholder='Enter OTP Code' className={'form-control w-50 ' + requiredOTP} value={otp}
                    style={{
                      color: "black"
                    }}
                    onChange={(e) => {
                      setOTP(e.target.value);
                      setRequiredOTP('');
                    }} />
                </div>

                <div className="form-group m-3">
                  <button className='btn btn-primary'
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      if (otp == '') {
                        setRequiredOTP('required-border')
                      } else {
                        verify2FaBtn();
                      }
                    }}>

                    {confirmLoading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>


                  <button className='btn btn-default'
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      setOTP('');
                      setOTPModalToggle(false);
                    }}>

                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const updateReconcileData = async () => {
    try {
      setActionLoading(true);
      const response = await generateOtp(email);
      if (response.data.status === 200) {
        toast.success(response.data.message)
        setOTPModalToggle(!optModalToggle);
      } else {
        toast.error(response.data.message)
      }

      // setActionLoading(false);
    } catch (error: any) {
      setActionLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    }
    setActionLoading(false);
  }

  const verify2FaBtn = async () => {
    setConfirmLoading(true);
    try {
      const result = await verifyOTP(email, otp);
      if (result.data.status === 200) {
        saveReconcileData();
      }else if (result.data.status === 400) {
        toast.error(result.data.message);
        setConfirmLoading(false);
      }
    } catch (error: any) {
      setConfirmLoading(false);
      setActionLoading(false);
      toast.error(error.data.message);
    }
  }

  const saveReconcileData = async () => {
    setActionLoading(true);
    try {
      const response = await equityHoldersRecociliation(email, reconcData, fund_code);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setFileName('');
        data.length = 0;
        setData(data);
        setOTP('');
        setReconcData([]);
        setAllFunds([]);
        setConfirmLoading(false);
        setOTPModalToggle(false);
        setActionLoading(false);
      } else {
        toast.error(response.data.message);
        setActionLoading(false);
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Request Failed!");
      }
      setConfirmLoading(false);
      setActionLoading(false);
    }
  }

  const addDataForBulk = (obj) => {
    count += 1;
    const check = reconcData.filter((item) => {
      return item.symbol === obj.symbol;
    });

    if (check.length === 0) {
      let temp = {
        symbol: obj.symbol || '',
        name: obj.name || '',
        market: obj.market,
        close_rate: obj.close_rate || '0',
        cdc_holding: obj.cdc_holding || '0',
        macs_holding: obj.macs_holding,
      };
      reconcData.push(temp);
      setReconcData(reconcData);

    }
    setCount(count);

  }

  const removeDataForBulk = (obj) => {
    count -= 1;
    const check = reconcData.filter((item) => {
      return item.symbol !== obj.symbol;
    });
    reconcData = check;
    setReconcData(reconcData);
    setCount(count);
  }
  const checkBoolean = (obj) => {
    let select = false;
    reconcData.map((item, index) => {
      if (item.symbol === obj) {
        select = true;
      }
    })
    return select;
  }

  const addAllDataForBulk = (data) => {

    data.filter((txnObj) => {
      const check = reconcData.filter((item) => {
        return item.symbol === txnObj.symbol;
      });
      if (check.length === 0) {
        let temp = {
          symbol: txnObj.symbol || '',
          name: txnObj.name || '',
          market: txnObj.market,
          close_rate: txnObj.close_rate || '0',
          cdc_holding: txnObj.cdc_holding || '0',
          macs_holding: txnObj.macs_holding,
        };
        reconcData.push(temp);
        setReconcData(reconcData);
      }
    })
    setCount(reconcData.length);
  }

  const removeAllDataFromBulk = (data) => {
    count = count - 1;
    data.filter((dataObj) => {
      const check: any = reconcData.filter((item) => {
        return item.symbol !== dataObj.symbol;
      });
      reconcData = check;
      setReconcData(check);
    })
    setCount(reconcData.length);
  }

  const checkAllBoolean = (data) => {
    let cnt = 0;
    data.filter((elem) => {
      reconcData.map((item, index) => {
        if (item.symbol === elem.symbol) {
          checkBoolean(item.symbol);
          cnt += 1;
        }
      })
    })
    // return false;
    if (cnt === data.length) {
      return true;
    } else {
      return false;
    }
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Equity Holding Reconciliation
                      <a className='float-right' href="./../../Uploader-Sample/insideZip.txt" download>Download Sample</a>
                    </h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              getFunds(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setData([]);
                              setFundCode(e.target.value);
                              let filterFund = allFunds.filter((item, any) => {
                                return (item.symbol_code === e.target.value);
                              });
                              setFundStockMarketData(JSON.parse(filterFund[0].stock_market))
                              participantID = filterFund[0].cdc_participant_id;
                              setParticipantID(participantID)
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>
                      <Col md="2">
                        <div>Upload Securities</div>
                        <div
                          onClick={() => myRef1?.current?.click()} >
                          <div className="form-group">
                            <div className="form-control" data-tip="Upload File">

                              {filename === '' ? 'Upload File' : filename}
                            </div>
                            <input
                              className="form-control mt-1"
                              type="file"
                              ref={myRef1}
                              style={{ display: 'none' }}
                              onChange={(e) => {
                                uploadCDCFile(e);
                              }}
                              onClick={(e) => {
                                e.currentTarget.value = '';
                              }}
                            />
                          </div>
                        </div>
                      </Col>


                      <Col md="2">
                        <div className=" ">
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              loadData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Reconcile</span>
                            )}
                          </button>
                        </div>
                      </Col>
                      <Col md='2'></Col>
                       
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    {data.length > 0 ? (
                      <>
                        <div className="title-row m-4 d-flex justify-content-center">
                          <h3>Equity Holding Reconciliation</h3>
                        </div>

                        <div className='table-reponsive'
                          style={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                          }}
                        >

                          <table className="table">
                            <thead style={{ alignSelf: 'center' }}>
                              <tr>
                                <th>
                                  <FormGroup check>
                                    <Label check>
                                      <Input type="checkbox"
                                        checked={checkAllBoolean(data)}
                                        onChange={(e) => {
                                          if (e.target.checked === true) {
                                            addAllDataForBulk(data);
                                          } else {
                                            removeAllDataFromBulk(data);
                                          }
                                        }} />
                                      <span className="form-check-sign" />
                                    </Label>
                                  </FormGroup>
                                </th>
                                <th>Symbol</th>
                                <th>Name</th>
                                <th>Market</th>
                                <th className="text-right">Close Rate</th>
                                <th className="text-right">Holding as per MACS</th>
                                <th className="text-right">CDC</th>
                                <th className="text-right">Difference</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item: any, index: number) => {
                                return (
                                  <>
                                    {parseFloat(item.difference) > 0 && (
                                      <tr key={index} className="highlight-row-success">
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input type="checkbox"
                                                checked={checkBoolean(item.symbol)}
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }} />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>

                                        </td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">{item.close_rate}</td>
                                        <td className="text-right">{item.macs_holding}</td>
                                        <td className="text-right">{item.cdc_holding}</td>
                                        <td className="text-right">{item.difference}</td>
                                      </tr>
                                    )}
                                    {parseFloat(item.difference) < 0 && (
                                      <tr key={index} className="highlight-row-error">
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input type="checkbox"
                                                checked={checkBoolean(item.symbol)}
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }} />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>

                                        </td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">{item.close_rate}</td>
                                        <td className="text-right">{item.macs_holding}</td>
                                        <td className="text-right">{item.cdc_holding}</td>
                                        <td className="text-right">{item.difference}</td>
                                      </tr>
                                    )}
                                    {parseFloat(item.difference) == 0 && (
                                      <tr key={index}>
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input type="checkbox"
                                                checked={checkBoolean(item.symbol)}
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }} />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>

                                        </td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">{item.close_rate}</td>
                                        <td className="text-right">{item.macs_holding}</td>
                                        <td className="text-right">{item.cdc_holding}</td>
                                        <td className="text-right">{item.difference}</td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}


                            </tbody>
                          </table>
                        </div>


                        {
                          reconcData.length > 0 && (
                            <button className='btn btn-primary float-right'
                              disabled={Boolean(actionLoading)}
                              onClick={(e) => {
                                updateReconcileData();
                              }}>
                              {actionLoading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Loading...</span>
                                </>
                              ) : (
                                <span>Action</span>
                              )}
                            </button>
                          )
                        }

                      </>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">No record found</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
              {renderOTPModal()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EquityHoldingReconciliationReport;
