import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../../stores/services/amc.service';
import { getFundByAmc } from '../../../stores/services/funds.service';
import moment from 'moment';
import { isNumber, numberWithCommas, sortAMCS } from '../../../utils/customFunction';

const csv = require('csvtojson/v2');

const FundPortfolio = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);

  const [selected, setSelected] = useState<any>([]);
  const [selectedFundInvestment, setSelectedFundInvestment] = useState<any>([]);
  const [selectedMoneyMarket, setSelectedMoneyMarket] = useState<any>([]);
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('0');
  const [totalUnits, setUnits] = useState('');
  const [amcName, setAmcName] = useState('');
  const [symbolCode, setSymbolCode] = useState('');
  const [totalMoneyMarAmount, setTotalMoneyMarAmount] = useState('0');
  const [totalfacevalue, setTotalFaceValue] = useState('0');
  const [grandTotal, setGrandTotal] = useState('0');
  const [fundInvestmentTotalAmount,setFundInvestmentTotalAmount] = useState('0');
  const [fundInvestmentUnits,setFundInvestmentUnits] = useState('0');
  // const [fundInvestmentTotalAmount,setFundInvestmentTotalAmount] = useState('0');

  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        let temp = sortAMCSByName.map((val) => {
          return {
            ...val,
            label: `${val.name}-${val.amc_code.replace('AMC_', '')}`,
            value: val.amc_code.replace('AMC_', ''),
          };
        });
        setAmcdata(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getfundByAmcCode = async (code: string) => {
    // setLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.fund_name}-${val.symbol_code}`,
          value: val.symbol_code,
        };
      });
      setAllFunds(temp);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setAccFundLoading(false);
    // setLoading(false);
  };

  const decimalValue = (value, len) => {
    // eslint-disable-next-line 
    value === undefined ? value = '0' : value;
    let startingValue = value.toString().split('.')[0];
    let decimalVal = value.toString().split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  const renderStockMarketData = () => {
    return selected.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.market}</td>
          <td>{items.code ? items.code : 'N/A'}</td>
          <td>{numberWithCommas(decimalValue(items.unit, 4))}</td>
          <td>
            {numberWithCommas(decimalValue(items.close_rate, 4))}
          </td>
          <td className="text-right">
            {numberWithCommas(decimalValue(items.amount, 2))}
          </td>
        </tr>
      );
    });
  };

  const renderFundInvestmentMarketData = () => {
    return selectedFundInvestment.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          {/* <td>{items.code ? items.code : 'N/A'}</td> */}
          <td>{numberWithCommas(decimalValue(items.units, 4))}</td>
          <td>
            {numberWithCommas(decimalValue(items.price, 4))}
          </td>
          <td className="text-right">
            {numberWithCommas(decimalValue(items.total_amount, 2))}
          </td>
        </tr>
      );
    });
  };

  const renderMoneyMarketData = () => {
    return selectedMoneyMarket.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.code ? items.code : 'N/A'}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.units || '')}</td>
          <td>{moment(items.issue_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.maturity_date).format('DD-MM-YYYY')}</td>
          <td className="text-right">{numberWithCommas(decimalValue(items.face_value == "" ? "0" : items.face_value, 2))}</td>
          <td className="text-right">{numberWithCommas(decimalValue(items.total_amount == "" ? "0" : items.total_amount, 2))}</td>
        </tr>
      );
    });
  };


  
  const getCalculatredData = (marketData) => {
    setSelectedMoneyMarket([]);
    setSelected([])
    setSelectedFundInvestment([]);
    //for stock Market
    let totalamount = 0, mmAmount = 0, tdAmount = 0, allTotalFaceValue = 0;
    if (marketData.stock_market !== '' && marketData.stock_market !== '[]' && marketData.stock_market !== undefined) {
      let stock_market = JSON.parse(marketData.stock_market);
      let unit = 0;
      // let amount = 0;
      stock_market.map((item, index) => {
        if (item.unit === '' || item.unit === 'NaN' || item.unit === NaN || item.unit === undefined) {
          item.unit = '0'
        }
        if (item.amount === '' || item.amount === 'NaN' || item.amount === NaN || item.amount === undefined) {
          item.amount = '0'
        }
        unit = unit + parseFloat(item.unit);
        totalamount = totalamount + parseFloat(item.amount);
      });
      setTotalAmount(totalamount.toFixed(2));
      setUnits(unit.toString());
      setSelected(stock_market);
    } else {
      selected.length = 0;
      setTotalAmount('0');
      setSelected(selected);
    }
    //For Fund Security Data
    // let totalamount = 0, mmAmount = 0, tdAmount = 0, allTotalFaceValue = 0;
    let fundInvestmentTotalAmount = 0
    if (marketData.fund_market !== '' && marketData.fund_market !== '[]' && marketData.fund_market !== undefined) {
      let fund_market = JSON.parse(marketData.fund_market);
      let no_of_units = 0;
      // let amount = 0;
      fund_market.map((item, index) => {
        no_of_units = no_of_units + parseFloat(isNumber(item.units));
        fundInvestmentTotalAmount = fundInvestmentTotalAmount + parseFloat(isNumber(item.total_amount));
      });
      setFundInvestmentTotalAmount(fundInvestmentTotalAmount.toFixed(2));
      setFundInvestmentUnits(no_of_units.toString());
      setSelectedFundInvestment(fund_market);
    } else {
      selectedFundInvestment.length = 0;
      setFundInvestmentTotalAmount('0');
      setSelectedFundInvestment(selected);
    }
    //for money market data
    if (marketData.money_market !== '' && marketData.money_market !== '[]' && marketData.money_market !== undefined) {
      let money_market = JSON.parse(marketData.money_market);
      // let amount = 0;

      money_market.map((item, index) => {
        if (item.face_value === '' || item.face_value === 'NaN' || item.face_value === NaN || item.face_value === undefined) {
          item.face_value = '0'
        }
        if (item.total_amount === '' || item.total_amount === 'NaN' || item.total_amount === NaN || item.total_amount === undefined) {
          item.total_amount = '0'
        }
        mmAmount = mmAmount + parseFloat(item.total_amount === '' ? item.total_amount = '0' : item.total_amount);
        allTotalFaceValue = allTotalFaceValue + parseFloat(item.face_value === '' ? item.face_value = '0' : item.face_value);

      });
      // let term_dep = JSON.parse(marketData.term_deposit);
      // let newtermdep = term_dep.map((item:any)=>{
      //   return {
      //     code: item.code, issue_date: item.from_date, maturity_date:item.to_date, name : item.name, total_amount: item.principal_amount
      //   }
      // })\
      // let moneyMark = [...money_market, ...newtermdep];
      setTotalMoneyMarAmount(mmAmount.toFixed(2));
      setTotalFaceValue(allTotalFaceValue.toFixed(2));
      setSelectedMoneyMarket(money_market);
    } else {
      selectedMoneyMarket.length = 0;
      setTotalMoneyMarAmount('0');
      selectedMoneyMarket.length = 0;
      setSelectedMoneyMarket(selectedMoneyMarket);
    }

    if (mmAmount.toString() === '' || mmAmount.toString() === 'NaN' || mmAmount === NaN || mmAmount === undefined) {
      mmAmount = 0;
    }
    if (totalamount.toString() === '' || totalamount.toString() === 'NaN' || totalamount === NaN || totalamount === undefined) {
      totalamount = 0;
    }
    if (tdAmount.toString() === '' || tdAmount.toString() === 'NaN' || tdAmount === NaN || tdAmount === undefined) {
      tdAmount = 0;
    }
    if (allTotalFaceValue.toString() === '' || allTotalFaceValue.toString() === 'NaN' || allTotalFaceValue === NaN || allTotalFaceValue === undefined) {
      allTotalFaceValue = 0;
    }
    let GrandTotal = (parseFloat(mmAmount.toFixed(2)) + parseFloat(totalamount.toFixed(2)) + parseFloat(fundInvestmentTotalAmount.toFixed(2))).toFixed(2);
    setGrandTotal(GrandTotal);

  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className=" ">
                <div className="d-flex align-items-center"></div>
                {!loading ? (
                  <div className="">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          Fund Investment Portfolio
                        </h4>
                      </div>
                      <div className="card-body">
                        <Row>
                          <Col md="6">
                            <div className="form-group " >
                              <label>AMC Name</label>
                              <Select
                                className="react-select  info"
                                classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  setAmcName(e.value);
                                  getfundByAmcCode(e.value);
                                  selected.length = 0;
                                  setSelected(selected);
                                  setSymbolCode('');
                                  setSelectedMoneyMarket(selected);
                                  setSelectedFundInvestment(selected);
                                  setFundInvestmentUnits('0');
                                  setFundInvestmentTotalAmount('0');
                                  setTotalMoneyMarAmount('0');
                                  setTotalAmount('0');
                                  setTotalFaceValue('0');
                                  setGrandTotal('0');
                                }}
                                value={amcdata.filter(
                                  (option) => option.value === amcName
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={amcdata}
                              // menuPortalTarget={document.body}
                              // menuShouldBlockScroll={true} 
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group  ">
                              <label>Select Fund</label>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  setUnitLoading(true);
                                  let value = allFunds.filter((item: any) => {
                                    return item.symbol_code === e.value;
                                  });
                                  setSymbolCode(value[0].symbol_code);
                                  getCalculatredData(value[0]);
                                  setUnitLoading(false);
                                  // selected()
                                }}
                                value={allFunds.filter(
                                  (option) => option.value === symbolCode
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={allFunds}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-6">
                        {selected.length > 0 ? (
                          <div className=" card">
                            <div className="card-header">
                              <h4 className="card-title">
                                Stock Market
                              </h4>
                            </div>
                            <div className="card-body">

                              <div className='table-responsive'>
                                <table className="table ">
                                  <thead>
                                    <tr  >
                                      <th> Market </th>
                                      <th>Equity Portfolio</th>
                                      <th>Quantity</th>
                                      <th>Rate</th>
                                      <th className="text-right">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {renderStockMarketData()}
                                    <tr>
                                      <td>Total</td>
                                      <td></td>
                                      <td>{numberWithCommas(totalUnits)}</td>
                                      <td></td>
                                      <td className="text-right">{numberWithCommas(totalAmount)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : unitLoading ? (
                          ''
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            {folio_no === '' ? (
                              <p className="text-center empty-text">
                                Select Fund
                              </p>
                            ) : (
                              <p className="text-center empty-text">Empty</p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        {selectedMoneyMarket.length > 0 ? (
                          <div className="  card">
                            <div className="card-header">
                              <h4 className="card-title">
                                Money Market
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className='table-responsive'>
                                <table className="table ">
                                  <thead>
                                    <tr>
                                      <th>Symbol</th>
                                      <th>Security Name</th>
                                      <th>Units</th>
                                      <th>Issue Date</th>
                                      <th>Maturity Date</th>
                                      <th className="text-right">Total Face Value</th>
                                      <th className="text-right">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {renderMoneyMarketData()}
                                    <tr>
                                      <td>Total</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right mr-2">{numberWithCommas(totalfacevalue) == "NaN" ? "0" : numberWithCommas(totalfacevalue)}</td>
                                      <td className="text-right mr-2">{numberWithCommas(totalMoneyMarAmount) == "NaN" ? "0" : numberWithCommas(totalMoneyMarAmount)}</td>

                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            {folio_no === '' ? (
                              <p className="text-center empty-text">
                                Select Fund
                              </p>
                            ) : (
                              <p className="text-center empty-text">Empty</p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-6">
                        {selectedFundInvestment.length > 0 ? (
                          <div className=" card">
                            <div className="card-header">
                              <h4 className="card-title">
                              Mutual Funds Portfolio
                              </h4>
                            </div>
                            <div className="card-body">

                              <div className='table-responsive'>
                                <table className="table ">
                                  <thead>
                                    <tr  >
                                      <th> Symbol / Code </th>
                                      <th>No of Units</th>
                                      <th>NAV Price</th>
                                      <th className="text-right">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {renderFundInvestmentMarketData()}
                                    <tr>
                                      <td>Total</td>
                                      {/* <td></td> */}
                                      <td>{numberWithCommas(fundInvestmentUnits)}</td>
                                      <td></td>
                                      <td className="text-right">{numberWithCommas(fundInvestmentTotalAmount)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : unitLoading ? (
                          ''
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            {folio_no === '' ? (
                              <p className="text-center empty-text">
                                Select Fund
                              </p>
                            ) : (
                              <p className="text-center empty-text">Empty</p>
                            )}
                          </div>
                        )}
                      </div>

                    </div>


                    {+grandTotal > 0 && (
                      <div className="card">
                        <div className="card-body">
                          <h4>
                            Grand Total :{' '}
                            {/* {(parseFloat(totalMoneyMarAmount) +  +totalAmount + +totalTermDepositAmount).toLocaleString()} */}
                            {numberWithCommas(grandTotal)}
                          </h4>
                        </div>
                      </div>
                    )}

                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <i className="fa fa-spinner fa-spin fa-3x" ></i>
                  </div>
                )}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FundPortfolio;
