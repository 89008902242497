import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
const ViewStaff = () => {
  const history = useHistory();
  const baseEmail = sessionStorage.getItem("email") || "";

  //  Signature Code
  const myRef = useRef<HTMLInputElement>(null);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');

  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState('');
  const [name, setName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [cnic, setCnic] = useState('')
  const [designation, setDesignation] = useState('')
  const [bloodGroup, setBloodGroup] = useState('')

  const [signature, setSignature] = useState<any | null>('');

  const [picture, setPicture] = useState<any | null>('');
  const [pictureFileName, setPictureFileName] = useState('');
  const [pictureFile, setPictureFile] = useState('');
  // const [signature, setSignature] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  const [nameError, setNameError] = useState('')
  const [cnicError, setCnicError] = useState('')
  const [signatureError, setSignatureError] = useState('')
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const staffOBJ: any = JSON.parse(sessionStorage.getItem('staffOBJ') || '');
    console.log("🚀 ~ file: view-staff.tsx:44 ~ useEffect ~ staffOBJ:", staffOBJ)
    const getData = async () => {
      let parseDataSignature;
      try {
        parseDataSignature = await JSON.parse(staffOBJ?.signature_specimen || '');
        setSignature(parseDataSignature);
        setFileName(parseDataSignature?.name);
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON data:", error);
      }

      let parseDataPicture;
      try {
        parseDataPicture = await JSON.parse(staffOBJ?.picture || '');
        setPicture(parseDataPicture);
        setPictureFileName(parseDataPicture?.name);
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON data:", error);
      }
    }
    getData();
    setName(staffOBJ?.name);
    setFatherName(staffOBJ?.father_name);
    setCnic(staffOBJ?.cnic);
    setDesignation(staffOBJ?.designation);
    setEmail(staffOBJ?.email);
    setContact(staffOBJ?.mobile);
    setPhoneNo(staffOBJ?.phone);
    setBloodGroup(staffOBJ?.blood_group);

  }, [])


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/staff-management')
                  }}></i>View - Staff</h4>

                  <Link to="/admin/staff-management" className="btn btn-primary btn-sm ml-auto" replace>
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Name </label>
                        <input
                          type="text"
                          placeholder="Name"
                          className={`form-control  w-100 ${nameError ? 'required-border' : ''
                            }`}
                          value={name}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Father Name</label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100`}
                          value={fatherName}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label> CNIC   </label>
                        <div  >
                          <InputMask
                            className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            placeholder=""
                            value={cnic}
                            readOnly
                          ></InputMask>
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Email  </label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100 ${emailError ? 'required-border' : ''
                            }`}
                          value={email}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Mobile No.</label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100`}
                          value={contact}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Phone No.</label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100`}
                          value={phoneNo}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Designation </label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100`}
                          value={designation}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Blood Group</label>
                        <input
                          type="text"
                          placeholder=""
                          className={`form-control  w-100`}
                          value={bloodGroup}
                          readOnly
                        />
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Signature</label>
                        <div
                          className="multi-input pointer"
                        >
                          <div className="form-group">
                            <div style={{color:'#6c757c'}} className={`form-control  w-100 ${signatureError ? 'required-border' : ''
                              }`}>
                              {fileName === '' || fileName === undefined ? 'No File Uploaded' : fileName}
                            </div>
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              multiple={false}
                              accept="image/png, image/jpeg"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      {signature ? (
                        <img src={signature?.content} className="rounded" alt="spicemen" width="100px" height="100px" />
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Picture</label>
                        <div
                          className="multi-input pointer"
                        >
                          <div className="form-group">
                            <div style={{color:'#6c757c'}} className={`form-control  w-100`}>
                              {pictureFileName === '' || pictureFileName === undefined ? 'No File Uploaded' : pictureFileName}                            </div>
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              multiple={false}
                              accept="image/png, image/jpeg"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      {picture ? (
                        <img src={picture?.content} className="rounded" alt="Profile Picture" width="100px" height="100px" />
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Container >

          </Col>
        </Row>
      </div>
    </>
  );
};
export default ViewStaff;