import axios from "axios";
import Config from "./../../config/index";
import RefreshTokenHandler from "./refresh-token";
const addInvoice = async (
  email: string,
  amc_code: string,
  fund_code: string,
  due_date: string,
  period: string,
  tax: string,
  net_amount: string,
  gross_amount: string,
  head_detail: string,
  period_start: string,
  period_end: string
) => {
  const url = `${Config.baseUrl}/invoice/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        fund_code,
        due_date,
        period,
        tax,
        net_amount,
        gross_amount,
        head_detail,
        period_start,
        period_end,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addInvoice(
          email,
          amc_code,
          fund_code,
          due_date,
          period,
          tax,
          net_amount,
          gross_amount,
          head_detail,
          period_start,
          period_end
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};


const updateInvoice = async (
  email: string,
  amc_code: string,
  fund_code: string,
  due_date: string,
  period: string,
  tax: string,
  net_amount: string,
  gross_amount: string,
  head_detail: string,
  period_start: string,
  period_end: string
) => {
  const url = `${Config.baseUrl}/invoice/update`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        fund_code,
        due_date,
        period,
        tax,
        net_amount,
        gross_amount,
        head_detail,
        period_start,
        period_end,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addInvoice(
          email,
          amc_code,
          fund_code,
          due_date,
          period,
          tax,
          net_amount,
          gross_amount,
          head_detail,
          period_start,
          period_end
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const searchInvoice = async (
  email: string,
  amc_code: string,
  fund_code: string,
  status:string,
  period: string =""
) => {
  const url = `${Config.baseUrl}/invoice?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&status=${status}&period=${period}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        if (period) {
          return await searchInvoice(email, amc_code, fund_code, status, period);
        } else {
          return await searchInvoice(email, amc_code, fund_code, status);
        }
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};


const updateStatusInvoice = async (
  email: string,
  id: string,
  period_status: string
) => {
  const url = `${Config.baseUrl}/invoice/update-status`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        id,
        period_status,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateStatusInvoice(email, id, period_status);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const updateBalance = async (
  email: string,
  id: string,
  amount_pay: string,
  balance_detail:string,
  
) => {
  const url = `${Config.baseUrl}/invoice/balance`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        id,
        amount_pay,
        balance_detail
        
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        
          return await updateBalance(email,
            id,
            amount_pay,
            balance_detail);
        
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
const sendInvoice = async (
  email: string,
  cc_email:string,
  to_email:string,
  pdf:string,
  data:string,
  pdfdetail:string,
  
) => {
  const url = `${Config.baseUrl}/invoice/email_invoice`;
  try{
  let result: any = await axios.post(
    url,
    { email,cc_email,to_email,pdf, data,pdfdetail },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await sendInvoice(email,cc_email,to_email,pdf, data,pdfdetail )
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
};

export { addInvoice, searchInvoice, updateStatusInvoice, updateBalance, sendInvoice, updateInvoice };
