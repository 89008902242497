import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const fundInvestmentSmry = async (
    email: string,
  ) => {
    
    const url = `${Config.baseUrl}/fund/fund-Investment-summary?email=${email}`;
    // const url = `${Config.baseUrl}/amc?email=${email}&page_number=${page_number}&page_size=${page_size}&filter_email=${filter_email}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await fundInvestmentSmry(email)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
  };

  export { fundInvestmentSmry }