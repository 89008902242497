import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { toast, ToastContainer } from "react-toastify";
import { getFundByAmc } from "../../../../stores/services/funds.service";
import { getAmc } from "../../../../stores/services/amc.service";
import {
  getAccountByAmc,
} from "../../../../stores/services/account.service";
import Select from "react-select";
import { poCDRLetter } from "../../../letterTemplates/poCDR.letter.template";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import createDOMPurify from "dompurify";
import { getAllBanks } from "../../../../stores/services/bank.service";
import { PDFExport, PageTemplateProps } from "@progress/kendo-react-pdf";
import { components } from "react-select";
import { authorizedStaff_CB_PO_DD_Letter } from "../../../letterTemplates/authorized-staff-CB-PO-DD--letter";
import { authorizedStaffBankStatementLetter } from "../../../letterTemplates/authorized-staff-bank-statement-letter";
import moment from "moment";
import { getAllStaffOption } from "../../../../stores/services/staff.service";
const DOMPurify = createDOMPurify(window);

const StaffLetter = () => {
  let fulldate = new Date();
  let date = moment(fulldate).format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState(date);
  const [toDate, setToDate] = useState(date);
  const [letter, setLetter] = useState("Auto Staff");
  const [autorizedReason, setAutorizedReason] = useState("");
  const [Amc, setAmc] = useState("");
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allBranch, setAllBranch] = useState<any>([]);
  const [branchSelect, setBranchSelect] = useState<any>('');
  const [bank, setBank] = useState("");
  const [accountsOption, setAccountOption] = useState<any>([]);
  const [authorizedOption, setAuthorizedOption] = useState<any>([
    { label: 'ALL', value: '*' },
    { label: 'Cheque book(s)', value: 'CB' },
    { label: 'Pay Order(s)', value: 'PO' },
    { label: 'Demand Draft(s)', value: 'DD' },
  ]);
  const [allStaffData, setAllStaffData] = useState<any>([]);
  const [staffOption, setStaffOption] = useState<any>([]);
  const [allBank, setAllBank] = useState<any>([]);
  const [accountSelect, setAccountSelect] = useState<any>([]);
  const [staffSelect, setStaffSelect] = useState<any>([]);
  const [authorizedSelect, setAuthorizedSelect] = useState<any>([]);
  const [bankData, setBankData] = useState<any>([]);
  const [bankCity, setBankCity] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [displayLetterCheck, setDisplayLetterCheck] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountsToLetter, setAccountsToLetter] = useState<any>([]);
  const [staffToLetter, setStaffToLetter] = useState<any>([]);
  const [authorizedToLetter, setAuthorizedToLetter] = useState<any>([]);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const email = sessionStorage.getItem("email") || "";

  const canvasRefs = useRef(null);
  useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(email);
        setBankData(bankResponse.data.data.filter((item) => item.status === 'active'));
      } catch (error) { }
    };
    fetchBank();
  }, []);


  useEffect(() => {
    const getAllStaff = async () => {
      try {
        const response = await getAllStaffOption(email);
        if (response.data.status === 200) {
          let staffResponse = response.data.data.filter((item) => item.status === 'active').map(staff => ({
            label: `${staff?.name} - (${staff?.designation})`,
            value: staff?.staff_code,
            cnic: staff?.cnic,
            signature_specimen: staff?.signature_specimen,
            name: staff?.name
          }));

          if (staffResponse.length > 0) {
            staffResponse.unshift({
              value: "*",
              label: "ALL"
            });
          }
          setStaffOption(staffResponse);
        }
      } catch (error) { }
    };
    getAllStaff();
  }, []);

  const bankName = (bankcode) => {
    const bankdata1 = bankData.filter((item) => item.status === 'active').filter((item: any, index: string) => {
      return item.bank_code.replace("BANK_", "") === bankcode;
    });
    if (bankdata1.length > 0) {
      return bankdata1[0].bank_name;
    } else {
      return bankcode;
    }
  };

  const nameofBank = (bankcode) => {
    const bankdata1 = bankData.filter((item) => item.status === 'active').filter((item: any, index: string) => {
      return item.bank_code.replace("BANK_", "") === bankcode;
    });
    if (bankdata1.length > 0) {
      return bankdata1[0].bank_name;
    } else {
      return bankcode;
    }
  };

  // let poCDRLetterTemplate = poCDRLetter(
  //   bankName(bank),
  //   bankBranch,
  //   bankCity,
  //   accountsToLetter
  // );

  let authorizedStaffBankStatementLetterTemplate = authorizedStaffBankStatementLetter(
    nameofBank(bank),
    bankBranch,
    bankCity,
    accountsToLetter,
    staffSelect.filter((staff) => staff.value !== '*'),
    fromDate,
    toDate
  );

  let authorizedStaff_CB_PO_DD_LetterTemplate = authorizedStaff_CB_PO_DD_Letter(
    nameofBank(bank),
    bankBranch,
    bankCity,
    accountsToLetter,
    staffSelect.filter((staff) => staff.value !== '*'),
    authorizedSelect.filter((item) => item.value !== '*')
  );



  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: string) => {
      return (
        <option key={index} value={item.amc_code.replace("AMC_", "")}>
          {item.name}
        </option>
      );
    });
  };

  const getBanksAccount = async (value: any) => {
    try {
      setAllBank([]);
      accountSelect.length = 0;
      setAccountSelect([]);
      accountsOption.length = 0;
      setBank("");
      setAccountOption([]);
      accountsToLetter.length = 0
      setAccountsToLetter([])
      const response = await getAccountByAmc(email, value);
      setAllBank([...response.data.data.filter((item) => item.status === 'active')]);
    } catch (error) { }
  };

  const renderBankDropDown = () => {
    let unique: any = [];
    allBank.filter((elem) => elem.status === 'active').filter(function (item) {
      var i = unique.findIndex((x) => x.bank_name == item.bank_name);
      if (i <= -1) {
        unique.push(item);
      }
      return null;
    });

    return unique.map((item: any, index) => {
      return (
        <option key={index} value={item.bank_name}>
          {bankName(item.bank_name)}
        </option>
      );
    });
  };

  const renderBranchDropDown = (value: string) => {
    accountSelect.length = 0;
    setAccountSelect([]);
    accountsOption.length = 0;
    setAccountOption([]);
    accountsToLetter.length = 0
    setAccountsToLetter([])
    let unique: any = [];
    let Unique_Branch: any = [];
    allBank.filter(function (item) {
      if (item.bank_name == value) {
        unique.push(item);
      }
    });
    unique.filter(function (item) {
      var i = Unique_Branch.findIndex(
        (x) =>
          x.branch_name.trim().toLowerCase() ==
          item.branch_name.trim().toLowerCase() &&
          x.bank_city.trim().toLowerCase() ==
          item.bank_city.trim().toLowerCase()
      );
      if (i <= -1) {
        Unique_Branch.push(item);
      }
      return null;
    });

    Unique_Branch.map((item) => {
      allBranch.push({
        label: `${item.branch_name} – ${item.bank_city}`,
        value: `${item.branch_name} – ${item.bank_city}`,
      });
    });

    setAllBranch(allBranch);
  };

  useEffect(() => {
    const getAMC = async () => {
      try {
        // setLetter("");
        setAmc("");
        setBranchSelect('');
        setAllBranch([]);
        setBank("");
        setErrorShow(false);
        accountSelect.length = 0;
        setAccountSelect([]);
        accountsOption.length = 0;
        setAccountOption([]);
        accountsToLetter.length = 0
        setAccountsToLetter([])
        const amcResponse = await getAmc(email, "", "", "");
        setAllAmc(amcResponse.data.data.filter((item) => item.status === 'active'));
        setBankCity("");
        setBankBranch("");
        setDisplayLetterCheck(false);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
    };
    getAMC();
  }, []);

  const renderModalForLetterPopup = () => {
    switch (displayLetterCheck) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setDisplayLetterCheck(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setDisplayLetterCheck(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{letter} Letter</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 ">
                  {autorizedReason == "Bank Statement" && (
                    <PDFExport
                      paperSize="A4"
                      margin="1.2cm"
                      scale={0.8}
                      pageTemplate={PageTemplate}
                      fileName={`Letter-${bank}-${bankBranch}.pdf`}
                      ref={pdfExportComponent}
                    >
                      <div
                        className="a4-page-letter saleLetter"
                        id={"letterview"}
                        ref={canvasRefs}
                      >
                        <div
                          className="p-letter"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(authorizedStaffBankStatementLetterTemplate),
                          }}
                        />
                      </div>
                    </PDFExport>
                  )}
                  {autorizedReason == "CB / PO / DD" && (
                    <PDFExport
                      paperSize="A4"
                      margin="1.2cm"
                      scale={0.8}
                      pageTemplate={PageTemplate}
                      fileName={`Letter-${bank}-${bankBranch}.pdf`}
                      ref={pdfExportComponent}
                    >
                      <div
                        className="a4-page-letter saleLetter"
                        id={"letterview"}
                        ref={canvasRefs}
                      >
                        <div
                          className="p-letter"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(authorizedStaff_CB_PO_DD_LetterTemplate),
                          }}
                        />
                      </div>
                    </PDFExport>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      printContentLetterFormat();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Print</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const PageTemplate = (props: PageTemplateProps) => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          fontSize: "9px",
          fontFamily: "Palatino",
          textAlign: "center",
          color: "black",
          width: "100%",
          lineHeight: "1",
          marginTop: "20px",
          left: '0px',
          right: '0px'
        }}
      >
        <div style={{ marginBottom: "5px" }}>
          ______________________________________________________________________________________________________
        </div>
        <div>
          4th Floor, Perdesi House, 2/1 R-Y Old Queens Road, Karachi - 74200
        </div>
        <div>
          Direct Nos, 021-32430485, 32415454, 32415204, 32428731 PABX No.
          021-32419770, Fax No. 021-32416371,
        </div>
        <div>URL : http://www.Digitalcustodian.co</div>
      </div>
    );
  };

  const printContentLetterFormat = () => {
    try {
      var doc = new jsPDF("p", "pt", [595.275, 841.89], true);

      let pageHeight = doc.internal.pageSize.getHeight();
      var opt = {
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
      };
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }

      // doc.html(document.getElementById("letterview") as HTMLElement, {
      //   callback: function (doc) {
      //     let totalPages = doc.internal.pages.length - 1;

      //     for (let i = 1; i <= totalPages; i++) {
      //       doc.setPage(i)
      //       doc.setFontSize(9);
      //       // pdf.setTextColor(150)
      //       doc.text(
      //         `______________________________________________________________________________________________________`,
      //         doc.internal.pageSize.getWidth() - 550,
      //         doc.internal.pageSize.getHeight() - 55
      //       );
      //       doc.text(
      //         `Operations Office: Perdesi House | Old Queens Road | Karachi-74200 | Pakistan`,
      //         doc.internal.pageSize.getWidth() - 460,
      //         doc.internal.pageSize.getHeight() - 40
      //       );
      //       doc.text(
      //         `+92 21 32419770  |  www.digitalcustodian.co  |  info@digitalcustodian.co`,
      //         doc.internal.pageSize.getWidth() - 440,
      //         doc.internal.pageSize.getHeight() - 30
      //       );
      //     }
      //     doc.save(`Letter-${bank}-${bankBranch}.pdf`);
      //   },
      //   html2canvas: {
      //     useCORS: true,
      //     scale: 0.94,
      //   },

      //   margin: [30, 0, 72, 0],

      //   x: 0,
      //   y: 0,
      // });
    } catch (error: any) { }
  };

  const ErrorBorder = (value) => {
    if (errorShow && value.length === 0) {
      return "required-border";
    } else {
      return "";
    }
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label style={{ color: 'black' }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );




  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Staff Letter</h4>
              </div>
              <div className="card-body">
                <div className="">
                  <Row>
                    {/* <Col md="2">
                      <div className="form-group">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <label>Select Letter</label>
                        <select
                          className={"form-control " + ErrorBorder(letter)}
                          onChange={(e) => {
                            setLetter(e.target.value);
                          }}
                          value={letter}
                        >
                          <option value="" defaultChecked hidden>
                            Select Letter
                          </option>
                          <option value="Auto Staff">
                            Authorized Staff
                          </option>
                        </select>
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label>Select AMC</label>
                        <select
                          className={"form-control " + ErrorBorder(Amc)}
                          value={Amc}
                          onChange={(e) => {
                            setAmc(e.target.value);
                            getBanksAccount(e.target.value);
                            setBank("");
                            setAccountOption([]);
                            setAccountSelect([]);
                            setAccountsToLetter([])
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select AMC
                          </option>
                          {renderAmcDropdown()}
                        </select>
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label>Select Bank</label>
                        <select
                          className={"form-control " + ErrorBorder(bank)}
                          onChange={(e) => {
                            setBank(e.target.value);
                            allBranch.length = 0;
                            setAllBranch([]);
                            setBranchSelect('');
                            renderBranchDropDown(e.target.value);
                          }}
                          value={bank}
                        >
                          <option value="" defaultChecked hidden>
                            {Amc ? "Select Bank" : "First Select AMC"}
                          </option>
                          {renderBankDropDown()}
                        </select>
                      </div>
                    </Col>
                    {/* {letter == "Pre Ramzan PO CDR" && (
                      <> */}
                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label>Select Branch</label>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          label={'Select Branch'}
                          onChange={(e: any) => {
                            setBranchSelect(e.value);
                            accountSelect.length = 0;
                            setBankCity(e?.value.split("–")[1]);
                            setBankBranch(e?.value.split("–")[0]);
                            setAccountSelect([]);
                            accountsOption.length = 0
                            setAccountOption([])
                            accountsToLetter.length = 0
                            setAccountsToLetter([])
                            allBank.filter((x) => {
                              if (
                                x.branch_name.trim().toLowerCase() ==
                                e.value.split("–")[0].trim().toLowerCase() &&
                                x.bank_city.trim().toLowerCase() ==
                                e.value.split("–")[1].trim().toLowerCase() &&
                                x.bank_name.trim().toLowerCase() == bank.trim().toLowerCase()
                              ) {
                                accountsOption.push({
                                  value: `${x.account_title} – ${x.account_no}`,
                                  label: `${x.account_title} – ${x.account_no}`
                                });
                              }
                            });
                            if (accountsOption.length > 0) {
                              accountsOption.unshift({
                                value: "*",
                                label: "ALL"
                              })
                              setAccountOption(accountsOption)

                            }
                          }}
                          value={allBranch.filter(
                            (option) =>
                              option.value === branchSelect
                          )}
                          options={allBranch}
                          isClearable={false}
                          isSearchable={true}
                          name="color"
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label>Select Account</label>
                        <Select
                          isMulti
                          options={accountsOption}
                          placeholder={accountsToLetter.length > 0 ? `${accountsToLetter.length} Selected` : 'Select...'}
                          components={{
                            Option, MultiValue
                          }}
                          className={
                            "basic-multi-select " + ErrorBorder(accountSelect)
                          }
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(e) => {
                            let index = e.findIndex(x => x.label == "ALL")
                            let indexSelect = accountSelect.findIndex(x => x.label == "ALL")
                            if (index > -1 && indexSelect < 0) {
                              let temp = [...accountsOption]
                              temp.shift()
                              setAccountSelect([...accountsOption]);
                              setAccountsToLetter([...temp])

                            }
                            else if (index < 0 && accountSelect.length == accountsOption.length) {
                              setAccountSelect([]);
                              setAccountsToLetter([])

                            }
                            else if (index < 0) {
                              let temp = e;
                              setAccountSelect([...temp]);
                              setAccountsToLetter([...temp])

                            } else if (index > -1 && e.length != accountsOption.length) {
                              let temp = [...e]
                              temp.splice(index, 1)
                              setAccountSelect([...temp]);
                              setAccountsToLetter([...temp])

                            }




                          }}
                          allowSelectAll={true}
                          value={accountSelect}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          controlShouldRenderValue={false}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label>Staff</label>
                        <Select
                          isMulti
                          options={staffOption}
                          placeholder={staffToLetter.length > 0 ? `${staffToLetter.length} Selected` : 'Select...'}
                          components={{
                            Option, MultiValue
                          }}
                          className={
                            "basic-multi-select " + ErrorBorder(staffSelect)
                          }
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(e) => {
                            let index = e.findIndex(x => x.label == "ALL")
                            let indexSelect = staffSelect.findIndex(x => x.label == "ALL")
                            if (index > -1 && indexSelect < 0) {
                              let temp = [...staffOption]
                              temp.shift()
                              setStaffSelect([...staffOption]);
                              setStaffToLetter([...temp])

                            }
                            else if (index < 0 && staffSelect.length == staffOption.length) {
                              setStaffSelect([]);
                              setStaffToLetter([])

                            }
                            else if (index < 0) {
                              let temp = e;
                              setStaffSelect([...temp]);
                              setStaffToLetter([...temp])

                            } else if (index > -1 && e.length != staffOption.length) {
                              let temp = [...e]
                              temp.splice(index, 1)
                              setStaffSelect([...temp]);
                              setStaffToLetter([...temp])

                            }
                          }}
                          allowSelectAll={true}
                          value={staffSelect}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          controlShouldRenderValue={false}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="form-group">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <label>Authorized Reason</label>
                        <select
                          className={"form-control " + ErrorBorder(autorizedReason)}
                          onChange={(e) => {
                            setAutorizedReason(e.target.value);
                          }}
                          value={autorizedReason}
                        >
                          <option value="" defaultChecked hidden>
                            Select Authorization Reason
                          </option>
                          <option value="CB / PO / DD">
                            CB / PO / DD
                          </option>
                          <option value="Bank Statement">
                            Bank Statement
                          </option>
                        </select>
                      </div>
                    </Col>

                    {
                      autorizedReason === 'CB / PO / DD' && (
                        <Col md="2">
                          <div className="form-group ml-2 mr-2">
                            <label>Select Dilivery Of</label>
                            <Select
                              isMulti
                              options={authorizedOption}
                              placeholder={authorizedToLetter.length > 0 ? `${authorizedToLetter.length} Selected` : 'Select...'}
                              components={{
                                Option, MultiValue
                              }}
                              className={
                                "basic-multi-select " + ErrorBorder(authorizedSelect)
                              }
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onChange={(e) => {
                                let index = e.findIndex(x => x.label == "ALL")
                                let indexSelect = authorizedSelect.findIndex(x => x.label == "ALL")
                                if (index > -1 && indexSelect < 0) {
                                  let temp = [...authorizedOption]
                                  temp.shift()
                                  setAuthorizedSelect([...authorizedOption]);
                                  setAuthorizedToLetter([...temp])

                                }
                                else if (index < 0 && authorizedSelect.length == authorizedOption.length) {
                                  setAuthorizedSelect([]);
                                  setAuthorizedToLetter([])

                                }
                                else if (index < 0) {
                                  let temp = e;
                                  setAuthorizedSelect([...temp]);
                                  setAuthorizedToLetter([...temp])

                                } else if (index > -1 && e.length != authorizedOption.length) {
                                  let temp = [...e]
                                  temp.splice(index, 1)
                                  setAuthorizedSelect([...temp]);
                                  setAuthorizedToLetter([...temp])

                                }
                              }}
                              allowSelectAll={true}
                              value={authorizedSelect}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              controlShouldRenderValue={false}
                            />
                          </div>
                        </Col>
                      )
                    }
                    {
                      autorizedReason === 'Bank Statement' &&
                      (
                        <>
                          <Col md="2">
                            <div className={"form-group "}>
                              <label>From Date</label>
                              <input
                                type="date"
                                className=
                                {
                                  'form-control w-100 ' +
                                  ErrorBorder(fromDate)
                                }
                                value={fromDate}
                                onChange={(e) => {
                                  setFromDate(e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="form-group  ">
                              <label>To Date</label>
                              <input
                                type="date"
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(toDate)
                                }
                                value={toDate}
                                onChange={(e) => {
                                  setToDate(e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                        </>
                      )
                    }
                    <Col>

                      <button
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          if (
                            Amc == "" ||
                            bank == "" ||
                            branchSelect == "" ||
                            letter == "" ||
                            autorizedReason == "" ||
                            (authorizedSelect.length === 0 && autorizedReason !== 'Bank Statement') ||
                            (fromDate === '' && autorizedReason === 'Bank Statement') ||
                            (toDate === '' && autorizedReason === 'Bank Statement') ||
                            accountSelect.length == 0 ||
                            staffSelect.length == 0 
                            // accountSelect.length == 0
                          ) {
                            setErrorShow(true);
                          } else if (accountSelect.length == 0) {
                            toast.error("No Bank Account found");
                          } else {
                            setDisplayLetterCheck(true);
                          }
                        }}
                      >
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Preview</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {renderModalForLetterPopup()}
          </Container>
        </Col>
      </Row>
    </div>
  );
};
export default StaffLetter;
