import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { ipsHoldingStatementReport } from '../../stores/services/report.service';
import { isNumber, numberWithCommas, sortAMCS } from '../../utils/customFunction';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { getAccountByAmc } from '../../stores/services/account.service';

const IPSHoldingStatement = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [ipsHoldingStatementData, setIPSHoldingStatementData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [amcCode, setAmcCode] = useState('');
  const [fund, setFund] = useState('');
  const [fundCode, setFundCode] = useState('');
  const [fundMoneyMarketData, setFundMoneyMarketData] = useState<any>([])
  const [moneyMarketData, setMoneyMarketData] = useState<any>([])
  const [securityType, setSecurityType]= useState('');
  const [ipsAccount, setIPSAccount]= useState('');
  // const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [reportDate, setReportDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resSecurityType, setResSecurityType]= useState('');
  const [resIPSAccount, setResIPSAccount]= useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));
  let [totalFaceVal, setTotalFaceVal]= useState('');

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           let sortAMCSByName = await sortAMCS(amcResponse.data.data);
           setAllAmc(sortAMCSByName);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
            }
          }
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_','')}>
                {item.name}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{
              allFundAccounts.length=0;
              setAllFundsAccounts(allFundAccounts);
              allFunds.length=0;
              setAllFunds(allFunds);
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }

            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }

  //code for search data for Reports
  const searchData = async () => {  
        try {
           ipsHoldingStatementData.length=0;
           setIPSHoldingStatementData(ipsHoldingStatementData);
           setLoading(true);
           const response = await ipsHoldingStatementReport(email, amcCode, fundCode, securityType, reportDate);
           if(response.data.status===200){
            let data:any=[];
            data=response.data.data.filter(item => item !== undefined);
            let face_value=0;
            data.map((item:any, index:number)=>{
              face_value+=parseFloat(isNumber(item.face_value));
            })
            await setTotalFaceVal(face_value.toFixed(4));
            setMoneyMarketData(response.data.data.filter(item => item !== undefined));
            setResAmc(Amc);
            setResFund(fund);
            setResSecurityType(securityType);
            setResIPSAccount(ipsAccount);
            // setResFromDate(fromDate);
            setReportDate(reportDate);
            setLoading(false);
          }else{
            moneyMarketData.length=0;
            setMoneyMarketData(moneyMarketData);
            toast.error(response.data.message);
            setLoading(false);
          }
            
        } catch (err:any) {
            setLoading(false);
            if (err.response !== undefined) {
                setMoneyMarketData([]);
                toast.error(err.response.data.message)
                setLoading(false);
            } else {
              toast.error(err.message)
            }
        }
      }
    

  // const searchData = async () => { 
  //   setLoading(true); 
  //   // setTimeout( async () => {
  //     let filterdSecurities = fundMoneyMarketData.map((item:any)=>{
  //       if(securityType === item.security_type){
  //         return item;
  //       }
  //     })
      
  //     let data:any=[];
  //     data=filterdSecurities.filter(item => item !== undefined);
  //      let face_value=0;
  //      data.map((item:any, index:number)=>{
  //        face_value+=parseFloat(item.face_value);
  //      })
  //      await setTotalFaceVal(face_value.toFixed(2));
  //      setMoneyMarketData(filterdSecurities.filter(item => item !== undefined));
  //      setLoading(false);
  //   // }, 2000);
     
  // }

  function dateDiff(date1, endDate) {
    date1 = date1.split('-');
    var today = new Date(endDate);
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var yy = parseInt(date1[0]);
    var mm = parseInt(date1[1]);
    var dd = parseInt(date1[2]);
    var years, months, days;
    // months
    months = month - mm;
    if (day < dd) {
        months = months - 1;
    }
    // years
    years = year - yy;
    if (month * 100 + day < mm * 100 + dd) {
        years = years - 1;
        months = months + 12;
    }
    // days
    days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
    //
    return {
        years: years,
        months: months,
        days: days
    };
}

const getWordedDiff = (dateDiff) => {
    let phrase = '';
    if (dateDiff.years) {
        phrase += dateDiff.years + ' Year';
    }
    if (dateDiff.months) {
        phrase += dateDiff.months + ' Month';
    }
    if (dateDiff.days) {
        phrase += dateDiff.days + ' Day';
    }
    return phrase;
}

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">IPS Holding Statement</h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                      setAmcCode(e.target.value);
                      moneyMarketData.length = 0;
                        getFunds(e.target.value);
                        
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
                        setFundCode(e.target.value);
                        moneyMarketData.length = 0;
                        setSecurityType('-1')
                        let filterFund = allFunds.filter((item, any)=>{
                          return (item.symbol_code===e.target.value);
                       });
                       setFundMoneyMarketData(JSON.parse(filterFund[0].money_market))

                      
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>
              <Col md="2">
                  {/* <div className=" inline-box">	 */}
                    <div className="form-group ml-2 mr-2">	
                    <label >Security Type</label>
                      <select	
                        className="form-control"	
                        onChange={(e) => {	
                          setSecurityType(e.target.value);
                        moneyMarketData.length = 0;	
                        }}	
                        value={securityType}	
                      >	
                        <option value="-1">Select type</option>	
                        <option value="All">All</option>	
                        <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasury Bills</option>
                      <option value='SUKUK'>Sukuk Certificates</option>	
                      <option value='IJARA'>GoP Ijara Sukuks</option>
                      </select> 	
                      </div>	

                      </Col>
                      <Col md="2">
                <div className="form-group  ">
                  <label>Date</label>
                  <input
                    type="date"
                    defaultValue={reportDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setReportDate(date);
                    }}
                  />
                </div>
              </Col>
            {/* <Col md="2">
                <div className="form-group  ">
                  <label>Security Type</label>
                  <select 
                      className='form-control'
                      onChange={(e) => {
                        setSecurityType(e.target.value);
                        moneyMarketData.length = 0;
                    }}>
                       <option value="" defaultChecked hidden>
                        Select Security</option>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasuary bill</option>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      </select>
                </div>
              </Col> */}

            {/* <Col md="2">
                <div className="form-group  ">
                  <label>IPS Account No.</label>
                  <input
                    type="text"
                    defaultValue={ipsAccount}
                    className="form-control"
                    onChange={(e) => {  
                        setIPSAccount(e.target.value);
                    }}
                  />
                </div>
              </Col>

              
            
              {/* here Search Button */}
              <Col md="2">
                <div className=" ">
               <button
                className="btn btn-primary mt-4"
                 onClick={async() => {
                   await searchData();
                }}
                 disabled={Boolean(loading)}
               >
               {loading ? (
                 <>
                   <span
                     className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                   <span>Search</span>
                )}
              </button>
             </div>
             </Col>
              
            </Row>
            {/* End */}
          </div>
          </div>

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"IPS-Holding-Statement-"+date+".pdf"} ref={pdfExportComponent}>
              {moneyMarketData.length > 0 ? (
                <>
                <ReportHeader title="IPS Holding Statement"/>
                {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">IPS Holding Statement</h3>
                  </div> */}

<Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{fund}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Security Type:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{securityType!=='' ? securityType : 'All'}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>

                  {/* <Row>
                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{Amc}</span>
                        </Col>
                      </Row>
                    </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Fund:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap" >{fund}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Security Type:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap" >{securityType!=='' ? securityType : 'All'}</span>
                          </Col>
                        </Row>
                      </Col>

                     {resIPSAccount!=='' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              IPS Account No:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap" >{resIPSAccount}</span>
                          </Col>
                        </Row>
                      </Col>
                      )}

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              From Date:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col> 

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row> */}
                  
                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                            {/* <th></th> */}
                            <th>Security Name</th>
                            <th>Tenure</th>
                            <th>Issue Date</th>
                            <th>Maturity Date</th>
                            <th className="text-right" style={{paddingRight:'10px'}}>Total Face Value</th>
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                        {moneyMarketData.map((item: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                {/* <td></td> */}
                               <td>{item.name}</td>
                               <td>{getWordedDiff(dateDiff(item.issue_date,item.maturity_date))}</td>
                               <td>{moment(item.issue_date).format('DD-MM-YYYY')}</td>
                               <td>{moment(item.maturity_date).format('DD-MM-YYYY')}</td>
                               <td className="text-right">{numberWithCommas(parseFloat(isNumber(item.face_value)).toFixed(4))}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(totalFaceVal)).toFixed(4))}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
             Print
            </button>

          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default IPSHoldingStatement;









// import React, { useEffect } from 'react';
// import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { Container, Row, Col } from 'reactstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment';
// import { BsTable } from 'react-icons/bs';
// import { getAmc } from '../../stores/services/amc.service';
// import { getFundByAmc } from '../../stores/services/funds.service';
// import { ipsHoldingStatementReport } from '../../stores/services/report.service';
// import { numberWithCommas, sortAMCS } from '../../utils/customFunction';
// import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import ReportHeader from './report-header';

// const IPSHoldingStatement = () => {
//   const pdfExportComponent = React.useRef<PDFExport>(null);
//   const [loading, setLoading] = useState(false);
//   const email = sessionStorage.getItem('email') || '';
//   let date = new Date();
//   const [ipsHoldingStatementData, setIPSHoldingStatementData] = useState<any>([]);
//   const [allAmc, setAllAmc] = useState<any>([]);
//   const [allFunds, setAllFunds] = useState<any>([]);
//   const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
//   const [Amc, setAmc] = useState('');
//   const [amcCode, setAmcCode] = useState('');
//   const [fund, setFund] = useState('');
//   const [fundCode, setFundCode] = useState('');
//   const [securityType, setSecurityType]= useState('');
//   const [ipsAccount, setIPSAccount]= useState('');
//   const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
//   const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

//   const [resAmc, setResAmc] = useState('');
//   const [resFund, setResFund] = useState('');
//   const [resSecurityType, setResSecurityType]= useState('');
//   const [resIPSAccount, setResIPSAccount]= useState('');
//   const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
//   const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

//         useEffect(()=>{
//           const getAllAMC=async()=>{
//             try{
//            const amcResponse = await getAmc(email, '', '', '');
//            let sortAMCSByName = await sortAMCS(amcResponse.data.data);
//            setAllAmc(sortAMCSByName);
//             }catch(err:any){
//               if(err.response !== undefined ) {
//                 toast.error(err.response.data.message);	
//               }else {
//                 toast.error("Request Failed!")
//               }
//             }
//           }
//             getAllAMC();
//         },[])

//         let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

//         const CapTxnType = (type: any) => {
//             let i, txType;
//             for (i = 0; i < txnTypes.length; i++) {
//             if (txnTypes[i].api_name === type) {
//                 txType = txnTypes[i].dis_name;
//                 break;
//             }
//             }
//             return txType;
//         };

//         const renderAmcDropdown = () => {
//             return allAmc.map((item: any, index: number) => {
//             return (
//                 <option key={index} value={item.amc_code.replace('AMC_','')}>
//                 {item.name}
//                 </option>
//             );
//             });
//         };

//         const getFunds=async(code:any)=>{
//             try{
//               allFundAccounts.length=0;
//               setAllFundsAccounts(allFundAccounts);
//               allFunds.length=0;
//               setAllFunds(allFunds);
//                 const response = await getFundByAmc(email, code);
//                 setAllFunds(response.data.data);
//             }catch(error){

//             }
//         }

//         const renderFundsDropDown=()=>{
//             return allFunds.map((item: any, index: number) => {
//                 return (
//                     <option key={index} value={item.symbol_code}>
//                     {item.fund_name} - {item.symbol_code}
//                     </option>
//                 );
//           });
//         }

//   //code for search data for Reports

//   const searchData = async () => {  
//     try {
//        ipsHoldingStatementData.length=0;
//        setIPSHoldingStatementData(ipsHoldingStatementData);
//        setLoading(true);
//        const response = await ipsHoldingStatementReport(email, amcCode, fundCode, securityType, ipsAccount, fromDate, toDate);
//        setIPSHoldingStatementData(response.data.data);
//        setResAmc(Amc);
//        setResFund(fund);
//        setResSecurityType(securityType);
//        setResIPSAccount(ipsAccount);
//        setResFromDate(fromDate);
//        setResToDate(toDate);
//        setLoading(false);
//     } catch (error) {
//         setLoading(false);
//     }
//   }

//   function dateDiff(date1, endDate) {
//     date1 = date1.split('-');
//     var today = new Date(endDate);
//     var year = today.getFullYear();
//     var month = today.getMonth() + 1;
//     var day = today.getDate();
//     var yy = parseInt(date1[0]);
//     var mm = parseInt(date1[1]);
//     var dd = parseInt(date1[2]);
//     var years, months, days;
//     // months
//     months = month - mm;
//     if (day < dd) {
//         months = months - 1;
//     }
//     // years
//     years = year - yy;
//     if (month * 100 + day < mm * 100 + dd) {
//         years = years - 1;
//         months = months + 12;
//     }
//     // days
//     days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
//     //
//     return {
//         years: years,
//         months: months,
//         days: days
//     };
// }

// const getWordedDiff = (dateDiff) => {
//     let phrase = '';
//     if (dateDiff.years) {
//         phrase += dateDiff.years + ' Year';
//     }
//     if (dateDiff.months) {
//         phrase += dateDiff.months + ' Month';
//     }
//     if (dateDiff.days) {
//         phrase += dateDiff.days + ' Day';
//     }
//     return phrase;
// }

//   return (
//     <>
//     <div className="content">
//       <Row>
//         <Col md="12">
//       <Container fluid>
//         <ToastContainer limit={1} />
//         <div className="">
//           <div className="card">
//             <div className="card-header">
//               <h4 className="card-title">IPS Holding Statement</h4>
//             </div>
//             <div className="card-body">
//             <Row>
//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Select AMC</label>
//                   <select
//                     className="form-control"
//                     onChange={(e) => {
//                       setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
//                       setAmcCode(e.target.value);
//                         getFunds(e.target.value);
//                     }}
//                   >
//                     <option value="" defaultChecked hidden>
//                       Select AMC
//                     </option>
//                     {renderAmcDropdown()}
//                   </select>
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group">
//                   <label>Select Fund</label>
//                   <select
//                     className="form-control"
//                     onChange={(e) => {
//                         setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text);
//                         setFundCode(e.target.value);
//                       //   let filterFund = allFunds.filter((item, any)=>{
//                       //     return (item.symbol_code===e.target.value);
//                       //  });
                       
//                     }}>
//                       <option value="" defaultChecked hidden>
//                       Select Fund
//                     </option>
//                       {renderFundsDropDown()}
//                   </select>
//                 </div>
//               </Col>

//             <Col md="2">
//                 <div className="form-group  ">
//                   <label>Security Type</label>
//                   <select 
//                       className='form-control'
//                       onChange={(e) => {
//                         setSecurityType(e.target.value);
//                     }}>
//                        <option value="" defaultChecked hidden>
//                               {' '}
//                         Select Security</option>
//                       <optgroup>Select Security</optgroup>
//                       <option value='PIB'>Pakistan Investment Bonds</option>
//                       <option value='TBILL'>Treasuary bill</option>
//                       <option value='SUKUK'>Sukuk Certificates</option>
//                       </select>
//                 </div>
//               </Col>

//             <Col md="2">
//                 <div className="form-group  ">
//                   <label>IPS Account No.</label>
//                   <input
//                     type="text"
//                     defaultValue={ipsAccount}
//                     className="form-control"
//                     onChange={(e) => {  
//                         setIPSAccount(e.target.value);
//                     }}
//                   />
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Date From</label>
//                   <input
//                     type="date"
//                     defaultValue={fromDate}
//                     className="form-control"
//                     onChange={(e) => {
//                         let date = moment(e.target.value).format('YYYY-MM-DD');
//                         setFromDate(date);
//                     }}
//                   />
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group  ">
//                   <label>Date To</label>
//                   <input
//                     type="date"
//                     defaultValue={toDate}
//                     className="form-control"
//                     onChange={(e) => {
//                         let date = moment(e.target.value).format('YYYY-MM-DD');
//                         setToDate(date);
//                     }}
//                   />
//                 </div>
//               </Col>
            
//               <Col md="2">
//                 <div className=" ">
//               {/* here Search Button */}
//               <button
//                 className="btn btn-primary mt-4"
//                 onClick={() => {
//                   searchData();
//                 }}
//                 disabled={Boolean(loading)}
//               >
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border login-txt spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     <span className="login-txt"> Loading...</span>
//                   </>
//                 ) : (
//                   <span>Search</span>
//                 )}
//               </button>
//             </div>
//             </Col>
//             </Row>
//             {/* End */}
//           </div>
//           </div>

//           {/* here Search results for report Data */}
//           <div className="card mt-3"> 
//             <div className="card-body">
//             <div
//               id="printDiv"
//               className="p-4"
//               style={{ background: 'white', color: 'black' }}
//             >
//               <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"IPS-Holding-Statement-"+date+".pdf"} ref={pdfExportComponent}>
//               {ipsHoldingStatementData.length > 0 ? (
//                 <>
//                 <ReportHeader title="IPS Holding Statement"/>
//                 {/* <div className="title-row d-flex justify-content-center">
//                  <img src={DCClogo} alt="MACS-DCC" height="150" />
//                  </div>
//                   <div className="title-row m-4 d-flex justify-content-center">
//                     <h3 className="text-black">IPS Holding Statement</h3>
//                   </div> */}

//                   <Row>
//                     <Col md="4" style={{ marginLeft: '70px' }}>
//                       <Row>
//                         <Col md="5">
//                           <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                             AMC:
//                           </span>
//                         </Col>
//                         <Col md="6">
//                           <span className="text-nowrap">{resAmc}</span>
//                         </Col>
//                       </Row>
//                     </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               Fund:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap" >{resFund}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               Security Type:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap" >{resSecurityType!=='' ? resSecurityType : 'All'}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                     {resIPSAccount!=='' && (
//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               IPS Account No:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap" >{resIPSAccount}</span>
//                           </Col>
//                         </Row>
//                       </Col>
//                       )}

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
//                               From Date:
//                             </span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                       <Col md="4" style={{ marginLeft: '70px' }}>
//                         <Row>
//                           <Col md="5">
//                             <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
//                           </Col>
//                           <Col md="6">
//                             <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
//                           </Col>
//                         </Row>
//                       </Col>

//                   </Row>
                  
//                   <div
//                     style={{
//                       overflowX: 'auto',
//                       overflowY: 'hidden',
//                     }}
//                   >
//                     <table className="report" style={{ color: 'black' }}>
//                       <thead style={{ alignSelf: 'center' }}>
//                         <tr>
//                             {/* <th></th> */}
//                             <th>Security Name</th>
//                             <th>Tenure</th>
//                             <th>Issue Date</th>
//                             <th>Maturity Date</th>
//                             <th>Type</th>
//                             <th className="text-right">Total Face Value</th>
//                         </tr>
//                       </thead>
//                       <tbody className="report-table-body">
//                         {ipsHoldingStatementData.map((item: any, index: number) => {
//                           return (
//                             <>
//                               <tr key={index}>
//                                 {/* <td></td> */}
//                                <td>{item.symbol}</td>
//                                <td>{getWordedDiff(dateDiff(item.issue_date,item.maturity_date))}</td>
//                                <td>{moment(item.issue_date).format('DD-MM-YYYY')}</td>
//                                <td>{moment(item.maturity_date).format('DD-MM-YYYY')}</td>
//                                <td>{item.type}</td>
//                                <td className="text-right">{numberWithCommas(item.total_face_value)}</td>
//                               </tr>
//                             </>
//                           );
//                         })}
//                       </tbody>
//                     </table>
//                   </div>
//                 </>
//               ) : (
//                 <div className="table-empty">
//                   <BsTable className="icon" />
//                   <p className="text-center empty-text">No record found</p>
//                 </div>
//               )}
//                </PDFExport>
//             </div>
//             <button
//               className="btn btn-primary mt-3"
//               onClick={() => {
//                 // printReport();
//                 if (pdfExportComponent.current) {
//                   pdfExportComponent.current.save();
//                 }
//               }}
//             >
//              Print
//             </button>

//           </div>
//         </div>
//         </div>
//       </Container>
//       </Col>
//         </Row>
//       </div>
//     </>
//   );
// };

// export default IPSHoldingStatement;
