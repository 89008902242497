import React from 'react';
import { useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAmc } from '../../stores/services/amc.service';
import { getAccountByFund } from '../../stores/services/account.service';
import {
  getAccounttxn,
  changeReleaseCheck,
} from '../../stores/services/beta-transaction.service';
import {
  addReconciliation,
  getReconData,
} from '../../stores/services/report.service';
import moment from 'moment';
import { IoIosAddCircle } from 'react-icons/io';
import Pdf from '@mikecousins/react-pdf';
import { Modal } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import {sortAMCS} from '../../utils/customFunction';

// Core viewer
const csv = require('csvtojson/v2');

const ReconciliationScreen = () => {
  //set pdf path
  var pdfurl =
    'https://s3-ap-southeast-1.amazonaws.com/happay-local/HVP/BILL20198261213473719445688HP.pdf';
  // you can get full URL to display PDF by using getPdfHtmlURL() function

  // Create new plugin instance
  const [page, setPage] = useState(1);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false); // for save btn loading
  const [uploadLoading, setUploadLoading] = useState(false); // for upload btn loading
  const [dataLoading, setDataLoading] = useState(false); // for loading trusty data btn
  const [data, setData] = useState<any>([]);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  let date = new Date();

  const [fields, setFields] = useState({
    amc_code: '',
    fund_code: '',
    account_no: '',
    year: '',
    month: '',
    recon_date: moment(date).format('YYYY-MM-DD'),
    bank_only_txn: '',
    system_only_txn: '',
    open_balance: '',
    close_balance: '',
    reconcile_balance: '',
    bank_closing: '',
    unconciled: '',
  });

  const [changCheck, setChangeCheck] = useState('');
  const [systemData, setSystemData] = useState([]);

  const [viewAddModal, setViewAddModal] = useState(false);
  const [bankonlyTxn, setBankOnlyTxn] = useState<any>([]);
  const [bankonlySpecificTxn, setBankOnlySpecificTxn] = useState<any>([]);
  const [bankOnlyEntity, setBankOnlyEntity] = useState<any>({
    id: '',
    date: '',
    dr_amount: '0',
    cr_amount: '0',
    balance: '',
    txn_type: '',
    description: '',
    realized: true,
  });

  const [allSavedRecon, setAllSavedRecon] = useState<any>([]);

  const getSavedReconByAccount = async () => {
    let accountCode = '';
    accountNoData.map((item, index) => {
      if (item.account_no === fields.account_no) {
        accountCode = item.account_code;
      }
    });
    try {
      const res = await getReconData(email, fields, accountCode);
      if (res.data.data.length > 0) {
        fields.open_balance = res.data.data[0].open_balance;
        fields.close_balance = res.data.data[0].close_balance;
        fields.reconcile_balance = res.data.data[0].reconcile_balance;
        fields.bank_closing = res.data.data[0].bank_closing;
        fields.unconciled = res.data.data[0].unconciled;
        if (
          res.data.data[0].bank_only_txn !== '' &&
          res.data.data[0].bank_only_txn !== '[]' &&
          res.data.data[0].bank_only_txn !== undefined
        ) {
          let bankOnly = JSON.parse(res.data.data[0].bank_only_txn);
          setBankOnlySpecificTxn(bankOnly);
        } else {
          bankonlySpecificTxn.length = 0;
          setBankOnlySpecificTxn(bankonlySpecificTxn);
        }
        setChangeCheck(res.data.data.length);
      }
      setAllSavedRecon(res.data.data);
    } catch (error) {}
  };

  const [perviousBlance, setPerviousBlance] = useState('0');
  const [arrayIndex, setArrayIndex] = useState(0);
  const addTxnInArray = () => {
    var timestamp = new Date().valueOf().toString();
    var lastFive = timestamp.substr(timestamp.length - 5);
    bankOnlyEntity['id'] = lastFive;
    let count = 0;
    for (let i in bankOnlyEntity) {
      if (i !== 'realized' && i !== 'balance') {
        if (bankOnlyEntity[i] === '') {
          count = count + 1;
        }
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
    } else {
      if (+bankOnlyEntity.cr_amount > 0) {
        let temp = +fields['reconcile_balance'] + +bankOnlyEntity.cr_amount;
        fields['reconcile_balance'] = temp.toString();

        bankOnlyEntity['balance'] = temp.toFixed(2);
      }
      if (+bankOnlyEntity.dr_amount > 0) {
        let tempx = +fields['reconcile_balance'] - +bankOnlyEntity.dr_amount;

        fields['reconcile_balance'] = tempx.toString();
        bankOnlyEntity['balance'] = tempx.toFixed(2);
      }
      // bankonlyTxn.splice(arrayIndex + 1, 0, bankOnlyEntity);

      setChangeCheck(fields['reconcile_balance']);
      bankonlySpecificTxn.push(bankOnlyEntity);
      setBankOnlySpecificTxn(bankonlySpecificTxn);
      setViewAddModal(false);
    }
  };

  const [systemOnlyTxn, setSystemOnlyTxn] = useState<any>([]);
  const [systemOnlyEntity, setSystemOnlyEntity] = useState<any>({
    id: '',
    date: '',
    dr_amount: '0',
    cr_amount: '0',
    txn_type: '',
    description: '',
  });

  //in case when uncheck and txn add into system only
  const addSystemOnlyTxnInArray = (items) => {
    systemOnlyEntity['id'] = items.id;
    systemOnlyEntity['date'] = moment(items.date).format('DD-MMM-YYYY');
    if (items.dr_amount !== undefined) {
      systemOnlyEntity['dr_amount'] = parseFloat(items.dr_amount).toFixed(2);
    }
    if (items.cr_amount !== undefined) {
      systemOnlyEntity['cr_amount'] = parseFloat(items.cr_amount).toFixed(2);
    }
    systemOnlyEntity['txn_type'] = items.txn_type;
    systemOnlyEntity['description'] = CapTxnType(items.txn_type);

    systemOnlyTxn.push(systemOnlyEntity); // push entity into array
    setSystemOnlyTxn(systemOnlyTxn);

    setChangeCheck(systemOnlyEntity.id);
  };
  //end

  //in case when check and remove txn from system only
  const removeFromSystemOnlyArray = (txnId) => {
    const check = systemOnlyTxn.filter((item) => {
      return item.id !== txnId;
    });
    setSystemOnlyTxn(check);
    setChangeCheck(txnId);
  };

  //in case when check and remove txn from Bank only
  const removeFromBankOnlyArray = (txnId) => {
    const check = bankonlySpecificTxn.filter((item) => {
      return item.id !== txnId;
    });
    setBankOnlySpecificTxn(check);
    setChangeCheck(txnId);
  };

  const renderAddModal = () => {
    switch (viewAddModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setViewAddModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddModal(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Add Transaction </h6>
             </div>   
            <div className="modal-body">
              <div className="">
                <Row>
                  <Col>
                    <div className="form-group">
                      Date*
                      <input
                        type="date"
                        className="form-control w-100"
                        value={bankOnlyEntity.date}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Txn Type*
                      {/* <select
                        className="form-control w-100"
                        value={bankOnlyEntity.txn_type}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            txn_type: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden> Select Type </option>
                         
                        {renderTransactionDropdown()}
                      </select> */}
                      <select
                        className="form-control w-100"
                        value={bankOnlyEntity.txn_type}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            txn_type: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden> Select Type </option>
                        <option value="inflow">Inflow</option>
                        <option value="taxpayment">Tax Payment</option> 
                        <option value="bankcharges">Bank Charges</option>
                        <option value="bankprofit">Bank Profit</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      Dr Amount*
                      <input
                        type="number"
                        className="form-control w-100"
                        value={bankOnlyEntity.dr_amount}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            dr_amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Cr Amount*
                      <input
                        type="number"
                        className="form-control w-100"
                        value={bankOnlyEntity.cr_amount}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            cr_amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      Description*
                      <input
                        type="text"
                        className="form-control w-100"
                        value={bankOnlyEntity.description}
                        onChange={(e) => {
                          setBankOnlyEntity({
                            ...bankOnlyEntity,
                            description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-group mt-5">
                  <button
                    type="button"
                    className="btn btn-primary btn-block px-3 rounded"
                    onClick={() => {
                      addTxnInArray();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //render dropdown for type of transaction data
  const renderTransactionDropdown = () => {
    return txnTypes.map((item: any, index: number) => {
      return (
        <option key={index} value={item.api_name}>
          {item.dis_name}
        </option>
      );
    });
  };

  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      setLoading(true);
      try {
        const amcResponse = await getAmc(email);
        let sortedData= await sortAMCS(amcResponse.data.data);
        let tempAmc = sortedData.map((val) => {
          return {
            ...val,
            label: `${val.name} - ${val.amc_code.replace('AMC_','')}`,
            value: val.amc_code.replace('AMC_',''),
          };
        });
        setAllAmcData(tempAmc);
      } catch (err:any) {
        // toast.error(error.response.data.message);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderData = () => {
    return bankonlyTxn.map((items: any, index: any) => {
      return (
        <tr key={index}> 
          <td className="reconcil-row">
            {items.date ? moment(items.date).format('DD-MMM-YYYY') : 'N/A'}
          </td>
          <td className="reconcil-row">
            {items.txn_type ? CapTxnType(items.txn_type) : 'N/A'}
          </td>
          <td className="reconcil-row">
            {items.dr_amount ? parseFloat(items.dr_amount).toFixed(2) : ''}
          </td>
          <td className="reconcil-row ">
            {items.cr_amount ? parseFloat(items.cr_amount).toFixed(2) : ''}
          </td>
          <td className="reconcil-row">
            {items.balance ? parseFloat(items.balance).toFixed(2) : ''}
          </td>
          <td className="reconcil-row">
            {items.id.length !== 5 ? (
              <> 
                <input
                  type="checkbox"
                  className="pointer"
                  checked={items['realized']}
                  onChange={(e) => {
                    if (items['realized']) {
                      setSystemOnlyEntity((prevState) => ({
                        ...systemOnlyEntity,
                        id: '',
                        date: '',
                        dr_amount: '0',
                        cr_amount: '0',
                        txn_type: '',
                        description: '',
                      }));
                      //
                      if (items.dr_amount !== undefined) {
                        fields['reconcile_balance'] = (
                          +fields['reconcile_balance'] + +items.dr_amount
                        ).toString();
                      }
                      if (items.cr_amount !== undefined) {
                        fields['reconcile_balance'] = (
                          +fields['reconcile_balance'] - +items.cr_amount
                        ).toString();
                      }

                      items['realized'] = false;
                      addSystemOnlyTxnInArray(items);
                      setChangeCheck('false');
                      ToggleRelease(items.id, 'false');
                    } else {
                      if (items.dr_amount !== undefined) {
                        fields['reconcile_balance'] = (
                          +fields['reconcile_balance'] - +items.dr_amount
                        ).toString();
                      }
                      if (items.cr_amount !== undefined) {
                        fields['reconcile_balance'] = (
                          +fields['reconcile_balance'] + +items.cr_amount
                        ).toString();
                      }
                      items['realized'] = true;
                      removeFromSystemOnlyArray(items.id);
                      setChangeCheck('true');
                      ToggleRelease(items.id, 'true');
                    }
                  }}
                /> 

              </>
            ) : (
              <IoIosClose
                style={{ fontSize: '25px', color: 'red' }}
                onClick={() => {
                  let array = [...bankonlyTxn];
                  array.splice(index, 1);
                  setBankOnlyTxn(array);
                  removeFromBankOnlyArray(items.id);
                }}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  const renderbankonlyTxnData = () => {
    return bankonlySpecificTxn.map((items: any, index: any) => {
      return (
        <tr key={index}>
          
          <td className="reconcil-row">
            {items.date ? moment(items.date).format('DD-MMM-YYYY') : 'N/A'}
          </td>
          <td className="reconcil-row">
            {items.txn_type ? CapTxnType(items.txn_type) : 'N/A'}
          </td>
          <td className="reconcil-row">
            {items.dr_amount ? parseFloat(items.dr_amount).toFixed(2) : ''}
          </td>
          <td className="reconcil-row ">
            {items.cr_amount ? parseFloat(items.cr_amount).toFixed(2) : ''}
          </td>
          <td className="reconcil-row">
            {items.balance ? parseFloat(items.balance).toFixed(2) : ''}
          </td>
          <td className="reconcil-row">
            <IoIosClose
              style={{ fontSize: '25px', color: 'red', cursor: 'pointer' }}
              onClick={() => {
                if (+items.dr_amount > 0) {
                  fields['reconcile_balance'] = (
                    +fields['reconcile_balance'] + +items.dr_amount
                  ).toString();
                }
                if (+items.cr_amount > 0) {
                  fields['reconcile_balance'] = (
                    +fields['reconcile_balance'] - +items.cr_amount
                  ).toString();
                }

                let array = [...bankonlySpecificTxn];
                array.splice(index, 1);
                setBankOnlySpecificTxn(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const [selected, setSelected] = useState<any>([]);
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalUnits, setUnits] = useState('');

  const [allAmcData, setAllAmcData] = useState<any>([]); // all Amc Data
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [allFunds, setAllFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [accountNoData, setAccountNoData] = useState<any>([]); // all Account Numbers  against a Fund
  const [fundCheck, setFundCheck] = useState(false);
  const [accLoading, setAccLoading] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);

  // Get all Funds and accounts of a specific selected Amc
  const getFundByAMcCode = async (code: string) => {
    // fields['fund_code'] = '';
    // fields['account_no'] = '';
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    accountNoData.length = 0;
    setAccountNoData(accountNoData);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.fund_name}-(${val.symbol_code})`,
          value: val.symbol_code,
        };
      });
      setAllFunds(temp);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
        // Stock Market Array
      }
    } catch (error) {}
    // Get acconts by amc Code
    setAccFundLoading(false);
  };

  const getAccountByFundName = async (fund_code: string) => {
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          label: `${val.account_code.replace('ACCOUNT_','')}-(${val.account_no})`,
          value: val.account_no.replace('ACCOUNT_',''),
        };
      });
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };
  const ToggleRelease = async (txn_id, realize_status) => {
    try {
      const response = await changeReleaseCheck(email, txn_id, realize_status);
    } catch (error) {}
  };

  const getDataFromApiCall = async () => { 
    bankonlyTxn.length = 0;
    bankonlySpecificTxn.length = 0;
    setBankOnlyTxn(bankonlyTxn);
    setBankOnlySpecificTxn(bankonlySpecificTxn);
    setDataLoading(true);
    systemOnlyTxn.length = 0;
    setSystemOnlyTxn(systemOnlyTxn); 
    try {
      const res = await getAccounttxn(email, fields);
      let reconBal = 0;
      if (res.data.data.length > 0) {
        await res.data.data.map((items, index) => {
          bankonlyTxn.push({
            id: items.txn_id,
            date: moment(items.created_at).format('DD-MMM-YYYY'),
            dr_amount:
              items.dr_amount !== undefined
                ? parseFloat(items.dr_amount).toFixed(2)
                : '0',
            cr_amount:
              items.cr_amount !== undefined
                ? parseFloat(items.cr_amount).toFixed(2)
                : '0',
            balance:
              items.balance !== undefined
                ? parseFloat(items.balance).toFixed(2)
                : '0',
            txn_type: items.txnr_type,
            description: CapTxnType(items.txnr_type),
            realized: items.realized === 'true' ? true : false,
          });
          setChangeCheck(items.txn_id);
        });
        setBankOnlyTxn(bankonlyTxn);
        await res.data.data.map((items, index) => {
          if (items.realized !== 'true') {
            systemOnlyTxn.push({
              id: items.txn_id,
              date: moment(items.created_at).format('DD-MMM-YYYY'),
              dr_amount:
                items.dr_amount !== undefined
                  ? parseFloat(items.dr_amount).toFixed(2)
                  : '0',
              cr_amount:
                items.cr_amount !== undefined
                  ? parseFloat(items.cr_amount).toFixed(2)
                  : '0',
              balance:
                items.balance !== undefined
                  ? parseFloat(items.balance).toFixed(2)
                  : '0',
              txn_type: items.txnr_type,
              description: CapTxnType(items.txnr_type),
              realized: items.realized === 'true' ? true : false,
            });
          }
          setChangeCheck(items.txn_id);
        });
        setSystemOnlyTxn(systemOnlyTxn);
        fields['open_balance'] =
          bankonlyTxn.length > 0
            ? (
                +bankonlyTxn[0].balance +
                +bankonlyTxn[0].dr_amount -
                +bankonlyTxn[0].cr_amount
              ).toString()
            : '0';
        fields['close_balance'] =
          bankonlyTxn.length > 0
            ? bankonlyTxn[bankonlyTxn.length - 1].balance
            : 0;
        // fields['reconcile_balance'] =
        //   bankonlyTxn.length > 0
        //     ? bankonlyTxn[bankonlyTxn.length - 1].balance
        //     : 0;

        //
        reconBal =
          bankonlyTxn.length > 0
            ? +bankonlyTxn[0].balance +
              +bankonlyTxn[0].dr_amount -
              +bankonlyTxn[0].cr_amount
            : 0;

        await res.data.data.map((items, index) => {
          if (items.realized === 'true') {
            if (items.dr_amount !== undefined) {
              reconBal = reconBal - +items.dr_amount;
            }
            if (items.cr_amount !== undefined) {
              reconBal = reconBal + +items.cr_amount;
            }
          }
          setChangeCheck(items.txn_id);
        });
      }

      fields['reconcile_balance'] = reconBal.toString();
      // setSystemData(res.data.data);
    } catch (error:any) {
      toast.error(error.response.data.message);
      setDataLoading(false);
    }
    await getSavedReconByAccount();
    setDataLoading(false);
  };

  const myRef1 = React.useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const uploadAmcBarFile = (e: any) => {
    debugger;
    let file = e?.target.files[0];
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // log to console
      // logs data:<type>;base64,wL2dvYWwgbW9yZ...
      // setUploadedFile(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadedFile(file);
  };
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const saveRecon = async () => {
    setSaveBtnLoading(true);
    accountNoData.map((item, index) => {
      if (item.account_no === fields.account_no) {
        fields['account_no'] = item.account_code;
      }
    });
    fields['system_only_txn'] = JSON.stringify(systemOnlyTxn);
    fields['bank_only_txn'] = JSON.stringify(bankonlySpecificTxn);
    try {
      const response = await addReconciliation(
        email,
        fields.amc_code,
        fields.fund_code,
        fields.account_no,
        fields.month,
        fields.year,
        fields.recon_date,
        fields.bank_only_txn,
        fields.system_only_txn,
        fields.open_balance,
        fields.close_balance,
        fields.reconcile_balance,
        fields.bank_closing,
        fields.unconciled
      );
      toast.success(response.data.message);
      setFields((prevState) => ({
        ...fields,
        amc_code: '',
        fund_code: '',
        account_no: '',
        month: '',
        year: '',
        recon_date: moment(date).format('YYYY-MM-DD'),
        bank_only_txn: '',
        system_only_txn: '',
        open_balance: '',
        close_balance: '',
        reconcile_balance: '',
        bank_closing: '',
        unconciled: '',
      }));

      setChangeCheck(`${saveBtnLoading}-${allSavedRecon.length}`);
      allSavedRecon.length = 0;
      setAllSavedRecon(allSavedRecon);
    } catch (error) {}
    setSaveBtnLoading(false);
  };

  //render for ReconDropdown
  const renderReconDropdown = () => {
    return allSavedRecon.map((item: any, index: string) => {
      return (
        <option key={index} value={item.reconc_id}>
          {item.recon_date}
        </option>
      );
    });
  };

  const onChangeReconSelection = async (reconId) => {
    allSavedRecon.map((item, index) => {
      if (item.reconc_id === reconId) {
        fields.open_balance = item.open_balance;
        fields.close_balance = item.close_balance;
        fields.reconcile_balance = item.reconcile_balance;
        fields.bank_closing = item.bank_closing;
        fields.unconciled = item.unconciled;
        if (
          item.bank_only_txn !== '' &&
          item.bank_only_txn !== '[]' &&
          item.bank_only_txn !== undefined
        ) {
          let bankOnly = JSON.parse(item.bank_only_txn);
          setBankOnlySpecificTxn(bankOnly);
        } else {
          bankonlySpecificTxn.length = 0;
          setBankOnlySpecificTxn(bankonlySpecificTxn);
        }
      }
    });
    setChangeCheck(reconId);
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        {/* <Pdf file='./sample.pdf'/> */}
        {/* <Document file={pdfurl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document> */}
        <div className="">
          <div className="d-flex align-items-center"></div>
          {!loading ? (
            <div className=""> 
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"> Bank Account Reconciliation</h4>
                </div>
                <div className="card-body">
                <Row>
                  <Col>
                    <div className="form-group">
                      AMC
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={'Select'}
                        isDisabled={false}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            amc_code: e.value,
                            fund_code: '',
                            account_no: '',
                          });
                          bankonlyTxn.length = 0;
                          setBankOnlyTxn(bankonlyTxn);
                          setChangeCheck(`${e.value}`);
                          getFundByAMcCode(e.value);
                        }}
                        value={allAmcData.filter(
                          (option) => option.value === fields['amc_code']
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={allAmcData}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={()=>!true}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Fund
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={'Select'}
                        isDisabled={false}
                        onChange={(e) => {
                           // eslint-disable-next-line 
                          fields['reconcile_balance'];
                          setFields({
                            ...fields,
                            fund_code: e.value,
                            account_no: '',
                          });
                          bankonlyTxn.length = 0;
                          setBankOnlyTxn(bankonlyTxn);
                          setChangeCheck(`${e.value}`);
                          getAccountByFundName(e.value);
                        }}
                        value={allFunds.filter(
                          (option) => option.value === fields['fund_code']
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={allFunds}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Account
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        label={'Select'}
                        isDisabled={false}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            account_no: e.value,
                          });
                          bankonlyTxn.length = 0;
                          setBankOnlyTxn(bankonlyTxn);
                        }}
                        value={accountNoData.filter(
                          (option) => option.value === fields['account_no']
                        )}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={accountNoData}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Recon Date
                      <input
                        type="date"
                        className="form-control w-100"
                        value={fields.recon_date}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            recon_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      Recon Period
                      <input
                        type="month"
                        className="form-control w-100"
                        value={`${fields.year}-${fields.month}`}
                        onChange={(e) => {
                          var res = e.target.value.split('-');
                          setFields({
                            ...fields,
                            month: res[1],
                            year: res[0],
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <div>
                        <button
                          className="btn btn-primary mt-3 rounded w-100"
                          onClick={() => {
                            getDataFromApiCall();
                          }}
                          disabled={Boolean(dataLoading)}
                        >
                          {dataLoading ? (
                            <>
                              {/* <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span> */}
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Load Data</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      ref={myRef1}
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        uploadAmcBarFile(e);
                      }}
                    />
                  </Col>
                  <Col>
                    <input
                      type="file"
                      ref={myRef1}
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        uploadAmcBarFile(e);
                      }}
                    />
                  </Col>
                </Row>
              </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                  Trustee Statement
                  </h4>
                </div>
                <div className="card-body">  
                      <Row>
                        <Col>
                          <div className="form-group">
                            Opening Balance
                            <input
                              type="number"
                              className="form-control w-100"
                              value={
                                bankonlyTxn.length > 0
                                  ? (
                                      +bankonlyTxn[0].balance +
                                      +bankonlyTxn[0].dr_amount -
                                      +bankonlyTxn[0].cr_amount
                                    ).toString()
                                  : '0'
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  open_balance: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            Closing Balance
                            <input
                              type="number"
                              className="form-control w-100"
                              value={
                                bankonlyTxn.length > 0
                                  ? bankonlyTxn[bankonlyTxn.length - 1].balance
                                  : 0
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  close_balance: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row> 

                      <Row>
                    <Col md="6">
                      <div>
                        <h4 className="card-title mb-3">System Transactions</h4>
                        {bankonlyTxn.length > 0 ? (
                          <div className="recol-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="reconcil-row">Date</th>
                                  <th className="reconcil-row">Description</th>
                                  <th className="reconcil-row">Dr Amount</th>
                                  <th className="reconcil-row">Cr Amount</th>
                                  <th className="reconcil-row">Balance</th>
                                  <th className="reconcil-row">Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderData()}</tbody>
                            </table>
                          </div>
                        ) : dataLoading ? (
                          ''
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            {fields['account_no'] === '' ? (
                              <p className="text-center empty-text">
                                Load Data
                              </p>
                            ) : (
                              <p className="text-center empty-text">Empty</p>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ height: '70vh' }}>
                        <div className="d-flex justify-content-between mb-3">
                          <h4 className="card-title ">Bank Only Transactions</h4>
                          <button
                            // style={{ fontSize: '30px' }}
                            className={'mb-2 mr-3   btn-round btn-icon btn btn-primary '}
                            onClick={() => {
                              setBankOnlyEntity((prevState) => ({
                                ...bankOnlyEntity,
                                id: '',
                                date: moment(date).format('YYYY-MM-DD'),
                                dr_amount: '0',
                                cr_amount: '0',
                                balance: '',
                                txn_type: '',
                                description: '',
                                realized: true,
                              }));
                              setPerviousBlance(
                                parseFloat(fields.reconcile_balance).toFixed(2)
                              );
                              setViewAddModal(true);
                            }}
                          >  <i className="fa fa-plus"></i> </button>
                        </div>

                        {bankonlySpecificTxn.length > 0 ? (
                          <div className="recol-scroll">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th className="reconcil-row">Date</th>
                                  <th className="reconcil-row">Description</th>
                                  <th className="reconcil-row">Dr Amount</th>
                                  <th className="reconcil-row">Cr Amount</th>
                                  <th className="reconcil-row">Balance</th>
                                  <th className="reconcil-row">Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderbankonlyTxnData()}</tbody>
                            </table>
                          </div>
                        ) : dataLoading ? (
                          ''
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            {fields['account_no'] === '' ? (
                              <p className="text-center empty-text">
                                Load Data
                              </p>
                            ) : (
                              <p className="text-center empty-text">Empty</p>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                      </div>
              </div>

              
                     


                    <Col md="6" style={{ display: 'none' }}>
                      <h5 className="text-center mt-2">Bank Statement (PDF)</h5>
                      <Row>
                        <Col>
                          <div className="form-group">
                            Opening Balance
                            <input
                              type="number"
                              className="form-control w-100"
                              onChange={(e) => {}}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            Closing Balance
                            <input
                              type="number"
                              className="form-control w-100"
                              onChange={(e) => {}}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="form-holder" style={{ height: '50vh' }}>
                          {systemData.length > 0 ? (
                            <div></div>
                          ) : unitLoading ? (
                            ''
                          ) : (
                            <div className="table-empty">
                              <BsTable className="icon" />
                              {folio_no === '' ? (
                                <p className="text-center empty-text">
                                  Upload Bank Statement
                                </p>
                              ) : (
                                <p className="text-center empty-text">Empty</p>
                              )}
                            </div>
                          )}
                        </div>
                      </Row>
                    </Col>
              
                

              <div className="card">
                <div className="card-body"> 
                    <Row>
                      <Col>
                        <div className="form-group">
                          Reconciled Balance
                          <input
                            className="form-control w-100"
                            type="number"
                            value={fields['reconcile_balance']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                reconcile_balance: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          Bank closing Balance
                          <input
                            className="form-control w-100"
                            type="number"
                            value={fields['bank_closing']}
                            onChange={(e) => {
                              let amount =
                                parseFloat(fields['reconcile_balance']) -
                                parseFloat(e.target.value);
                              debugger;
                              setFields({
                                ...fields,
                                bank_closing: e.target.value,
                                unconciled: amount.toString(),
                              });
                              setChangeCheck(amount.toString());
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          Unconciled Amount
                          <input
                            className="form-control w-100"
                            type="number"
                            value={fields['unconciled']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                unconciled: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <button
                            className="btn btn-primary mt-3 rounded w-100"
                            onClick={() => {
                              saveRecon();
                            }}
                            disabled={Boolean(saveBtnLoading)}
                          >
                            {saveBtnLoading ? (
                              <>
                                {/* <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span> */}
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Save</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                </div>
              </div>
 
              {renderAddModal()}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
             <i className="fa fa-spinner fa-spin fa-3x" ></i> 
            </div>
          )}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ReconciliationScreen;
