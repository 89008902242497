import React from 'react';
import { useState } from 'react';
import Header from '../../components/Header';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import converter from 'number-to-words'

import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  checkTaxBoolean,
} from './field';
import {
  inWords,
  getAllBankTemplates
} from '../../stores/services/template.service';
import { isDateValid, isNumber, numberWithCommas } from '../../utils/customFunction';

import { getLedger, realizedMarkLedger } from '../../stores/services/transactions.service';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { forEach } from 'lodash';
import { FaBullseye, FaRegMoneyBillAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { RiAddLine, RiPrinterLine, RiCheckboxMultipleLine, RiCheckboxLine, RiMailSendFill, RiCheckboxCircleFill } from 'react-icons/ri';
import { FaCreditCard } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { getUnitHoldersByAmcAndFolioNo } from '../../stores/services/unit-holder.service';
import { getAllBanks } from '../../stores/services/bank.service';
import { getHBLID, paymentToBank, postHBLID, updateBankPaymentTransactionStatus } from '../../stores/services/payment-bank';
var typeCheck = require('type-check').typeCheck;


const ViewPaymentTXN = (props: any) => {
  const history = useHistory();
  const [headLabel, setHeadLabel] = React.useState('');
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const [systemTaxFlag, setSystemTaxFlag] = useState(false);
  const [systemTax, setSystemTax] = useState<any>();
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [checkChanges, setCheckChanges] = useState('');

  const [realizedCheck, setRealizedCheck] = useState(false);

  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  const email = sessionStorage.getItem('email') || '';
  const role_name = sessionStorage.getItem('role') || '';
  const [ledgerData, setLedgerData] = useState<any>([]);
  const [countledgerData, setCountLedgerData] = useState(0);
  const [ledgerLoading, setledgerLoading] = useState(false);
  const [onceUpdateRel, setOnceUpdateRel] = useState(false);
  const [paymentData, setPaymentData] = useState<any>({});
  const [ledgerStatus, setLedgerStatus] = useState('');
  let date = new Date();
  let disableDates = moment(date).format('YYYY-MM-DD');
  const [xReqID, setXReqID] = useState('');
  const [customerReferenceNumber, setCustomerReferenceNumber] = useState('');
  const [senderAccountID, setSenderAccountID] = useState('');
  const [restID, setRestID] = useState<any>([]);
  const [bankData, setBankData] = useState<any>([]);

  React.useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(email);
        setBankData(bankResponse.data.data);
      } catch (error) {
      }
    };
    fetchBank();
  }, []);

  const getHblIDS = async () => {
    try {
      const response = await getHBLID(email);
      let numberPart = response.data.data[0].x_req_id.match(/\d+$/)[0];
      let incrementedNumber = parseInt(numberPart) + 1;

      // Replace the number part in the original string
      let incrementedString = response.data.data[0].x_req_id.replace(/\d+$/, incrementedNumber);
      let xReqID: any = incrementedString;
      let customerRefNo: any = incrementedString;

      let numberPart1 = response.data.data[0].sender_account_id.match(/\d+$/)[0];
      let incrementedNumber1 = parseInt(numberPart1) + 1;
      let incrementedString1 = response.data.data[0].sender_account_id.replace(/\d+$/, incrementedNumber1);
      let senderAccID: any = incrementedString1;

      setXReqID(xReqID.toString())
      // setXReqID('62648FFR699')
      setCustomerReferenceNumber(customerRefNo.toString())
      // setCustomerReferenceNumber('62648FFR703')
      // setSenderAccountID('0001IFT0162703')
      setSenderAccountID(senderAccID.toString().replace('0002IBFT', '').replace('0001IFT', '').replace('0000RTGS', ''))
      // setAllAmc(amcResponse.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Request Failed!")
      }
    }
  }

  useEffect(() => {
    getHblIDS()
  }, [])
  const postHblIDS = async () => {
    try {
      // const response = await postHBLID(email, xReqID, customerReferenceNumber, senderAccountID);
      if (xReqID === '' || xReqID === undefined) {
        // xReqID = '62648FFR1132'
        setXReqID('62648FFR1000')
      }
      if (customerReferenceNumber === '' || customerReferenceNumber === undefined) {
        setCustomerReferenceNumber('62648FFR1000')
      }
      if (senderAccountID === '' || senderAccountID === undefined) {
        setSenderAccountID('62648FFR1000')
      }
      const response = await postHBLID(email, xReqID, customerReferenceNumber, senderAccountID);
      if (response.data.status === 200) {
        getHblIDS();
        setRestID(response.data.data)
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Request Failed!")
      }
    }
  }
  // useEffect(() => {
  //   postHblIDS()
  // }, [])



  const changeBankPaymentTransactionStatus = async (ledger_id, realized_in_bank, hbl_payment_reference_number, realized_in_bank_date) => {
    try {
      const response = await updateBankPaymentTransactionStatus(email, ledger_id, realized_in_bank, hbl_payment_reference_number, realized_in_bank_date);
      if (response.data.status === 200) {
        getLederByTxnId();
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Request Failed!")
      }
    }
  }

  const counterBankValidation = async (ledgerObj) => {
    let flag = false;
    let return_data = {};
    let senderBankCode = ''
    if (ledgerObj.counter_bank === 'HABB') {         // HABB 	HABIB BANK LIMITED
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 14
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '054'
      } else {
        senderBankCode = 'Length of Account No should be 11,14 or 24'
      }
    } else if (ledgerObj.counter_bank === 'ABPA') {  // Allied Bank Limited   ABPA
      flag = true;
      if (ledgerObj.counter_account_number.length === 13 || ledgerObj.counter_account_number.length === 16
        || ledgerObj.counter_account_number.length === 20 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '014'
      } else {
        senderBankCode = 'Length of Account No should be 13,16,20 or 24'
      }
    } else if (ledgerObj.counter_bank === 'ASCM') {   // ASKARI BANK LIMITED    ASCM
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 14
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '017'
      } else {
        senderBankCode = 'Length of Account No should be 11,14 or 24'
      }
    } else if (ledgerObj.counter_bank === 'ALFH') {   // Bank Alfalah Limited ALFH
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 14
        || ledgerObj.counter_account_number.length === 18 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '053'
      } else {
        senderBankCode = 'Length of Account No should be 11,14,18 or 24'
      }
    } else if (ledgerObj.counter_bank === 'AIIN') {   // Al Baraka Islamic Bank Limited AIIN
      flag = true;
      if (ledgerObj.counter_account_number.length === 13 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '031'
      } else {
        senderBankCode = 'Length of Account No should be 13 or 24'
      }
    } else if (ledgerObj.counter_bank === 'BAHL') {   // BAHL 	BANK AL HABIB LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 17 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '023'
      } else {
        senderBankCode = 'Length of Account No should be 17 or 24'
      }
    } else if (ledgerObj.counter_bank === 'BIPL') {   // BKIP 	BANKISLAMI PAKISTAN LIMITED
      flag = true;
      if (ledgerObj.counter_account_number.length === 15 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '021'
      } else {
        senderBankCode = 'Length of Account No should be 15 or 24'
      }
    } else if (ledgerObj.counter_bank === 'KHYB') {   // KHYB   BANK OF KHYBER
      flag = true;
      if (ledgerObj.counter_account_number.length === 12 || ledgerObj.counter_account_number.length === 16
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '061'
      } else {
        senderBankCode = 'Length of Account No should be 12,16 or 24'
      }
    } else if (ledgerObj.counter_bank === 'BPUN') {   // BPUN 	BANK OF PUNJAB
      flag = true;
      if (ledgerObj.counter_account_number.length === 16 || ledgerObj.counter_account_number.length === 17
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '083'
      } else {
        senderBankCode = 'Length of Account No should be 16,17 or 24'
      }
    } else if (ledgerObj.counter_bank === 'DUIB') {   // DUIB 	DUBAI ISLAMIC BANK PAKISTAN LIMITED
      flag = true;
      if (ledgerObj.counter_account_number.length === 10 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '013'
      } else {
        senderBankCode = 'Length of Account No should be 10 or 24'
      }
    } else if (ledgerObj.counter_bank === 'FAYS') {   // FAYS 	FAYSAL BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 14 || ledgerObj.counter_account_number.length === 16
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '060'
      } else {
        senderBankCode = 'Length of Account No should be 14,16 or 24'
      }
    } else if (ledgerObj.counter_bank === 'FWOM') {   // FWOM 	FIRST WOMEN BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 20 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '092'
      } else {
        senderBankCode = 'Length of Account No should be 20 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MPBL') {   // MPBL 	HABIB METROPOLITAN BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 19 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '064'
      } else {
        senderBankCode = 'Length of Account No should be 19 or 24'
      }
    } else if (ledgerObj.counter_bank === 'JSBL') {   // JSBL 	JS BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 6 || ledgerObj.counter_account_number.length === 7 ||
        ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '018'
      } else {
        senderBankCode = 'Length of Account No should be 6,7,11 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MUCB') {   // MUCB 	MCB BANK LIMITED
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 16
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '062'
      } else {
        senderBankCode = 'Length of Account No should be 11,16 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MCIB') {   // MCIB 	MCB ISLAMIC BANK 
      flag = true;
      if (ledgerObj.counter_account_number.length === 16 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '097'
      } else {
        senderBankCode = 'Length of Account No should be 16 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MEZN') {   // MEZN 	MEEZAN BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 14
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '089'
      } else {
        senderBankCode = 'Length of Account No should be 11,14 or 24'
      }
    } else if (ledgerObj.counter_bank === 'NBPA') {   // NBPA 	NATIONAL BANK OF PAKISTAN 
      flag = true;
      if (ledgerObj.counter_account_number.length === 10 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '070'
      } else {
        senderBankCode = 'Length of Account No should be 10 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MBPL') {   // MBPL 	SAMBA BANK LIMITED
      flag = true;
      if (ledgerObj.counter_account_number.length === 10 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '028'
      } else {
        senderBankCode = 'Length of Account No should be 10 or 24'
      }
    } else if (ledgerObj.counter_bank === 'PRUC') {   // PRUC 	Silkbank Limited 
      flag = true;
      if (ledgerObj.counter_account_number.length === 13 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '066'
      } else {
        senderBankCode = 'Length of Account No should be 13 or 24'
      }
    } else if (ledgerObj.counter_bank === 'SIND') {   // SIND 	SINDH BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 14 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '043'
      } else {
        senderBankCode = 'Length of Account No should be 14 or 24'
      }
    } else if (ledgerObj.counter_bank === 'SONE') {   // SONE 	SONERI BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 15 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '085'
      } else {
        senderBankCode = 'Length of Account No should be 15 or 24'
      }
    } else if (ledgerObj.counter_bank === 'SCBL') {   // SCBL 	STANDARD CHARTERED BANK
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 16
        || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '038'
      } else {
        senderBankCode = 'Length of Account No should be 11,16 or 24'
      }
    } else if (ledgerObj.counter_bank === 'BCEY') {   // BCEY 	SUMMIT BANK
      flag = true;
      if (ledgerObj.counter_account_number.length === 20 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '081'
      } else {
        senderBankCode = 'Length of Account No should be 20 or 24'
      }
    } else if (ledgerObj.counter_bank === 'UNIL') {   // UNIL 	UNITED BANK LIMITED 
      flag = true;
      if (ledgerObj.counter_account_number.length === 11 || ledgerObj.counter_account_number.length === 13
        || ledgerObj.counter_account_number.length === 16 || ledgerObj.counter_account_number.length === 24) {
        senderBankCode = '086'
      } else {
        senderBankCode = 'Length of Account No should be 11,13,16,20 or 24'
      }
    } else if (ledgerObj.counter_bank === 'MDL') {   // MDL
      flag = true;
      if (ledgerObj.counter_account_number.length === 14) {
        senderBankCode = '086'
      } else {
        senderBankCode = 'Length of Account No should be 11,13,16,20 or 24'
      }
    }
    return { invalidCode: flag, senderBankCode: senderBankCode };
  }


  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const [errorShow, setErrorShow] = useState(false);
  const [showDate, setShowDate] = useState(false);

  const ErrorBorder = (value) => {
    if (errorShow && value === '') {
      return 'required-border';
    } else {
      return '';
    }
  };

  React.useEffect(() => {
    fields['realized'] === 'true' ? setRealizedCheck(true) : setRealizedCheck(false);

    if (props.data) {
      setSystemTaxFlag(checkTaxBoolean(headLabel));
      if (props.flag) {
        const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');
        setHeadLabel(obj.txnr_type);
        counterAccounter = getCounterAccount(obj.txnr_type);
        setFields(obj);
        if (props.data.txnr_type === 'transferofunits') {
          JSON.parse(fields.transfrees)
        }
        if (props.data.txnr_type === 'termdeposit') {
          setTermDeposit(JSON.parse(fields.securities));
        }
      } else {
        setHeadLabel(props.data.txnr_type);
        counterAccounter = getCounterAccount(props.data.txnr_type);
        if (props.data.txnr_type === 'termdeposit') {
          setTermDeposit(JSON.parse(fields.securities));
        }
      }
      if (props.data.txn_status === 'COMPLETED') {
        ;

        const getLederByTxnId = async () => {
          try {
            setledgerLoading(true);
            const response = await getLedger(email, props.data.txn_id.replace('TXN_', ''));
            ledgerData.length = 0;
            setLedgerData(ledgerData);
            let array = Object.entries(response.data.data);

            let data: any = []
            array.forEach(([key, value]) => {
              let record: [] = []
              data = value;
              record['Record'] = data.Record
              record['Record']['ledger_id'] = data.Key
              ledgerData.push(record)
            })
            setCountLedgerData(ledgerData.length)
            setledgerLoading(false);
          } catch (err) {
            setledgerLoading(false);
          }
        };
        getLederByTxnId();
      }
    }

  }, [props]);

  const [fields, setFields] = useState(props.data);
  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setFlagForSecutities(false);

            }}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForSecutities(false);
                  // clearTaxobject();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> View Security  </h6>
            </div>

            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 && (
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Inv Type</th>
                          <th>Mkt</th>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Avg Rate</th>
                          {/* <th>Broker</th> */}
                          <th>Comm Rate</th>
                          <th>SST On Comm</th>
                          {/* <th>Capital Value Tax</th> */}
                          <th>Net Rate</th>
                          <th>Gross Amount</th>
                          <th>Commission</th>
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderPsxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [securites, setSecurites] = useState<any>([]);
  const renderPsxData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.investment_type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{isNumber(items.quantity)}</td>
          <td>{parseFloat(isNumber(items.avg_rate)).toFixed(2)}</td>
          {/* <td>{items.broker}</td> */}
          <td>{isNumber(items.commission_rate)}</td>
          <td>{isNumber(items.sst_on_commission)}</td>
          {/* <td>{items.capital_value_tax}</td> */}
          <td>{isNumber(items.net_rate)}</td>
          <td>{parseFloat(isNumber(items.gross_amount)).toFixed(2)}</td>
          <td>{isNumber(items.commission_charges)}</td>
          <td>{parseFloat(isNumber(items.net_amount)).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [flagForMoneyMarketSecutities, setFlagForMoneyMarketSecutities] = useState(false);
  const renderMoneyMarketSecuritiesModalPopup = () => {
    switch (flagForMoneyMarketSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false} keyboard={false}
            enforceFocus={false}
            onHide={() => { setFlagForMoneyMarketSecutities(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForMoneyMarketSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Money Market Security  </h6>
            </div><div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForMoneyMarketSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              {/* <h6 className="title title-up">View Security  </h6> */}
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 &&
                  <div className="form-group">
                    <table className="table transform">
                      <thead>
                        <tr>

                          <th>Security Type</th>
                          <th>Investment Type</th>
                          <th>Type</th>
                          <th>Symbol</th>
                          <th>Name</th>
                          <th>Issue Date</th>
                          <th>Maturity Date</th>
                          <th>Money Market Face Value</th>
                          <th>Price</th>
                          <th>Units</th>
                          <th>Yield</th>
                          <th>Coupon Rate</th>
                          <th>Next Coupon Date</th>
                          <th>Last Coupon date</th>
                          <th>Primary Dealer</th>
                          <th>Fund IPS Account</th>
                          <th>Counter Party IPS Account</th>
                          <th>Broker Name</th>
                          <th>Settlement Amount</th>
                          <th>Total Face Value</th>
                          <th>Accrued amount</th>
                          <th>Brokerage</th>
                          <th>Premium Discount</th>
                          <th>Accrued Days</th>
                          <th>Counter Party Name</th>
                          <th>Detail</th>
                          <th>Principal Amount</th>
                          <th>UnRedeem Value</th>
                          <th>Copoun Payment/Year</th>
                          <th>Days of Maturity</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderMoneyMarketSecurityData()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const renderMoneyMarketSecurityData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>

          <td>{items.security_type}</td>
          <td>{items.investment_type}</td>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{items.name}</td>
          <td>{isDateValid(moment(items.issue_date).format('DD-MM-YYYY'))}</td>
          <td>{isDateValid(moment(items.maturity_date).format('DD-MM-YYYY'))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.money_market_face_value)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.price)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.units)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.yield)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.coupon_rate)))}</td>
          <td>{isDateValid(moment(items.next_coupon_date).format('DD-MM-YYYY'))}</td>
          <td>{isDateValid(moment(items.last_coupon_date).format('DD-MM-YYYY'))}</td>
          <td>{items.primary_dealer}</td>
          <td>{items.fund_ips_account}</td>
          <td>{items.counter_party_ips_account}</td>
          <td>{items.broker_name}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.settlement_amount)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.total_face_value)))}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.accrued_amount)))}</td>
          <td>{items.brokerage}</td>
          <td>{items.premium_discount}</td>
          <td>{items.accrued_days}</td>
          <td>{items.counter_party_name}</td>
          <td>{items.detail}</td>
          <td>{numberWithCommas(parseFloat(isNumber(items.principal_amount)))}</td>
          <td>{items.unredeem_value}</td>
          <td>{items.coupon_payment}</td>
          <td>{items.days_of_maturity}</td>
        </tr>
      );
    });
  };

  const [transferessData, setTransferessData] = useState<any>([]);
  const renderTranfereesData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return transferessData.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.folio_no}</td>
          <td>{items.name}</td>
          <td >{numberWithCommas(parseFloat(items.transferees_unit_transfer))}</td>
        </tr>
      );
    });
  };

  const [flagForTransferessData, setFlagForTransferessData] = useState(false);
  const renderTransfereesModalPopup = () => {
    switch (flagForTransferessData) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setFlagForTransferessData(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForTransferessData(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transferees  </h6>
            </div><div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForTransferessData(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              {/* <h6 className="title title-up">View Security  </h6> */}
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                {transferessData.length > 0 &&
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>

                          <th>Folio No</th>
                          <th>Name</th>
                          <th >Transfer Units</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderTranfereesData()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  // const [investmentInFund, setInvestmentInFund] = useState<any>([]);
  const renderInvestmentInFund = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{parseFloat(items.avg_rate).toFixed(2)}</td>
          <td>{parseFloat(items.quantity).toFixed(4)}</td>
          <td>{parseFloat(isNumber(items.gross_amount)).toFixed(2)}</td>
          {
            items.type === 'Buy' ? (
              <>
                <td>{parseFloat(isNumber(items.sale_load)).toFixed(4)}</td>
              </>
            ) : (
              <>
                <td>{parseFloat(isNumber(items.redemption_load)).toFixed(4)}</td>
                <td>{parseFloat(isNumber(items.zakat_amount)).toFixed(4)}</td>
                <td>{parseFloat(isNumber(items.tax_cgt)).toFixed(4)}</td>
              </>
            )
          }
          <td>{parseFloat(isNumber(items.net_amount)).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [flagForInvestmentInFund, setFlagForInvestmentInFund] = useState(false);
  const renderModalInvestmentInFund = () => {
    switch (flagForInvestmentInFund) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setFlagForInvestmentInFund(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForInvestmentInFund(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transferees  </h6>
            </div><div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForInvestmentInFund(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              {/* <h6 className="title title-up">View Security  </h6> */}
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 &&
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Fund</th>
                          <th className='text-center'>NAV Price</th>
                          <th className='text-center'>No of Units</th>
                          <th className='text-center'>Gross Amount</th>
                          {
                            securites[0]['type'] === 'Buy' ? (
                              <>
                                <th className='text-center'>Sale Load</th>
                              </>
                            ) : (
                              <>
                                <th className='text-center'>Redemption Load</th>
                                <th className='text-center'>Zakat</th>
                                <th className='text-center'>CGT</th>
                              </>
                            )
                          }
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderInvestmentInFund()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [flagForequityautosettlementSecutities, setFlagForequityautosettlementSecutities] = useState(false);
  const renderAutoSettledModalPopup = () => {
    switch (flagForequityautosettlementSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setFlagForequityautosettlementSecutities(false);
            }}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForequityautosettlementSecutities(false);
                  // clearTaxobject();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> View Security  </h6>
            </div>

            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 && (
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Inv Type</th>
                          <th>Mkt</th>
                          <th>Symbol</th>
                          <th className='text-right'>Quantity</th>
                          <th className='text-center'>Avg Rate</th>
                          {/* <th>Broker</th> */}
                          <th className='text-center'>Comm Rate</th>
                          <th className='text-center'>SST On Comm</th>
                          {/* <th>Capital Value Tax</th> */}
                          <th className='text-center'>Net Rate</th>
                          <th className='text-center'>Gross Amount</th>
                          <th className='text-right'>Comm</th>
                          <th className='text-right'>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderAtuoSettledPsxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const renderAtuoSettledPsxData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.investment_type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td className='text-right'>{numberWithCommas(items.quantity)}</td>
          <td className='text-right'>{parseFloat(items.avg_rate).toFixed(2)}</td>
          {/* <td>{items.broker}</td> */}
          <td className='text-right'>{items.commission_rate}</td>
          <td className='text-right'>{items.sst_on_commission}</td>
          {/* <td>{items.capital_value_tax}</td> */}
          <td className='text-right'>{items.net_rate}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(items.gross_amount).toFixed(2))}</td>
          <td className='text-right'>{(items.commission_charges || '0.00')}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(items.net_amount).toFixed(2))}</td>
        </tr>
      );
    });
  };










  const [totalTax, setTotalTax] = useState<any>([]);
  //render Tax table data
  const renderTaxData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{parseFloat(items.amount).toFixed(2)}</td>
          <td>{parseFloat(items.calculatedAmount).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const renderSystemTaxData = () => {
    return (
      <tr>
        <td>{parseFloat(systemTax.amount).toFixed(2)}</td>
        <td>{systemTax.sst_rate}%</td>
        <td>{parseFloat(systemTax.sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.total_amount).toFixed(2)}</td>
        <td>{systemTax.wht_it_rate}%</td>
        <td>{parseFloat(systemTax.wht_it_amount).toFixed(2)}</td>
        <td>{systemTax.wht_sst_rate}%</td>
        <td>{parseFloat(systemTax.wht_sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.net_amount).toFixed(2)}</td>
      </tr>
    );
  };


  const [viewTaxModal, setTaxModal] = useState(false);
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setTaxModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTaxModal(false);
                  // clearTaxobject();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Txn Charges  </h6>
            </div>

            <div className="modal-body">
              <div className="">
                {totalTax.length > 0 && (
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [viewSystemTaxModal, setSystemTaxModal] = useState(false);
  const renderSystemTaxPopup = () => {
    switch (viewSystemTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setSystemTaxModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setSystemTaxModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Tax Details  </h6>
            </div>
            <div className="modal-body">

              {checkTaxBoolean(headLabel) && fields.system_tax !== '' && (
                <>
                  <h4 className="text-primary text-center" >System Tax</h4>
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Rate (SST)</th>
                        <th>Amount (SST)</th>
                        <th>Total Amount</th>
                        <th>Rate (WHT IT)</th>
                        <th>WHT IT AMOUNT</th>
                        <th>Rate WHT SST</th>
                        <th>SST WHT IT AMOUNT</th>
                        <th>Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>{renderSystemTaxData()}</tbody>
                  </table>
                </>
              )}

              {totalTax.length > 0 ? (
                <>
                  <h4 className="text-primary text-center" > Tax Charges</h4>
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                </>
              ) : '  Tax/charges were not entered !'}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  // const  numberWithCommas = (amount:any) => {
  //   return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  // }

  const decimalValue = (value, len) => {
    // eslint-disable-next-line 
    value === undefined ? value = '0' : value;
    let startingValue = value.toString().split('.')[0];
    let decimalVal = value.toString().split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const decimalValueNav = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  // ledger coding

  const getLederByTxnId = async () => {
    try {
      setledgerLoading(true);
      const response = await getLedger(email, fields.txn_id);
      ledgerData.length = 0;
      setLedgerData(ledgerData);
      let array = Object.entries(response.data.data);
      let data: any = []
      array.forEach(([key, value]) => {
        let record: [] = []
        data = value;
        record['Record'] = data.Record
        record['Record']['ledger_id'] = data.Key
        ledgerData.push(record)
      })
      setCountLedgerData(ledgerData.length)
      setledgerLoading(false);
    } catch (err) {
      setledgerLoading(false);
    }
  };


  const [ledgerId, setledgerId] = useState('');
  let [realizedDate, setRealizedDate] = useState<any>(moment(date).format('YYYY-MM-DD'))
  let [realize, setRealize] = useState(false);
  let [realized, setRealized] = useState('');
  const [addMarkRealized, setAddMarkRealized] = useState(false);
  const [macsRealized, setMACSRealized] = useState(false);
  const [disableIntrBtn, setDisableIntrBtn] = useState(false);
  let verifyPaymentFlag = false;


  const RealizedInBank = async () => {
    verifyPaymentFlag = false;
    try {
      setDisableIntrBtn(true);
      const response = await realizedMarkLedger(email, ledgerId, realized, realizedDate, '', '')
      if (response.data.status == 200) {
        if (flagBankPaymentWarning) {
          verifyPaymentFlag = true;
          paymentRealizedAction();
        } else {
          toast.success(response.data.message)
          setFlagBankPaymentRealized(false);
        }
        getLederByTxnId();
        setShowDate(false);
        setRealizedDate(moment(date).format('YYYY-MM-DD'));
        setDisableIntrBtn(false);
        setOnceUpdateRel(false);

      } else {
        toast.error(response.data.message)
        setDisableIntrBtn(false);
      }
      setledgerId('');
      // setRealizedDate('');
      setRealize(false);
      setRealized('');
      setAddMarkRealized(false);
    } catch (error) {
      setDisableIntrBtn(false);
      // setAddMarkRealized(false);
    }
  }
  const renderModalForMarkRealized = () => {
    switch (addMarkRealized) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setAddMarkRealized(false)
              setOnceUpdateRel(false);
              if (macsRealized) {
                setFlagBankPaymentRealized(true);
              }
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setAddMarkRealized(false);
                  setOnceUpdateRel(false);
                  if (macsRealized) {
                    setFlagBankPaymentRealized(true);
                  }
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Realized In Bank</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div>Realized In Bank</div>
                  <FormGroup check>
                    <Label check>
                      <input type="checkbox" checked={realized === "true" ? true : false} onChange={(e) => {
                        if (realize === true) {
                          setRealize(false);
                          setRealized('false')
                          setErrorShow(false);
                          setShowDate(false);
                        } else {
                          setRealize(true);
                          setRealized('true');
                          setShowDate(true);
                        }
                      }} />
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </div>
                {showDate == true && (
                  <div className="col-md-12 mt-3">
                    <div>Realized Date</div>
                    <input type="date" disabled value={realizedDate} className={"form-control "} />
                  </div>
                )}

                <div className="col-md-12 mt-3">
                  {onceUpdateRel === false ? (
                    <button className="btn btn-primary btn-block" onClick={() => {
                      if (realize == true && realizedDate == '') {
                        setErrorShow(true);
                      } else {
                        setErrorShow(false);
                        RealizedInBank();
                      }

                    }} disabled={Boolean(disableIntrBtn) || !showDate}>
                      {disableIntrBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="login-txt"> Loading...</span></> : <span>Mark</span>}
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" disabled={Boolean(onceUpdateRel)}>
                      <span className="login-txt"> Already Realized</span>
                    </button>
                  )}


                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [addInstrumentModal, setAddInstrumentModal] = useState(false);
  const [instrumentNo, setInstrumentNo] = useState('');
  const [instrumentDate, setInstrumentDate] = useState('');
  const addInstrumentCall = async () => {
    let realized_date: any = realized === '' ? '' : realizedDate;
    setDisableIntrBtn(true);
    try {
      const response = await realizedMarkLedger(email, ledgerId, realized, realized_date, instrumentNo, instrumentDate);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        getLederByTxnId()
        setDisableIntrBtn(false);
        setAddInstrumentModal(false);
      } else {
        toast.error(response.data.message);
        setDisableIntrBtn(false);
        setAddInstrumentModal(false);
      }
      setledgerId('');
      setInstrumentNo('');
      setInstrumentDate('');
      setDisableIntrBtn(false);
    } catch (error) {
      setDisableIntrBtn(false);
    }
  };
  const renderModalForInstrumentPopup = () => {
    switch (addInstrumentModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setAddInstrumentModal(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setAddInstrumentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">  Add Instrument Detail</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div>Instrument No</div>
                    <input type="text" value={instrumentNo != '' ? instrumentNo : fields['instrument_no']} className="form-control" onChange={(e) => {
                      setInstrumentNo(e.target.value);
                    }} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div>Instrument Date</div>
                    <input type="date" className="form-control" onChange={(e) => {
                      let insDate: any = moment(e.target.value).format('YYYY-MM-DD');
                      setInstrumentDate(insDate)
                    }} />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <button className="btn btn-primary btn-block" onClick={() => {
                    addInstrumentCall();
                  }} disabled={Boolean(disableIntrBtn)}>
                    {disableIntrBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="login-txt"> Loading...</span></> : <span>Add</span>}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [displayChequeModal, setDisplayChequeModal] = useState(false);
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  // render Model for adding Fund	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
  const [tempLoading, setTempLoading] = useState(false);

  const getTemplates = async (bankName) => {
    try {
      const response = await getAllBankTemplates(email, bankName);
      setAllTemplates(response.data.data);
      if (response.data.data.length < 1) {
        setTempLoading(true);
      } else {
        setTempLoading(false);
      }
    } catch (error) { }
  };

  const renderTemplateDropdown = () => {
    return allTemplates.map((item: any, index: string) => {
      return (
        <option key={index} value={item.template_detail}>
          {item.template_name}
        </option>
      );
    });
  };

  const printChequeValidation = () => {
    let check = true;
    if (selectedTemplate === '') {
      check = false;
    } else if (title === '') {
      check = false;
    } else if (amount === '') {
      check = false;
    }
    return check;
  };

  const printContent = async () => {
    let unitholder_cnic = ''
    const isValid = printChequeValidation();
    //&& fields['mode_of_payment']!='RTGS' && fields['mode_of_payment']!='PO/DD'
    if (fields['txnr_type'] === "redemptionofunits" || fields["txnr_type"] === "funddividendpayout") {
      let unitholder_data = await getUnitHoldersByAmcAndFolioNo(email, fields['amc_code'], fields['folio_no'])
      if (unitholder_data.data.status == 200) {
        unitholder_cnic = unitholder_data.data.data[0].cnic
      }

    }
    if (isValid) {
      //for cheque	
      let bankName;
      // if(fields['mode_of_payment']==='PO/DD' || fields['mode_of_payment']==='RTGS'){
      //   bankName = fields['bank'];
      // }else{
      //   bankName = title;
      // }

      bankName = title

      let tempData = [...selectedTemplate];
      let txn_name = CapTxnType(fields['txnr_type']);
      //  let txn_name = CapTxnType(data.description.split('-')[0]);	
      // let txn_name = data.Record.description.split('-')[0];

      tempData[0].label = moment(data.instrument_date).format('DD-MM-YYYY');
      tempData[1].label = unitholder_cnic.trim() != "" ? bankName + "–" + unitholder_cnic : bankName;
      tempData[2].label = amount;
      tempData[3].label = inWords(amount).replace("and Paisa Zero ", "");
      tempData[5].label = `${txn_name} #${data.Record.txn_id}`;
      sessionStorage.setItem('template', JSON.stringify(tempData));
      sessionStorage.setItem('modeofpaymentCheque', JSON.stringify({ "mode_of_payment": fields['mode_of_payment'], "bank_name": fields['bank'], "cnic": unitholder_cnic }))
      history.replace('/admin/print-screen/pending');
    }
  };

  const renderModaForPrintChequePopup = () => {
    switch (displayChequeModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setDisplayChequeModal(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setDisplayChequeModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">  Select Template</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Template</label>
                    <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                    <select className="form-control"
                      onChange={(e) => {
                        let x = JSON.parse(e.target.value);
                        setSelectedTemplate(x);
                      }}>
                      <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Template'}</option>
                      {renderTemplateDropdown()}
                    </select>
                  </div>

                </div>
                <div className="col-md-12">
                  <button className="btn btn-primary btn-block mt-3" onClick={() => {
                    printContent();
                  }} disabled={Boolean(disableAddBtn)}>
                    {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="login-txt"> Loading...</span></> : <span>Print</span>}
                  </button>

                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };



  const bankName = (bankcode) => {
    return bankData.filter((item: any, index: string) => (item.bank_code.replace('BANK_', '') === bankcode))
  }

  const [paymentApiLoading, setPaymentApiLoading] = useState(false)

  const paymentRealizedAction = async () => {
    setPaymentApiLoading(true);
    let filterData = paymentData?.Record;
    let txnMonth = fields.txn_history.filter(item => item.action === "ACCEPTED")
    txnMonth = moment(txnMonth[txnMonth?.length - 1]?.updated_at).format('M');
    const flagCounterBankCode: any = await counterBankValidation(filterData);
    let flagBankCode = false;
    let senderBankAccountLength = '';
    if (filterData.bank_code === 'HABB') {         // HABB 	HABIB BANK LIMITED
      flagBankCode = true;
      if (filterData.account_number.length === 11 || filterData.account_number.length === 14 || filterData.account_number.length === 24)  
      {
        senderBankAccountLength = '054';
      }
      // else if(filterData.account_number.length === 24)
      // {
      //   senderBankAccountLength="Only Account Number will be Accepted"
      // }
       else {
        senderBankAccountLength = 'Length of Account No should be 11 or 14 or 24';
      }
    }
    let paymentDetails: any = {};
    // if (filterData.counter_bank === 'HABB') {
    paymentDetails = {
      Company: 'DCCLT',
      TransactionType: fields['mode_of_payment'] === 'RTGS' ? 'RTGS' : filterData.counter_bank === 'HABB' ? 'IFT' : 'IBFT',
      // CustomerReferenceNumber: fields['mode_of_payment'] ==='RTGS' ? 'DCCLT1904230950' :customerReferenceNumber,
      CustomerReferenceNumber: customerReferenceNumber,
      SenderAccountID: fields['mode_of_payment'] === 'RTGS' ? '0000RTAPI' + senderAccountID?.slice(0,-1) : filterData.counter_bank === 'HABB'
        ? '0001LFAPI0' + senderAccountID?.slice(0,-1) : '0002IBAPI' + senderAccountID?.slice(0,-1),
      SenderBankName: 'HBL',
      SenderBankAccountNo: filterData.account_number?.slice(-14),
      ReceiversName: filterData.counter_bank !== 'HABB' || filterData.counter_bank !== 'BIPL' ? 'NA' : filterData.counter_account_title,
      ReceiversAddress: filterData.counter_bank !== 'HABB' ? 'NA' : `${filterData.counter_bank} ${filterData.counter_branch}`,
      ReceiversCNICorNTN: filterData.counter_bank !== 'HABB' ? 'NA' : '',
      ReceiversContactDetails: 'NA',
      ReceiverID: filterData.counter_bank !== 'HABB' ? '' : flagCounterBankCode?.senderBankCode, // in RTGS format MDL insteadof empty.
      ReceiverBankName: fields['mode_of_payment'] === 'RTGS' ? filterData.counter_bank : 'NA',
      ReceiverBankCode: filterData.counter_bank === 'HABB' ? 'MDL' : flagCounterBankCode?.senderBankCode,
      ReceiverBankAccountNo: filterData.counter_account_number,
      ReceiverBranchAddress: '',
      ReceiverBranchCode: '',
      Amount: parseFloat(filterData?.credit_amount || '0'),
      PurposeofPayment: CapTxnType(fields?.txnr_type || ''), //or in case of ibft Fund Transfer
      // PurposeofPayment: filterData.counter_bank === 'HABB' ? CapTxnType(fields?.txnr_type || '') : 'RTGS  UAT', //or in case of ibft Fund Transfer
      EmailAddress: '',
      PrintLocation: '',
      Deliverto: '',
      ChequeNumber: '',
      ChequeDate: '',
      userid: '',
      UniqueRequestID: '',
      CompanyName: '',
      PaymentDate: '',
      TransactionRefNumber:ledgerId ?ledgerId: '',
      TransactionMonth: filterData.counter_bank === 'HABB' ? txnMonth : '18',
      UD1: '',
      UD2: '',
      UD3: '',
      TransactionStatus: fields['mode_of_payment'] === 'RTGS' ? "DCCLT Initiate" : "HBL and DCCLT initiate",
    }
    // } else {
    //   paymentDetails = {
    //     Company: "DCCLT",
    //     TransactionType: "IBFT",
    //     CustomerReferenceNumber: customerReferenceNumber,
    //     SenderAccountID: '0002IBFT' + senderAccountID,
    //     SenderBankName: "",
    //     SenderBankAccountNo: filterData.account_number,
    //     ReceiversName: 'NA',
    //     ReceiversAddress: "NA",
    //     ReceiversCNICorNTN: "NA",
    //     ReceiversContactDetails: "NA",
    //     ReceiverID: "MDL",
    //     ReceiverBankName: "NA",
    //     ReceiverBankCode: "MDL",
    //     ReceiverBankAccountNo: paymentData?.Record.counter_account_number,
    //     ReceiverBranchAddress: "",
    //     ReceiverBranchCode: "",
    //     Amount: parseFloat(filterData?.gross_amount || '0'),
    //     PurposeofPayment: "Fund Transfer",
    //     EmailAddress: "",
    //     PrintLocation: "",
    //     Deliverto: "",
    //     ChequeNumber: "",
    //     ChequeDate: "",
    //     userid: "",
    //     UniqueRequestID: "",
    //     CompanyName: "",
    //     PaymentDate: "",
    //     TransactionRefNumber: "",
    //     TransactionMonth: "18",
    //     UD1: "",
    //     UD2: "",
    //     UD3: "",
    //     TransactionStatus: "HBL and DCCLT initiate"
    //   }
    // }
    // }
    postHblIDS();
    if (flagBankCode) {         // HABB 	HABIB BANK LIMITED
      if (!senderBankAccountLength.includes('Length of Account No should be')) {
        if (flagCounterBankCode?.invalidCode || paymentDetails.ReceiverBankAccountNo !== '') {
          if (!flagCounterBankCode?.senderBankCode.includes('Length of Account No should be')) {
            await paymentToBank(
              paymentDetails.Company,
              paymentDetails.TransactionType,
              paymentDetails.CustomerReferenceNumber,
              paymentDetails.SenderAccountID,
              paymentDetails.SenderBankName,
              paymentDetails.SenderBankAccountNo,
              paymentDetails.ReceiversName,
              paymentDetails.ReceiversAddress,
              paymentDetails.ReceiversCNICorNTN,
              paymentDetails.ReceiversContactDetails,
              paymentDetails.ReceiverID,
              paymentDetails.ReceiverBankName,
              paymentDetails.ReceiverBankCode,
              paymentDetails.ReceiverBankAccountNo,
              paymentDetails.ReceiverBranchAddress,
              paymentDetails.ReceiverBranchCode,
              paymentDetails.Amount,
              paymentDetails.PurposeofPayment,
              paymentDetails.EmailAddress,
              paymentDetails.PrintLocation,
              paymentDetails.Deliverto,
              paymentDetails.ChequeNumber,
              paymentDetails.ChequeDate,
              paymentDetails.userid,
              paymentDetails.UniqueRequestID,
              paymentDetails.CompanyName,
              paymentDetails.PaymentDate,
              paymentDetails.TransactionRefNumber,
              paymentDetails.TransactionMonth,
              paymentDetails.UD1,
              paymentDetails.UD2,
              paymentDetails.UD3,
              paymentDetails.TransactionStatus
            )
              .then((response) => {
                let flag = false;
                let cus_ref_number = '';

                if (response.status === 200) {
                  flag = true;
                  changeBankPaymentTransactionStatus(paymentData.Record.ledger_id.replace('ID_', ''), flag, response.data.data.CustRefNumber, moment(date).format('YYYY-MM-DD'));

                  toast.success(response.data.message);
                  setFlagBankPaymentRealized(false)
                  setFlagBankPaymentWarning(false)
                  setPaymentApiLoading(false);
                  if (verifyPaymentFlag) {
                    setFlagBankPaymentRealized(false)
                  }
                } else {
                  flag = false;
                  cus_ref_number = '';
                  setFlagBankPaymentRealized(false);
                  setPaymentApiLoading(false);
                  changeBankPaymentTransactionStatus(paymentData.Record.ledger_id.replace('ID_', ''), false, '', moment(date).format('YYYY-MM-DD'));
                  toast.error(response.data.message);
                  if (verifyPaymentFlag) {
                    setFlagBankPaymentRealized(false);
                    setFlagBankPaymentWarning(false);
                  }
                }

              })
              .catch((err) => {
                if (verifyPaymentFlag) {
                  setFlagBankPaymentRealized(false)
                  setFlagBankPaymentWarning(false)
                }
                setPaymentApiLoading(false);
                if (err.response !== undefined) {
                  if (err.response.status === 206) {
                    changeBankPaymentTransactionStatus(paymentData.Record.ledger_id.replace('ID_', ''), false, '', moment(date).format('YYYY-MM-DD'));
                    toast.error(err.response.data.data.error.developer_message);
                  } else {
                    changeBankPaymentTransactionStatus(paymentData.Record.ledger_id.replace('ID_', ''), false, '', moment(date).format('YYYY-MM-DD'));
                    toast.error(err.response.data.data.error.developer_message);
                  }
                } else {
                  toast.error(err.message)
                }
              });
          } else {
            setPaymentApiLoading(false);
            toast.error(flagCounterBankCode?.senderBankCode)
            if (verifyPaymentFlag) {
              setFlagBankPaymentRealized(false)
            }
          }
        } else {
          setPaymentApiLoading(false);
          toast.error('counter account not valid!')
          if (verifyPaymentFlag) {
            setFlagBankPaymentRealized(false)
          }
        }
      } else {
        setPaymentApiLoading(false);
        if(senderBankAccountLength.includes('Length of Account No should be'))
        {
          toast.error('Length of fund account No should be 11,14');
        }else if(senderBankAccountLength.includes("Only Account Number will be Accepted"))
        {
          toast.error('IBAN not allowed, Only Account Number will be Accepted');
        }
        
        if (verifyPaymentFlag) {
          setFlagBankPaymentRealized(false)
        }
      }
    } else {
      setPaymentApiLoading(false);
      toast.error('Bank account not valid!')
      if (verifyPaymentFlag) {
        setFlagBankPaymentRealized(false)
      }
    }
  };




  const [flagBankPaymentRealized, setFlagBankPaymentRealized] = useState(false);
  const [flagBankPaymentWarning, setFlagBankPaymentWarning] = useState(false);
  const renderModalForMarkBankPaymentRealized = () => {
    switch (flagBankPaymentRealized) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setFlagBankPaymentRealized(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagBankPaymentRealized(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Payment In Bank</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                {
                  !macsRealized ? (
                    <>
                      <div className="col-md-12">
                        {
                          ledgerStatus !== 'PAYMENT-COMPLETE' && (
                            <>
                              <span style={{ color: "#EED202", fontWeight: "bold" }}>Please proceed with bank payment only if you have confirmed that all transaction details are valid as this will deduct amount from actual bank account.</span>

                              <FormGroup check>
                                <Label check>
                                  <input type="checkbox" disabled={ledgerStatus === 'PAYMENT-COMPLETE'} checked={flagBankPaymentWarning} onChange={(e) => {
                                    setFlagBankPaymentWarning(!flagBankPaymentWarning)
                                  }} />
                                  <span className="form-check-sign" />Payment in Bank
                                </Label>
                              </FormGroup>
                            </>
                          )}
                      </div>

                      <div className="col-md-12 mt-3">
                        {
                          ledgerStatus === 'PAYMENT-COMPLETE' ? (
                            <button className="btn btn-primary btn-block" onClick={() => {
                              paymentRealizedAction()
                            }} disabled={true}>
                              <span>Payment Complete</span>
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-block" onClick={() => {
                              paymentRealizedAction()
                            }} disabled={Boolean(paymentApiLoading || !flagBankPaymentWarning)}>
                              {paymentApiLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="login-txt"> Loading...</span></> : <span>Mark</span>}
                            </button>
                          )
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-12">
                        <span style={{ color: "#EED202", fontWeight: "bold" }}>Ledger is not realized in MACS</span>
                        <div className='row'>
                          <div className="col-md-6 mt-3">
                            <FormGroup check>
                              <Label check>
                                <input type="checkbox"
                                  checked={realized === "true" ? true : false} onChange={(e) => {
                                    setFlagBankPaymentWarning(false)
                                    if (realize === true) {
                                      setRealize(false);
                                      setRealized('false')
                                      setErrorShow(false);
                                      setShowDate(false);
                                    } else {
                                      setRealize(true);
                                      setRealized('true');
                                      setShowDate(true);
                                    }
                                  }} />
                                <span className="form-check-sign" />Realized in MACS
                              </Label>
                            </FormGroup>
                            {showDate == true && (
                              <>
                                <div>Realized Date</div>
                                <input type="date" disabled value={realizedDate} className={"form-control "} />
                              </>
                            )}
                          </div>
                        </div>
                        {showDate == true && (
                          <div className='row'>
                            <div className="col-md-12 mt-3">
                              <span style={{ color: "#EED202", fontWeight: "bold" }}>Please proceed with bank payment only if you have confirmed that all transaction details are valid as this will deduct amount from actual bank account.</span>
                              <FormGroup check>
                                <Label check>
                                  <input type="checkbox" disabled={ledgerStatus === 'PAYMENT-COMPLETE'}
                                    checked={flagBankPaymentWarning} onChange={(e) => { setFlagBankPaymentWarning(!flagBankPaymentWarning) }} />
                                  <span className="form-check-sign" /> Payment in Bank
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-12 mt-3">

                        {
                          !flagBankPaymentWarning ? (
                            <button className="btn btn-primary btn-block" onClick={() => {
                              setFlagBankPaymentWarning(false)
                              if (realize == true && realizedDate == '') {
                                setErrorShow(true);
                              } else {
                                setErrorShow(false);
                                RealizedInBank();
                              }

                            }} disabled={Boolean(disableIntrBtn) || !realize}>
                              {disableIntrBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="login-txt"> Loading...</span></> : <span>Mark</span>}
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-block" onClick={() => {
                              if (realize == true && realizedDate == '') {
                                setErrorShow(true);
                              } else {
                                setErrorShow(false);
                                RealizedInBank();
                              }

                            }} disabled={Boolean(paymentApiLoading || !flagBankPaymentWarning)}>
                              {paymentApiLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="login-txt"> Loading...</span></> : <span>Mark</span>}
                            </button>
                          )
                        }
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <div  >
      <Row >
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Instruction</h4>
            </div>
            <div className="card-body">
              <div>
                {/* amc Dropdown */}
                <div className="form-group">
                  AMC
                  <div className="form-control   w-100">{fields['amc_code']}</div>
                </div>
                {/* fund dropdown , itx value depend on amc value  */}
                <div className="form-group">
                  Fund Name
                  <div className="form-control    w-100">
                    {fields['fund_code']}
                  </div>
                </div>
                <div className="form-group">
                  Instruction Date
                  <div className="form-control   w-100">
                    {isDateValid(moment(fields['instruction_date']).format('DD-MM-YYYY'))}
                  </div>
                </div>
                <div className="form-group">
                  Execution Date
                  <div className="form-control   w-100">
                    {moment(fields['execution_date']).format('DD-MM-YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {(headLabel === 'transferofunits') && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Transferor
                </h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <div>Folio Number</div>
                  <div className="form-control    w-100">
                    {fields['folio_no']}
                  </div>
                </div>
                <div className="form-group">
                  <div>Name</div>
                  <div className="form-control    w-100">
                    {fields['unitholder_name']}
                  </div>
                </div>
                <div className="form-group">
                  <div>Current Holding</div>
                  <div className="form-control    w-100">
                    {numberWithCommas(parseFloat(fields['current_holding']))}
                  </div>
                </div>
                <div className="form-group">
                  <div>Remaining Holding</div>
                  <div className="form-control    w-100">
                    {numberWithCommas(parseFloat(fields['remain_holding']))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
        {(headLabel === 'transferofunits') && (
          <Col md='4'>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Transferees
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <div>View Transferees</div>
                      <div className="form-group mb-4">
                        {fields.transfrees !== '[]' && fields.transfrees !== '' && (
                          <div
                            className="btn btn-success"

                            onClick={() => {
                              setFlagForTransferessData(true);
                              setTransferessData(JSON.parse(fields.transfrees));
                            }}
                          >
                            View Tranferees
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {(headLabel === 'pledgedin' || headLabel === 'pledgedout') && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{CapTxnType(headLabel)}</h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    Symbol
                    <div className="form-control   w-100">{fields['symbol']}</div>
                  </div>
                  <div className="form-group">
                    Shares to {CapTxnType(headLabel)}
                    <div className="form-control    w-100">
                      {fields['volume']}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {headLabel === 'fundtransfer' ? 'From Account' : 'Fund Account'}
                </h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    Fund Account
                    <div className="form-control   w-100">
                      {fields['fund_account']}
                    </div>
                  </div>
                  <div className="form-group">
                    Account Title
                    <div className="form-control   w-100">
                      {fields['account_title']}
                    </div>
                  </div>
                  <div className="form-group">
                    Account Number
                    <div className="form-control   w-100">
                      {fields['account_number']}
                    </div>
                  </div>
                  <div className="form-group  row">
                    <div className="col-6 flex">
                      <div>Bank</div>
                      <div className="form-control w-100  ">{fields['bank']}</div>

                    </div>
                    <div className="col-6 flex">
                      <div>Branch</div>
                      <div className="form-control w-100  ">{fields['branch']}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {headLabel === 'fundtransfer'
                    ? 'To Account'
                    : 'Counter Account'}
                </h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    <div className="text-capitalize">{counterAccounter}</div>
                    <div className="form-control    w-100">
                      {fields['folio_no'] !== ''
                        ? fields['folio_no']
                        : headLabel === 'moneymarketpurchase' ? fields['counter_type'] : fields['counter_account_type']}
                      {fields['counter_type'] === 'Other' && fields['counter_account_type'] !== 'Other' ? <span className='text-primary'> ({fields['counter_type']}) </span> : ''}
                    </div>
                  </div>
                  <div className="form-group">
                    Account Title
                    <div className="form-control    w-100">
                      {fields['counter_account_title']}
                    </div>
                  </div>
                  <div className="form-group">
                    Account Number
                    <div className="form-control   w-100">
                      {fields['counter_account_number']}
                    </div>
                  </div>
                  <div className="form-group   row">
                    <div className="col-6 flex">
                      <div>Bank</div>
                      <div className="form-control w-100  ">
                        {fields['counter_bank']}
                      </div>
                    </div>
                    <div className="col-6 flex">
                      <div>Branch</div>
                      <div className="form-control w-100  ">
                        {fields['counter_branch']}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

      </Row>
      {(headLabel === 'transferofunits') && (
        <Row>

          <Col md='4'>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transaction Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  Description
                  <div className="form-control   w-100">{fields['detail']}</div>
                </div>
              </div>
            </div>
          </Col>

        </Row>
      )}
      {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
        <Row >
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Instrument</h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    Mode of Payment
                    <div className="form-control   w-100">
                      {fields['mode_of_payment']}
                    </div>
                  </div>
                  <div className="form-group">
                    Instrument Type
                    <div className="form-control   w-100">
                      {fields['instrument_type']}
                    </div>
                  </div>

                  <Row>
                    <Col>
                      <div className="form-group">
                        Instrument No./Reference No
                        <div className="form-control    w-100">
                          {fields['instrument_no']}
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        Instrument Date / Deposit Date
                        <div className="form-control   w-100">
                          {fields['instrument_date'] === '' ? '' : moment(fields['instrument_date']).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* {fields.payment_type !== 'Receipt' &&
                fields.txn_status === 'COMPLETED' ? (
                  <>
                    <div className="form-group">
                      Instrument Date / Deposit Date
                      <div className="form-control   w-100">
                        {moment(fields['instrument_date']).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )} */}
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transaction Details</h4>
              </div>
              <div className="card-body">
                <div>
                  {chechFieldDisplay(headLabel, 'folio_no') && (
                    <>
                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            Folio Number
                            <div className="form-control    w-100">
                              {fields['folio_no']}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            UnitHolder Name
                            <div className="form-control    w-100">
                              {fields['unitholder_name']}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  {chechFieldDisplay(headLabel, 'nav') && (
                    <div className="form-group">
                      NAV
                      <div className="form-control   w-100">
                        {parseFloat(isNumber(fields['nav'])).toFixed(4)}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'total_holding') && (
                    <div className="form-group">
                      Total Holding
                      <div className="form-control   w-100">
                        {parseFloat(isNumber(fields['total_holding'])).toFixed(4)}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'units') && (
                    <div className="form-group">
                      Units{' '}
                      {headLabel === 'redemptionofunits'
                        ? 'Redeemed'
                        : headLabel === 'saleofunit' ||
                          headLabel === 'conversionin'
                          ? 'Sold'
                          : headLabel === 'salereturn'
                            ? 'Returned'
                            : ''}
                      <div className="form-control   w-100">
                        {fields['units']
                          ? parseFloat(fields['units']).toFixed(4)
                          : '0.0000'}
                      </div>
                    </div>
                  )}

                  {/* {headLabel === 'conversionout' &&
                    fields.txn_status === 'COMPLETED' ? (
                    <>
                      <div className="form-group">
                        Total Holding
                        <div className="form-control w-100">
                          0
                        </div>
                      </div>
                    </>
                  ) : (
                    <> */}
                  {chechFieldDisplay(headLabel, 'current_holding') && (
                    <div className="form-group">
                      Previous Holding
                      <div className="form-control w-100">
                        {fields['current_holding']
                          ? parseFloat(fields['current_holding']).toFixed(4)
                          : '0.0000'}
                      </div>
                    </div>
                  )}
                  {/* </>
                  )} */}

                  {/* {chechFieldDisplay(headLabel, 'current_holding') && (
                  <div className="form-group">
                    Current Holding
                    <div className="form-control   w-100">
                      {fields['current_holding']
                        ? parseFloat(fields['current_holding']).toFixed(4)
                        : '0.0000'}
                    </div>
                  </div>
                )} */}
                  {chechFieldDisplay(headLabel, 'type') && (
                    <div className="form-group">
                      Type
                      <div className="form-control   w-100">{fields['type']}</div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'sale_date') && (
                    <div className="form-group">
                      Sale Date
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['sale_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'dividend_date') && (
                    <div className="form-group">
                      <div>Dividend Date</div>
                      <div className="form-control   w-100">
                        {moment(fields['dividend_date']).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'dividend_rate') && (
                    <div className="form-group">
                      <div>Dividend Rate</div>
                      <div className="form-control   w-100">
                        {fields['dividend_rate']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'period') && (
                    <div className="form-group">
                      <div>Select Period</div>
                      <div className="form-control   w-100">{fields['period']}</div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'reinvest') && (
                    <div className="form-group">
                      <div>Reinvest</div>
                      <input
                        type="checkbox"
                        checked={fields['reinvest'].toUpperCase() === 'YES' ? true : false}
                        readOnly
                      />
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'refund_of_capital') && (
                    <div className="form-group">
                      Refund of Capital
                      <div className="form-control   w-100">
                        {numberWithCommas(fields['refund_of_capital'])}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'sale_txn_no') && (
                    <div className="form-group">
                      Sale Txn No
                      <div className="form-control   w-100">
                        {fields['sale_txn_no']}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'return_date') && (
                    <div className="form-group">
                      Return Date
                      <div className="form-control   w-100">
                        {moment(fields['return_date']).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'symbol') && (
                    <div className="form-group">
                      Symbol
                      <div className="form-control   w-100">{fields['symbol']}</div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'volume') && (
                    <div className="form-group">
                      Shares to plegged
                      <div className="form-control   w-100">{fields['volume']}</div>
                    </div>
                  )}
                  {/* {chechFieldDisplay(headLabel, 'dividend_percentage') && (
                  <div className="form-group">
                    Dividend Percentage
                    <div className="form-control   w-100">
                      {fields['dividend_percentage']}
                    </div>
                  </div>
                )} */}
                  {headLabel === 'cashdividend' && (
                    <>
                      <Row>
                        <Col>
                          <div className="form-group">
                            Volume
                            <div className="form-control   w-100">{fields['volume']}</div>
                          </div>
                        </Col>

                        <Col>
                          <div className="form-group">
                            Par Value
                            <div className="form-control   w-100">{fields['par_value']}</div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group">
                        Announcement Date
                        <div className="form-control   w-100">
                          {fields['announcement_date'] == '' ? '' : moment(fields['announcement_date']).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    </>
                  )}
                  {chechFieldDisplay(headLabel, 'maturity_type') && (
                    <div className="form-group">
                      Maturity Type
                      <div className="form-control   w-100">
                        {fields['maturity_type']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'security_type') && (
                    <div className="form-group">
                      Security Type
                      <div className="form-control    w-100">
                        {fields['security_type']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'primary_dealer') && (
                    <div className="form-group">
                      Primary Dealer
                      <div className="form-control    w-100">
                        {fields['primary_dealer']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'issue_date') && (
                    <div className="form-group">
                      Issue Date
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['issue_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'conversion_date') && (
                    <div className="form-group">
                      Conversion Date
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['conversion_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'associated_transaction') && (
                    <div className="form-group">
                      Associated Txn No
                      <div className="form-control   w-100">
                        {fields['associated_transaction']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'maturity_date') && (
                    <div className="form-group">
                      Maturity Date
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['maturity_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'coupon_rate') && (
                    <div className="form-group">
                      Coupon Rate
                      <div className="form-control   w-100">
                        {parseFloat(isNumber(fields['coupon_rate'])).toFixed(2)}
                      </div>
                    </div>
                  )}

                  {fields['security_type'] === 'PIB' && headLabel === 'saleofsecurities' && (
                    <div className="form-group">
                      <div>Last Coupon Date</div>
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['last_coupon_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {fields['security_type'] === 'PIB' && headLabel === 'saleofsecurities' && (
                    <div className="form-group">
                      <div>Next Coupon Date</div>
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['next_coupon_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}
                  {fields['security_type'] === 'PIB' && headLabel === 'saleofsecurities' && (
                    <div className="form-group">
                      <div>Tenor</div>
                      <div className="form-control   w-100">{isNumber(fields['tenor'])}</div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'yield') && (
                    <div className="form-group">
                      <div>Yield</div>
                      <div className="form-control   w-100">{isNumber(fields['yield'])}</div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'price') && (
                    <div className="form-group">
                      Price
                      <div className="form-control   w-100">
                        {parseFloat(isNumber(fields['price'])).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'face_value') && (
                    <div className="form-group">
                      Face Value
                      <div className="form-control    w-100">
                        {parseFloat(isNumber(fields['face_value'])).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'money_market_face_value') && (
                    <div className="form-group">
                      Money Market Face Value
                      <div className="form-control  w-100">
                        {parseFloat(isNumber(fields['money_market_face_value'])).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {/* {chechFieldDisplay(headLabel, 'detail') && ( */}
                  <div className="form-group">
                    Description
                    <div className="form-control   w-100">{fields['detail']}</div>
                  </div>

                  {(headLabel === 'equityinvestment' || headLabel === 'advisoryfee' || headLabel === 'auditorsfee' || headLabel === 'bankcharges' || headLabel === 'managementfee' || headLabel === 'trusteefee' || headLabel === 'custodyfee'
                    || headLabel === 'shariahadvisoryfee' || headLabel === 'cdcfee' || headLabel === 'listingfee' || headLabel === 'brokagefee' ||
                    headLabel === 'printingfee' || headLabel === 'professionalfee' || headLabel === 'ratingfee' || headLabel === 'taxpayment' || headLabel === 'secpfee' || headLabel === 'otherpayment' || headLabel === 'equityautosettlement') && (
                      <div className="form-group">
                        Sales Tax Collector
                        <div className="form-control   w-100">{fields['sales_tax_collector']}</div>
                      </div>
                    )}

                  {(headLabel === 'termdeposit' && termDeposit.length > 0) ? (
                    <>
                      <div className="form-group">
                        Type
                        <div className="form-control w-100">{termDeposit[0]['name']}</div>
                      </div>
                      <div className="form-group">
                        Principal Amount
                        <div className="form-control w-100">{termDeposit[0]['principal_amount']}</div>
                      </div>
                      <div className="form-group">
                        Interest Rate
                        <div className="form-control w-100">{termDeposit[0]['interest_rate']}</div>
                      </div>

                      <div className="form-group">
                        From Date
                        <div className="form-control w-100">{termDeposit[0]['from_date']}</div>
                      </div>

                      <div className="form-group">
                        To Date
                        <div className="form-control w-100">{termDeposit[0]['to_date']}</div>
                      </div>

                    </>
                  ) : (
                    //   <>
                    //  <div className="form-group">
                    //    Type
                    //   <div className="form-control w-100">{termDeposit['name']}</div>
                    // </div>
                    //    <div className="form-group">
                    //    Principal Amount
                    //   <div className="form-control w-100">{termDeposit['principal_amount']}</div>
                    // </div>
                    // <div className="form-group">
                    //    Interest Rate
                    //   <div className="form-control w-100">{termDeposit['interest_rate']}</div>
                    // </div>

                    // <div className="form-group">
                    //   From Date
                    //  <div className="form-control w-100">{termDeposit['from_date']}</div>
                    // </div>

                    // <div className="form-group">
                    //   To Date
                    //   <div className="form-control w-100">{termDeposit['to_date']}</div>
                    // </div>
                    //   </>
                    ''
                  )}

                  {chechFieldDisplay(headLabel, 'redemption_date') && (
                    <div className="form-group">
                      Redemption Date
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['redemption_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  )}

                  {/* {chechFieldDisplay(headLabel, 'remain_holding') && (
                  <div className="form-group">
                    Remaining Holding
                    <div className="form-control   w-100">
                      {fields['remain_holding']
                        ? parseFloat(fields['remain_holding']).toFixed(4)
                        : '0.0000'}
                    </div>
                  </div>
                )} */}
                  {chechFieldDisplay(headLabel, 'settlement_by') || headLabel === 'equityautosettlement' && (
                    <div className="form-group">
                      Settlement By*
                      <div className="form-control   w-100">
                        {fields['settlement_by']}
                      </div>
                    </div>
                  )}
                  {chechFieldDisplay(headLabel, 'settlement_date') && (
                    <div className="form-group">
                      Settlement Date
                      <div className="form-control   w-100">
                        {moment(fields['settlement_date']).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  )}

                  {/* {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Fund IPS Account</div>
                    <div className="form-control w-100">
                      {fields['fund_ips_account']}
                    </div>
                  </div>
                )}
                {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Counter Party IPS Account</div>
                    <div className="form-control w-100">
                      {fields['counter_party_ips_account']}
                    </div>
                  </div>
                )} */}


                  {chechFieldDisplay(headLabel, 'tax_type') && (
                    <div className="form-group">
                      Tax Type
                      <div className="form-control   w-100">
                        {fields['tax_type']}
                      </div>
                    </div>
                  )}
                  {headLabel === 'equityinvestment' && (
                    <div className="form-group mb-4">
                      {fields.securities !== '[]' && fields.securities !== '' && (
                        <button className="btn btn-success flex ml-auto justify-content-center mr-2"
                          onClick={() => {
                            setFlagForSecutities(true);
                            setSecurites(JSON.parse(fields.securities))
                          }}
                        >
                          View Securities
                        </button>
                      )}
                    </div>
                  )}
                  {headLabel === 'investmentinfund' && (
                    <div className="form-group mb-4">
                      {fields.securities !== '[]' && fields.securities !== '' && (
                        <button className="btn btn-success flex ml-auto justify-content-center mr-2"
                          onClick={() => {
                            setFlagForInvestmentInFund(true);
                            setSecurites(JSON.parse(fields.securities))
                          }}
                        >
                          View Fund Securities
                        </button>
                      )}
                    </div>
                  )}

                  {headLabel === 'debtmarketinvestment' && (
                    <div className="form-group mb-4">
                      {fields.securities !== '[]' && fields.securities !== '' && (
                        <div
                          className="btn btn-success"

                          onClick={() => {
                            setFlagForMoneyMarketSecutities(true);
                            setSecurites(JSON.parse(fields.securities));
                          }}
                        >
                          View Money Market Securities
                        </div>
                      )}

                    </div>
                  )}
                  {headLabel === 'equityautosettlement' && (
                    <div className="form-group mb-4">
                      {fields.securities !== '[]' && fields.securities !== '' && (
                        <div
                          className="btn btn-success"

                          onClick={() => {
                            setFlagForequityautosettlementSecutities(true);
                            setSecurites(JSON.parse(fields.securities));
                          }}
                        >
                          View Equity Auto Settlement Securities
                        </div>
                      )}

                    </div>
                  )}

                </div>
              </div>
            </div>
          </Col>


          {headLabel === 'cashdividend' && (
            <Col md="4">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Cash Dividend</div>
                </div>
                <div className="card-body">
                  {chechFieldDisplay(headLabel, 'dividend_percentage') && (
                    <div className="form-group">
                      <div>Dividend Percentage</div>
                      <div className="form-control w-100">
                        {fields['dividend_percentage']}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'credit_date') && (
                    <div className="form-group">
                      <div>Dividend Credit Date</div>
                      <div className="form-control w-100">
                        {moment(fields['credit_date']).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  )}

                </div>
              </div>

            </Col>
          )}

          {headLabel === 'cashdividend' && (
            <Col md="4">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">
                    Bonus Shares
                  </div>
                </div>
                <div className="card-body">

                  <div className="form-group">
                    <div>Bonus Percentage</div>
                    <div className="form-control w-100">
                      {fields['bonus_percentage']}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>Gross Bonus Share</div>
                    <div className="form-control w-100">
                      {fields['bonus_gross_volume']}
                    </div>
                  </div>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <div>Tax on Bonus (%)*</div>
                        <div className="form-control w-100">
                          {fields['tax_on_bonus']}
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        <div>Tax on Bonus Shares</div>
                        <div className="form-control w-100">
                          {fields['tax_it_bonus_amount']}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="form-group">
                    <div>Net Bonus Shares</div>
                    <div className="form-control w-100">
                      {fields['net_bonus_volume']}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>Bonus Credit Date</div>
                    <div className="form-control w-100">
                      {fields['bonus_credit_date'] == '' ? '' : moment(fields['bonus_credit_date']).format('DD-MM-YYYY')}
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          )}

          {headLabel === 'cashdividend' && (
            <Col md="4">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">
                    Right Shares
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <div>Right Share Percentage</div>
                    <div className="form-control w-100">
                      {fields['right_share_percentage']}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>Gross Right Share</div>
                    <div className="form-control w-100">
                      {fields['right_share_gross_volume']}
                    </div>
                  </div>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <div>Tax on Right (%)*</div>
                        <div className="form-control w-100">
                          {fields['tax_on_right']}
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="form-group">
                        <div>Tax on Right Shares</div>
                        <div className="form-control w-100">
                          {fields['tax_it_right_share_amount']}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="form-group">
                    <div>Net Right Shares</div>
                    <div className="form-control w-100">
                      {fields['net_right_share_volume']}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>Right Share Credit Date</div>
                    <div className="form-control w-100">
                      {fields['right_share_credit_date'] == '' ? '' : moment(fields['right_share_credit_date']).format('DD-MM-YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Amount</h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    <div>Gross Amount</div>
                    <div className="form-control w-100" title={inWords(parseFloat(fields['gross_amount']).toFixed(2))}>
                      {numberWithCommas(parseFloat(isNumber(fields['gross_amount'])).toFixed(2))}
                    </div>
                  </div>

                  {chechFieldDisplay(headLabel, 'tax_sst') && (
                    <div className="form-group">
                      Sale Tax (SST/PST)
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['tax_sst'])))}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                    <div className="form-group">
                      WHT IT
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['tax_wht_it'])))}
                      </div>
                    </div>
                  )}

                  {headLabel === 'funddividendpayout' && (
                    <div className="form-group">
                      WHT IT
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['tax_wht_it'])))}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                    <div className="form-group">
                      WHT Sale Tax
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['tax_wht_sst'])))}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'tax_cgt') && (
                    <div className="form-group">
                      CGT
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['tax_cgt'])))}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'sale_load') && (
                    <div className="form-group">
                      Sale Load
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['sale_load'])))}
                      </div>
                    </div>
                  )}

                  {chechFieldDisplay(headLabel, 'redemption_load') && (
                    <div className="form-group">
                      Redemption Load
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['redemption_load'])))}
                      </div>
                    </div>
                  )}

                  {(headLabel === 'redemptionofunits' || headLabel === 'conversionout') && (
                    <div className="form-group">
                      Zakat
                      <div className="form-control w-100">
                        {numberWithCommas(parseFloat(isNumber(fields['zakat_amount'])))}
                      </div>
                    </div>
                  )}

                  {/* <div className="form-group">

                <div className="d-flex space-between">
                  <div>Charges</div>
                      <div className=" ">	
                      {fields.txn_charges !== '[]' && fields.txn_charges !== '' && ( 
                          <a className=" "  	
                          onClick={() => {	
                              // setTaxModal(true);	
                              setTotalTax(JSON.parse(fields.txn_charges))	
                              // system
                              setSystemTaxModal(true);
                              let temp = JSON.parse(fields.system_tax);
                              setSystemTax(temp);
                              setCheckChanges(temp.amount);
                          }}	
                          >	
                          View Tax	
                          </a>

                       )} 
                      </div>
                   </div> 
                  <div className="form-control   w-100">
                    {fields['total_charges']
                      ? parseFloat(fields['total_charges']).toFixed(2)
                      : '0.00'}
                  </div>
                </div> */}
                  {chechFieldDisplay(headLabel, 'total_charges') && (
                    <div className="form-group">
                      <div>Total Amount</div>
                      <div className="form-control w-100" title={inWords(parseFloat(fields['total_charges']).toFixed(2))}>
                        {/* {parseFloat(fields['total_charges']).toFixed(2)} */}
                        {numberWithCommas(decimalValue(isNumber(fields['total_charges']), 2))}

                        {/* {parseFloat(fields['total_charges']).toLocaleString()} */}
                      </div>
                    </div>
                  )}

                  {headLabel === 'equityinvestment' && (
                    <>
                      <div className="form-group">
                        <div>Brokerage Commission</div>
                        <div className="form-control   w-100">
                          {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                          {numberWithCommas(decimalValue(isNumber(fields['broker_commission']), 2))}

                        </div>
                      </div>

                      <div className="form-group">
                        <div>SST on Commission</div>
                        <div className="form-control   w-100">
                          {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                          {numberWithCommas(decimalValue(isNumber(fields['tax_sst']), 2))}

                        </div>
                      </div>

                    </>
                  )}

                  {headLabel === 'cashdividend' && (

                    <>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <div>Tax Percentage on Dividend</div>
                            <div className="form-control   w-100">
                              {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                              {numberWithCommas(parseFloat(isNumber(fields['tax_on_dvidend'])))}

                            </div>
                          </div>
                        </Col>

                        <Col>
                          <div className="form-group">
                            <div>Tax Amount on Dividend</div>
                            <div className="form-control   w-100">
                              {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                              {numberWithCommas(parseFloat(isNumber(fields['tax_wht_it'])))}

                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  <div className="form-group">
                    <div>Net Amount</div>
                    <div className="form-control   w-100">
                      {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                      {numberWithCommas(parseFloat(isNumber(fields['net_amount'])).toFixed(2))}

                    </div>
                  </div>

                  <div className="form-group">
                    <div>Net Amount In Words</div>
                    <div className="form-control w-100">
                      {inWords(parseFloat(isNumber(fields['net_amount'])).toFixed(2))}
                    </div>
                  </div>

                  <div className="form-group">
                    <div> Type</div>
                    <div className="form-control   w-100">
                      {fields['payment_type']}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className=" mt-2">
            </div>

          </Col>

        </Row>

      )}

      {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (

        <Row>
          <Col md='4'>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Transaction Processing
                </h4>
              </div>
              <div className="card-body">

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Transaction Type</div>
                      <div className="form-control   w-100">
                        {CapTxnType(fields['txnr_type'])}

                      </div>
                    </div>

                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <div>Transaction ID</div>
                      <div className="form-control   w-100">
                        {fields['txn_id'] != undefined ? fields['txn_id'].replaceAll('TXN_', '') : ''}
                      </div>
                    </div>
                  </Col>
                </Row>


                <div className="form-group">
                  <div> Created By</div>
                  <div className="form-control   w-100">
                    {fields['created_by']}
                  </div>
                </div>
                <div className="form-group">
                  <div> Status</div>
                  <div className="form-control   w-100">
                    {fields['txn_status']}
                  </div>
                </div>

                <Row>
                  <Col md="6">

                    <FormGroup check className="mt-3">

                      <Input type="checkbox"
                        checked={realizedCheck}

                      />
                      <span className="form-check-sign" />
                      <span className="ml-4 mt-minus66">  Realized In Bank </span>

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <div>Realized Date</div>
                      <div className="form-control   w-100">
                        {isDateValid(moment(fields['realized_date']).format('DD-MM-YYYY'))}
                      </div>
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </Col>
          {fields['txn_status'] === 'COMPLETED' && fields['source_portal'] && (
            <Col md='4'>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Portal Details</h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <div>Source Portal Name</div>
                    <div className="form-control w-100">
                      {fields['source_portal']}
                    </div>
                  </div>

                  {fields['source_txn_id'] && <div className="form-group">
                    <div> Source Transaction ID</div>
                    <div className="form-control   w-100">
                      {fields['source_txn_id']}
                    </div>
                  </div>}

                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
      <Row>
        {fields['bankCode'] === 'HBL' && (
          <Col md='12'>
            <div className='float-right'>
              <button
                className="btn btn-primary btn-sm mr-3 "
              // disabled={false}
              // onClick={() => {
              // })
              >
                Payment Complete
              </button>
            </div>
          </Col>
        )}
        <Col md='12'>
          {(fields['txn_status'] === 'COMPLETED' && headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (

            <Col md='12'>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Ledgers</h4>
                </div>
                <div className="card-body">
                  {!ledgerLoading ? (

                    countledgerData > 0 && (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Ledger ID</th>
                              <th>Description</th>
                              <th>Fund Account</th>
                              <th>Debit Amount</th>
                              <th>Credit Amount</th>
                              <th>Counter Account</th>
                              <th>Status</th>
                              <th>Realized</th>
                              {(role_name === 'ADMIN' || role_name === 'CONCERNED_OFFICER') && (
                                <>
                                  <th>Action</th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {ledgerData.map((item: any, index: string) => {
                              return (
                                <tr key={index}
                                  className={
                                    item.Record.realized_in_bank_status === 'PAYMENT-COMPLETE'
                                      ? "highlight-row-success"
                                      : ""
                                  }
                                >
                                  <td>{item.Record.ledger_id.replace('ID_', '')}</td>
                                  <td>{item.Record.description.split('-')[0]}</td>
                                  <td>{item.Record.account_number}
                                    <br /> <small><span className="text-muted"> {item.Record.account_title} </span></small>
                                  </td>
                                  <td>{numberWithCommas(parseFloat(isNumber(item.Record.debit_amount)))}</td>
                                  <td>{numberWithCommas(parseFloat(isNumber(item.Record.credit_amount)))}</td>
                                  <td>{item.Record.counter_account_number}
                                    <br /> <small><span className="text-muted"> {item.Record.counter_account_title} </span></small>
                                  </td>
                                  {
                                    fields.bank === 'HABB' && parseFloat(isNumber(item.Record.credit_amount)) > 0 ? (
                                      <td>{item?.Record?.realized_in_bank_status || 'PENDING'}</td>
                                    ) : (
                                      <td></td>
                                    )}
                                  <td>
                                    <FormGroup check>
                                      <Label check>
                                        <input type="checkbox" checked={item.Record.realized === "true" ? true : false} />
                                        <span className="form-check-sign" />
                                      </Label>
                                    </FormGroup>
                                  </td>
                                  {(role_name === 'ADMIN' || role_name === 'CONCERNED_OFFICER') && (
                                    <>
                                      <td>
                                        <ReactTooltip
                                          textColor="white"
                                          backgroundColor="black"
                                          effect="float"
                                        />

                                        {(item.Record.counter_account_number !== '' && fields.bank === 'HABB' && parseFloat(isNumber(item.Record.credit_amount)) > 0 && fields.txn_status === 'COMPLETED' && ((role_name === 'CONCERNED_OFFICER' && item.Record.realized === "false") || (role_name === 'ADMIN'))) ? (
                                          <>
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <FaCreditCard
                                              data-tip={'Make Bank Payment'}
                                              className={'ml-1 '}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                setFlagBankPaymentRealized(true);

                                                setPaymentData(item);
                                                setLedgerStatus(item?.Record?.realized_in_bank_status || '');
                                                setledgerId(item.Record.ledger_id.replace('ID_', ''));
                                                setRealized(item.Record.realized);
                                                if (item.Record.realized !== 'true') {
                                                  setMACSRealized(true);
                                                  setRealize(false);
                                                  setShowDate(false);
                                                } else {
                                                  setMACSRealized(false);
                                                }
                                              }}
                                            />
                                            <span className="text-primary"> {'|'} </span>
                                          </>
                                        ) : (
                                          ''
                                        )}

                                        {fields.txn_status === 'COMPLETED' &&
                                          parseFloat(item.Record.credit_amount) > 0 &&
                                          fields.instrument_type === 'Cheque' && (
                                            <>
                                              <RiPrinterLine
                                                data-tip={'Print Cheque'}
                                                className={'mr-1'}
                                                onClick={() => {
                                                  getTemplates(item.Record.bank_code);
                                                  setData(item);
                                                  setTitle(fields['counter_account_title']);
                                                  setAmount(item.Record.credit_amount);
                                                  sessionStorage.setItem('cheque', JSON.stringify(item.Record));
                                                  setDisplayChequeModal(true);
                                                }}

                                              />
                                              <span className="text-primary">{'|'}</span>
                                            </>
                                          )}

                                        {(item.Record.instrument_no == '' && role_name === 'CONCERNED_OFFICER') || (role_name === 'ADMIN') ? (
                                          <>
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <FaRegMoneyBillAlt
                                              data-tip={'Add Instrument Detail'}
                                              className={'ml-1 mr-1'}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                setledgerId(item.Record.ledger_id.replace('ID_', ''));
                                                setInstrumentNo(item.Record.instrument_no);
                                                setAddInstrumentModal(true);
                                                setRealized(item.Record.realized);
                                              }}
                                            />
                                            <span className="text-primary"> {'|'} </span>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {(fields.txn_status === 'COMPLETED' && ((role_name === 'CONCERNED_OFFICER' && item.Record.realized === "false") || (role_name === 'ADMIN'))) && (
                                          <>
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <RiCheckboxMultipleLine
                                              data-tip={'Realized In Bank'}
                                              className={'ml-1'}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                setledgerId(item.Record.ledger_id.replace('ID_', ''));
                                                setRealized(item.Record.realized);
                                                setMACSRealized(false);
                                                if (item.Record.realized == 'true') {
                                                  setRealize(true);
                                                  setShowDate(true);
                                                  setOnceUpdateRel(true);
                                                } else {
                                                  setRealize(false);
                                                  setShowDate(false);
                                                }

                                                setAddMarkRealized(true);
                                              }}
                                            />
                                          </>
                                        )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                      </div>
                    )

                  ) :
                    (
                      <div className="d-flex justify-content-center my-5">
                        <i className="fa fa-spinner fa-spin fa-3x" ></i>
                      </div>
                    )}
                </div>
              </div>
            </Col>
          )}
        </Col>
      </Row>

      <Row>

      </Row>

      {renderSecuritiesModalPopup()}
      {renderMoneyMarketSecuritiesModalPopup()}
      {renderTransfereesModalPopup()}
      {renderAutoSettledModalPopup()}
      {renderTaxPopup()}
      {renderSystemTaxPopup()}
      {renderModalForMarkRealized()}
      {renderModalForMarkBankPaymentRealized()}
      {renderModalForInstrumentPopup()}
      {renderModaForPrintChequePopup()}
      {renderModalInvestmentInFund()}
    </div >
  );
};

export default ViewPaymentTXN;
