import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getUserActivity } from '../../stores/services/report.service';
import { PDFExport, } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isDateValid, isNumber, sortAMCS } from '../../utils/customFunction';
import { getAllUsers } from '../../stores/services/user.service';
import Select from 'react-select';

const UserActivitySummaryReport = () => {
    const pdfExportComponent = React.useRef<PDFExport>(null);
    const email = sessionStorage.getItem('email') || '';
    let date = new Date();
    const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

    const [loading, setLoading] = useState(false);
    const [userActivity, setUserActivity] = useState<any>([]);

    const [searchEmail, setSearchEmail] = useState<any>('');
    const [allEmail, setAllEmail] = useState<any>([]);
    const [searchEmailError, setSearchEmailError] = useState('');
    const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));
    let actionCounter: any = {};


    useEffect(() => {
        const getAllUserEmail = async () => {
            try {
                const response = await getAllUsers(email);
                let tempAmc = response.data.data.map((val) => {
                    return {
                      ...val,
                      label: `${val.email}`,
                      value: val.email,
                    };
                  });
                setAllEmail(tempAmc);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message)
                }
            }
        }
        getAllUserEmail()
    }, [])

    const renderEmailDropdown = () => {
        return allEmail.map((item: any, index: number) => {
            return (
                <option key={index} value={item.email}>
                    {item.email}
                </option>
            );
        });
    }

    let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

    const CapTxnType = (type: any) => {
        let i, txType;
        for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
        }
        return txType;
    };

    //code for search data for Reports

    const searchData = async () => {
        userActivity.length = 0;
        setUserActivity(userActivity)
        if (searchEmail.trim() === '') {
            setSearchEmailError('required-border');
        } else {
            try {
                setLoading(true)
                const response = await getUserActivity(email, fromDate, toDate, searchEmail);
                if (response.data.status === 200) {
                    let filterData: any = response.data.data.map((item) => {
                        let role = '';
                        allEmail.filter((elem) => {
                            if ((item.created_by === '' ? item.updated_by : item.created_by) === elem.email) {
                                role = elem.role;
                            }
                        })
                        return { ...item, role: role }
                    })
                    setUserActivity(filterData);
                    setLoading(false)
                    setResFromDate(fromDate);
                    setResToDate(toDate);
                } else {
                    toast.error(response.data.message);
                    setUserActivity([]);
                    setLoading(false)
                }
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message)
                }
                setLoading(false);
            }
        }
    }


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">User Activity Summary Report</h4>
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="3">
                                                <div className="form-group  ">
                                                    <label>Select Email</label>
                                                    {/* <select
                                                        className={"form-control " + searchEmailError}

                                                        onChange={(e) => {
                                                            setSearchEmail(e.target.value);
                                                            setSearchEmailError('');
                                                            setUserActivity([]);
                                                        }}
                                                    >
                                                        <option value="" defaultChecked hidden>
                                                            Select Email
                                                        </option>
                                                        {renderEmailDropdown()}
                                                    </select> */}
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        label={'Select'}
                                                        isDisabled={false}
                                                        onChange={(e) => {
                                                            setSearchEmail(e.value);
                                                            setSearchEmailError('');
                                                            setUserActivity([]);


                                                        }}
                                                        value={allEmail.filter(
                                                            (option, index) => option.value === searchEmail
                                                            )}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={allEmail}
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                    />
                                                </div>
                                            </Col>


                                            <Col md="2">
                                                <div className="form-group  ">
                                                    <label>Date From</label>
                                                    <input
                                                        type="date"
                                                        defaultValue={fromDate}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            let date = moment(e.target.value).format('YYYY-MM-DD');
                                                            setFromDate(date);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="form-group  ">
                                                    <label>Date To</label>
                                                    <input
                                                        type="date"
                                                        defaultValue={toDate}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            let date = moment(e.target.value).format('YYYY-MM-DD');
                                                            setToDate(date);
                                                        }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className=" ">
                                                    {/* here Search Button */}
                                                    <button
                                                        className="btn btn-primary mt-4"
                                                        onClick={() => {
                                                            searchData();
                                                        }}
                                                        disabled={Boolean(loading)}
                                                    >
                                                        {loading ? (
                                                            <>
                                                                <span
                                                                    className="spinner-border login-txt spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="login-txt"> Loading...</span>
                                                            </>
                                                        ) : (
                                                            <span>Search</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* End */}
                                    </div>
                                </div>

                                {/* here Search results for report Data */}
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div
                                            id="printDiv"
                                            className="p-4"
                                            style={{ background: 'white', color: 'black' }}
                                        >
                                            <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"User Activity Summary Report-" + date + ".pdf"} ref={pdfExportComponent}>
                                                {userActivity.length > 0 ? (
                                                    <>
                                                        <ReportHeader title="User Activity Summary Report" />

                                                        <Row>
                                                            <Col md="5" style={{ marginLeft: '70px' }}>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                            Email:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <span className="text-nowrap">{searchEmail}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md="5" style={{ marginLeft: '70px' }}>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                            Report Date:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <span className="text-nowrap" >{moment(date).format('DD-MM-YYYY')}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md="5" style={{ marginLeft: '70px' }}>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                            From Date:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md="5" style={{ marginLeft: '70px' }}>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                            To Date:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <span className="text-nowrap" >{moment(resToDate).format('DD-MM-YYYY')}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        {/* <Row>
                                                            <Col md='6'>
                                                                <Row>
                                                                    <Col md='2'></Col>
                                                                    <Col md='4'> <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                        Email:
                                                                    </span></Col>
                                                                    <Col md='6'> <span className="text-nowrap">{searchEmail}</span></Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md='6'>
                                                                <Row>
                                                                    <Col md='4'> <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                        Report Date:
                                                                    </span></Col>
                                                                    <Col md='6'> <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span></Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md='6'>
                                                                <Row>
                                                                    <Col md='2'></Col>
                                                                    <Col md='4'><span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                        From Date:
                                                                    </span></Col>
                                                                    <Col md='6'><span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span> </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md='6'>
                                                                <Row>
                                                                    <Col md='4'><span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                        To Date:
                                                                    </span></Col>
                                                                    <Col md='6'><span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span></Col>
                                                                </Row>
                                                            </Col>
                                                        </Row> */}

                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                overflowY: 'hidden',
                                                            }}
                                                        >
                                                            <table className="report" style={{ color: 'black' }}>
                                                                <thead style={{ alignSelf: 'center' }}>
                                                                    <tr>
                                                                        <th style={{ width: '25%' }}>Transaction ID</th>
                                                                        <th style={{ width: '25%' }}>Transaction Type</th>
                                                                        <th style={{ width: '25%' }}>Action</th>
                                                                        <th style={{ width: '25%' }}>Action Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="report-table-body">
                                                                    {

                                                                        userActivity.map((item: any, index: number) => {
                                                                            if (actionCounter[item.action.replace('-AMC', '').replace('-TRUSTEE', '')]) {
                                                                                actionCounter[item.action.replace('-AMC', '').replace('-TRUSTEE', '')] = actionCounter[item.action.replace('-AMC', '').replace('-TRUSTEE', '')] + 1;
                                                                            } else {
                                                                                actionCounter[item.action.replace('-AMC', '').replace('-TRUSTEE', '')] = 1
                                                                            }
                                                                            return (
                                                                                <>

                                                                                    <tr key={index}>
                                                                                        <td style={{ width: '25%' }}>{item.txn_id}</td>
                                                                                        <td style={{ width: '25%' }}>{CapTxnType(item.txnr_type)}</td>
                                                                                        <td style={{ width: '25%' }}>{item.action}</td>
                                                                                        <td style={{ width: '25%' }}>{item.created_at === '' ? moment(item.updated_at).format("DD-MM-YYYY, h:mm a") : moment(item.created_at).format("DD-MM-YYYY, h:mm a")}</td>
                                                                                    </tr>
                                                                                </>
                                                                            );
                                                                        })
                                                                        // )
                                                                    }

                                                                </tbody>
                                                                <Row style={{ marginTop: '20px' }}>
                                                                    <Col md="12" >
                                                                        <Row>
                                                                            <Col md="5">
                                                                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                                    TOTAL CREATED:
                                                                                </span>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <span className="text-nowrap" style={{ marginLeft: '50px' }}>{actionCounter.CREATED ? actionCounter.CREATED : '0'}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <Row>
                                                                            <Col md="5">
                                                                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                                    TOTAL REJECTED:
                                                                                </span>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <span className="text-nowrap" style={{ marginLeft: '50px' }}>{actionCounter.REJECTED ? actionCounter.REJECTED : '0'}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="12">
                                                                        <Row>
                                                                            <Col md="5">
                                                                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                                    TOTAL ACCEPTED:
                                                                                </span>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <span className="text-nowrap" style={{ marginLeft: '50px' }}>{actionCounter.ACCEPTED ? actionCounter.ACCEPTED : '0'}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    {(userActivity[0].role !== 'TXN_CREATOR' && userActivity[0].role !== 'AUTHORIZER-A' && userActivity[0].role !== 'AUTHORIZER-B') && (
                                                                        <Col md="12">
                                                                            <Row>
                                                                                <Col md="5">
                                                                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                                                                        TOTAL COMPLETED:
                                                                                    </span>
                                                                                </Col>
                                                                                <Col md="6">
                                                                                    <span className="text-nowrap" style={{ marginLeft: '50px' }}>{actionCounter.COMPLETED ? actionCounter.COMPLETED : '0'}</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    )
                                                                    }

                                                                </Row>
                                                                {/* <Row>
                                                                    <Col md='4'>
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold', marginLeft: '70px' }}>
                                                                            TOTAL ACCEPTED:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md='8'>{actionCounter.ACCEPTED ? actionCounter.ACCEPTED : '0'}</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md='4'>
                                                                        <span className="text-nowrap" style={{ fontWeight: 'bold', marginLeft: '70px' }}>
                                                                            TOTAL COMPLETED:
                                                                        </span>
                                                                    </Col>
                                                                    <Col md='8'>{actionCounter.COMPLETED ? actionCounter.COMPLETED : '0'}</Col>
                                                                </Row> */}
                                                                {/* <tfoot >
                                                                    <tr key={0} style={{ marginTop: 30 }}>
                                                                        <td>CREATED</td>
                                                                        <td>{actionCounter.CREATED}</td>
                                                                    </tr>
                                                                    <tr key={1}>
                                                                        <td>ACCEPTED</td>
                                                                        <td>{actionCounter.ACCEPTED}</td>
                                                                    </tr>
                                                                    <tr key={2}>
                                                                        <td>COMPLETED</td>
                                                                        <td>{actionCounter.COMPLETED}</td>
                                                                    </tr>

                                                                </tfoot> */}

                                                            </table>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="table-empty">
                                                        <BsTable className="icon" />
                                                        <p className="text-center empty-text">No record found</p>
                                                    </div>
                                                )}
                                            </PDFExport>
                                        </div>
                                        <button
                                            className="btn btn-primary mt-3"
                                            onClick={() => {
                                                // printReport();
                                                if (pdfExportComponent.current) {
                                                    pdfExportComponent.current.save();
                                                }
                                            }}
                                        >
                                            Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UserActivitySummaryReport;
