const txnObj = [
    {
      type: 'fund',
      counter_type: 'saleofunit',
      required: [
        'fund_code',
        'account_no',
        'folio_no',
        'from_date',
        'to_date',
        'txn_id',
        'date_type',
        'txn_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'redemptionofunits',
      required: [
        'fund_code',
        'account_no',
        'folio_no',
        'from_date',
        'to_date',
        'txn_id',
        'date_type',
        'txn_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'unitconversion',
      required: [
        'fund_code',
        'from_date',
        'to_date',
        'txn_id',
        'date_type',
        'txn_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'conversionin',
      required: [
        'fund_code',
        'from_date',
        'to_date',
        'txn_id',
        'date_type',
        'txn_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'conversionout',
      required: [
        'fund_code',
        'from_date',
        'to_date',
        'txn_id',
        'txn_type',
        'date_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'funddividendpayout',
      required: [
        'fund_code',
        'account_no',
        'folio_no',
        'from_date',
        'to_date',
        'txn_id',
        'date_type',
        'txn_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'debtmarketinvestment',
      required: [
        'fund_code',
        'from_date',
        'to_date',
        'txn_id',
        'txn_type',
        'issue_date',
        'maturity_date',
        'settlement_date',
        'security_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'fundtransfer',
      required: [
        'fund_code',
        'account_no',
        'bank_code',
        'to_bank_code',
        'from_date',
        'to_date',
        'txn_id',
        'txn_type',
        'status',
      ],
    },
    // {
    //   type: 'fund',
    //   counter_type: 'inflow',
    //   required: [
    //     'fund_code',
    //     'account_no',
    //     'bank_code',
    //     'from_date',
    //     'to_date',
    //     'txn_id',
    //     'txn_type',
    //     'status',
    //   ],
    // },
    // {
    //   type: 'fund',
    //   counter_type: 'outflow',
    //   required: [
    //     'fund_code',
    //     'account_no',
    //     'bank_code',
    //     'from_date',
    //     'to_date',
    //     'txn_id',
    //     'txn_type',
    //     'status',
    //   ],
    // },
    {
      type: 'fund',
      counter_type: 'Payment',
      required: [
        'fund_code',
        'account_no',
        'from_date',
        'to_date',
        'txn_id',
        'bank_code',
        'branch_code',
        'payment_type',
        'status',
      ],
    },
    {
      type: 'fund',
      counter_type: 'Receipt',
      required: [
        'fund_code',
        'account_no',
        'from_date',
        'to_date',
        'txn_id',
        'bank_code',
        'branch_code',
        'payment_type',
        'status',
      ],
    },
  ];
  
  const fieldValue = {
    fund_code: '',
    account_no: '',
    folio_no: '',
    from_date: '',
    to_date: '',
    txn_id: '',
    txn_type: '',
    date_type: '',
    bank_code: '',
    branch_code: '',
    issue_date: '',
    maturity_date: '',
    settlement_date: '',
    security_type: '',
    to_bank_code: '',
    payment_type: '',
    status: '',
  };
  
  const fieldData = () => {
    return txnObj;
  };
  
  const reportObj = (type, counter_type) => {
    let tx;
    txnObj.map((item, index) => {
      if (item.type === type && item.counter_type === counter_type) {
        tx = item.required;
      }
    });
    return tx;
  };
  
  const checkFieldDisplay = (type, counter_type, name) => {
    let tx;
    txnObj.map((item, index) => {
      if (item.type === type && item.counter_type === counter_type) {
        tx = item.required.includes(name);
      }
    });
    return tx;
  };
  
  const getDateType = (type, counter_type) => {
    let dateType = [] as any;
    txnObj.map((item, index) => {
      if (item.type === type && item.counter_type === counter_type) {
        if (counter_type === 'saleofunit') {
          dateType.push(
            { value: 'NAV date', label: 'NAV date' },
            { value: 'Sale Booking date', label: 'Sale Booking date' },
            { value: 'Realize date', label: 'Realize date' }
          );
        } else if (counter_type === 'redemptionofunits') {
          dateType.push(
            { value: 'NAV date', label: 'NAV date' },
            { value: 'Red Booking date', label: 'Red Booking date' },
            { value: 'Payment date', label: 'Payment date' }
          );
        } else if (counter_type === 'unitconversion') {
          dateType.push(
            { value: 'NAV date', label: 'NAV date' },
            {
              value: 'Transaction Booking date',
              label: 'Transaction Booking date',
            },
            { value: 'Payment/Sale date', label: 'Payment/Sale date' }
          );
        } else if (counter_type === 'conversionin') {
          dateType.push(
            { value: 'NAV date', label: 'NAV date' },
            {
              value: 'Transaction Booking date',
              label: 'Transaction Booking date',
            },
            { value: 'Payment/Sale date', label: 'Payment/Sale date' }
          );
        } else if (counter_type === 'conversionout') {
          dateType.push(
            { value: 'NAV date', label: 'NAV date' },
            {
              value: 'Transaction Booking date',
              label: 'Transaction Booking date',
            },
            { value: 'Payment/Sale date', label: 'Payment/Sale date' }
          );
        }
      }
    });
    return dateType;
  };
  
  const getCounterType = (type) => {
    let tx;
    txnObj.map((item, index) => {
      if (item.type === type) {
        tx = item.counter_type;
      }
    });
    return tx;
  };
  
  export {
    fieldData,
    reportObj,
    getCounterType,
    fieldValue,
    checkFieldDisplay,
    getDateType,
  };
  