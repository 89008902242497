
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import { getFunds } from './../../stores/services/funds.service';
import html2pdf from 'html2pdf.js';

import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAccountByFund } from '../../stores/services/account.service';
import { getdailyLedgerBalanceReport } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { sortAMCS } from '../../utils/customFunction';

const AccountSummaryReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [accountSummaryReport, setAccountSummaryReport] = useState<any>([]);
  const [accountSummaryReportCount, setAccountSummaryReportCount] = useState(0);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [fundAccount, setFundAccount] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resFundAccount, setResFundAccount] = useState('');
  const [resAccountTitle, setResAccountTitle] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [totalProcessedOpeningBalance, setTotalProcessedOpeningBalance] = useState('');
  const [totalDailyDebit, setTotalDailyDebit] = useState('');
  const [totalDailyCredit, setTotalDailyCredit] = useState('');
  const [totalProcessedClosingBalance, setTotalProcessedClosingBalance] = useState('');

  const [totalRealizedOpeningBalance, setTotalRealizedOpeningBalance] = useState('');
  const [totalRealizedDebitAmount, setTotalRealizedDebitAmount] = useState('');
  const [totalRealizedCrebitAmount, setTotalRealizedCrebitAmount] = useState('');
  const [totalRealizedClosingBalance, setTotalRealizedClosingBalance] = useState('');

  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [totalNet, setTotalNet] = useState('');

  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  const getFundAccounts = async (fund_code) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getAccountByFund(email, fund_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  }

  //code for search data for Reports

  const searchData = async () => {
    try {
      accountSummaryReport.length = 0;
      setAccountSummaryReport(accountSummaryReport);
      setAccountSummaryReportCount(accountSummaryReport);
      setTotalProcessedOpeningBalance('');
      setTotalDailyDebit('');
      setTotalDailyCredit('');
      setTotalProcessedClosingBalance('');
      setTotalRealizedDebitAmount('');
      setTotalRealizedCrebitAmount('');
      setLoading(true);

      const response = await getdailyLedgerBalanceReport(email, fundAccount, fromDate, toDate);
      let array = Object.entries(response.data.data)
      setResAmc(Amc);
      setResFund(fund);
      setResFundAccount(fundAccount)
      setResAccountTitle(accountTitle);
      setResFromDate(fromDate);
      setResToDate(toDate);
      let data: any = [];
      array.forEach(([key, value]) => {
        data = value;
        let record = [];
        record = data.Record;
        record['ledger_id'] = data.Key;
        record['processed_closing_balance'] = (parseFloat(data.Record.processed_opening_balance === 'NaN' ? '0' : data.Record.processed_opening_balance || '0') + (parseFloat(data.Record.daily_debit === 'NaN' ? '0' : data.Record.daily_debit || '0') - parseFloat(data.Record.daily_credit === 'NaN' ? '0' : data.Record.daily_credit || '0'))).toFixed(2);
        record['realized_closing_balance'] = (parseFloat(data.Record.realized_opening_balance === 'NaN' ? '0' : data.Record.realized_opening_balance || '0') + (parseFloat(data.Record.daily_realized_debit === 'NaN' ? '0' : data.Record.daily_realized_debit || '0') - parseFloat(data.Record.daily_realized_credit === 'NaN' ? '0' : data.Record.daily_realized_credit || '0'))).toFixed(2);
        record['debit'] = (parseFloat(data.Record.daily_debit === 'NaN' ? '0' : data.Record.daily_debit || '0') - parseFloat(data.Record.daily_realized_debit === 'NaN' ? '0' : data.Record.daily_realized_debit || '0')).toFixed(2);
        record['credit'] = (parseFloat(data.Record.daily_credit === 'NaN' ? '0' : data.Record.daily_credit || '0') - parseFloat(data.Record.daily_realized_credit === 'NaN' ? '0' : data.Record.daily_realized_credit || '0')).toFixed(2);
        record['net'] = (parseFloat(record['debit'] === 'NaN' ? '0' : record['debit'] || '0') - parseFloat(record['credit'] === 'NaN' ? '0' : record['credit'] || '0')).toFixed(2);
        accountSummaryReport.push(record);
      });
      let processedopeningBalance = 0, realizedopeningBalance = 0, dailyDebit = 0, dailyCredit = 0, processedclosingBalance = 0, realizedclosingBalance = 0, realizedDebitAmount = 0, realizedCreditAmount = 0, debit = 0, credit = 0, net = 0;
      accountSummaryReport.map((item: any, index: number) => {
        processedopeningBalance += parseFloat(item.processed_opening_balance === 'NaN' ? '0' : item.processed_opening_balance || '0');
        dailyDebit += parseFloat(item.daily_debit === 'NaN' ? '0' : item.daily_debit || '0');
        dailyCredit += parseFloat(item.daily_credit === 'NaN' ? '0' : item.daily_credit || '0');
        processedclosingBalance += parseFloat(item.processed_closing_balance === 'NaN' ? '0' : item.processed_closing_balance || '0');
        realizedopeningBalance += parseFloat(item.realized_opening_balance === 'NaN' ? '0' : item.realized_opening_balance || '0');
        realizedDebitAmount += parseFloat(item.daily_realized_debit === 'NaN' ? '0' : item.daily_realized_debit || '0');
        realizedCreditAmount += parseFloat(item.daily_realized_credit === 'NaN' ? '0' : item.daily_realized_credit || '0');
        realizedclosingBalance += parseFloat(item.realized_closing_balance === 'NaN' ? '0' : item.realized_closing_balance || '0');
        debit += parseFloat(item.debit === 'NaN' ? '0' : item.debit || '0');
        credit += parseFloat(item.credit === 'NaN' ? '0' : item.credit || '0');
        net += parseFloat(item.net === 'NaN' ? '0' : item.net || '0');
      });

      setTotalProcessedOpeningBalance(processedopeningBalance.toFixed(2));
      setTotalDailyDebit(dailyDebit.toFixed(2));
      setTotalDailyCredit(dailyCredit.toFixed(2));
      setTotalProcessedClosingBalance(processedclosingBalance.toFixed(2));
      setTotalRealizedOpeningBalance(realizedopeningBalance.toFixed(2));
      setTotalRealizedDebitAmount(realizedDebitAmount.toFixed(2));
      setTotalRealizedCrebitAmount(realizedCreditAmount.toFixed(2));
      setTotalRealizedClosingBalance(realizedclosingBalance.toFixed(2));
      setTotalDebit(debit.toFixed(2));
      setTotalCredit(credit.toFixed(2));
      setTotalNet(net.toFixed(2));
      setAccountSummaryReportCount(accountSummaryReport.length);
      setLoading(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
      setLoading(false);
    }
  }

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Daily Account Summary</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFunds(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFundAccounts(e.target.value);
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund Account</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFundAccount(e.target.value);
                              var AccountTitle = e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setAccountTitle(AccountTitle.split('(')[1].replace(')', ''));
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund Account
                            </option>
                            {renderFundAccountsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Account-Summary-Report-" + date + ".pdf"} ref={pdfExportComponent}>
                        {accountSummaryReportCount > 0 ? (
                          <>
                            <ReportHeader title="Daily Account Summary" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Daily Account Summary</h3>
                  </div> */}

<Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Account Title:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAccountTitle}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Account No:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFundAccount}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                From Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                To Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resFund}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account Title:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resAccountTitle}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account No:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resFundAccount}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>


                            </Row> */}

                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr>
                                    <th>Date</th>
                                    <th colSpan={4}>Posted</th>
                                    <th colSpan={4}>Realized</th>
                                    <th colSpan={3}>Difference</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th className="text-right">Opening Balance</th>
                                    <th className="text-right">Daily Debit </th>
                                    <th className="text-right">Daily Credit </th>
                                    <th className="text-right">Closing Balance </th>
                                    <th className="text-right">Opening Balance</th>
                                    <th className="text-right">Debit Amount</th>
                                    <th className="text-right">Credit Amount</th>
                                    <th className="text-right">Closing Balance </th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit</th>
                                    <th className="text-right">Net</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {accountSummaryReport.map((item: any, index: string) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                          <td className="text-right">{numberWithCommas(item.processed_opening_balance)}</td>
                                          <td className="text-right">{numberWithCommas(item.daily_debit)}</td>
                                          <td className="text-right">{numberWithCommas(item.daily_credit)}</td>
                                          <td className="text-right"> {numberWithCommas(item.processed_closing_balance)} </td>
                                          <td className="text-right">{numberWithCommas(item.realized_opening_balance)}</td>
                                          <td className="text-right">{numberWithCommas(item.daily_realized_debit)}</td>
                                          <td className="text-right">{numberWithCommas(item.daily_realized_credit)}</td>
                                          <td className="text-right"> {numberWithCommas(item.realized_closing_balance)} </td>
                                          <td className="text-right"> {numberWithCommas(item.debit)} </td>
                                          <td className="text-right"> {numberWithCommas(item.credit)} </td>
                                          <td className="text-right"> {numberWithCommas(item.net)} </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <th>Total</th>
                                    <th className="text-right">{numberWithCommas(totalProcessedOpeningBalance)}</th>
                                    <th className="text-right">{numberWithCommas(totalDailyDebit)}</th>
                                    <th className="text-right">{numberWithCommas(totalDailyCredit)}</th>
                                    <th className="text-right"> {numberWithCommas(totalProcessedClosingBalance)} </th>
                                    <th className="text-right">{numberWithCommas(totalRealizedOpeningBalance)}</th>
                                    <th className="text-right">{numberWithCommas(totalRealizedDebitAmount)}</th>
                                    <th className="text-right">{numberWithCommas(totalRealizedCrebitAmount)}</th>
                                    <th className="text-right"> {numberWithCommas(totalRealizedClosingBalance)} </th>
                                    <th className="text-right"> {numberWithCommas(totalDebit)} </th>
                                    <th className="text-right"> {numberWithCommas(totalCredit)} </th>
                                    <th className="text-right"> {numberWithCommas(totalNet)} </th>

                                  </tr>

                                </tbody>
                              </table>
                            </div>

                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AccountSummaryReport;
