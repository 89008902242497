import React from 'react';
import {
  addBroker,
  getBrokerType,
} from '../../../stores/services/broker.service';
import { getAllBanks, getCities } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardHeader, CardTitle, CardBody, Card } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { accountTitle_AccountNumber_Regex } from './../../../utils/customFunction';
import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format";



const BrokerComponent = () => {
  const history = useHistory();
  //hooks for getting all inputs from user	
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const [brokerType, setBrokerType] = useState('');
  const [revenueAuthority, setRevenueAuthority] = useState('');
  const [iban, setIban] = useState('');
  const [acc_title, setAccTitle] = useState('');
  const [code, setCode] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [companyName, setCompanyName] = useState('');

  // new feild 
  const [registered_name, setRegisteredName] = useState('');
  const [payment_section, setPaymentSection] = useState('');
  const [cnic, setCnic] = useState('');
  const [ntn, setNtn] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [category, setCategory] = useState('');
  const [nature_of_bussiness, setNatureBusiness] = useState('');

  //error getting hooks	
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [brokerNameError, setBrokerError] = useState('');
  const [brokerTypeError, setBrokerTypeError] = useState('');
  const [revenueAuthorityError, setRevenueAuthorityError] = useState('');
  const [ibanError, setIbanError] = useState('');
  const [acc_titleError, setAccTitleError] = useState('');
  const [codeError, setCodeError] = useState('');
  // new error getting hooks
  const [registeredNameError, setRegisteredNameError] = useState('');
  const [paymentSectionError, setPaymentSectionError] = useState('');
  const [cnicError, setCnicError] = useState('');
  const [ntnError, setNtnError] = useState('');
  const [cityError, setCityError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [natureBusinessError, setNatureBusinessError] = useState('');

  const [Loading, setLoading] = useState(false);
  const [companyNameError, setCompanyNameError] = useState('');
  const [activeError, setActiveError] = useState('');
  const [brokerTypeData, setBrokerTypeData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCitiesData, setAllCitiesData] = useState<any>([]);

  const [active, setActive] = useState('');
  //	
  const codeRegex = new RegExp('^[A-Z]{1,3}-[0-9]{1,3}$');
  //for dropdown data	
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    setBranchName('');
    setBranchNameError('');
    //get all branches for selected bank for dropdown	
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false)
      } else {
        setBranchCheckFlag(true)
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  };
  React.useEffect(() => {
    const fetchBank = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown	
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const res = await getBrokerType(email);
        setBrokerTypeData(res.data.company_names);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const response = await getCities();
        setAllCitiesData(response.data.cities);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      //get tx nature data for dropdown	
    };
    fetchBank();
  }, []);

  const renderCitiesDropdown = () => {
    return allCitiesData.map((item: any) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
  };

  const changeCodeValue = (value: any) => {
    setCode(value);
    if (value.trim() === '') {
      setCodeError('Required');
    } else {
      codeRegex.test(value) !== true
        ? setCodeError('Invalid')
        : setCodeError('');
    }
  };
  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_name} ({item.bank_code.replace('BANK_', '')})
        </option>
      );
    });
  };
  //render dropdown for bank name data	
  const renderBrokerTypeDropdown = () => {
    return brokerTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data	
  const validate = (value: any) => {
    let bankErr,
      branchErr,
      brokerErr,
      brokerTypeErr,
      revenueAuthorityErr,
      ibanErr,
      titleErr,
      codeErr,
      regNameErr,
      paymentErr,
      cnicErr,
      cityErr,
      ntnError,
      addressErr,
      categoryErr,
      activeErr,
      nature_of_bussinessErr = '';

    bankname.trim() === '' ? (bankErr = 'Required') : (bankErr = '');
    branchName.trim() === '' ? (branchErr = 'Required') : (branchErr = '');
    brokerType.trim() === ''
      ? (brokerTypeErr = 'Required')
      : (brokerTypeErr = '');
    revenueAuthority.trim() === ''
      ? (revenueAuthorityErr = 'Required')
      : (revenueAuthorityErr = '');
    iban.trim() === '' ? (ibanErr = 'Required') : (ibanErr = '');
    acc_title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');
    companyName.trim() === '' ? (brokerErr = 'Required') : (brokerErr = '');
    // New
    registered_name.trim() === '' ? (regNameErr = 'Required') : (regNameErr = '');
    payment_section.trim() === '' ? (paymentErr = 'Required') : (paymentErr = '');
    cnic.trim() === '' ? (cnicErr = 'Required') : (cnicErr = '');
    ntn.trim() === '' ? (ntnError = 'Required') : (ntnError = '');
    city.trim() === '' ? (cityErr = 'Required') : (cityErr = '');
    address.trim() === '' ? (addressErr = 'Required') : (addressErr = '');
    category.trim() === '' ? (categoryErr = 'Required') : (categoryErr = '');
    active.trim() === '' ? (activeErr = 'Required') : (activeErr = '');
    nature_of_bussiness.trim() === '' ? (nature_of_bussinessErr = 'Required') : (nature_of_bussinessErr = '');
    nature_of_bussiness.trim() === '' ? (nature_of_bussinessErr = 'Required') : (nature_of_bussinessErr = '');

    if (value === 'broker') {
      setBankNameError('');
      setAccTitleError('');
      setCodeError('');
      //setCompanyNameError('');
      setBranchNameError('');
      setBrokerTypeError('');
      setRevenueAuthorityError('');
      setIbanError('');
      setRegisteredNameError('');
      setPaymentSectionError('');
      setNtnError('');
      setCnicError('');
      setCityError('')
      setAddressError('');
      setCategoryError('');
      setActiveError('');
      setNatureBusinessError('');
    } else if (value === 'createValid') {
      setCodeError(codeErr);
      setCompanyNameError(brokerErr)
      if (codeErr.length > 0 || brokerErr.length>0)  {
        return false;
      } else {
        return true;
      }
    } else if (
      bankErr ||
      branchErr ||
      brokerErr ||
      brokerTypeErr ||
      revenueAuthorityErr ||
      ibanErr ||
      titleErr ||
      codeErr ||
      regNameErr ||
      paymentErr ||
      cnicErr ||
      ntnError ||
      cityErr ||
      addressErr ||
      categoryErr ||
      activeErr ||
      nature_of_bussinessErr 
    ) {
      setBankNameError(bankErr);
      setAccTitleError(titleErr);
      setCodeError(codeErr);
      setCompanyNameError(brokerErr);
      setBranchNameError(branchErr);
      setBrokerTypeError(brokerTypeErr);
      setRevenueAuthorityError(revenueAuthorityErr);
      setIbanError(ibanErr);
      setRegisteredNameError(regNameErr);
      setPaymentSectionError(paymentErr);
      setNtnError(ntnError);
      setCnicError(cnicErr);
      setCityError(cityErr)
      setAddressError(addressErr);
      setCategoryError(categoryErr);
      setActiveError(activeErr);
      setNatureBusinessError(nature_of_bussinessErr);
    } else {
      return true;
    }
    // (value === 'create'){

    //   return false;
    // }else{
    //   setCodeError(codeErr);
    //   return false;
    // }
  };
  const addBrokerProcess = async () => {
    let isValid: any;
    if (brokerType === 'Primary Dealer') {
      isValid = validate('createValid');
    } else {
      isValid = validate('create');
    }

    if (isValid) {
      setLoading(true);
      try {
        const response = await addBroker(
          email,
          brokerType,
          bankname,
          branchName,
          iban,
          code,
          acc_title,
          companyName,
          revenueAuthority,
          active,
          registered_name,
          payment_section,
          cnic,
          ntn,
          city,
          address,
          category,
          nature_of_bussiness
        );
        if (response.data.status === 200) {
          setBrokerType('');
          setRevenueAuthority('');
          setCompanyName('');
          setCode('');
          setAccTitle('');
          setBankName('');
          setBranchName('');
          setIban('');
          setRegisteredNameError('');
          setPaymentSectionError('');
          setCnicError('');
          setNtnError('');
          setCityError('')
          setAddressError('');
          setCategoryError('');
          setActiveError('');
          setNatureBusinessError('')
          toast.success(response.data.message);
          history.replace('/admin/associated-beneficiaries');
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message[0]);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/associated-beneficiaries')
                  }}></i>Associated Beneficiary</h4>
                <Link to="/admin/associated-beneficiaries" className="t-3 ml-auto btn btn-primary btn-sm" replace>
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Associated Beneficiary
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Code: <span className="color-amber">*</span></label>
                        <div>
                          <div className="">
                            <input
                              type="text"
                              className={`form-control  w-100 ${codeError ? 'required-border' : ''
                                }`}
                              value={code}
                              onChange={(e) => {

                                var cleaned = e.target.value;
                                if (cleaned.length == 3 && !cleaned.includes("-")) {
                                  cleaned = cleaned + "-";
                                }
                                else if (cleaned.length > 7) {
                                  cleaned = cleaned.substring(0, 7);
                                }
                                changeCodeValue(cleaned.toUpperCase())
                              }} />


                          </div>
                          <div className="d-flex">
                            <p style={{ fontSize: '11px' }}>
                              Code Pattern (AAA-999)
                            </p>
                            {codeError ? (
                              <span
                                className="ml-auto"
                                style={{ fontSize: '11px' }}
                              >
                                {codeError}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>

                        </div>
                      </div>
                      {/* <Row>
                    <Col>
                              
                    </Col>
                    <Col>
                              
                    </Col>
                  </Row> */}
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Company Name:  <span className="color-amber">*</span></label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${companyNameError ? 'required-border' : ''
                                  }`}
                                value={companyName}
                                onChange={(e) => {
                                  // eslint-disable-next-line 
                                  setCompanyName(e.target.value)
                                  // setCompanyNameError('');
                                }}
                              />
                              {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Registered Name: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${registeredNameError ? 'required-border' : ''
                                  }`}
                                value={registered_name}
                                onChange={(e) => {
                                  // eslint-disable-next-line 
                                  setRegisteredName(e.target.value),
                                    setRegisteredNameError('');
                                }}
                              />
                              {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Company Type: <span className="color-amber">*</span></label>
                            <div className="">
                              <select
                                className={`form-control  w-100 ${brokerTypeError ? 'required-border' : ''
                                  }`}
                                value={brokerType}
                                onChange={(e) => {
                                  if (e.target.value === 'Primary Dealer') {
                                    validate('broker');
                                  }
                                  // eslint-disable-next-line 
                                  setBrokerType(e.target.value), setBrokerTypeError('');
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select Broker Type
                                </option>
                                {renderBrokerTypeDropdown()}
                              </select>
                              {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Revenue Authority: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <select
                                className={`form-control  w-100 ${revenueAuthorityError ? 'required-border' : ''
                                  }`}
                                value={revenueAuthority}
                                onChange={(e) => {
                                  // eslint-disable-next-line 
                                  setRevenueAuthority(e.target.value);
                                  setRevenueAuthorityError('');
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select Revenue Authority
                                </option>
                                <option value="FBR">FBR - Federal Board Of Revenue</option>
                                <option value="SRB">SRB - Sindh Revenue Board</option>
                                <option value="PRA">PRA - Punjab Revenue Authority</option>
                                <option value="BRA">BRA - Balochistan Revenue Authority</option>
                                <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>
                              </select>
                              {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="form-group">
                        <label>Payment Section: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                        <div className="">
                          <NumberFormat
                            thousandSeparator={true}
                            inputMode="numeric"
                            className={`form-control  w-100 text-right ${paymentSectionError ? 'required-border' : ''
                              }`}
                            value={payment_section}
                            onValueChange={(e) => {
                              setPaymentSection(e.value);
                              setPaymentSectionError('');
                            }}
                          />
                          {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <div className="form-group  ">
                            <label>CNIC: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div  >

                              <InputMask
                                className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                  }`}
                                mask="99999-9999999-9" value={cnic}
                                onChange={(e) => {
                                  setCnic(e.target.value.toUpperCase());
                                  setCnicError('');
                                }}>

                              </InputMask>

                              {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>NTN: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${ntnError ? 'required-border' : ''
                                  }`}
                                value={ntn}
                                onChange={(e) => {
                                  setNtn(e.target.value);
                                  setNtnError('');
                                }}
                              />
                              {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>City: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <select
                              className={`form-control w-100 
                        ${cityError ? 'required-border' : ''
                                }`}
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                                setCityError('');
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {' '}
                                Select City
                              </option>
                              {renderCitiesDropdown()}
                            </select>
                            {/* {cityError ? <p className="error-labels error-message2">{cityError}</p> : ''} */}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Address: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${addressError ? 'required-border' : ''
                                  }`}
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setAddressError('');
                                }}
                              />
                              {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Category: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <select
                                className={`form-control  w-100 ${categoryError ? 'required-border' : ''
                                  }`}
                                value={category}
                                onChange={(e) => {
                                  setCategory(e.target.value);
                                  setCategoryError('');
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select
                                </option>
                                <option value="FBR">INDIVIDUAL</option>
                                <option value="SRB">BUSINESS INDIVIDUAL</option>
                                <option value="PRA">AOP</option>
                                <option value="BRA">OTHER INDIVIDUAL</option>
                                <option value="KPRA">REGISTERED FIRM</option>
                                <option value="KPRA">COMPANY</option>
                                <option value="KPRA">WHA NTP</option>
                              </select>
                              {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Nature of Business: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${natureBusinessError ? 'required-border' : ''
                                  }`}
                                value={nature_of_bussiness}
                                onChange={(e) => {
                                  setNatureBusiness(e.target.value);
                                  setNatureBusinessError('');
                                }}
                              />
                              {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">

                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Bank Account Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Account Title: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${acc_titleError ? 'required-border' : ''
                              }`}
                            value={acc_title}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setAccTitle(e.target.value);
                                setAccTitleError('');
                              }
                            }}
                          />
                          {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>IBAN / Account No: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${ibanError ? 'required-border' : ''
                              }`}
                            value={iban}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setIban(e.target.value);
                                setIbanError('');
                              }
                            }}
                          />
                          {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Bank Name: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                        <div className="">
                          <select
                            className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                              }`}
                            value={bankname}
                            onChange={(e) => {
                              setBankNameError('');
                              setBankName(e.target.value);
                              getBranchByName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Bank
                            </option>
                            {renderBankNameDropdown()}
                          </select>
                          {/* {bankNameError ? <p className="error-labels error-message">{bankNameError}</p> : ''} */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Branch Name: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>

                        <input type="text" className={`form-control  w-100 ${branchNameError ? 'required-border' : ''
                          }`}
                          value={branchName}
                          onChange={(e) => {
                            setBranchNameError('');
                            setBranchName(e.target.value);
                          }}
                        />
                        {/* <div className="">	
                              <select	
                                className={`form-control  w-100 ${	
                                  bankNameError ? 'required-border' : ''	
                                }`}	
                                value={branchName}	
                                onChange={(e) => {	
                                  setBankNameError('');	
                                  setBranchName(e.target.value);	
                                }}	
                              >	
                                <option value="" defaultChecked hidden>	
                                {branchCheckFlag? 'No Branch Found' :'Select Branch Name'}	
                                </option>	
                                {renderBranchsDropdown()}	
                              </select>	
                            </div>	 */}
                      </div>
                      <div className="form-group">
                        <label>Active: {brokerType !== 'Primary Dealer' ? <span className="color-amber">*</span> : <span></span>}</label>
                        <select
                          className={`form-control  w-100 ${activeError ? 'required-border' : ''
                            }`}
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.value);
                            setActiveError('');
                          }}>
                          <option value="" hidden>Active / Deactive</option>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </select>

                      </div>
                    </CardBody>
                  </Card>


                </Col>

              </Row>

              <button
                className="btn btn-primary"
                onClick={addBrokerProcess}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Create</span>
                )}
              </button>

            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BrokerComponent;