import React, {useContext} from 'react';	
import { useState } from 'react';	
import { getAllBanks, getCities } from '../../stores/services/bank.service';		
import { getAmc } from '../../stores/services/amc.service';	
import { getFunds } from '../../stores/services/funds.service';	
import { getBranchByBankName } from '../../stores/services/branch.service';	
import { getNatureOfTx } from '../../stores/services/transactions.service';	
import { addUnitHolder } from '../../stores/services/unit-holder.service';	
import ReactTooltip from 'react-tooltip';	
import { getFundByAmc } from '../../stores/services/funds.service';	
import { BsPlusCircleFill } from 'react-icons/bs';	
import { Modal } from 'react-bootstrap';	
import { IoIosClose } from 'react-icons/io';	
import { Container, Row, Col, FormGroup, Label, Input, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';	
import moment from 'moment';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';	
import ToggleButton from 'react-toggle-button';
import { replace } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import {accountTitle_AccountNumber_Regex} from './../../utils/customFunction';
import { useHistory } from 'react-router-dom';
import {AMCContext} from './single-beta-txn';

const AddUnitHolder = ({setPropState}) => {	
  const history = useHistory();
  const amcCode = useContext(AMCContext);
  //hooks for getting all inputs from user	
  const [amcName, setAmcName] = useState('');	
  const [name, setName] = useState('');	
  const [folioNo, setFolioNo] = useState('');	
  const [cnic, setCnic] = useState('');	
  const [cnicExpiry, setCnicExpiry] = useState('');	
  const [ntn, setNtn] = useState('');	
  const [mainFolioNo, setMainFolioNo] = useState('');	
  const [taxStatus, setTaxStatus] = useState('');
  //	
  const [clientEmail, setClientEmail] = useState('');	
  const [mobile, setMobile] = useState('');	
  const [phone, setPhone] = useState('');	
  const [address, setAddress] = useState('');	
  const [city, setCity] = useState('');
  // const [filer, setFiler] = useState(false)	;
  // const updateFiler = () => setFiler(!filer)
  const [province, setProvince] = useState('');
  //	
  const [accTitle, setAccTitle] = useState('');	
  const [accNo, setAccNo] = useState('');	
  const [bankname, setBankName] = useState('');	
  const [branchName, setBranchName] = useState('');	
  //	
  const [blnUpdate, setBlnUpdate] = useState<any>();	
  const [reinvestCheck, setReinvestCheck] = useState(false);	
  const email = sessionStorage.getItem('email') || '';	
  const [fundLoading, setFundLoading] = useState<boolean>(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  const folioRegex = new RegExp('^[A-Z]{1,5}[A-Z]{1,5}-[0-9]{1,8}$');	
  // guardian
  const [guardian_name, setGuardian_name] = useState('');	
  const [guardian_relation, setGuardian_relation] = useState('');	
  const [guardian_cnic, setGuardian_cnic] = useState('');	
  // nominee
  const [nominee_name, setNomineeNname] = useState('');	
  const [nominee_relation, setNomineeRelation] = useState('');	
  const [nominee_cnic, setNomineeCnic] = useState('');	
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState('');	
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState('');	
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState('');	
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState('');
  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState('');	
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState('');	
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState('');	
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState('');
  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState('');	
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState('');	
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState('');	
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState('');
  const [portfolioManagement, setPortfolioManagement] = useState('');	

  const [cdc_IAS, setCDCIAS] = useState('');
  const [cdc_subAccount, setCDCSubAccount] = useState('');
  const [cdc_participantID, setCDCParticipantID] = useState('');


  const getfundByAmcCode = async (code: string) => {	
    allFunds.length = 0;	
    setAllFunds(allFunds);	
    //get funds by amc for dropdown	
    try {	
    //   const response = await getFundByAmc(email, code);	
      const response = await getFundByAmc(email, setPropState.amc_code);	
      setAllFunds(response.data.data);	
      if (response.data.data.length > 0) {	
        setFundLoading(false);	
      } else {	
        setFundLoading(true);	
      }	
    } catch (error) {}	
  };	
  //error getting hooks	
  const [accTitleError, setAccTitleError] = useState('');	
  const [accNoError, setAccNoError] = useState('');	
  const [bankNameError, setBankNameError] = useState('');	
  const [branchNameError, setBranchNameError] = useState('');	
  const [amcNameError, setAmcError] = useState('');	
  const [blnUpdateError, setBlnUpdateError] = useState('');	
  const [folioNoError, setFolioNoError] = useState('');	
  const [phoneError, setPhoneError] = useState('');	
  const [clientEmailError, setClientEmailError] = useState('');	
  const [cnicExpiryError, setCnicExpiryError] = useState('');	
  const [cnicError, setCnicError] = useState('');	
  const [ntnError, setNtnError] = useState('');	
  const [mobileError, setMobileError] = useState('');	
  const [addressError, setAddressError] = useState('');	
  const [cityError, setCityError] = useState('');	
  const [provinceError, setProvinceError] = useState('');	
  const [nameError, setNameError] = useState('');	
  const [Loading, setLoading] = useState(false);	
  //for dropdown data	
  const [amcdata, setAmcdata] = useState<any>([]);	
  const [bankNameData, setBankNameData] = useState<any>([]);	
  const [allbranchesData, setAllBranchesData] = useState<any>([]);	
  const [allFunds, setAllFunds] = useState<any>([]);	
  const [allTxData, setAllTxData] = useState<any>([]);	
  const [branchLoading, setBranchLoading] = useState<boolean>(false);	
  const [allCitiesData, setAllCitiesData] = useState<any>([]);	
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);	
  // const [amcCode, setAmcCode] = useState('');	
  const [nav, setNav] = useState('');	
  const getBranchByName = async (name: string) => {	
    setBranchLoading(true);	
    allbranchesData.length = 0;	
    setAllBranchesData(allbranchesData);	
    //get all branches for selected bank for dropdown	
    try {	
      const response = await getBranchByBankName(email, name);	
      setAllBranchesData(response.data.data);	
      if (response.data.data.length > 0) {	
        setBranchCheckFlag(false);	
      } else {	
        setBranchCheckFlag(true);	
      }	
    } catch (error) {}	
    setBranchLoading(false);	
  };	


  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const [father, setFather] = useState('');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(false);

  const [modalView, setModalView] = useState(false);	
  React.useEffect(() => {
    //   setPropState("USAMA");
    const fetchAmc = async () => {	
      amcdata.length = 0;	
      setAmcdata(amcdata);	
      bankNameData.length = 0;	
      setBankNameData(bankNameData);	
      //get all Amc for dropdown	
      try {	
        const amcResponse = await getAmc(email);	
        setAmcdata(amcResponse.data.data);	
      } catch (error) {}	
      //get all banks list for dropdown	
      try {	
        const response = await getAllBanks(email);	
        setBankNameData(response.data.data);	
      } catch (error) {}	
      try {	
        const response = await getCities();	
        setAllCitiesData(response.data.cities);	
      } catch (error) {}	
    };	
    fetchAmc();	
  }, []);	
  //render dropdown for amc data	
  const renderAmcDropdown = () => {	
    return amcdata.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.amc_code}>	
          {item.name} ({item.amc_code})	
        </option>	
      );	
    });	
    debugger	
  };	
  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {	
    return bankNameData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.bank_code.replace('BANK_','')}>	
          {item.bank_name} ({item.bank_code.replace('BANK_','')})	
        </option>	
      );	
    });	
  };	
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {	
    return allbranchesData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.branch_code}>	
          {item.branch_name} ({item.branch_code})	
        </option>	
      );	
    });	
  };	
  const renderFundsDropdown = () => {	
    return allFunds.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.symbol_code}>	
          {item.fund_name} ({item.symbol_code})	
        </option>	
      );	
    });	
  };	
  const renderCitiesDropdown = () => {	
    return allCitiesData.map((item: any) => {	
      return (	
        <option key={item} value={item}>	
          {item}	
        </option>	
      );	
    });	
  };	
  const validate = () => {	
    let accTitleErr,	
      nameErr,	
      accNoErr,	
      banknameErr,	
      branchNameErr,	
      amcErr,	
      cnicExpErr,	
      phoneErr = '';      	
    let folioErr,	
      cnicErr,	
      ntnErr,	
      mobileErr,	
      addressErr,	
      cityErr = '',
      provinceErr = '';	
    accTitle.trim() === '' ? (accTitleErr = 'Required') : (accTitleErr = '');	
    accNo.trim() === '' ? (accNoErr = 'Required') : (accNoErr = '');	
    bankname.trim() === '' ? (banknameErr = 'Required') : (banknameErr = '');	
    branchName.trim() === ''	
      ? (branchNameErr = 'Required')	
      : (branchNameErr = '');	
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');	
    folioNo.trim() === ''	? (folioErr = 'Required') : (folioErr = '');	;	
      // : folioRegex.test(folioNo) !== true	
      // ? (folioErr = 'Invalid')	
      // : (folioErr = '');	
    name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');	
    if (	
      accTitleErr ||	
      cnicExpErr ||	
      phoneErr ||	
      accNoErr ||	
      nameErr ||	
      branchNameErr ||	
      amcErr ||	
      folioErr ||	
      cnicErr ||	
      ntnErr ||	
      mobileErr ||	
      addressErr ||	
      cityErr ||
      provinceErr
    ) {	
      setAccTitleError(accTitleErr);	
      setPhoneError(phoneErr);	
      setCnicError(cnicExpErr);	
      setAccNoError(accNoErr);	
      setBankNameError(banknameErr);	
      setBranchNameError(branchNameErr);	
      setAmcError(amcErr);	
      setFolioNoError(folioErr);	
      setCnicError(cnicErr);	
      setNtnError(ntnErr);	
      setMobileError(mobileErr);	
      setAddressError(addressErr);	
      setCityError(cityErr);
      setProvinceError(provinceErr);	
      return true;	
    } else {	
      return true;	
    }	
  };	

  const [ type , setType ] = useState('');
  const addClients = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        if(folioNo===''){
          setLoading(false);
           return;
        }
        const response = await addUnitHolder(
          email,
        //   amcCode,
          amcCode,
          amcCode+'-'+folioNo,
          accNo,
          bankname,
          JSON.stringify(totalFunds),
          cnic,
          mobile,
          city,
          accTitle,
          branchName,
          amcName,
          ntn,
          address,
          name,
          mainFolioNo,
          phone,
          cnicExpiry,
          JSON.stringify(reinvestCheck),
          clientEmail,
          father,
          tax_exempt.toString(),
          zakat_exempt.toString(),
          type,
          guardian_name,
          guardian_relation,
          guardian_cnic,
          nominee_name,
          nominee_relation,
          nominee_cnic,
          joint_h_1_name,
          joint_h_1_father_spouse,
          joint_h_1_cnic,
          joint_h_2_name,
          joint_h_2_father_spouse,
          joint_h_2_cnic,
          joint_h_3_name,
          joint_h_3_father_spouse,
          joint_h_3_cnic,
          portfolioManagement,
          // JSON.stringify(filer),
          province,
          taxStatus,
          joint_h_1_taxStatus,
          joint_h_2_taxStatus,
          joint_h_3_taxStatus,
          cdc_IAS,
          cdc_subAccount,
          cdc_participantID,
        );
        if(response.data.status===200){
            setPropState({
                amc_code:amcCode,
                folioNo:amcCode+'-'+folioNo,
                unitholder:name,
                account_title:accTitle,
                account_no:accNo,
                bank:bankname,
                branch:branchName
            })
        // setCnicExpiry('');
        // setName('');
        // setType('')
        // setAccTitle('');
        // setAccNo('');
        // setPhone('');
        // setBankName('');
        // setBranchName('');
        // setAmcName('');
        // setReinvestCheck(false);
        // setBlnUpdate('');
        // setFolioNo('');
        // setCnic('');
        // setNtn('');
        // setMobile('');
        // setAddress('');
        // setClientEmail('');
        // totalFunds.length = 0;
        // setTotalFunds(totalFunds);
        // setMainFolioNo('');
        // setAmcName('');
        // setCity('');
        // setFather('');
        // setTax_exempt(false);
        // setZakat_exempt(false);
        // setGuardian_name('');
        // setGuardian_relation('');
        // setGuardian_cnic('');
        // setNomineeNname('');
        // setNomineeRelation('');
        // setNomineeCnic('');
        // setJointHolderOneName('');
        // setJointHolderOneFather('');
        // setJointHolderOneCnic('');
        // setJointHolderTwoName('');
        // setJointHolderTwoFather('');
        // setJointHolderTwoCnic('');
        // setJointHolderThreeName('');
        // setJointHolderThreeFather('');
        // setJointHolderThreeCnic('');
        // setPortfolioManagement('');
        // // setFiler(false);
        // setProvince('');
        // setTaxStatus('');
        // setJointHolderOneTaxStatus('');
        // setJointHolderTwoTaxStatus('');
        // setJointHolderThreeTaxStatus('');
        // setCDCIAS('');
        // setCDCSubAccount('');
        // setCDCParticipantID('');
        
        toast.success(response.data.message);
        }else{
          toast.error(response.data.message);
        }
      } catch (error:any) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };
  const [totalFunds, setTotalFunds] = useState<any>([]);	
  const [fundEntity, setFundEntity] = useState<any>({	
    fund_code: '',	
    fund_name: '',	
    nav: '',	
    fund_unit: '',	
  });		
  // ADD TAX Entity inside tax Array	
  const addFundInArray = async () => {	
    setDisableAddBtn(true);	
    let count = 0;	
    //push data inside array	
    for (let i in fundEntity) {	
      if (fundEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableAddBtn(false);	
    } else {	
      setModalView(false);	
      const check = totalFunds.filter((item) => {	
        return item.symbol_code === fundEntity['fund_code'];	
      });	
      if (check.length > 0) {	
        totalFunds.map((item, index) => {	
          if (item.symbol_code === fundEntity['fund_code']) {	
            item.fund_unit = (	
              +item.fund_unit + +fundEntity['fund_unit']	
            ).toString();	
            fundEntity['fund_name'] = item.fund_name;	
          }	
        });	
      } else {	
        totalFunds.push(fundEntity);	
        setTotalFunds(totalFunds);	
      }	
      const filedEntirty = {	
        fund_code: '',	
        fund_name: '',	
        nav: '',	
        fund_unit: '',	
      };	
      setFundEntity(filedEntirty);	
      setDisableAddBtn(false);	
    }	
  };	
  //render Fund table data	
  const renderFundDataInTable = () => {	
    return totalFunds.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.fund_code}</td>	
          <td>{items.fund_name}</td>	
          <td>{items.nav}</td>	
          <td>{items.fund_unit}</td>	
          {/* <td>{items.calculatedAmount}</td> */}	
          <td>	
            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...totalFunds];	
                array.splice(index, 1);	
                setTotalFunds(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	
  const changeCodeValue = (value: string) => {	
    setFolioNo(value);	
    if (value.trim() === '') {	
      setFolioNoError('Required');	
    } else {	
      folioRegex.test(value) !== true	
        ? setFolioNoError('Invalid')	
        : setFolioNoError('');	
    }	
  };	
  // render Model for adding Fund		
  const renderModalPopup = () => {		
    switch (modalView) {		
      case true:		
        return (		
          <Modal		
            className=""		
            dialogClassName="modal90w"		
            show={true}		
            size="lg"		
            backdrop={true}	
            enforceFocus={false}	
            onHide={() => {		
              setModalView(false);		
            }}		
          >	 	
            <div className="modal-header">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {		
                  setModalView(false);		
                  // clearTaxobject();		
                }}		
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">Add Fund</h6>	
            </div>	
            <div className="modal-body">		
              <div className="row">		
                <div className="col-sm-12">		
                <div className="form-group">		
                  <label>Fund Selection</label>		
                  <ReactTooltip		
                    textColor="white"		
                    backgroundColor="black"		
                    effect="float"		
                  />		
                  <select		
                    className="form-control"		
                    value={fundEntity['fund_code']}		
                    onChange={(e) => {		
                      let value = allFunds.filter((item: any) => {		
                          return item.symbol_code === e.target.value;		
                      })		
                      setFundEntity({		
                        ...fundEntity,		
                        fund_code: e.target.value,		
                        fund_name: value[0].fund_name,		
                        nav: value[0].nav,
                      });		
                    }}		
                  >		
                    <option value="" defaultChecked hidden>		
                      {fundLoading ? 'No Amc Fund Found' : 'Select Fund'}		
                    </option>		
                    {renderFundsDropdown()}		
                  </select>		
                </div>		
                </div>	
                <div className="col-md-12">
                <div className="form-group">
                  <div>NAV</div>
                  <input
                    type="number"
                    className="form-control w-100 "
                    value={fundEntity['nav']}
                    // value={nav}		
                    onChange={(e) => {		 
                      //setNav(e.target.value);
                      setFundEntity({		
                        ...fundEntity,		
                        nav: e.target.value,		
                      });	
                    }}	
                     
                  />
                </div>
                </div>
                <div className="col-sm-12">		
                  <div className="form-group">		
                    <div>Units</div>		
                    <input		
                      type="number"		
                      className="form-control w-100"		
                      value={fundEntity['fund_unit']}		
                      onChange={(e) => {
                        let value=decimalValue(e.target.value,4);		
                        setFundEntity({		
                          ...fundEntity,		
                          fund_unit: value,		
                        });		
                      }}		
                    />		
                  </div>		
                </div>		
                <div className="col-sm-12">		
                  <button		
                    className="btn btn-primary btn-block mt-2"		
                    onClick={() => {		
                      addFundInArray();		
                    }}		
                    disabled={Boolean(disableAddBtn)}		
                  >		
                    {disableAddBtn ? (		
                      <>		
                        <span		
                          className="spinner-border login-txt spinner-border-sm"		
                          role="status"		
                          aria-hidden="true"		
                        ></span>		
                        <span className="login-txt"> Loading...</span>		
                      </>		
                    ) : (		
                      <span>Add</span>		
                    )}		
                  </button>		
                </div>		
              </div>		
            </div>		
          </Modal>		
        );		
      default:		
        return '';		
    }		
  };		
  const borderRadiusStyle = { borderRadius: 2 };
  

  return (		
    <>		
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>		
        <ToastContainer limit={1} />				

        <div className="input-holder">		
              <h4 className="card-title">
                Add - Unit Holder</h4>		
            </div>	

            <Row>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Registration
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                  {/* <div className="form-group">		
                    <label>AMC Name  <span className="color-amber">*</span></label>		
                    <div  >		
                      <select		
                        className={`form-control  w-100 ${		
                          amcNameError ? 'required-border' : ''		
                        }`}		
                        defaultValue={amcName}		
                        onChange={(e) => {				
                          let value = amcdata.filter((item: any) => {		
                            return item.amc_code === e.target.value;		
                          });		
                          setAmcName(value[0].name);	
                          setAmcError('');	
                          getfundByAmcCode(e.target.value);	
                          setAmcCode(`${value[0].amc_code}`);	
                          setFolioNo(`${value[0].amc_code}-`);	
                        }}		
                      >		
                        <option value="" defaultChecked hidden>		
                          {' '}		
                          Select An AMC		
                        </option>		
                        {renderAmcDropdown()}		
                      </select>			
                    </div>		
                  </div>	 */}

                  <div className="form-group  ">		
                    <div> Folio No </div>		
                    <div>		
                      <div  >		
                        <input		
                          type="number"		
                          className={`form-control  w-100 ${		
                            folioNoError ? 'required-border' : ''		
                          }`}		
                        //   placeholder={value+'-00000'}
                          value={folioNo}		
                          onChange={(e) => {	 	
                            // var cleaned = e.target.value;	
                            // if(cleaned.length > 20){	
                            //   cleaned = cleaned.substring(0,20);	
                            //  }	
                            // changeCodeValue(e.target.value.toUpperCase());	
                            setFolioNo(e.target.value);	
                            setFolioNoError('');	
                          }}		
                        />		
                      </div>		
                      <div className="d-flex">		 
                        {folioNoError ? (		
                          <span		
                            className="ml-auto"		
                            style={{ fontSize: '11px' }}		
                          >		
                            {folioNoError}		
                          </span>		
                        ) : (		
                          ''		
                        )}		
                      </div>		
                    </div>		
                  </div> 

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Investor Type</label>
                        <div>
                          <select
                            className={`form-control  w-100`}
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Type
                            </option>
                            <option value="Individual">Individual</option>
                            <option value="Corporate">Corporate</option>
                          </select>
                       </div>
                    </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Portfolio Management</label>
                         <div>
                          <select
                            className={`form-control  w-100`}
                            value={portfolioManagement}
                            onChange={(e) => {
                              setPortfolioManagement(e.target.value);
                            }}
                            
                          >
                            <option value="" defaultChecked hidden>
                              Fund
                            </option>
                            <option value="fund">Fund</option>
                            <option value="sma">SMA</option>
                          </select>
                      </div>
                </div>

                    </Col>
                  </Row>

                  

                <div className="form-group d-none  ">		
                    <label>Main Folio No (Optional)</label>		
                    <div  >		
                      <input		
                        type="text"		
                        className="form-control"		
                        value={mainFolioNo}		
                        onChange={(e) => {		
                          setMainFolioNo(e.target.value.toUpperCase());		
                        }}		
                      />		
                    </div>		
                  </div>

                  <div className="form-group">		
                     
                     <label>Tax Status</label>
                     <select
                     className="form-control"
                     value={taxStatus}
                     onChange={(e)=>{
                       setTaxStatus(e.target.value)
                     }}>
                       <option value="" hidden> Select Tax Type</option>
                       <option value="Filer">Filer</option>
                       <option value="Non-Filer">Non-Filer</option>
                     </select>
                 </div>

                  <div className="d-flex justify-content-between allign-flex"  >
                  
                        <div className="form-group d-flex mt-3"> 
                            <ToggleButton
                              className="mt-2 "
                              value={tax_exempt}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (tax_exempt) {
                                  setTax_exempt(false);
                                } else {
                                  setTax_exempt(true);
                                }
                              }}
                            /> 

                          <label className="mt-2 ml-1">Tax Exempt</label> 
                        </div> 
                        <div className="form-group d-flex mt-3"> 
                              <ToggleButton
                              value={zakat_exempt}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (zakat_exempt) {
                                  setZakat_exempt(false);
                                } else {
                                  setZakat_exempt(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Zakat Exempt</label> 
                      </div> 
                     
                  </div>
                  <div className="mt-3">
                      <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox"		
                              checked={reinvestCheck}		
                              onChange={(e) => {		
                                setReinvestCheck(!reinvestCheck);		
                              }} />		
                              <span className="form-check-sign" />		
                              Fund Dividend Reinvest		
                            </Label>		
                          </FormGroup>	
                      </div>
 
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                     <CardTitle tag="h4">
                     Personal Details
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                  <div className="form-group">		
                    <label>Client Name <span className="color-amber">*</span> </label>		
                    <div>		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={name}		
                        onChange={(e) => {		
                          setName(e.target.value);		
                          setNameError('');		
                        }}		
                      />		
                      {/* {nameError ? <p className="error-labels error-message">{nameError}</p> : ''} */}		
                    </div>		
                  </div>   
                  {type === 'Individual' && (
                      <div className="form-group ">
                        <label>Father/Spouse Name</label>
                        <div>
                          <input
                            type="text"
                            className={'form-control  w-100'}
                            value={father}
                            onChange={(e) => {
                              setFather(e.target.value);
                            }}
                          />
                        </div>
                      </div>  
                  )}
                     {type === 'Individual' && (
                      <Row>
                        <Col md="12">
                          <div className="form-group  ">		
                            <label>CNIC</label>		
                            <div  >		

                        <InputMask  
                        className={`form-control  w-100 ${		
                                  cnicError ? 'required-border' : ''		
                                }`}
                                 mask="99999-9999999-9" value={cnic} onChange={(e) => {
                                  setCnic(e.target.value.toUpperCase());		
                                  setCnicError('');		
                                }}></InputMask>
                            </div>		
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="form-group  ">		
                            <label>CNIC Expiry</label>		
                            <div  >		
                              <input		
                                type="date"		
                                className={`form-control  w-100 ${		
                                  cnicExpiryError ? 'required-border' : ''		
                                }`}		
                                value={cnicExpiry}		
                                onChange={(e) => {		
                                  setCnicExpiry(e.target.value);		
                                  setCnicExpiryError('');		
                                }}		
                              />		
                              {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}		
                            </div>		
                          </div>
                        </Col>
                      </Row>
                        )} 

                      {type === 'Corporate' && (
                      <div className="form-group  ">		
                        <label>NTN</label>		
                        <div  >		
                          <input		
                            type="number"		
                            className={`form-control  w-100 ${		
                              ntnError ? 'required-border' : ''		
                            }`}		
                            value={ntn}		
                            onChange={(e) => {		
                              setNtn(e.target.value);		
                              setNtnError('');		
                            }}		
                          />		
                        </div>		
                      </div>
                      )}

                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                       Bank Account Detail
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                  <div className="form-group  ">		
                    <label>Account Title <span className="color-amber">*</span></label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accTitleError ? 'required-border' : ''		
                        }`}		
                        value={accTitle}		
                        onChange={(e) => {
                        if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                        {			
                          setAccTitle(e.target.value);		
                          setAccTitleError('');	
                        }	
                        }}		
                      />		
                      {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}		
                    </div>		
                  </div>

                  <div className="form-group  ">		
                    <label>Account No <span className="color-amber">*</span></label>		
                    <div  >		
                      <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          accNoError ? 'required-border' : ''		
                        }`}		
                        value={accNo}		
                        onChange={(e) => {
                        if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                        {			
                          setAccNo(e.target.value);		
                          setAccNoError('');	
                        }	
                        }}		
                      />		
                      {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}		
                    </div>		
                  </div>

                  <Row>
                  <Col md="6">
                    <div className="form-group  ">		
                      <label>Bank Name <span className="color-amber">*</span></label>		
                      <div  >		
                        <select		
                          className={`form-control  w-100 ${		
                            bankNameError ? 'required-border' : ''		
                          }`}		
                          value={bankname}		
                          onChange={(e) => {		
                            setBankNameError('');		
                            setBankName(e.target.value);		
                            getBranchByName(e.target.value);		
                          }}		
                        >		
                          <option value="" defaultChecked hidden>		
                            {' '}		
                            Select Bank		
                          </option>		
                          {renderBankNameDropdown()}
                          <option value="Other">Other</option>	
                        </select>		
                        {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}		
                      </div>		
                    </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group  ">		
                    <label>Branch Name <span className="color-amber">*</span></label>		
                    <input type="text" className={`form-control  w-100 ${		
                            bankNameError ? 'required-border' : ''		
                          }`}		
                          value={branchName}		
                          onChange={(e) => {		
                            setBankNameError('');		
                            setBranchName(e.target.value);		
                          }}
                    />
                    {/* <ReactTooltip		
                      textColor="white"		
                      backgroundColor="black"		
                      multiline={true}		
                      effect="float"		
                    />		
                    <div		
                      className=" "		
                      id="x"		
                      data-tip={`First Select Bank`}		
                    >		
                      {branchLoading ? (		
                        <div className="form-group">		
                          <div className="ml-2 form-control" >Branch Loading		
                             <i className="fa fa-spinner fa-spin fa-1x ml-2" aria-hidden="true"></i> 		
                          </div> 		
                        </div>		
                      ) : (		
                        
                        <select		
                          className={`form-control  w-100 ${		
                            bankNameError ? 'required-border' : ''		
                          }`}		
                          value={branchName}		
                          onChange={(e) => {		
                            setBankNameError('');		
                            setBranchName(e.target.value);		
                          }}		
                        >		
                          <option value="" defaultChecked hidden>		
                            {branchCheckFlag		
                              ? 'No Branch Found'		
                              : 'Select Branch Name'}		
                          </option>		
                          {renderBranchsDropdown()}		
                        </select>		
                      )}		 	
                    </div>		 */}
                  </div>
                  </Col>
                  </Row>
 
                  </CardBody>
                </Card>
              </Col> 

            </Row>

            <div className=" ">		
              <button		
                className="btn btn-primary "		
                onClick={addClients}		
                disabled={Boolean(Loading)}		
              >		
                {Loading ? (		
                  <>		
                    <span		
                      className="spinner-border login-txt spinner-border-sm"		
                      role="status"		
                      aria-hidden="true"		
                    ></span>		
                    <span className="login-txt"> Loading...</span>		
                  </>		
                ) : (		
                  <span>Create</span>		
                )}		
              </button>		
            </div>	
             	 
           	
            	 	
          {renderModalPopup()}		 
      </Container>		
      </Col>
        </Row>
      </div>
    </>		
  );		
};		
export default AddUnitHolder;