import moment from "moment";
import DCClogo from "../../assets/img/DCC-Logo.png";
import { inWords } from "../../stores/services/template.service";
import { isNumber, numberWithCommas } from "../../utils/customFunction";
import Config from "../../config";

export const mmMaturityOfTDRLetter = (
  transaction: any,
  securities: any,
  transType: any,
  amtWords: any,
  transNetAmount: any,
  transGrossAmount: any
) => {
  let date = new Date();
  let dateFormat = moment(date).format("LL");
  let q = "qrcodemmMaturityOfTDR";
  let id = `${Config.q_r_url}/admin/verify-transaction/` + transaction.txn_id;
  let txnId = transaction.txn_id;
  let branchName = "";
  let branchCity = "";
  //     if(transaction.branch != undefined){
  //         branchName = transaction.branch.split(',')[0];
  //         branchCity = transaction.branch.split(',')[1];
  //         branchName = branchCity === undefined ? branchName+',' : branchName;
  //         branchCity = branchCity === undefined ? '' : branchCity+',';
  // }
  if (transaction.branch != undefined) {
    if (transaction.branch.includes("–")) {
      branchName = transaction.branch.split("–")[0];
      branchCity = transaction.branch.split("–")[1];
      branchName =
        branchCity === undefined
          ? branchName.trim() + ","
          : branchName.trim() + ",";
      branchCity = branchCity === undefined ? "" : branchCity.trim() + ".";
    } else {
      branchName = transaction.branch + ",";
    }
  }

  setTimeout(() => {
    if (id != undefined) {
      var element = document.getElementById(q)!;
      if (element != null) {
        element.innerHTML = "";
      }
      var qrcode = new QRCode(document.getElementById(q), {
        width: 50,
        height: 50,
      });
      qrcode.makeCode(id);
    }
  }, 500);

//   let securityTypeData = securities.map((u) => u.security_type).join("|");
let securityTypeData = "TDR"



  let totalAmt = 0;

  const decimalValue = (value, len) => {
    let startingValue = value.toString().split('.')[0];
    let decimalVal = value.toString().split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const getData = (items) => {
    
   
   
    let rows = "";
    for (let i = 0; i < items.length; i++) {
      
      totalAmt += +isNumber(items[i].principal_amount) ;
      let maturityAmount = parseFloat((parseFloat(isNumber(items[i].principal_amount)) + (parseFloat(isNumber(items[i].principal_amount)) * (parseFloat(isNumber(items[i].yield))/365)* moment( items[i].maturity_date).diff(moment( items[i].issue_date),"days"))/100)?.toString() || "0").toFixed(2)
      let taxAmount = decimalValue(((+maturityAmount- +isNumber(items[i].principal_amount) ) * (+items[i].tax || 0)) /100,2)

      rows += ` <tr>

      <td style="border: 1px solid #333; padding:0 5px;">TDR</td>
      <td style="border: 1px solid #333; padding:0 5px;">${numberWithCommas(
        parseFloat(isNumber(items[i].principal_amount)).toFixed(2)
      )}</td>
            <td style="border: 1px solid #333; padding:0 5px;">${moment(
              items[i].issue_date
            ).format("DD/MM/YYYY")}</td>
            <td style="border: 1px solid #333; padding:0 5px;">${moment(
              items[i].maturity_date
            ).format("DD/MM/YYYY")}</td>
            
            <td style="border: 1px solid #333; padding:0 5px;">${parseFloat(isNumber(items[i].yield)).toFixed(2)}%</td>
            <td style="border: 1px solid #333; padding:0 5px;">${numberWithCommas(maturityAmount)}</td>
            <td style="border: 1px solid #333; padding:0 5px;">${numberWithCommas(taxAmount)} (${items[i].tax || "0"}%) </td> 
            <td style="border: 1px solid #333; padding:0 5px;">${numberWithCommas(+maturityAmount + +taxAmount)} </td> 
        </tr>`;
    }
    
    return rows;
  };

  const getTotalFaceValue = (items) =>
  {
    let total = 0
    for (let i = 0; i < items.length; i++) {
      
      total += +isNumber(items[i].principal_amount) ;
    }

    return total

  }


  return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Manager <br /> ${
              transaction.bank
            } <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <table style="width: 100%;"><tr>
            <td style="vertical-align:top;width:20px" ><b >Sub:</b></td>
            <td style="width:12px">&nbsp; &nbsp; </td>
            <td ><b >Maturity of ${
              securityTypeData === "TBILL" ? "T-Bill's" : securityTypeData
            } face value PKR.${numberWithCommas(
              getTotalFaceValue(securities.filter(item=>item.security_type == "TDR" && item.type == "Maturity"))
              )}/ ${transaction.account_title}.</b></td>
             </tr></table>
            
            <br />
            <div>Dear Sir/Madam,</div>    
            <br />
            <div>This is with reference to the above-mentioned subject, please be noted that  ${
              transaction.account_title 
            }  has matured ${
    securityTypeData === "TBILL" ? "T-Bill's" : securityTypeData
  } under following details.</div>
  <br />
            <table style="width: 100%; border: 1px solid #333;">
          
            <tr>
                <th style="border: 1px solid #333;padding:0 5px;"> Product    </th>
                <th style="border: 1px solid #333;padding:0 5px;"> Face Value    </th>
                <th style="border: 1px solid #333;padding:0 5px;">  Issue Date    </th>
                <th style="border: 1px solid #333;padding:0 5px;"> Maturity Date   </th>
                <th style="border: 1px solid #333;padding:0 5px;"> Interest Rate   </th> 
                <th style="border: 1px solid #333;padding:0 5px;"> Maturity Amount (PKR)    </th> 
                <th style="border: 1px solid #333;padding:0 5px;"> Tax Amount    </th> 
                <th style="border: 1px solid #333;padding:0 5px;"> Maturity After Tax   </th> 
            </tr>
 
                ${
                  transType === "debtmarketinvestment"
                    ? getData(securities.filter(item=>item.security_type == "TDR" && item.type == "Maturity"))
                    : ""
                }

            </table>
            <br />
            <div style="width: 100%;"> 
                You are requested to kindly mature the  ${
                  securityTypeData === "TBILL" ? "T-Bill's" : securityTypeData
                } and transfer the above mentioned maturity amount through RTGS 
                into our account number  ${
                  transaction.account_number
                }  
                titled  ${
                  transaction.account_title
                }   maintained with  ${transaction.bank}  ${
    branchCity
      ? branchName + " " + branchCity.replace(".", "")
      : transaction.branch
  }.
            </div>
            <br />
            <div >
               Thanking you with best regards,
            </div> 
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;"><b>Authorized Signatory</b></div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;"><b>Authorized Signatory</b></div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `;
};
